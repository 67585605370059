import { useAppLoading } from "contexts";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";
import { ShumokuManagementContent } from "../ShumokuManagementContent/ShumokuManagementContent";
import { ShumokuManagementSideBar } from "../ShumokuManagementSideBar/ShumokuManagementSideBar";
import { ShumokuManagementMainStyled } from "./ShumokuManagementMain.styled";

const ShumokuManagementMain = () => {
  const { startLoading, stopLoading } = useAppLoading();
  const { loading } = useAppSelector((state) => state.shumoku);
  const dispatch = useAppDispatch();

  useEffect(() => {
    loading ? startLoading() : stopLoading();
    return stopLoading;
  }, [loading]);

  useEffect(() => {
    dispatch(shumokuActions.getAllShumokus());
  }, []);
  return (
    <ShumokuManagementMainStyled>
      <ShumokuManagementSideBar />
      <ShumokuManagementContent />
    </ShumokuManagementMainStyled>
  );
};

export { ShumokuManagementMain };
