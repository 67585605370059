export const TEXT_TRANSLATION = {
  TITLE: "会員リスト",
  PENDING: "保留",
  TRAINING: "トレーニング中",
  LABEL_SEARCH_MEMBER: "Search by Member ID",
  LABEL_SEARCH_DATE: "Search by Date of birth",
  PLACEHOLDER_SEARCH_MEMBER: "会員IDを入力してください",
  PLACEHOLDER_SEARCH_DATE: "生年月日を選択してください",
  BACK: "戻る",
  OR: "又は",
  DUE_DATE: "トレーニング最終日:",
  MEMBER_ID: "会員ID:",
  BIRTHDAY: "誕生日:",
  REGISTRATION_DATE: "登録日:",
  NO_TRAINING_PROGRAM: "トレーニングメニューを持っていません",
  NEXT_PHASE: "次のアンケートへ進む",
};
