import images from "constants/images";
import { PATHNAME_LIST } from "constants/pathname";
import { ArrowLeftIcon, ArrowLeftShortIcon } from "constants/svgs";
import { useAppLocation } from "contexts";
import { useNavigate, useParams } from "react-router-dom";
import { SurveyRoute, TMemberAxtosParams } from "routers/router";
import {
  ActionsWrapperStyled,
  AppLogoStyled,
  ButtonBackStyled,
  ButtonLinkStyled,
  MemberIdStyled,
  MemberNewbieContainerStyled,
} from "./MemberNewbie.styled";

function MemberNewbiePage() {
  const { memberAxtosId = "" } = useParams<TMemberAxtosParams>();

  const navigate = useNavigate();
  const { backToPrevPage } = useAppLocation();

  const handleBackPage = () => {
    backToPrevPage(PATHNAME_LIST.MEMBER_CONFIRMATION);
  };

  const navigateToSurvey = () =>
    navigate(SurveyRoute.genPath(memberAxtosId), { replace: true });

  return (
    <MemberNewbieContainerStyled>
      <div className="overlay-bg">
        <ButtonBackStyled onClick={handleBackPage}>
          <ArrowLeftIcon /> 戻る
        </ButtonBackStyled>

        <AppLogoStyled src={images.AppLogoSecondary} />

        <MemberIdStyled>
          <span className="id-title">会員ID</span>
          <span className="member-id">{memberAxtosId}</span>
        </MemberIdStyled>

        <ActionsWrapperStyled>
          <ButtonLinkStyled onClick={navigateToSurvey}>
            <img className="action-icon" src={images.CheckListImage} />
            <p className="action-name">アンケート実施</p>
            <ArrowLeftShortIcon className="action-right" />
          </ButtonLinkStyled>
        </ActionsWrapperStyled>
      </div>
    </MemberNewbieContainerStyled>
  );
}

export { MemberNewbiePage };
