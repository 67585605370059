import styled from "styled-components";

export const StoreViewDetailStyled = styled.div`
  .ant-modal-header {
    font-weight: 600;
    font-size: 30px !importance;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    position: absolute;
    right: -14px;
  }
`;
