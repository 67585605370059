import { Button, Col, Row } from "antd";
import { RESPONSIVE_SIZE } from "pages/PublicMemberDetailPage/PublicMemberDetailPage.styled";
import styled from "styled-components";

export const StatisticWrapperStyled = styled.div`
  flex: 1;
  padding: 24px;
  padding-right: 36px;
  background-color: #f4f4f4;

  .main-content {
    margin-top: 24rem;
    margin-bottom: 8px;
  }

  .no-content {
    justify-content: center;
    padding-bottom: 24px;
  }

  @media only screen and (max-width: ${RESPONSIVE_SIZE}) {
    padding-right: 24px;

    .btn-back {
      position: absolute;
      z-index: 1;
      top: 25px;
      left: 24px;

      color: var(--color-white);
      fill: var(--color-white);
    }
  }
`;

export const SkeletonWrapper = styled(Row)`
  .ant-skeleton,
  .ant-skeleton-image {
    width: 100% !important;
    font-size: 32px;
  }

  .ant-skeleton-image.custom-chart-skeleton {
    height: 128px;
  }
`;

export const ButtonDetailStyled = styled(Button)`
  min-width: 104rem;

  background-color: #fff1e8;
  color: var(--color-primary);
  border: none;
`;

export const InfoSectionWrapperStyled = styled(Col)`
  .title {
    font-weight: 700;
    color: #b0b0b0;
    font-size: var(--font-size-md);
  }

  .section-content {
    margin-top: max(8px, 8rem);
  }

  .section-list {
    display: flex;
    flex-direction: column;
    gap: max(12px, 12rem);
  }
`;

export const ProgramActionWrapperStyled = styled.div`
  padding: 9px 16px;

  display: flex;
  align-items: center;
  gap: 8px;

  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius);
  background-color: #fff1e8;

  .icon {
    color: var(--color-primary);
    font-size: 24px;
  }

  .description {
    flex: 1;
    color: #000000d9;
  }
`;
