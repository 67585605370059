import { SpinProps } from "antd";
import { PropsWithChildren, createContext, useContext, useState } from "react";
import { SpinStyled } from "./LoadingContext.styled";

type TSpinProp = Omit<SpinProps, "spinning">;

const WRAPPER_SPIN_CLASS_NAME = "antd-spin-wrapper";

type TLoadingContext = {
  startLoading: (props?: TSpinProp) => void;
  stopLoading: () => void;
};

const initialLoadingValues: TLoadingContext = {
  startLoading: () => {
    //
  },
  stopLoading: () => {
    //
  },
};

const LoadingContext = createContext<TLoadingContext>(initialLoadingValues);

const useAppLoading = () => useContext(LoadingContext);

function AppLoadingProvider(props: PropsWithChildren) {
  const { children } = props;
  const [loading, setLoading] = useState(false);
  const [spinProps, setSpinProps] = useState<TSpinProp>();

  const startLoading = (props?: TSpinProp) => {
    setSpinProps(props);
    setLoading(true);
  };

  const stopLoading = () => setLoading(false);

  return (
    <LoadingContext.Provider
      value={{
        startLoading,
        stopLoading,
      }}
    >
      <SpinStyled
        {...spinProps}
        spinning={loading}
        wrapperClassName={WRAPPER_SPIN_CLASS_NAME}
      >
        {children}
      </SpinStyled>
    </LoadingContext.Provider>
  );
}

export { useAppLoading, AppLoadingProvider };
