import { ThunderboltFilled, ToolFilled } from "@ant-design/icons";
import { TProgramSv } from "model/program";
import { AppleIcon, ArmIcon, OtherIcon, PersonIcon } from "./svgs";
import { ReactElement } from "react";

export enum ProgramMenuType {
  Diet = 1,
  BodyMakeup = 2,
  BulkUp = 3,
  MuscleStrength = 4,
  HealthMaintenanceAndPromotion = 5,
  OTHER = 0,
}

export enum ProgramPhaseType {
  FirstPhase = 1,
  SecondPhase = 2,
  ThirdPhase = 3,
  FourthPhase = 4,
}

export enum FrequencyType {
  OneToThreeTimes = 1,
  FourOrMoreTimes = 2,
}

export enum DurationType {
  ThirtyMinutes = 1,
  FortyFiveMinutes = 2,
  SixtyMinutes = 3,
  MoreSixtyMinutes = 4,
}

export const PROGRAM_SURVEY_TYPES = {
  PHASE_NUMBER: "phaseNumber",
  FREQUENCY: "frequency",
  DURATION: "duration",
};

export const SURVEYS: TProgramSv[] = [
  {
    id: "1",
    title: "フェーズ",
    type: PROGRAM_SURVEY_TYPES.PHASE_NUMBER,
    options: [
      {
        id: "stage",
        name: "第1フェーズ",
        value: ProgramPhaseType.FirstPhase,
      },
      {
        id: "basal",
        name: "第2フェーズ",
        value: ProgramPhaseType.SecondPhase,
      },
      // {
      //   id: "period",
      //   name: "第3フェーズ",
      //   value: ProgramPhaseType.ThirdPhase,
      // },
      // {
      //   id: "strengthening",
      //   name: "第4フェーズ",
      //   value: ProgramPhaseType.FourthPhase,
      // },
    ],
  },
  {
    id: "2",
    title: "頻度",
    type: PROGRAM_SURVEY_TYPES.FREQUENCY,
    options: [
      {
        id: "1-3 times / week",
        name: "1週間に1~3回",
        value: FrequencyType.OneToThreeTimes,
      },
      {
        id: "4 times or more / week",
        name: "1週間に4回以上",
        value: FrequencyType.FourOrMoreTimes,
      },
    ],
  },
  {
    id: "3",
    title: "毎回のトレーニング時間",
    type: PROGRAM_SURVEY_TYPES.DURATION,
    options: [
      {
        id: "30 min / time",
        name: "30分",
        value: DurationType.ThirtyMinutes,
      },
      {
        id: "45 min / time",
        name: "45分",
        value: DurationType.FortyFiveMinutes,
      },
      {
        id: "60 min / time",
        name: "60分",
        value: DurationType.SixtyMinutes,
      },
      {
        id: "Over 60 min / time",
        name: "60分以上",
        value: DurationType.MoreSixtyMinutes,
      },
    ],
  },
];

export const PROGRAM_MENU_CONFIGS = {
  [ProgramMenuType.Diet]: {
    icon: <AppleIcon />,
    name: "ダイエット",
  },
  [ProgramMenuType.BodyMakeup]: {
    icon: <ThunderboltFilled />,
    name: "ボディメイク（シェイプアップ）",
  },
  [ProgramMenuType.BulkUp]: {
    icon: <ArmIcon />,
    name: "ボディメイク（筋肥大）",
  },
  [ProgramMenuType.MuscleStrength]: {
    icon: <PersonIcon />,
    name: "姿勢の改善",
  },
  [ProgramMenuType.HealthMaintenanceAndPromotion]: {
    icon: <ToolFilled />,
    name: "健康維持促進",
  },
  [ProgramMenuType.OTHER]: {
    icon: <OtherIcon />,
    name: "",
  },
};

export const PROGRAM_STEPS = {
  STEP_ONE: "0",
  STEP_TWO: "1",
  STEP_THREE: "2",
};

export type TProgramMenuType = {
  id: number;
  icon: ReactElement;
  name: string | ReactElement;
  value: number;
};

export const PROGRAM_MENU_TYPES: TProgramMenuType[] = [
  {
    id: ProgramMenuType.Diet,
    icon: <AppleIcon />,
    name: "ダイエット",
    value: ProgramMenuType.Diet,
  },
  {
    id: ProgramMenuType.BodyMakeup,
    icon: <ThunderboltFilled />,
    name: "ボディメイク（シェイプアップ）",
    value: ProgramMenuType.BodyMakeup,
  },
  {
    id: ProgramMenuType.BulkUp,
    icon: <ArmIcon />,
    name: "ボディメイク（筋肥大）",
    value: ProgramMenuType.BulkUp,
  },
  {
    id: ProgramMenuType.MuscleStrength,
    icon: <PersonIcon />,
    name: "姿勢の改善",
    value: ProgramMenuType.MuscleStrength,
  },
  {
    id: ProgramMenuType.HealthMaintenanceAndPromotion,
    icon: <ToolFilled />,
    name: "健康維持促進",
    value: ProgramMenuType.HealthMaintenanceAndPromotion,
  },
];
