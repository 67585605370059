export const TRANSLATION = {
  MAJOR_ITEM_MANAGEMENT: "大項目マスター管理",
  MAJOR_ITEM_CREATE: "大項目マスター追加",
  MAJOR_ITEM_NAME: "目名",
  MAJOR_ITEM_CODE: "コード",
  ACTION: "アクション",
  SAVE: "保存",
  BACK: "戻る",
  YES: "はい",

  CONFIRM_DELETE: "選択した種目を削除してもよろしいですか？",
  DELETE_SUCCESS_NOTIFICATION: "選択された種目は正常に削除されました。",
  UPDATE_SUCCESS_NOTIFICATION: "選択された種目は正常に更新されました。",

  MAJOR_ITEM_NAME_REQUIRED: "目名を入力してください。",
  MAJOR_ITEM_CODE_REQUIRED: "コードを入力してください。",
  MAJOR_ITEM_CODE_MAX_LENGTH: "コードが50文字以下の数字である必要。",
  CREATE_SUCCESS_NOTIFICATION: "大項目マスターは正常に追加されました。",

  MAJOR_ITEM_CODE_EXISTED: "コードが既に存在している。",
  MAJOR_ITEM_NAME_EXISTED: "目名が既に存在している。",
  MAJOR_ITEM_CODE_INVALID: "コード無効です。",
};
