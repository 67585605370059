import styled from "styled-components";
import { PolygonSixEdgeUrl, PolygonSixEdgeRedUrl } from "constants/svgs";

export const ProgramSurveyStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SurveyItemStyled = styled.div``;

export const SurveyWrapperStyled = styled.div`
&.hasError .radio-group {
  border: 1px solid red;
}



.error-message {
  color:#EE1C28;
  ;
  ;
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
}

  .item--1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .item--2 {
    display: flex;
    justify-content: space-between;
  }

  .item--3 {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .radio-container {
    flex-basis: calc(50% - 5px); /* Adjust the width of each button here */
    margin-bottom: 10px;
    .radio-item  {
      width:100%;
    }

    .ant-radio-inner::after {
      background-color: var(--color-white);
    }
  }

  .radio-item {

    border: 1px solid #e7e7e7;
    border-radius: 2px;
    padding: 15px 16px;
    background:: black; 
    margin-right: 0;
    &.selected {
        background: #FFF1E8;
        border: 1px solid #FF6B00;
        border-radius: 2px;
    }
  }
`;
export const SurveryItemTitleStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 20px 0;

  .shape {
    width: 50px;
    height: 50px;
    background: url(${PolygonSixEdgeUrl}) center no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    transition: background-color 0.3s ease;

    &.hasError {
      background: url(${PolygonSixEdgeRedUrl}) center no-repeat;
      color: #ff4d4f;
    }
  }

  .number {
    font-weight: 700;
    font-size: 16px;
    color: #1b1d26;
    display: inline-block;

    &.hasError {
      color: ff4d4f;
    }
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    color: #1b1d26;

    &.hasError {
      color: #ff4d4f;
    }
  }
`;
export const RadioItemStyled = styled.div``;

export const ProgramSurveyContainerStyled = styled.div`
  .btn-next {
    background: var(--color-primary);
    color: var(--color-white);
    width: 206px;
    margin-top: 60rem;
    margin-bottom: 48rem;
    padding: 8rem;
    height: 100%;

    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bolder);

    &:active {
      color: var(--color-white);
      background: var(--color-primary);
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
