import { ProgramEditingLayout } from "layouts/ProgramEditingLayout/ProgramEditingLayout";
import { FC } from "react";
import { ProgramDetailEditingMain } from "./components/ProgramDetailEditingMain/ProgramDetailEditingMain";

const TITLE = "トレーニングメニュー更新";
const ProgramDetailEditingPage: FC = () => {
  return (
    <ProgramEditingLayout headerTitle={TITLE}>
      <ProgramDetailEditingMain />
    </ProgramEditingLayout>
  );
};

export { ProgramDetailEditingPage };
