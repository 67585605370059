import { ClockCircleOutlined, StarOutlined } from "@ant-design/icons";
import { Col, Tabs } from "antd";
import { EmptyContent } from "components";
import { useAppLoading } from "contexts";
import { memberActions } from "features/member/memberSlice";
import { useAppSelector } from "hooks";
import {
  KEY_TAB,
  PAGINATION,
  STATUS,
} from "pages/MemberListPage/MemberListPage.const";
import {
  ListRowStyled,
  MemberListBySearchWrapperStyled,
  MemberListMainStyled,
  SearchResultContainer,
} from "pages/MemberListPage/MemberListPage.styled";
import { TEXT_TRANSLATION } from "pages/MemberListPage/MemberListPage.translate";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { RootState } from "redux/store";
import { TTabItem } from "types/antd";
import { MemberItemForward } from "../MemberItem/MemberItem";

function useFirstRender() {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  });

  return isFirstRender.current;
}

function NotFoundMember() {
  return (
    <EmptyContent
      showLogo
      title="会員が見つかりませんでした 。"
      style={{ padding: "82rem", overflowY: "auto", flex: 1 }}
    />
  );
}

const MemberListMain: FC = () => {
  const dispatch = useDispatch();
  const {
    membersPending,
    membersTraining,
    allMembers,
    hasPendingMembers,
    hasMore,
    loading,
    isOnChangeValue,
    isNotBothTabData,
  } = useSelector((state: RootState) => state.member);

  const user = useAppSelector((state) => state.auth.user);

  const [searchParams, _setSearchParams] = useSearchParams();
  const memberId = searchParams.get("memberId");
  const birthDay = searchParams.get("birth");

  const observer = useRef<any>();
  const [pageNumber, setPageNumber] = useState(PAGINATION.PAGE_NUMBER);
  const isFirstRender = useFirstRender();
  const [activeTab, setActiveTab] = useState("");
  const isSearch = useRef(true);

  const { startLoading, stopLoading } = useAppLoading();

  const lastMemberItemRef = useCallback(
    (node: any) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading]
  );

  const renderMembersPending = useMemo(() => {
    return (
      <ListRowStyled gutter={[20, 20]}>
        {membersPending?.length > 0 ? (
          membersPending?.map((item: any, index: number) => {
            if (membersPending?.length === index + 1) {
              return (
                <Col key={index} xs={24} sm={12} xl={8}>
                  <MemberItemForward ref={lastMemberItemRef} item={item} />
                </Col>
              );
            } else {
              return (
                <Col key={index} xs={24} sm={12} xl={8}>
                  <MemberItemForward item={item} />
                </Col>
              );
            }
          })
        ) : (
          <NotFoundMember />
        )}
      </ListRowStyled>
    );
  }, [membersPending, activeTab]);

  const renderMembersTraining = useMemo(() => {
    return (
      <ListRowStyled gutter={[20, 20]}>
        {membersTraining?.length > 0 ? (
          membersTraining?.map((item, index) => {
            if (membersTraining?.length === index + 1) {
              return (
                <Col key={index} xs={24} sm={12} xl={8}>
                  <MemberItemForward ref={lastMemberItemRef} item={item} />
                </Col>
              );
            } else {
              return (
                <Col key={index} xs={24} sm={12} xl={8}>
                  <MemberItemForward item={item} />
                </Col>
              );
            }
          })
        ) : (
          <NotFoundMember />
        )}
      </ListRowStyled>
    );
  }, [membersTraining, activeTab]);

  const renderSearchedMembers = useMemo(() => {
    if (!allMembers || allMembers.length === 0) {
      return (
        <MemberListBySearchWrapperStyled>
          <NotFoundMember />
        </MemberListBySearchWrapperStyled>
      );
    }

    return (
      <MemberListBySearchWrapperStyled>
        <ListRowStyled gutter={[20, 20]}>
          {allMembers?.map((item: any, index: number) => {
            if (allMembers?.length === index + 1) {
              return (
                <Col key={index} xs={24} sm={12} xl={8}>
                  <MemberItemForward ref={lastMemberItemRef} item={item} />
                </Col>
              );
            } else {
              return (
                <Col key={index} xs={24} sm={12} xl={8}>
                  <MemberItemForward item={item} />
                </Col>
              );
            }
          })}
        </ListRowStyled>
      </MemberListBySearchWrapperStyled>
    );
  }, [allMembers]);

  const items: TTabItem[] = [
    {
      key: KEY_TAB.PENDING,
      label: (
        <>
          <ClockCircleOutlined />
          {TEXT_TRANSLATION.PENDING}
        </>
      ),
      children: renderMembersPending,
    },
    {
      key: KEY_TAB.TRAINING,
      label: (
        <>
          <StarOutlined />
          {TEXT_TRANSLATION.TRAINING}
        </>
      ),
      children: renderMembersTraining,
    },
  ];

  const onChange = (key: string) => {
    dispatch(memberActions.resetMembers());
    isSearch.current = false;
    setActiveTab(key);
    setPageNumber(PAGINATION.PAGE_NUMBER);
  };

  useEffect(() => {
    if (isNotBothTabData) {
      setActiveTab(KEY_TAB.PENDING);
    }
  }, [isNotBothTabData]);

  useEffect(() => {
    setPageNumber(PAGINATION.PAGE_NUMBER);
  }, [isOnChangeValue]);

  useEffect(() => {
    hasPendingMembers
      ? setActiveTab(KEY_TAB.PENDING)
      : setActiveTab(KEY_TAB.TRAINING);
  }, [hasPendingMembers]);

  // Search members
  useEffect(() => {
    if (isFirstRender) return;
    isSearch.current = true;

    if ((memberId || birthDay) && isSearch.current) {
      dispatch(
        memberActions.getMembers({
          data: {
            StoreNumber: user?.store?.storeNumber,
            Status: STATUS.ALL,
            PageNumber: pageNumber,
            PageSize: PAGINATION.PAGE_SIZE,
            MemberId: memberId || "",
            Birth: birthDay || "",
          },
        })
      );
      dispatch(memberActions.setIsOnChangeValue({ data: false }));
    }
  }, [memberId, birthDay, pageNumber]);

  // Filter members status
  useEffect(() => {
    if (memberId || birthDay) return;
    isSearch.current = false;

    if (
      activeTab === KEY_TAB.PENDING &&
      !isSearch.current &&
      !memberId &&
      !birthDay
    ) {
      dispatch(
        memberActions.getMembers({
          data: {
            StoreNumber: user?.store?.storeNumber,
            IsStore: true,
            Status: STATUS.PENDING,
            PageNumber: pageNumber,
            PageSize: PAGINATION.PAGE_SIZE,
          },
        })
      );
    } else if (
      !hasPendingMembers ||
      (activeTab === KEY_TAB.TRAINING &&
        !isSearch.current &&
        !memberId &&
        !birthDay)
    ) {
      dispatch(
        memberActions.getMembers({
          data: {
            StoreNumber: user?.store?.storeNumber,
            IsStore: true,
            Status: STATUS.TRAINING,
            PageNumber: pageNumber,
            PageSize: PAGINATION.PAGE_SIZE,
          },
        })
      );
    }
  }, [activeTab, pageNumber, memberId, birthDay]);

  // Handle loading
  useEffect(() => {
    if (loading) {
      startLoading();
    } else {
      stopLoading();
    }
    return () => {
      stopLoading();
    };
  }, [loading]);

  const isSearchingMode = memberId || birthDay;

  return (
    <MemberListMainStyled>
      {isSearchingMode ? (
        <SearchResultContainer>{renderSearchedMembers}</SearchResultContainer>
      ) : (
        <Tabs activeKey={activeTab} onChange={onChange} items={items} />
      )}
    </MemberListMainStyled>
  );
};

export { MemberListMain };
