import { Col, Row, Select } from "antd";
import { HeaderRender } from "antd/es/calendar/generateCalendar";
import { DATE_FORMAT, MONTH_OPTIONS, YEAR_OPTIONS } from "constants/common";
import dayjs, { Dayjs } from "dayjs";
import { dayjsLocalFromUTC } from "helpers";
import { THistoryCheckIn } from "model/member";
import { MEMBER_TRANSLATE } from "pages/MemberDetailPage/MemberDetailPage.translate";
import { Fragment, useState } from "react";
import {
  CalendarStyled,
  DateCellStyled,
  HeaderWrapperStyled,
} from "./TrainingCalendar.styled";

type TTrainingCalendarProps = {
  history?: THistoryCheckIn[];
  startDate?: string;
  lastCheckInDate?: string;
};

function checkIsCheckedIn(date: Dayjs, history: THistoryCheckIn[]) {
  for (let i = 0; i < history.length; i++) {
    if (
      dayjs(history[i].checkedInDate, { utc: true })
        .local()
        .isSame(date, "date")
    ) {
      return true;
    }
  }

  return false;
}

function TrainingCalendar(props: TTrainingCalendarProps) {
  const { history = [], startDate = dayjs(), lastCheckInDate } = props;

  const [currentDate, setCurrentDate] = useState(dayjs());

  const calendarHeader: HeaderRender<Dayjs> = ({ onChange, value }) => {
    return (
      <HeaderWrapperStyled>
        <div className="legend-wrapper">
          <p className="checked-in"> : 来店日</p>
          <div className="today"> : 今日</div>
        </div>

        <div className="panel-wrapper">
          <Select
            className="select-year"
            size="small"
            value={value.year()}
            options={YEAR_OPTIONS}
            onChange={(year) => onChange(value.year(year))}
          />
          <Select
            className="select-month"
            size="small"
            value={value.month()}
            options={MONTH_OPTIONS}
            onChange={(month) => onChange(value.month(month))}
          />
        </div>
      </HeaderWrapperStyled>
    );
  };

  const renderCell = (date: Dayjs) => {
    const isCheckedIn = checkIsCheckedIn(date, history);
    const isToday = dayjs().isSame(date, "date");

    return (
      <DateCellStyled
        className={isCheckedIn ? "checked-in" : isToday ? "today" : ""}
      >
        {date.date()}
      </DateCellStyled>
    );
  };

  return (
    <Fragment>
      <CalendarStyled
        value={currentDate}
        onChange={setCurrentDate}
        fullscreen={false}
        headerRender={calendarHeader}
        fullCellRender={renderCell}
      />

      <Row
        justify="space-between"
        gutter={[24, 24]}
        style={{ marginTop: "8px" }}
      >
        <Col>
          {MEMBER_TRANSLATE.START_TRAINING_DATE}:{" "}
          <strong>{dayjs(startDate).format(DATE_FORMAT)}</strong>
        </Col>
        {lastCheckInDate && (
          <Col>
            {MEMBER_TRANSLATE.END_TRAINING_DATE}:{" "}
            <strong>
              {dayjsLocalFromUTC(lastCheckInDate).format(DATE_FORMAT)}
            </strong>
          </Col>
        )}
      </Row>
    </Fragment>
  );
}

export { TrainingCalendar };
