import { EAccessRole, EUserRoles } from "model/user";
import { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "hooks";
import { DEFAULT_PATHNAME, PATHNAME_LIST } from "constants/pathname";

type TAuthRouteProps = {
  accessRoles: EUserRoles[] | EAccessRole;
  fallbackPath?: string;
};

function AuthRoute(props: PropsWithChildren<TAuthRouteProps>) {
  const { children, accessRoles, fallbackPath } = props;
  const location = useLocation();
  const user = useAppSelector((state) => state.auth.user);
  const { role: userRole } = user || {};

  const navigateProps = {
    replace: true,
    state: { from: location },
  };

  // Not has user info => login page
  if (!userRole) {
    return (
      <Navigate to={fallbackPath || PATHNAME_LIST.LOGIN} {...navigateProps} />
    );
  }

  // has access permission => access page
  if (accessRoles === EAccessRole.AllUsers || accessRoles.includes(userRole)) {
    return children as JSX.Element;
  }

  // Not has permission => default path
  return <Navigate to={fallbackPath || DEFAULT_PATHNAME} {...navigateProps} />;
}

export default AuthRoute;
