import { Col, Row } from "antd";
import { CUSTOM_TEXT_GRADIENT_CLASS_NAME } from "constants/common";
import images from "constants/images";
import {
  InfoGradientIcon,
  ProfileGradientIcon,
  RightGradientIcon,
  UserGradientIcon,
} from "constants/svgs";
import { useAppSelector } from "hooks";
import { EUserRoles } from "model/user";
import { Link } from "react-router-dom";
import { PATHNAME_LIST } from "constants/pathname";
import { ButtonLinkStyled, HeaderStyled } from "./HeaderHomePage.styled";

function HeaderHomePage() {
  const { store, role } = useAppSelector((state) => state.auth.user) || {};

  const isHeadquarterAdmin = role === EUserRoles.HEADQUARTER;

  const MENU_ITEMS = [
    {
      label: "会員リスト",
      pathname: PATHNAME_LIST.MEMBER_LIST,
      icon: <ProfileGradientIcon />,
    },
    {
      label: "会員登録",
      pathname: PATHNAME_LIST.MEMBER_CONFIRMATION,
      icon: <InfoGradientIcon />,
    },
    {
      label: "設定管理",
      pathname: PATHNAME_LIST.PERSONAL_INFO,
      icon: <UserGradientIcon />,
    },
  ];

  const welcomeText = isHeadquarterAdmin
    ? "アクトス本部へようこそ！"
    : `${store?.storeName || "Admin"}へようこそ！`;

  return (
    <HeaderStyled>
      <Row className="menu-wrapper">
        <Col span={24}>
          <img src={images.AppLogoSecondary} alt="logo" className="app-logo" />
          <p className={`welcome-text ${CUSTOM_TEXT_GRADIENT_CLASS_NAME}`}>
            {welcomeText}
          </p>
          {!isHeadquarterAdmin && (
            <p className="store-number">店番: {store?.storeNumber || "-"}</p>
          )}
        </Col>

        {/* Menu list */}
        <Col xs={24} sm={12} md={12}>
          <div className="menu-list">
            {MENU_ITEMS.map((item, index) => {
              return (
                <Link to={item.pathname} key={index}>
                  <ButtonLinkStyled>
                    <span
                      className={`menu-item-icon ${CUSTOM_TEXT_GRADIENT_CLASS_NAME}`}
                    >
                      {item.icon} <div className="gradient-overlay"></div>
                    </span>

                    <span className="menu-item-label">{item.label}</span>
                    <span className="menu-item-redirect">
                      <RightGradientIcon />
                    </span>
                  </ButtonLinkStyled>
                </Link>
              );
            })}
          </div>
        </Col>
      </Row>
    </HeaderStyled>
  );
}

export { HeaderHomePage };
