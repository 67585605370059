import styled from "styled-components";

export const MemberConfirmedSurveyStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MemberConfirmedSurveyContainerStyled = styled.div`
  background: linear-gradient(91.78deg, #ffeccf 2.16%, #ffc165 103.68%);
  padding: 13px 24px;
`;

export const ButtonGroupStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 16px;

  .btn-item {
    flex: 1;
    background: rgba(255, 255, 255, 0.5);
    border: 0;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

    font-size: var(--font-size-md);
    font-weight: var(--font-weight-md);
  }
`;
