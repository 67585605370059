import styled from "styled-components";

export const LabelWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .label-icon {
    color: var(--color-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 22px;
      width: 22px;
    }
  }

  .label-text {
    color: var(--color-black-2);
  }

  .required-mark {
    color: #ff4d4f;
    font-size: 14px;
    font-weight: 500;
  }
`;
