import {
  DEFAULT_PATHNAME,
  PATHNAME_LIST,
  TITLE_LIST,
} from "constants/pathname";
import { LoginLayout, ResetPasswordLayout, SecondaryLayout } from "layouts";
import { EAccessRole, EUserRoles } from "model/user";
import {
  FAQPage,
  ForgotPasswordPage,
  HomePage,
  LoginPage,
  MemberBodyIndexPage,
  MemberConfirmation,
  MemberDetailPage,
  MemberListPage,
  MemberNewbiePage,
  PersonalInfoPage,
  ResetPasswordPage,
  SurveyDetailPage,
  SurveyEditPage,
  TrainingProgramDetailPage,
  SurveyPage,
  SurveyPhaseIntermediatePage,
  TermsAndConditionsPage,
  TrainingProgramPage,
  ShumokuManagementPage,
  SurveyManagementPage,
  PublicProgramPage,
  MenuTrainingEditingPage,
  ProgramDetailEditingPage,
} from "pages";
import { PublicMemberDetailPage } from "pages/PublicMemberDetailPage/PublicMemberDetailPage";
import { ShumokuMaster } from "pages/ShumokuMaster/ShumokuMaster";
import { Navigate } from "react-router-dom";
import { TRouteDynamicPathType, TRouteType } from "routers";

// =================================== PARAM TYPE ===================================
export type TMemberUrlParams = {
  memberId: string;
};

export type TSurveyDetailUrlParams = {
  memberId: string;
  phaseNumber: number;
  step?: number;
};

export type TSurveyEditUrlParams = {
  memberId: string;
  phaseNumber: number;
  step: number;
};

export type TMemberAxtosParams = {
  memberAxtosId: string;
};

export type TProgramUrlParams = {
  memberId: string;
  step?: number;
  phaseNumber: number;
};

export type TProgramDetailParams = {
  memberId: string;
  programId: string;
};

export type TSurveyManagementParams = {
  phaseNumber: number;
};

export type TProgramEditingParams = {
  memberId: string;
  programMemberId: string;
  phaseNumber: number;
};

// =================================== DEFINE ROUTE ===================================
// Define routes
export const HomeRoute: TRouteType = {
  id: "HomeRoute",
  path: PATHNAME_LIST.HOME,
  title: TITLE_LIST.HOME,
  component: HomePage,
  // layout: MainLayout,
  accessRoles: EAccessRole.AllUsers,
};

export const MemberConfirmationRoute: TRouteType = {
  id: "MemberConfirmationRoute",
  path: PATHNAME_LIST.MEMBER_CONFIRMATION,
  title: TITLE_LIST.MEMBER_CONFIRMATION,
  component: MemberConfirmation,
  layout: SecondaryLayout,
  accessRoles: EAccessRole.AllUsers,
};

export const MemberDetailsRoute: TRouteDynamicPathType<string> = {
  id: "MemberDetailsRoute",
  path: PATHNAME_LIST.MEMBER_DETAIL,
  title: TITLE_LIST.MEMBER_DETAIL,
  component: MemberDetailPage,
  accessHomePage: true,
  genPath: (memberId) =>
    PATHNAME_LIST.MEMBER_DETAIL.replace(":memberId", memberId),
  accessRoles: EAccessRole.AllUsers,
};

export const MemberNewbieRoute: TRouteDynamicPathType<string> = {
  id: "MemberMemberRoute",
  path: PATHNAME_LIST.MEMBER_NEWBIE,
  title: TITLE_LIST.MEMBER_NEWBIE,
  component: MemberNewbiePage,
  genPath: (memberAxtosId) =>
    PATHNAME_LIST.MEMBER_NEWBIE.replace(":memberAxtosId", memberAxtosId),
  accessRoles: EAccessRole.AllUsers,
};

export const MemberBodyIndexRoute: TRouteDynamicPathType<string> = {
  id: "MemberBodyIndexRoute",
  path: PATHNAME_LIST.MEMBER_BODY_INDEX,
  title: TITLE_LIST.MEMBER_BODY_INDEX,
  component: MemberBodyIndexPage,
  genPath: (memberId) =>
    PATHNAME_LIST.MEMBER_BODY_INDEX.replace(":memberId", memberId),
  accessRoles: EAccessRole.AllUsers,
  accessHomePage: true,
};

export const MemberListRoute: TRouteType = {
  id: "MemberListRoute",
  path: PATHNAME_LIST.MEMBER_LIST,
  title: TITLE_LIST.MEMBER_LIST,
  component: MemberListPage,
  accessRoles: EAccessRole.AllUsers,
};

export const SurveyRoute: TRouteDynamicPathType<string> = {
  id: "SurveyRoute",
  path: PATHNAME_LIST.SURVEY,
  title: TITLE_LIST.SURVEY,
  component: SurveyPage,
  accessRoles: EAccessRole.AllUsers,
  accessHomePage: true,
  genPath: (memberAxtosId) =>
    PATHNAME_LIST.SURVEY.replace(":memberAxtosId", memberAxtosId),
};

export const PersonalInfoRoute: TRouteType = {
  id: "PersonalInfoRoute",
  path: PATHNAME_LIST.PERSONAL_INFO,
  title: TITLE_LIST.PERSONAL_INFO,
  component: PersonalInfoPage,
  accessRoles: EAccessRole.AllUsers,
};

export const TrainingProgramRoute: TRouteDynamicPathType<TProgramUrlParams> = {
  id: "TrainingProgramRoute",
  path: PATHNAME_LIST.TRAINING_PROGRAM,
  title: TITLE_LIST.TRAINING_PROGRAM,
  component: TrainingProgramPage,
  accessRoles: EAccessRole.AllUsers,
  accessHomePage: true,
  genPath: ({ memberId, step, phaseNumber }) => {
    return `${PATHNAME_LIST.TRAINING_PROGRAM.replaceAll(
      ":memberId",
      memberId
    ).replaceAll(":phaseNumber", phaseNumber.toString())}?step=${step || 0}`;
  },
};

export const TrainingProgramDetailRoute: TRouteDynamicPathType<TProgramDetailParams> =
  {
    id: "TrainingProgramDetailRoute",
    path: PATHNAME_LIST.TRAINING_PROGRAM_DETAIL,
    title: TITLE_LIST.TRAINING_PROGRAM_DETAIL,
    component: TrainingProgramDetailPage,
    accessRoles: EAccessRole.AllUsers,
    accessHomePage: true,
    genPath: ({ memberId, programId }) => {
      const queryParams = { memberId, programId };
      const queryString = Object.entries(queryParams)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      return `${PATHNAME_LIST.TRAINING_PROGRAM_DETAIL}?${queryString}`;
    },
  };

export const ShumokuManagementRoute: TRouteType = {
  id: "ShumokuManagementRoute",
  path: PATHNAME_LIST.SHUMOKU_MANAGEMENT,
  title: TITLE_LIST.SHUMOKU_MANAGEMENT,
  component: ShumokuManagementPage,
  accessRoles: [EUserRoles.HEADQUARTER],
  accessHomePage: true,
};

export const ShumokuMasterManagementRoute: TRouteType = {
  id: "ShumokuMasterManagementRoute",
  path: PATHNAME_LIST.SHUMOKU_MASTER_MANAGEMENT,
  title: TITLE_LIST.SHUMOKU_MASTER_MANAGEMENT,
  component: ShumokuMaster,
  accessRoles: [EUserRoles.HEADQUARTER],
  accessHomePage: true,
};

export const SurveyPhaseIntermediateRoute: TRouteDynamicPathType<TMemberUrlParams> =
  {
    id: "SurveyPhaseIntermediateRoute",
    path: PATHNAME_LIST.SURVEY_PHASE_INTERMEDIATE,
    title: TITLE_LIST.SURVEY_PHASE_INTERMEDIATE,
    component: SurveyPhaseIntermediatePage,
    accessRoles: EAccessRole.AllUsers,
    accessHomePage: true,
    genPath: ({ memberId }) =>
      PATHNAME_LIST.SURVEY_PHASE_INTERMEDIATE.replace(":memberId", memberId),
  };

export const SurveyEditRoute: TRouteDynamicPathType<TSurveyEditUrlParams> = {
  id: "SurveyEditRoute",
  path: PATHNAME_LIST.SURVEY_EDIT,
  title: TITLE_LIST.SURVEY_EDIT,
  component: SurveyEditPage,
  accessRoles: EAccessRole.AllUsers,
  accessHomePage: true,
  genPath: (params) => {
    const path = PATHNAME_LIST.SURVEY_EDIT.replaceAll(
      ":memberId",
      params.memberId
    )
      .replaceAll(":phaseNumber", params.phaseNumber.toString())
      .replaceAll(":step", params.step.toString());

    return path;
  },
};

export const SurveyDetailRoute: TRouteDynamicPathType<TSurveyDetailUrlParams> =
  {
    id: "SurveyDetailRoute",
    path: PATHNAME_LIST.SURVEY_DETAIL,
    title: TITLE_LIST.SURVEY_DETAIL,
    component: SurveyDetailPage,
    accessHomePage: true,
    accessRoles: EAccessRole.AllUsers,
    genPath: (params) => {
      const path = PATHNAME_LIST.SURVEY_DETAIL.replaceAll(
        ":memberId",
        params.memberId
      );

      return `${path}?phaseNumber=${params.phaseNumber}&step=${
        params.step || 0
      }`;
    },
  };

export const SurveyManagementRoute: TRouteDynamicPathType<TSurveyManagementParams> =
  {
    id: "SurveyManagementRoute",
    path: PATHNAME_LIST.SURVEY_MANAGEMENT,
    title: TITLE_LIST.SURVEY_MANAGEMENT,
    component: SurveyManagementPage,
    accessRoles: [EUserRoles.HEADQUARTER],
    genPath: ({ phaseNumber }) => {
      return PATHNAME_LIST.SURVEY_MANAGEMENT.replaceAll(
        ":phaseNumber",
        phaseNumber.toString()
      );
    },
  };

export const MenuTrainingEditingRoute: TRouteDynamicPathType<TProgramEditingParams> =
  {
    id: "MenuTrainingEditingRoute",
    path: PATHNAME_LIST.MENU_TRAINING_EDITING,
    title: TITLE_LIST.MENU_TRAINING_EDITING,
    component: MenuTrainingEditingPage,
    accessRoles: EAccessRole.AllUsers,
    accessHomePage: true,
    genPath: ({ memberId, programMemberId, phaseNumber }) => {
      const queryParams = { memberId, programMemberId, phaseNumber };
      const queryString = Object.entries(queryParams)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      return `${PATHNAME_LIST.MENU_TRAINING_EDITING}?${queryString}`;
    },
  };

export const ProgramDetailEditingRoute: TRouteDynamicPathType<TProgramEditingParams> =
  {
    id: "ProgramDetailEditingRoute",
    path: PATHNAME_LIST.PROGRAM_DETAIL_EDITING,
    title: TITLE_LIST.PROGRAM_DETAIL_EDITING,
    component: ProgramDetailEditingPage,
    accessRoles: EAccessRole.AllUsers,
    accessHomePage: true,
    genPath: ({ memberId, programMemberId, phaseNumber }) => {
      const queryParams = { memberId, programMemberId, phaseNumber };
      const queryString = Object.entries(queryParams)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      return `${PATHNAME_LIST.PROGRAM_DETAIL_EDITING}?${queryString}`;
    },
  };

// Auth routes
export const LoginRoute: TRouteType = {
  id: "LoginRoute",
  path: PATHNAME_LIST.LOGIN,
  component: LoginPage,
  layout: LoginLayout,
  layoutProps: { title: "Login" },
  accessRoles: EAccessRole.UnLoginUser,
  title: TITLE_LIST.LOGIN,
};

export const ForgotPasswordRoute: TRouteType = {
  id: "ForgotPasswordRoute",
  path: PATHNAME_LIST.FORGOT_PASSWORD,
  component: ForgotPasswordPage,
  layout: LoginLayout,
  layoutProps: { title: "Forgot password" },
  title: TITLE_LIST.FORGOT_PASSWORD,
};

export const ResetPasswordRoute: TRouteType = {
  id: "ResetPasswordRoute",
  path: PATHNAME_LIST.RESET_PASSWORD,
  component: ResetPasswordPage,
  layout: ResetPasswordLayout,
  title: TITLE_LIST.RESET_PASSWORD,
};

// Public routes
export const TermsAndConditionsRoute: TRouteType = {
  id: "TermsAndConditionsRoute",
  path: PATHNAME_LIST.TERMS_AND_CONDITIONS,
  component: TermsAndConditionsPage,
  title: TITLE_LIST.TERMS_AND_CONDITIONS,
};

export const FQARoute: TRouteType = {
  id: "FAQRoute",
  path: PATHNAME_LIST.FAQ,
  component: FAQPage,
  title: TITLE_LIST.FAQ,
};

export const PublicProgramRoute = {
  id: "PublicProgramRoute",
  path: PATHNAME_LIST.PUBLIC_PROGRAM,
  title: TITLE_LIST.PUBLIC_PROGRAM,
  component: PublicProgramPage,
};

export const PublicMemberDetailsRoute = {
  id: "PublicMemberDetailsRoute",
  path: PATHNAME_LIST.PUBLIC_MEMBER_DETAIL,
  title: TITLE_LIST.MEMBER_DETAIL,
  component: PublicMemberDetailPage,
};

// =================================== EXPORT ===================================
export const appRoutes: TRouteType[] = [
  // Auth
  LoginRoute,
  ForgotPasswordRoute,
  ResetPasswordRoute,

  // Main function
  HomeRoute,
  MemberConfirmationRoute,
  MemberDetailsRoute,
  MemberBodyIndexRoute,
  MemberNewbieRoute,
  MemberListRoute,

  // Survey
  SurveyRoute,
  PersonalInfoRoute,
  SurveyPhaseIntermediateRoute,
  SurveyDetailRoute,
  SurveyEditRoute,
  SurveyManagementRoute,

  // Program
  TrainingProgramRoute,
  TrainingProgramDetailRoute,
  ShumokuManagementRoute,
  MenuTrainingEditingRoute,
  ProgramDetailEditingRoute,
  ShumokuMasterManagementRoute,

  // Public
  TermsAndConditionsRoute,
  FQARoute,
  PublicProgramRoute,
  PublicMemberDetailsRoute,

  // Others
  {
    id: "private-routes",
    path: "*",
    component: () => <Navigate to={DEFAULT_PATHNAME} />,
    title: TITLE_LIST.DEFAULT,
  },
];
