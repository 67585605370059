import { ReactNode } from "react";
import { LabelWrapperStyled } from "./FormLabel.styled";

type TFormLabelProps = { label: string; icon: ReactNode; required?: boolean };

function FormLabel(props: TFormLabelProps) {
  const { icon, label, required } = props;

  return (
    <LabelWrapperStyled>
      <span className="label-icon">{icon}</span>
      <span className="label-text">
        {label} {required && <span className="required-mark">*</span>}
      </span>
    </LabelWrapperStyled>
  );
}

export { FormLabel };
