import styled from "styled-components";

export const DietItemStyled = styled.div`
  border: 1px solid #e7e7e7;
  padding: 16rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  .name-item {
    display: flex;
    align-items: center;
    gap: 12rem;
    font-weight: var(--font-weight-md);
    font-size: var(--font-size-md);
  }

  .icon {
    color: var(--color-primary);
    background: #f5f5f5;
    border: 1px solid #e9e9e9;
    font-size: 25px;
    width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.active {
    border-radius: 2px;
    border: 1px solid #ff6b00;
    background: #fff1e8;

    .icon {
      color: var(--color-white);
      background: var(--color-primary);
      border: 0;

      svg {
        fill: var(--color-white);

        .cir {
          fill: var(--color-white);
        }

        .inner-cir {
          fill: var(--color-primary);
        }
      }
    }
  }
`;
