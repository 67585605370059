import { Form, Row } from "antd";
import { TBodyIndexInfo } from "model/member";
import styled from "styled-components";

export const FormStyled = styled(Form<TBodyIndexInfo>)`
  padding: 24rem;

  .ant-form-item {
    margin-bottom: 16px;

    .ant-form-item-label {
      font-weight: 500;
    }

    input {
      color: #000000d9;

      /* Chrome, Safari, Edge, Opera */
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .ant-input-suffix {
      color: #00000040;
    }
  }
`;

export const ActionWrapperStyled = styled(Row)`
  padding: 16rem 24rem;
  border-top: 1px solid #f0f0f0;
`;
