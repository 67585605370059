import { FC, ReactElement } from "react";
import { ComponentWrapperStyled } from "./DirectionComponent.styled";

type TProps = {
  name: string;
  iconPrev?: ReactElement;
  iconNext?: ReactElement;
  callback?: () => void;
  className?: string;
};

const DirectionComponent: FC<TProps> = ({
  name,
  iconPrev,
  iconNext,
  callback,
  className,
}) => {
  const handleRouteDirection = () => {
    callback?.();
  };
  return (
    <ComponentWrapperStyled
      className={className}
      onClick={handleRouteDirection}
    >
      {iconPrev}
      <span className="direct-name">{name}</span>
      {iconNext}
    </ComponentWrapperStyled>
  );
};

export { DirectionComponent };
