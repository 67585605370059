import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "configs/axiosInstance";
import dayjs from "dayjs";
import { TResponse } from "types/service";

const DATE_FORMAT_API = "YYYY-MM-DD";
const SURVEY_QUESTIONS = [
  "アクトスで運動しようと思ったきっかけを教えてください。",
  "以前通っていたクラブを退会された理由について教えてください。(「はい」回答の方)",
  "スタッフのサポートを希望しますか?",
  "希望する場合のみ回答ください。",
  "アクトスで運動する目的を教えてください。",
  "1週間にどれくらいの頻度で通えそうですか?",
  "1日の運動時間はどれくらいを予定していますか？",
  "利用曜日はいつごろを予定していますか？",
];
const statisticApi = {
  getHistoryCheckInByDay: async () => {
    const url = "get-history-check-in-by-day";
    const config: AxiosRequestConfig = {
      params: { date: dayjs().format(DATE_FORMAT_API) },
    };

    const response: TResponse<THistoryCheckInRes> = await axiosInstance.get(
      url,
      config
    );

    return response;
  },

  getHistoryCheckInByMonth: async () => {
    const url = "get-history-check-in-by-month";
    const config: AxiosRequestConfig = {
      params: { date: dayjs().format(DATE_FORMAT_API) },
    };

    const response: TResponse<THistoryCheckInRes> = await axiosInstance.get(
      url,
      config
    );

    return response;
  },

  getCheckInTrendingThisWeek: async () => {
    const url = "get-history-check-in-by-week";
    const config: AxiosRequestConfig = {
      params: { date: dayjs().format(DATE_FORMAT_API) },
    };

    const response: TResponse<TCheckInTrendingRes> = await axiosInstance.get(
      url,
      config
    );

    return response;
  },

  getCheckInTrendingAllTime: async (data: TFilterDashboardForStoreRequest) => {
    const url = "get-history-check-in-by-week-trending";

    const response: TResponse<TCheckInTrendingRes> = await axiosInstance.post(
      url,
      data
    );

    return response;
  },

  getStatisticalSurveyForStore: async (
    filterRequest: TFilterDashboardForStoreRequest
  ) => {
    const apiRequests = SURVEY_QUESTIONS.map(async (questionName) => {
      const url = "get-statistical-questions-for-store";
      const requestPayload = { questionName, filterRequest };
      const response: TResponse<TStatisticalSurveyByQuestion> =
        await axiosInstance.post(url, requestPayload);

      if (response.succeeded) {
        const data = response.data;
        return getStatisticalSurveyByQuestionFromResponse(data);
      }
    });

    // Use Promise.all to wait for all requests to complete
    const results = await Promise.all(apiRequests);

    const validResults = results.filter(
      (result) => result !== null
    ) as TStatisticalSurveyByQuestion[];

    return validResults;
  },

  getStatisticalSurveyForHeadQuarter: async (
    filterRequest: TFilterDashboardRequest
  ) => {
    const apiRequests = SURVEY_QUESTIONS.map(async (questionName) => {
      const url = "get-statistical-questions";
      const requestPayload = { questionName, filterRequest };
      const response: TResponse<TStatisticalSurveyByQuestion> =
        await axiosInstance.post(url, requestPayload);
      if (response.succeeded) {
        return getStatisticalSurveyByQuestionFromResponse(response.data);
      }
    });

    // Use Promise.all to wait for all requests to complete
    const results = await Promise.all(apiRequests);
    const validResults = results.filter(
      (result) => result !== null
    ) as TStatisticalSurveyByQuestion[];

    return validResults;
  },

  getTrainingStatusAnalysis: async (data: TFilterDashboardForStoreRequest) => {
    const url = "get-history-member-status";

    const response: TResponse<TTrainingStatusAnalysisRes> =
      await axiosInstance.post(url, data);

    return response;
  },

  getTrainingStatusAnalysisForHeadquarter: async (
    data: TFilterDashboardRequest
  ) => {
    const url = "get-history-member-status-with-axtos-headquarter";

    const response: TResponse<TTrainingStatusAnalysisRes> =
      await axiosInstance.post(url, data);

    return response;
  },

  getCheckInTrendingForHeadquarter: async (data: TFilterDashboardRequest) => {
    const url = "get-history-member-checkin-with-axtos-headquarter";

    const response: TResponse<TCheckInTrendingRes> = await axiosInstance.post(
      url,
      data
    );

    return response;
  },

  getPhaseStatusAnalysis: async (data: TFilterDashboardForStoreRequest) => {
    const url = "get-history-member-stage";

    const response: TResponse<TPhaseStatusAnalysisRes> =
      await axiosInstance.post(url, data);

    return response;
  },

  getPhaseStatusAnalysisForHeadquarter: async (
    data: TFilterDashboardRequest
  ) => {
    const url = "get-history-member-stage-with-axtos-headquarter";

    const response: TResponse<TPhaseStatusAnalysisRes> =
      await axiosInstance.post(url, data);

    return response;
  },
};

const getStatisticalSurveyByQuestionFromResponse = (
  data: TStatisticalSurveyByQuestion
) => {
  return {
    questionName: data.questionName,
    totalAnswer: data.totalAnswer,
    answers: data.answers.map((answer) => ({
      answerName: answer.answerName,
      quantityAnswer: answer.quantityAnswer,
      percentAnswer: answer.percentAnswer,
    })),
  };
};

type THistoryCheckInRes = {
  totalCheckIn: number;
  percent: number;
  checkInVolatility: number;
  status: number;
  date: string;
};

export type TCheckInTrendingRes = {
  sun: number;
  mon: number;
  tue: number;
  wed: number;
  thu: number;
  fri: number;
  sat: number;
};

export type TStatisticalAnswerByQuestion = {
  answerName: string;
  quantityAnswer: number;
  percentAnswer: number;
};

export type TStatisticalSurveyByQuestion = {
  questionName: string;
  totalAnswer: number;
  answers: TStatisticalAnswerByQuestion[];
};

export type TTrainingStatusAnalysisRes = {
  totalMember: number;
  other: number;
  // Active
  memberActive: number;
  percentMemberActive: number;
  // Inactive 2 weeks
  memberInactiveInTwoWeek: number;
  percentMemberInactiveInTwoWeek: number;
  // Inactive 1 month
  memberInactiveInMonth: number;
  percentMemberInactiveInMonth: number;
};

export type TPhaseStatusAnalysisRes = {
  totalMember: number;
  other: number;
  // Phase 1
  memberBeginner: number;
  percentMemberBeginner: number;
  // Phase 2
  memberIntermediate: number;
  percentMemberIntermediate: number;
};

export type TFilterDashboardRequest = {
  fromDate?: string;
  toDate?: string;
  date?: string;
  storeNumber: string[];
  gender: string;
  age: string[];
};

export type TFilterDashboardForStoreRequest = {
  fromDate?: string;
  toDate?: string;
  date?: string;
  gender: string;
  age: string[];
};

export type TFilterSurveyRequest = {
  fromDate?: string;
  toDate?: string;
  storeNumber: string[];
  phase: string;
};

export default statisticApi;
