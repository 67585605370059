import { FC } from "react";
import {
  ShumokuProvider,
  useShumokuContext,
} from "./ShumokuContext/ShumokuContext";
import { ShumokuManagementPageStyled } from "./ShumokuManagementPage.styled";

const ShumokusManagement: FC = () => {
  const { step } = useShumokuContext();
  return <ShumokuManagementPageStyled>{step}</ShumokuManagementPageStyled>;
};

const ShumokuManagementPage = () => {
  return (
    <ShumokuProvider>
      <ShumokusManagement />
    </ShumokuProvider>
  );
};

export { ShumokuManagementPage };
