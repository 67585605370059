import { useAppSelector } from "hooks";
import React, {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type TProgramContext = {
  sectionRefs?: any[];
  handleButtonClick?: (buttonIndex: number) => void;
  activeStep?: number;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
};

type TProps = {
  children: ReactElement;
};

export const ProgramContext = createContext<TProgramContext>({});

export const useProgramContext = () => useContext(ProgramContext);

const ProgramProvider: React.FC<TProps> = ({ children }) => {
  const sectionRefs = [1, 2, 3, 4, 5].map(() => useRef<any>());
  const [activeStep, setActiveStep] = useState<number>(1);
  const programDetail = useAppSelector((state) => state.program.programDetail);

  // Handlers
  const handleButtonClick = (buttonIndex: number) => {
    setActiveStep(buttonIndex);
    sectionRefs[buttonIndex - 1].current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  let debounceTimer: ReturnType<typeof setTimeout> | null = null;
  let programsElement: HTMLElement | null;
  const THRESHOLD = 100;
  const TIMER = 30;

  const handleScroll = () => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      let firstVisibleSectionIndex = -1;

      sectionRefs.forEach((sectionRef, index) => {
        const section = sectionRef.current;
        if (section) {
          const { top } = section.getBoundingClientRect();
          const containerTop =
            programsElement?.getBoundingClientRect().top || 0;

          if (top >= containerTop && top <= containerTop + THRESHOLD) {
            if (firstVisibleSectionIndex === -1) {
              firstVisibleSectionIndex = index;
            }
          }
        }
      });
      if (firstVisibleSectionIndex !== -1) {
        setActiveStep((prevActiveStep) => {
          const activeButtonIndex = firstVisibleSectionIndex + 1;
          if (prevActiveStep !== activeButtonIndex) {
            return activeButtonIndex;
          }
          return prevActiveStep;
        });
      }
    }, TIMER);
  };

  // Effects
  useEffect(() => {
    programsElement = document.querySelector(".program-list-container");

    if (programsElement) {
      programsElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (programsElement) {
        programsElement.removeEventListener("scroll", handleScroll);
      }
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, [sectionRefs, activeStep, programDetail]);

  return (
    <ProgramContext.Provider
      value={{
        sectionRefs,
        handleButtonClick,
        activeStep,
        setActiveStep,
      }}
    >
      {children}
    </ProgramContext.Provider>
  );
};

export { ProgramProvider };
