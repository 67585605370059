import { ACCESS_TOKEN_TEXT, REFRESH_TOKEN_TEXT } from "constants/common";
import { TTokenRes } from "features/auth/authApi";

type TAuthToken = Pick<TTokenRes, "accessToken" | "refreshToken">;

export function saveAuthToken({ accessToken, refreshToken }: TAuthToken) {
  localStorage.setItem(ACCESS_TOKEN_TEXT, accessToken);
  localStorage.setItem(REFRESH_TOKEN_TEXT, refreshToken);
}

export function getAuthToken(): TAuthToken {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_TEXT) || "";
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_TEXT) || "";

  return { accessToken, refreshToken };
}

export function removeAuthToken() {
  localStorage.removeItem(ACCESS_TOKEN_TEXT);
  localStorage.removeItem(REFRESH_TOKEN_TEXT);
}

export const setLocalStorageItems = (items: Record<string, string>): void => {
  Object.entries(items).forEach(([key, value]) => {
    localStorage.setItem(key, value);
  });
};

export const getLocalStorageItems = (
  keys: string[]
): Record<string, string | null> => {
  const items: Record<string, string | null> = {};

  keys.forEach((key) => {
    items[key] = localStorage.getItem(key);
  });

  return items;
};

export const removeLocalStorageItems = (keys: string[]): void => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};
