import styled from "styled-components";

export const CheckInBarChartWrapperStyled = styled.div`
  border: var(--border-config);
  background-color: var(--color-white);
  padding: 16px;

  .chart-title {
    margin-bottom: 20px;

    color: #000000d9;
    font-size: var(--font-size-md);
    font-weight: 500;
    line-height: 24px;
  }
`;
