import { authReducer } from "features/auth/authSlice";
import { memberReducer } from "features/member/memberSlice";
import { programReducer } from "features/program/programSlice";
import { shumokuReducer } from "features/shumoku/shumokuSlice";
import { combineReducers } from "redux";

export const rootReducer = combineReducers({
  auth: authReducer,
  member: memberReducer,
  program: programReducer,
  shumoku: shumokuReducer,
});
