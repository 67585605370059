import { Checkbox } from "antd";
import styled from "styled-components";

export const CheckBoxGroupStyled = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 8rem;

  &&& label {
    padding: 12rem 16rem;
    margin-inline-start: 0;

    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    font-size: var(--font-size-md);
    line-height: 24px;

    &.ant-checkbox-wrapper-checked {
      background-color: var(--color-selected);
      border-color: var(--color-primary);
      /* font-weight: 700; */
    }
  }
`;

export const CheckboxOtherStyled = styled(Checkbox)`
  width: 100%;
  padding: 12rem 16rem;
  margin-top: 8rem;

  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);

  span:last-child {
    flex: 1;
  }

  input {
    width: 100% !important;
    height: 24px;
    padding-inline: 0;
    padding: 0;

    border: none;
    border-bottom: 1px solid var(--color-border) !important;
    border-radius: 0;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;

    font-size: var(--font-size-md);
  }

  &.ant-checkbox-wrapper-checked {
    background-color: var(--color-selected);
    border-color: var(--color-primary);

    input {
      /* font-weight: 700; */
      border-bottom: 1px solid var(--color-primary) !important;
    }
  }
`;
