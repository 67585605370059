import dayjs from "dayjs";
import {
  TCheckInTrendingRes,
  TPhaseStatusAnalysisRes,
  TTrainingStatusAnalysisRes,
} from "features/statistic/statisticApi";
import {
  TCheckInBarChartValue,
  TMemberStatusDonutChartValue,
} from "model/statistic";

const WEEKDAY_CONFIGS = {
  MONDAY: {
    label: "月",
    weekday: 1,
    key: "mon",
  },
  TUESDAY: {
    label: "火",
    weekday: 2,
    key: "tue",
  },
  WEDNESDAY: {
    label: "水",
    weekday: 3,
    key: "wed",
  },
  THURSDAY: {
    label: "木",
    weekday: 4,
    key: "thu",
  },
  FRIDAY: {
    label: "金",
    weekday: 5,
    key: "fri",
  },
  SATURDAY: {
    label: "土",
    weekday: 6,
    key: "sat",
  },
  SUNDAY: {
    label: "日",
    weekday: 0,
    key: "sun",
  },
};

const START_DAY_OF_WEEK = WEEKDAY_CONFIGS.MONDAY;
const DAY_OF_WEEK = 7;

// Get a new weekday that depend on START_DAY_OF_WEEK
function getRealWeekday(weekday: number) {
  return (weekday - START_DAY_OF_WEEK.weekday + DAY_OF_WEEK) % DAY_OF_WEEK;
}

function getCheckInTotalForDay({
  total,
  weekday,
}: {
  total: number;
  weekday: number;
}) {
  const _realWeekday = getRealWeekday(weekday);
  const weekdayForCompare = getRealWeekday(dayjs().weekday());

  const isPassWeekday = _realWeekday <= weekdayForCompare;

  if (isPassWeekday) return total || 0;

  return undefined;
}

export function getReportByWeekdayForBarChart({
  report,
  onlyShowPassDay,
}: {
  report?: TCheckInTrendingRes;
  onlyShowPassDay?: boolean;
}): TCheckInBarChartValue[] {
  return Object.values(WEEKDAY_CONFIGS).map((day) => {
    let value: number | undefined =
      report?.[day.key as keyof TCheckInTrendingRes] || 0;
    if (onlyShowPassDay) {
      value = getCheckInTotalForDay({
        total: value,
        weekday: day.weekday,
      });
    }

    return {
      day: day.label,
      value,
    };
  });
}

export function getTrainingStatusAnalysisForDonutChart(
  report?: TTrainingStatusAnalysisRes
): TMemberStatusDonutChartValue[] {
  return [
    {
      status: "来館",
      value: report?.memberActive || 0,
      color: "#52C41A",
    },
    {
      status: "２週間未来館",
      value: report?.memberInactiveInTwoWeek || 0,
      color: "#FA8C16",
    },
    {
      status: "1ヶ月未来館",
      value: (report?.memberInactiveInMonth || 0) + (report?.other || 0),
      color: "#FA541C",
    },
  ];
}

export function getPhaseStatusAnalysisForDonutChart(
  report?: TPhaseStatusAnalysisRes
): TMemberStatusDonutChartValue[] {
  return [
    {
      status: "フェーズ1の会員",
      value: report?.memberBeginner || 0,
      color: "#40A9FF",
    },
    {
      status: "フェーズ2の会員",
      value: (report?.memberIntermediate || 0) + (report?.other || 0),
      color: "#FADB14",
    },
  ];
}
