import { CreateIcon } from "constants/svgs";
import { Fragment, FC } from "react";
import { ShumokuCreateButtonStyle } from "./ShumokuCreateButton.styled";

type TProps = {
  handleOpenCreateFormModal: () => void;
};

const ShumokuCreateButton: FC<TProps> = ({ handleOpenCreateFormModal }) => {
  const handleClick = () => {
    handleOpenCreateFormModal();
  };

  return (
    <Fragment>
      <ShumokuCreateButtonStyle onClick={handleClick}>
        <CreateIcon />
      </ShumokuCreateButtonStyle>
    </Fragment>
  );
};
export { ShumokuCreateButton };
