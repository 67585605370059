import { createSlice } from "@reduxjs/toolkit";
import { TUser } from "model/user";
import { TAction } from "types/common";

type stateType = {
  user: TUser | null;
  loading: boolean;
};

const initialState: stateType = {
  user: null,
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserInfo(state, action: TAction<TUser>) {
      state.user = action.payload.data;
      action.payload.onSuccess?.();
    },

    clearUserInfo(_state) {
      return initialState;
    },

    forgotPassword(state, _action) {
      state.loading = true;
    },
    forgotPasswordSuccess(state) {
      state.loading = false;
    },
    forgotPasswordFailure(state) {
      state.loading = false;
    },
    resetPassword(state, _action) {
      state.loading = true;
    },
    resetPasswordSuccess(state) {
      state.loading = false;
    },
    resetPasswordFailure(state) {
      state.loading = false;
    },
  },
});

const { actions, reducer } = authSlice;

export { actions as authActions, reducer as authReducer };
