export const FILTER_TRANSLATE = {
  GENDER: "性別",
  AGE: "年代",
  FILTER: "検 索",
  ALL: "全",
  TYPE_DATE: "期間指定",
  DATE: "期間設定",
  SELECT_TYPE_DATE: "日付の種類",
  WEEK: "週",
  MONTH: "月",
};
