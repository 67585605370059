import styled from "styled-components";

export const EmptyAnswerStyled = styled.div`
  padding: 12rem 16rem;

  display: flex;
  flex-direction: row;
  gap: 8px;

  background-color: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: var(--border-radius);
  font-size: var(--font-size-md);
  line-height: 24px;

  .icon-info {
    font-size: 18px;
    color: #bfbfbf;
  }
`;
