import { Form, Input } from "antd";
import { DirectionComponent } from "components";
import { DEFAULT_PATHNAME } from "constants/pathname";
import { ArrowLeftIcon } from "constants/svgs";
import { useAppLocation } from "contexts";
import { memberActions } from "features/member/memberSlice";
import { useDebounce } from "hooks";
import {
  FieldsWrapper,
  MemberListTopStyled,
  TopHeader,
} from "pages/MemberListPage/MemberListPage.styled";
import { TEXT_TRANSLATION } from "pages/MemberListPage/MemberListPage.translate";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavigateOptions, useSearchParams } from "react-router-dom";

const navigateOptions: NavigateOptions = {
  replace: true,
};

const MemberListForm: FC = () => {
  const [inputValue, setInputValue] = useState("");
  // const [date, setDate] = useState("");

  const [_searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const { backToPrevPage } = useAppLocation();
  const debouncedValue = useDebounce(inputValue, 300);

  const handleInputChange = (e: any) => {
    const newValue = e.target.value.trim();
    setInputValue(newValue);
  };

  // const onChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
  //   setDate(dateString);
  // };

  useEffect(() => {
    dispatch(memberActions.setIsOnChangeValue({ data: true }));
    dispatch(memberActions.resetMembers());

    if (debouncedValue) {
      setSearchParams({ memberId: debouncedValue }, navigateOptions);
    } else if (!debouncedValue) {
      setSearchParams({}, navigateOptions);
    } else {
      setSearchParams({ memberId: debouncedValue }, navigateOptions);
    }
  }, [debouncedValue]);

  // useEffect(() => {
  //   dispatch(memberActions.setIsOnChangeValue({ data: true }));
  //   dispatch(memberActions.resetMembers());

  //   if (debouncedValue && date) {
  //     setSearchParams(
  //       { memberId: debouncedValue, birth: date },
  //       navigateOptions
  //     );
  //   } else if (date) {
  //     setSearchParams({ birth: date }, navigateOptions);
  //   } else if (!debouncedValue) {
  //     setSearchParams({}, navigateOptions);
  //   } else {
  //     setSearchParams({ memberId: debouncedValue }, navigateOptions);
  //   }
  // }, [debouncedValue, date]);

  return (
    <MemberListTopStyled>
      <TopHeader>
        <DirectionComponent
          name={TEXT_TRANSLATION.BACK}
          iconPrev={<ArrowLeftIcon />}
          callback={() => backToPrevPage(DEFAULT_PATHNAME)}
        />
        <h2 className="title">{TEXT_TRANSLATION.TITLE}</h2>
      </TopHeader>
      <FieldsWrapper>
        <Form className="form-container">
          <Form.Item className="custom-form-item">
            <Input
              placeholder={TEXT_TRANSLATION.PLACEHOLDER_SEARCH_MEMBER}
              onChange={handleInputChange}
              allowClear
            />
          </Form.Item>
          {/* <span>{TEXT_TRANSLATION.OR}</span>
          <Form.Item className="custom-form-item">
            <DatePicker
              onChange={onChangeDate}
              className="date-field"
              placeholder={TEXT_TRANSLATION.PLACEHOLDER_SEARCH_DATE}
              format="YYYY/MM/DD"
            />
          </Form.Item> */}
        </Form>
      </FieldsWrapper>
    </MemberListTopStyled>
  );
};

export { MemberListForm };
