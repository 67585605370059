import { ProgramEditingLayout } from "layouts/ProgramEditingLayout/ProgramEditingLayout";
import { FC } from "react";
import { MenuTrainingMain } from "./components/MenuTrainingMain/MenuTrainingMain";
import { TRANSLATIONS } from "./MenuTrainingEditingPage.const";

const MenuTrainingEditingPage: FC = () => {
  return (
    <ProgramEditingLayout headerTitle={TRANSLATIONS.TITLE}>
      <MenuTrainingMain />
    </ProgramEditingLayout>
  );
};

export { MenuTrainingEditingPage };
