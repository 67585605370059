import styled from "styled-components";

export const StoreCreateButtonStyle = styled.div`
  position: fixed;
  bottom: 35px;
  right: 35px;

  cursor: pointer;
  display: grid;
  place-items: center;

  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;

  background: var(--color-bg-linear);
`;
