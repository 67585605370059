import { Form } from "antd";
import styled from "styled-components";
import { TSurveyFormValue } from "./SurveyForm";

export const FormContainerStyled = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
`;

export const FormStyled = styled(Form<TSurveyFormValue>)`
  padding: 32rem 24rem 0;

  overflow-y: auto;
  flex: 1;
  height: 100%;

  .ant-form-item {
    margin-bottom: 26px;

    .ant-form-item-explain-error {
      margin-bottom: 26rem;
      margin-top: 16rem;

      font-weight: 500;
      font-size: var(--font-size-md);
      color: var(--color-error);
    }
  }
`;

export const ButtonSubmitContainerStyled = styled.div`
  width: 100%;
  padding: clamp(16px, 35rem, 35px);
  padding-top: clamp(16px, 32rem, 32px);

  background-color: var(--color-white);

  button {
    width: 100%;
    max-width: 206px;

    display: block;
    margin: auto;
  }
`;
