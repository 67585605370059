import { Col, Row } from "antd";
import { TShumoku } from "model/program";
import { FC, useMemo } from "react";
import { ProgramVideoListStyled } from "./ProgramVideoList.styled";
import { useAppSelector } from "hooks";
import { FreeSelectComponent } from "../FreeSelectComponent/FreeSelectComponent";
import { ShumokuItem } from "components";

type TProps = {
  videos: TShumoku[];
  type?: string;
  isUpdate?: boolean;
};

const ProgramVideoList: FC<TProps> = ({ videos, type, isUpdate }) => {
  const { program } = useAppSelector((state) => ({
    program: state.program.programDetail,
    extensions: state.program.extensions,
  }));

  const calculateShumokus = useMemo(() => {
    const extensionsNumber = program?.shumokuExtensions?.length as number;
    const freeSelectionNumber = program?.numOfFreeSelection as number;

    return {
      totalRemainingShumoku:
        (freeSelectionNumber || 0) - (extensionsNumber || 0),
      extensionsNumber,
      freeSelectionNumber,
    };
  }, [program?.shumokuExtensions?.length, program?.numOfFreeSelection]);

  const componentsArray = Array.from(
    { length: calculateShumokus.totalRemainingShumoku as number },
    (_, index) => (
      <FreeSelectComponent
        key={index}
        freeId={index + calculateShumokus.extensionsNumber}
      />
    )
  );

  return (
    <ProgramVideoListStyled>
      <Row gutter={[20, 24]}>
        {videos.map((video, index) => (
          <Col xs={24} md={12} xl={8} key={index}>
            <ShumokuItem
              type={type}
              data={video}
              orderNumber={String(index + 1)}
              isUpdate={isUpdate}
            />
          </Col>
        ))}
        {componentsArray.length > 0 && isUpdate && componentsArray}
      </Row>
    </ProgramVideoListStyled>
  );
};
export { ProgramVideoList };
