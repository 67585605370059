import { Input } from "antd";
import styled from "styled-components";

const HexagonWidthPerHeight = 44 / 37;

type TQuestionWrapperStyled = {
  type: "default" | "locked";
};

export const QuestionWrapperStyled = styled.div`
  padding: 16px;
  border: 1px solid var(--color-primary);
  background-color: var(--color-white);

  .draggable-icon {
    width: fit-content;
    margin: auto;

    cursor: pointer;
    display: block;
  }

  .option-list {
    margin-top: 16px;
  }
`;

export const QuestionTitleStyled = styled.div<TQuestionWrapperStyled>`
  display: flex;
  align-items: center;
  gap: 8px 24px;
  color: ${(props: TQuestionWrapperStyled) =>
    props.type === "locked" ? "#bfbfbf" : "#1b1d26"};

  .question-title-wrapper {
    display: flex;
    align-items: baseline;
    flex: 1;

    .question-number-wrapper {
      width: 44px;
      height: calc(44px / ${HexagonWidthPerHeight});
      padding: 1px calc(1px * ${HexagonWidthPerHeight});

      flex: 0 0 44px;

      clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);

      background-color: #e7e7e7;

      .question-number {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 700;

        clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
        background-color: #f5f5f5;
      }
    }

    .question-title-edit,
    .question-title-view {
      margin-left: 8px;
      flex: 1;

      color: ${(props: TQuestionWrapperStyled) =>
        props.type === "locked" ? "#bfbfbf" : "#1b1d26"};
      font-weight: 700;
      font-size: var(--font-size-md);
    }

    .question-title-edit {
      width: 100%;

      flex: 1;
      border: none;
      border-bottom: 1px solid #17181d;
      border-radius: 0;
      outline: none;

      color: #1b1d26;
      font-weight: 700;
      font-size: var(--font-size-md);

      ::placeholder {
        font-weight: 400;
      }
    }

    .question-title-view {
      word-break: break-all;

      .required {
        color: ${(props: TQuestionWrapperStyled) =>
          props.type === "locked" ? "#bfbfbf" : "var(--color-secondary)"};
      }
    }

    .icon-lock {
      font-size: 22px;
      color: #595959;
    }
  }

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: stretch;

    .question-title-wrapper {
      .question-title-edit {
        margin-right: 0;
      }
    }

    .option-type-select {
      flex: 1;
    }
  }
`;

export const OptionActionsStyled = styled.div`
  margin-top: 16px;
`;

export const ActionsWrapperStyled = styled.div`
  min-height: 32px;
  margin-top: 16px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;

  .error-alert {
    flex: 1;
    color: var(--color-secondary);
    font-size: var(--font-size-md);
    line-height: 20px;
  }

  .divider {
    margin: 0;
    height: 32px;
    border-color: #00000040;
  }

  svg {
    font-size: 21px;
    color: var(--color-primary);
    cursor: pointer;
  }

  .required-toggle {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const QuestionTypeOptionStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const InputPlanTextStyled = styled(Input)`
  pointer-events: none;
`;
