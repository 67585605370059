export const TRANSLATIONS = {
  TITLE: "目的更新",
  MODAL_MESSAGE_TITLE: "選択内容を変更しました。",
  MODAL_OK: "終了 ",
};

export const PARAMS = {
  MEMBER_ID: "memberId",
  PROGRAM_MEMBER_ID: "programMemberId",
  PHASE_NUMBER: "phaseNumber",
};
