import styled from "styled-components";

export const NoContentContainerStyled = styled.div`
  min-height: 100vh;
  min-height: 100dvh;

  display: flex;
  justify-content: center;
  align-items: center;

  .btn-navigate {
    position: fixed;
    top: 24px;
    left: 24px;
  }

  .empty-content {
    padding: 32px;
  }
`;
