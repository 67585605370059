import { Button, Form, Input } from "antd";
import { FC, Fragment, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHNAME_LIST } from "constants/pathname";
import {
  ButtonWrapperStyled,
  EmailContainerStyled,
  LinkStyled,
} from "./ForgotPasswordPage.styled";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "features/auth/authSlice";
import { NotificationModal } from "components/NotificationModal/NotificationModal";
import { useAppLoading } from "contexts";
import { RootState } from "redux/store";
import MESSAGES from "constants/messages";

export const TEXT_TRANSLATION = {
  TITLE: "パスワードを忘れた場合",
  EMAIL_PLACEHOLDER: "メールアドレスを入力してください",
  VERIFY: "メールの確認",
  LOGIN: "ログイン",
  MODAL_BTN_TEXT: "ログインに戻る",
  MODAL_TITLE: "確認メールがあなたのメールに送付されました!",
};

const ForgotPasswordPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state: RootState) => state.auth.loading);

  const [form] = Form.useForm();
  const { startLoading, stopLoading } = useAppLoading();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const emailRef = useRef("");
  const [inputError, setInputError] = useState(false);

  isLoading ? startLoading() : stopLoading();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleVerifyEmail = () => {
    const email = form.getFieldValue("email").trim();
    emailRef.current = email;

    if (email) {
      dispatch(
        authActions.forgotPassword({
          email,
          onSuccess: () => showModal(),
          onError: () => setInputError(true),
        })
      );
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    setInputError(false);
  };

  const allowSubmit = () => {
    return email ? false : true;
  };

  const handleBtnClick = () => {
    navigate(PATHNAME_LIST.LOGIN);
  };

  const MESSAGE_CONTENT = `新しいパスワードを再設定するためのメールの確認が必要です。確認メールは <span class="message-content">${emailRef.current}</span> へ送信されました。`;
  return (
    <Fragment>
      <EmailContainerStyled>
        <h2 className="title">{TEXT_TRANSLATION.TITLE}</h2>
        <Form
          form={form}
          layout="vertical"
          size="large"
          onFinish={handleVerifyEmail}
        >
          <Form.Item
            name="email"
            validateStatus={inputError ? "error" : ""}
            help={inputError ? MESSAGES["SCR-W04-MSG-001"] : ""}
            className="custom-form-item"
          >
            <Input
              placeholder={TEXT_TRANSLATION.EMAIL_PLACEHOLDER}
              onChange={handleEmailChange}
              allowClear
            />
          </Form.Item>
          <Form.Item className="verify-button-wrapper">
            <ButtonWrapperStyled>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-item"
                disabled={allowSubmit()}
              >
                {TEXT_TRANSLATION.VERIFY}
              </Button>
              <LinkStyled to={PATHNAME_LIST.LOGIN}>
                {TEXT_TRANSLATION.LOGIN}
              </LinkStyled>
            </ButtonWrapperStyled>
          </Form.Item>
        </Form>
      </EmailContainerStyled>
      <NotificationModal
        open={isModalOpen}
        onCancel={handleCancel}
        btnContent={TEXT_TRANSLATION.MODAL_BTN_TEXT}
        messageContent={MESSAGE_CONTENT}
        title={TEXT_TRANSLATION.MODAL_TITLE}
        handleBtnClick={handleBtnClick}
      />
    </Fragment>
  );
};

export { ForgotPasswordPage };
