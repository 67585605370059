import {
  BranchesOutlined,
  ColumnHeightOutlined,
  FireOutlined,
  GoldOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import { FormItemProps } from "antd";
import { FormLabel } from "../FormLabel/FormLabel";
import MESSAGES from "constants/messages";
import { GLOBAL_TRANSLATE } from "constants/translate";

type TFormConfigs = {
  [key in
    | "WEIGHT"
    | "HEIGHT"
    | "BODY_FAT"
    | "PRESSURE"
    | "SYSTOLIC_PRESSURE"
    | "DIASTOLIC_PRESSURE"
    | "HEARTBEAT"]: FormItemProps & { unit?: string };
};

export const BODY_INDEX_FORM_CONFIGS: TFormConfigs = {
  WEIGHT: {
    name: "weight",
    label: (
      <FormLabel
        icon={<GoldOutlined />}
        label={GLOBAL_TRANSLATE.WEIGHT}
        required
      />
    ),
    unit: "kg",
    required: true,
    rules: [
      {
        required: true,
        message: MESSAGES["COM-MSG-001"](GLOBAL_TRANSLATE.WEIGHT),
      },
    ],
  },
  HEIGHT: {
    name: "height",
    label: (
      <FormLabel
        icon={<ColumnHeightOutlined />}
        label={GLOBAL_TRANSLATE.HEIGHT}
        required
      />
    ),
    unit: "cm",
    required: true,
    rules: [
      {
        required: true,
        message: MESSAGES["COM-MSG-001"](GLOBAL_TRANSLATE.HEIGHT),
      },
    ],
  },
  BODY_FAT: {
    name: "bodyFat",
    label: <FormLabel icon={<FireOutlined />} label="体脂肪" required />,
    unit: "%",
    required: true,
    rules: [
      {
        required: true,
        message: MESSAGES["COM-MSG-001"]("体脂肪"),
      },
    ],
  },
  PRESSURE: {
    label: <FormLabel icon={<BranchesOutlined />} label="血圧" />,
  },
  SYSTOLIC_PRESSURE: {
    name: "maxBloodPressure",
    unit: "mmHg",
  },
  DIASTOLIC_PRESSURE: {
    name: "minBloodPressure",
    unit: "mmHg",
  },
  HEARTBEAT: {
    name: "heartbeat",
    label: <FormLabel icon={<HeartOutlined />} label="心拍数" />,
    unit: "bpm",
  },
};

export const FORM_NAME = "body-index-form";
