import { ConfigProvider } from "antd";
import { themeConfig } from "configs/antdThemeConfig";
import { AppLoadingProvider, ModalContextProvider } from "contexts";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "styles/custom-antd.css";
import "styles/index.css";
import "styles/typography.css";

// Locale
import locale from "antd/locale/ja_JP";
import "dayjs/locale/ja";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ConfigProvider theme={themeConfig} locale={locale}>
    <Provider store={store}>
      <AppLoadingProvider>
        <ModalContextProvider>
          <App />
        </ModalContextProvider>
      </AppLoadingProvider>
    </Provider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
