import { Row } from "antd";
import styled from "styled-components";

export const MemberBodyIndexPageWrapper = styled.div`
  min-height: 100vh;
  min-height: 100dvh;

  padding: 25px 25px 25px 24px;
  background-color: #f2f2f2;
`;

export const StatisticWrapper = styled(Row)`
  margin-top: 20px;

  .header-row {
    margin-bottom: 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bmi-title {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .section-title {
    color: #b0b0b0;
    font-weight: 700;
    font-size: var(--font-size-md);
    line-height: 24px;
  }

  .panel-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    .select-year,
    .select-month {
      .ant-select-selector {
        padding: 0px 12px;
        color: var(--color-black);
      }

      .ant-select-arrow {
        color: #000000d9;
        font-size: 9px;
      }

      .ant-select-selection-item {
        padding-inline-end: 16px !important;
      }
    }

    .select-year {
      min-width: 74px;
    }

    .select-month {
      min-width: 64px;
    }
  }
`;
