import images from "constants/images";
import styled from "styled-components/macro";

export const PersonalInfoContainerStyled = styled.div`
  background-color: var(--color-white);
  min-height: 100vh;
  min-height: 100dvh;

  .logoutButton {
    cursor: pointer;
  }
`;

export const TopHeaderContainerStyled = styled.div`
  display: flex;
  flex-direction: column;

  padding: 25px 24px 25px 24px;
  background-color: #555555;

  background-image: url(${images.ProfileInfoHeader});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  .btn-back {
    color: var(--color-white);
    fill: var(--color-white);
    font-weight: 500;
    font-size: var(--font-size-md);
  }

  .avatarContainer {
    display: flex;
    width: 100%;
    padding-bottom: 20px;

    flex-direction: column;
    align-items: center;

    .avatarTitle {
      display: flex;
      font-weight: 700;
      font-size: 32px;
      text-align: center;
      justify-content: center;
      margin-top: 7px;

      background: linear-gradient(91.78deg, #ffeccf 2.16%, #ffc165 103.68%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .storeInfoContainer {
      margin-left: 0px;
      align-self: center;
    }

    .storeInfoContent {
      display: flex;
      justify-content: center;
      margin-top: 9px;
    }

    .storeAdminContainer {
      margin-top: 9px;
      text-align: center;
    }

    .valueStore {
      color: var(--color-white);
      font-weight: 700;
      font-size: var(--font-size-md);
    }

    .titleStore {
      color: var(--color-white);
      font-weight: 400;
      font-size: var(--font-size-md);
    }

    .line {
      width: 1px;
      height: auto;
      background-color: rgba(255, 255, 255, 0.3);
      margin-inline: 12px;
    }
  }

  .logo {
    height: 126px;
    width: 109px;

    margin-top: -15px;
  }
`;

export const ContentWrapperStyled = styled.div`
  padding: 40px 24px;

  .role-menu,
  .common-menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
