import { showErrorNotification } from "./../../helpers/notification";
import { call, put, takeEvery } from "redux-saga/effects";
import authApi from "./authApi";
import { authActions } from "./authSlice";
import { TResetPassword } from "pages/ResetPassword/ResetPassword.type";
import MESSAGES from "constants/messages";

export type TForgotPasswordType = {
  type: string;
  payload: {
    email: string;
    onSuccess?: () => void;
    onError?: () => void;
  };
};

export type TResetPasswordType = {
  type: string;
  payload: {
    data: {
      email: string;
      password: string;
      token: string;
    };
    onSuccess?: () => void;
    onError?: () => void;
  };
};

export function* forgotPassword(action: TForgotPasswordType): any {
  const MESSAGE_CODE = "NotExistUser";
  try {
    const response = yield call(authApi.forgotPassword, action.payload.email);

    if (response?.errors[0]?.code === MESSAGE_CODE) {
      action.payload.onError?.();
      yield put(authActions.forgotPasswordFailure());
      return;
    }

    action.payload.onSuccess?.();
    yield put(authActions.forgotPasswordSuccess());
  } catch (error: any) {
    showErrorNotification(MESSAGES["COM-MSG-002"]);
    yield put(authActions.forgotPasswordFailure());
  }
}

export function* resetPassword(action: TResetPasswordType): any {
  const MESSAGE_CODE = "InvalidToken";
  const STATUS = {
    SUCCESS: true,
    FAILURE: false,
  };
  try {
    const response = yield call(
      authApi.resetPassword,
      action.payload.data as TResetPassword
    );

    if (response?.succeeded === STATUS.FAILURE) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
      yield put(authActions.forgotPasswordFailure());
      return;
    }

    action.payload.onSuccess?.();
    yield put(authActions.resetPasswordSuccess());
  } catch (error: any) {
    if (error?.data?.errors[0]?.code === MESSAGE_CODE) {
      showErrorNotification(MESSAGES["SCR-W05-MSG-001"]);
      yield put(authActions.forgotPasswordFailure());
      return;
    }
    showErrorNotification(MESSAGES["COM-MSG-002"]);
    yield put(authActions.resetPasswordFailure());
  }
}

export const authWatcher = [
  takeEvery(authActions.forgotPassword, forgotPassword),
  takeEvery(authActions.resetPassword, resetPassword),
];
