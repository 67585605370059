import { Radio } from "antd";
import { FemaleIcon, MaleIcon } from "constants/svgs";
import { useEffect, useState } from "react";
import { TOptionsProps } from "../../Question";
import {
  RadioContainerStyled,
  RadioGroupStyled,
} from "./OptionsIconRadio.styled";
import { checkIsNullOrUndefined } from "helpers";
import { TOptionFormValue } from "model/survey";
import { EmptyAnswer } from "../EmptyAnswer/EmptyAnswer";

const ICONS = {
  男性: <MaleIcon key="male-icon" />,
  女性: <FemaleIcon key="female-icon" />,
};

function OptionsIconRadio(props: TOptionsProps<TOptionFormValue>) {
  const { options, onChange, value, readOnly, ...restProps } = props;
  const [internalValue, setInternalValue] = useState<string | number>();

  const handleChangeExternal = (val: any) => {
    if (!onChange) return;

    if (checkIsNullOrUndefined(val)) {
      onChange(undefined);
      return;
    }

    onChange({ ...value, answer: [val] });
  };

  const handleClickOption = (value: any) => {
    if (readOnly) return;

    setInternalValue(value);
    handleChangeExternal(value);
  };

  // Effect
  useEffect(() => {
    if (value?.answer && value?.answer?.length < 1 && !value?.otherAnswer) {
      onChange?.(undefined);
      setInternalValue(undefined);
    } else {
      setInternalValue(value?.answer?.[0]);
    }
  }, [value]);

  const selectedValue = value?.answer?.[0] || internalValue;
  const optionList = readOnly ? value?.answer || [] : options;

  if (readOnly && optionList.length === 0) {
    return <EmptyAnswer />;
  }

  return (
    <RadioGroupStyled value={selectedValue} {...restProps}>
      {optionList.map((opt) => {
        return (
          <RadioContainerStyled
            key={opt}
            onClick={() => handleClickOption(opt)}
            className={selectedValue === opt ? "selected" : ""}
          >
            <Radio value={opt}>{opt}</Radio>
            {ICONS[opt as keyof typeof ICONS]}
          </RadioContainerStyled>
        );
      })}
    </RadioGroupStyled>
  );
}

export default OptionsIconRadio;
