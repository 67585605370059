import {
  BranchesOutlined,
  ColumnHeightOutlined,
  FireOutlined,
  GoldOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import { checkIsNullOrUndefined } from "helpers";
import { TBodyIndexInfo } from "model/member";
import { ReactNode } from "react";
import {
  MeasurementInfoWrapperStyled,
  MeasurementItemStyled,
} from "./MeasurementInfo.styled";
import { GLOBAL_TRANSLATE } from "constants/translate";

type TMeasurementInfoProps = { className?: string; data?: TBodyIndexInfo };
type TBodyIndexItem = {
  label: string;
  icon: ReactNode;
  value: string;
};

const EMPTY_DATA_STRING = "-";

function formatData({
  unit = "",
  value,
}: {
  value?: number | null;
  unit?: string;
}) {
  if (checkIsNullOrUndefined(value)) {
    return EMPTY_DATA_STRING;
  }

  return `${value}${unit}`;
}

function getBodyIndexAsList(data?: TBodyIndexInfo): TBodyIndexItem[] {
  if (!data) return [];

  const {
    bodyFat,
    height,
    weight,
    maxBloodPressure = null,
    minBloodPressure = null,
    heartbeat,
  } = data;

  const isNotHasBloodPressureValue =
    checkIsNullOrUndefined(maxBloodPressure) &&
    checkIsNullOrUndefined(minBloodPressure);

  const bloodPressureValue = isNotHasBloodPressureValue
    ? EMPTY_DATA_STRING
    : `${formatData({ value: maxBloodPressure })}/${formatData({
        value: minBloodPressure,
      })}`;

  return [
    {
      label: GLOBAL_TRANSLATE.HEIGHT,
      icon: <ColumnHeightOutlined />,
      value: formatData({ unit: "cm", value: height }),
    },
    {
      label: GLOBAL_TRANSLATE.WEIGHT,
      icon: <GoldOutlined />,
      value: formatData({ unit: "kg", value: weight }),
    },
    {
      label: "体脂肪",
      icon: <FireOutlined />,
      value: formatData({ unit: "%", value: bodyFat }),
    },
    {
      label: "血圧",
      icon: <BranchesOutlined />,
      value: bloodPressureValue,
    },
    {
      label: "心拍数",
      icon: <HeartOutlined />,
      value: formatData({ unit: "bpm", value: heartbeat }),
    },
  ];
}

function MeasurementInfo(props: TMeasurementInfoProps) {
  const { className, data } = props;

  const dataAsList = getBodyIndexAsList(data);

  return (
    <MeasurementInfoWrapperStyled className={className}>
      {dataAsList.map((info) => {
        return (
          <MeasurementItemStyled key={info.label}>
            <div className="icon">{info.icon}</div>
            <div className="title">{info.label}</div>
            <div className="value">{info.value}</div>
          </MeasurementItemStyled>
        );
      })}
    </MeasurementInfoWrapperStyled>
  );
}

export { MeasurementInfo };
