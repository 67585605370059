import { PlusCircleFilled } from "@ant-design/icons";
import { Col } from "antd";
import { SelectShumokuModal, TSubmitShumokuModalValue } from "components";
import images from "constants/images";
import { programActions } from "features/program/programSlice";
import { useAppDispatch } from "hooks";
import { TShumoku } from "model/program";
import { FC } from "react";
import { FreeSelectComponentStyled } from "./FreeSelectComponent.styled";

type TProps = {
  freeId: number;
};

const FreeSelectComponent: FC<TProps> = ({ freeId }) => {
  const dispatch = useAppDispatch();

  const handleFormSubmit = async (values: TSubmitShumokuModalValue) => {
    const data = {
      ...values,
      freeId,
    };

    dispatch(
      programActions.setExtensions({
        data: data as any as TShumoku & { freeId: number },
      })
    );
  };

  return (
    <Col xs={24} md={12} xl={8}>
      <SelectShumokuModal onSubmit={handleFormSubmit}>
        <FreeSelectComponentStyled>
          <div className="video-index">
            <span className="order-number">{freeId + 1}</span>
          </div>
          <img src={images.FreeShumokuImage} />
          <div className="add-container">
            <span className="icon">
              <PlusCircleFilled />
            </span>
            <span className="text">種目選択</span>
          </div>
        </FreeSelectComponentStyled>
      </SelectShumokuModal>
    </Col>
  );
};

export { FreeSelectComponent };
