import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .btn-action {
    background: var(--color-primary);
    color: var(--color-background);
    margin-top: 22rem;
    align-self: flex-end;
    border-radius: 2rem;

    &:hover {
      color: var(--color-white);
    }
  }

  .message {
    margin-top: 20rem;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-normal);
    margin-left: 30rem;
  }

  .message-content {
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-sm);
    color: var(--color-primary);
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const TopContent = styled.div`
  display: flex;
  gap: 16px;

  .title {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bolder);
  }
`;
