import { InfoCircleFilled } from "@ant-design/icons";
import { EmptyAnswerStyled } from "./EmptyAnswer.styled";

const NO_DATA_TEXT = "なし";

function EmptyAnswer() {
  return (
    <EmptyAnswerStyled>
      <InfoCircleFilled className="icon-info" />
      <span>{NO_DATA_TEXT}</span>
    </EmptyAnswerStyled>
  );
}

export { EmptyAnswer };
