import { Input } from "antd";
import { PLACE_HOLDER_TEXT } from "components/Question/Question";
import { TOptionFormValue } from "model/survey";
import { ChangeEvent, useEffect, useState } from "react";
import { TValueForFormAntd } from "types/antd";

type TTextAreaProps = TValueForFormAntd<TOptionFormValue, TOptionFormValue>;

function TextArea(props: TTextAreaProps) {
  const { value, onChange, readOnly, ...restProps } = props;

  const [internalValue, setInternalValue] = useState<string>();

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const textValue = e.target.value;
    setInternalValue(textValue);

    if (textValue.trim()) {
      onChange?.({
        answer: [textValue],
      });
      return;
    }

    onChange?.(undefined);
  };

  useEffect(() => {
    if (value?.answer && value?.answer?.length < 1) {
      onChange?.(undefined);
      setInternalValue(undefined);
    } else {
      setInternalValue(value?.answer?.[0].toString());
    }
  }, [value]);

  return (
    <Input.TextArea
      value={value?.answer?.[0] || internalValue}
      onChange={handleTextChange}
      size="large"
      autoSize
      placeholder={!readOnly ? PLACE_HOLDER_TEXT : ""}
      readOnly={readOnly}
      {...restProps}
    />
  );
}

export default TextArea;
