import { useAppLoading, useAppModal } from "contexts";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import { useAppDispatch, useAppSelector, useScrollLock } from "hooks";
import { useEffect, useLayoutEffect, useState } from "react";

import { ShumokuMasterManagementMainStyled } from "./ShumokuMasterManagementMain.styled";
import { ShumokuMasterSideBar } from "../ShumokuMasterSideBar/ShumokuMasterSideBar";
import { ShumokuMasterContent } from "../ShumokuMasterContent/ShumokuMasterContent";
import { ShumokuCreateButton } from "../ShumokuCreate/ShumokuCreateButton/ShumokuCreateButton";
import { ShumokuMasterDeleteButton } from "../ShumokuMasterDelete/ShumokuMasterDeleteButton/ShumokuMasterDeleteButton";
import { ShumokuMasterCreateFormModal } from "../ShumokuCreate/ShumokuMasterCreateFormModal/ShumokuMasterCreateFormModal";
import { CheckCircleOutlined } from "@ant-design/icons";
import { TRANSLATION } from "../ShumokuMaster.translate";
import { DEFAULT_MODAL_PROPS } from "constants/common";
import { TShumoku } from "model/shumoku";
import { ShumokuMasterUpdateFormModal } from "../ShumokuCreate/ShumokuMasterUpdateFormModal/ShumokuMasterUpdateFormModal";

const ShumokuMasterManagementMain = () => {
  const { startLoading, stopLoading } = useAppLoading();
  const { loading } = useAppSelector((state) => state.shumoku);
  const dispatch = useAppDispatch();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [shumokuUpdateId, setShumokuUpdateId] = useState("");
  const { appModal } = useAppModal();
  const [selectedIds, setSelectedIds] = useState<Set<string> | undefined>();
  useScrollLock(openCreateModal || openUpdateModal);

  useEffect(() => {
    loading ? startLoading() : stopLoading();
    return stopLoading;
  }, [loading]);

  useEffect(() => {
    dispatch(shumokuActions.getAllShumokus());
  }, []);

  useLayoutEffect(() => {
    const homePageButtonElement = document.querySelector(
      ".home-page-btn"
    ) as HTMLElement;

    if (homePageButtonElement) {
      homePageButtonElement.style.display = "none";
    }

    return () => {
      homePageButtonElement.style.display = "grid";
    };
  });

  const handleOpenCreateFormModal = () => {
    setOpenCreateModal(true);
  };
  const handleOpenSuccessModal = (message: string) => {
    appModal.confirm({
      ...DEFAULT_MODAL_PROPS.SUCCESS,
      width: 520,
      title: message,
      okText: TRANSLATION.YES,
      icon: <CheckCircleOutlined style={{ color: "#52C41A" }} />,
      cancelButtonProps: { style: { display: "none" } },
    });
  };

  const changeSelectedIds = (selectedIdSet: Set<string>) => {
    setSelectedIds(selectedIdSet);
  };
  const handleOpenUpdateForm = (shumoku: TShumoku) => {
    setShumokuUpdateId(shumoku.id);
    setOpenUpdateModal(true);
  };
  return (
    <ShumokuMasterManagementMainStyled>
      <ShumokuMasterSideBar />
      <ShumokuMasterContent
        changeSelectedIds={changeSelectedIds}
        handleOpenUpdateForm={handleOpenUpdateForm}
      />
      <ShumokuCreateButton
        handleOpenCreateFormModal={handleOpenCreateFormModal}
      />
      <ShumokuMasterDeleteButton
        selectedIds={selectedIds}
        handleOk={() => {
          handleOpenSuccessModal(TRANSLATION.DELETE_SUCCESS_NOTIFICATION);
        }}
      />
      {openCreateModal && (
        <ShumokuMasterCreateFormModal
          open={openCreateModal}
          handleOk={() => {
            setOpenCreateModal(false);
            handleOpenSuccessModal(TRANSLATION.CREATE_SUCCESS_NOTIFICATION);
          }}
          handleCancel={() => {
            setOpenCreateModal(false);
          }}
        />
      )}
      {openUpdateModal && (
        <ShumokuMasterUpdateFormModal
          open={openUpdateModal}
          shumokuUpdateId={shumokuUpdateId}
          handleOk={() => {
            setOpenUpdateModal(false);
            handleOpenSuccessModal(TRANSLATION.UPDATE_SUCCESS_NOTIFICATION);
          }}
          handleCancel={() => {
            setOpenUpdateModal(false);
          }}
        />
      )}
    </ShumokuMasterManagementMainStyled>
  );
};

export { ShumokuMasterManagementMain };
