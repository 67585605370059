import { FC, ReactElement } from "react";
import {
  ExtraContentStyled,
  LayoutContainerStyled,
  MainLayoutStyled,
  TopLayoutStyled,
} from "./ResetPasswordLayout.styled";
import { AppLogoImage } from "constants/images";
import { PATHNAME_LIST } from "constants/pathname";
import { Link } from "react-router-dom";

type TProps = {
  children: ReactElement;
  title: string;
};

const TEXT_TRANSLATION = {
  TITLE: "新しいパスワードを作成",
  TERM_AND_CONDITION: "利用規約",
  QNA: "よくある質問",
};

const ResetPasswordLayout: FC<TProps> = ({ children }) => {
  return (
    <LayoutContainerStyled>
      <TopLayoutStyled>
        <img src={AppLogoImage} alt="logo" className="logo" />
        <h2 className="title">{TEXT_TRANSLATION.TITLE}</h2>
      </TopLayoutStyled>
      <MainLayoutStyled>
        {children}
        <ExtraContentStyled>
          <Link to={PATHNAME_LIST.TERMS_AND_CONDITIONS}>
            {TEXT_TRANSLATION.TERM_AND_CONDITION}
          </Link>
          <Link to={PATHNAME_LIST.FAQ}>{TEXT_TRANSLATION.QNA}</Link>
        </ExtraContentStyled>
      </MainLayoutStyled>
    </LayoutContainerStyled>
  );
};

export { ResetPasswordLayout };
