import { ProgramMenuType, TProgramMenuType } from "constants/program";
import { DietItem } from "../DietItem/DietItem";
import { DietListContainerStyled, DietListStyled } from "./DietList.styled";
import { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { TProgramSurvey, programActions } from "features/program/programSlice";
import { Button } from "antd";
import { useProgramContext } from "pages/TrainingProgramPage/ProgramContext/ProgramContext";
import { AppleIcon, ArmIcon, OtherIcon, PersonIcon } from "constants/svgs";
import { ThunderboltFilled, ToolFilled } from "@ant-design/icons";
import { EditableComponent } from "components";

const DietList: FC = () => {
  const { next } = useProgramContext();
  const dispatch = useAppDispatch();
  const { programMenu, hasOther } = useAppSelector(
    (state) => state.program.programSurvey
  );

  const { isViewSurvey } = useAppSelector((state) => state.program);

  const [activeProgramMenuItem, setActiveProgramMenuItem] = useState(0);
  const [isEditAnswer, setIsEditAnswer] = useState(false);
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState<boolean>(false);
  const isFirstRender = useRef(true);

  const PROGRAM_MENU_TYPES: TProgramMenuType[] = [
    {
      id: ProgramMenuType.Diet,
      icon: <AppleIcon />,
      name: "ダイエット",
      value: ProgramMenuType.Diet,
    },
    {
      id: ProgramMenuType.BodyMakeup,
      icon: <ThunderboltFilled />,
      name: "ボディメイク（シェイプアップ）",
      value: ProgramMenuType.BodyMakeup,
    },
    {
      id: ProgramMenuType.BulkUp,
      icon: <ArmIcon />,
      name: "ボディメイク（筋肥大）",
      value: ProgramMenuType.BulkUp,
    },
    {
      id: ProgramMenuType.MuscleStrength,
      icon: <PersonIcon />,
      name: "姿勢の改善",
      value: ProgramMenuType.MuscleStrength,
    },
    {
      id: ProgramMenuType.HealthMaintenanceAndPromotion,
      icon: <ToolFilled />,
      name: "健康維持促進",
      value: ProgramMenuType.HealthMaintenanceAndPromotion,
    },
    {
      id: ProgramMenuType.OTHER,
      icon: <OtherIcon />,
      name: (
        <EditableComponent
          isEditAnswer={isEditAnswer}
          setIsEditAnswer={setIsEditAnswer}
          answer={answer}
          setAnswer={setAnswer}
        />
      ),
      value: ProgramMenuType.OTHER,
    },
  ];

  const handleSelectingProgramMenu = (programMenu: number) => {
    if (activeProgramMenuItem === programMenu) return;

    setActiveProgramMenuItem(programMenu);

    dispatch(
      programActions.setProgramSurvey({
        data: { programMenu: programMenu } as TProgramSurvey,
      })
    );
  };

  const handleNextStep = () => {
    if (
      !hasOther &&
      programMenu === ProgramMenuType.OTHER &&
      answer.trim() === ""
    ) {
      setError(true);
      return;
    } else {
      next();
    }
  };

  useEffect(() => {
    if (programMenu || programMenu === 0) {
      setActiveProgramMenuItem(programMenu);
    }

    if (programMenu !== ProgramMenuType.OTHER) {
      setError(false);
    }

    if (programMenu === ProgramMenuType.OTHER && !answer.trim()) {
      setError(false);
    }
  }, [programMenu]);
  useEffect(() => {
    isFirstRender.current = false;
    if (isViewSurvey) {
      return () => {
        dispatch(
          programActions.setProgramSurvey({
            data: { programMenu: null, hasOther: "" } as TProgramSurvey,
          })
        );
      };
    }
  }, []);

  useEffect(() => {
    if (hasOther) {
      setError(false);
    }
  }, [hasOther]);

  return (
    <DietListContainerStyled>
      <DietListStyled>
        {PROGRAM_MENU_TYPES.map((item: TProgramMenuType) => (
          <DietItem
            item={item}
            key={item.id}
            active={activeProgramMenuItem}
            setActive={setActiveProgramMenuItem}
            handleSelectProgramMenu={handleSelectingProgramMenu}
          />
        ))}
        {error && <p className="error-field">回答を選択してください。</p>}
      </DietListStyled>
      <Button
        type="primary"
        htmlType="submit"
        className="update-btn"
        disabled={isEditAnswer}
        onClick={handleNextStep}
      >
        次へ
      </Button>
    </DietListContainerStyled>
  );
};

export { DietList };
