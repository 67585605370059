import { FC, Fragment, ReactElement, useEffect, useState } from "react";
import { ProgramEditingLayoutStyled } from "./ProgramEditingLayout.styled";
import { ProgramHeader } from "./components/ProgramHeader/ProgramHeader";
import memberApi from "features/member/memberApi";
import { useAppDispatch, useAppSelector } from "hooks";
import { memberActions } from "features/member/memberSlice";
import { programActions } from "features/program/programSlice";
import { useAppLoading } from "contexts";
import { useSearchParams } from "react-router-dom";
import { showErrorNotification } from "helpers/notification";
import MESSAGES from "constants/messages";

type TProps = {
  children: ReactElement;
  headerTitle: string;
};

export const PARAMS = {
  MEMBER_ID: "memberId",
  PROGRAM_MEMBER_ID: "programMemberId",
  PHASE_NUMBER: "phaseNumber",
};

const ProgramEditingLayout: FC<TProps> = ({ headerTitle, children }) => {
  const dispatch = useAppDispatch();
  const { startLoading, stopLoading } = useAppLoading();
  const [searchParams] = useSearchParams();
  const [loadingMember, setLoadingMember] = useState(true);

  const loading = useAppSelector((state) => state.program.loading);

  const memberId = searchParams.get(PARAMS.MEMBER_ID);
  const phase = searchParams.get(PARAMS.PHASE_NUMBER);

  useEffect(() => {
    async function getMemberDetail(memberId: string) {
      setLoadingMember(true);
      try {
        const { data } = await memberApi.getMemberById(memberId);

        const memberIndexes = {
          memberAxtosId: data?.memberAxtosId,
          height: data?.height,
          weight: data?.weight,
          bodyFat: data?.bodyFat,
        };

        dispatch(
          memberActions.setMemberIndexes({
            data: memberIndexes,
          })
        );

        dispatch(
          programActions.getProgramSurvey({
            data: {
              memberAxtosId: data?.memberAxtosId,
              phaseNumber: Number(phase),
            },
          })
        );
      } catch (error) {
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      } finally {
        setLoadingMember(false);
      }
    }
    getMemberDetail(memberId as string);
  }, [memberId]);

  useEffect(() => {
    loading ? startLoading() : stopLoading();
    return stopLoading;
  }, [loading]);

  if (loadingMember) return <Fragment />;

  return (
    <ProgramEditingLayoutStyled>
      <ProgramHeader headerTitle={headerTitle} />
      {children}
    </ProgramEditingLayoutStyled>
  );
};

export { ProgramEditingLayout };
