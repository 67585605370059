import { Button } from "antd";
import { useAppSelector } from "hooks";
import { useShumokuContext } from "pages/ShumokuManagementPage/ShumokuContext/ShumokuContext";
import { TRANSLATION } from "pages/ShumokuManagementPage/ShumokuManagementPage.translate";
import { useState } from "react";
import {
  AnchorContainerStyled,
  ShumokuManagementSideBarStyled,
} from "./ShumokuManagementSideBar.styled";
import { TMajorItem } from "model/shumoku";

export type SideBarItem = {
  key: string;
  href: string;
  title: string;
};

const MARGIN_TOP = 178;

const ShumokuManagementSideBar = () => {
  const { handleApplyShumokus } = useShumokuContext();
  const { majorItems } = useAppSelector((state) => state.shumoku);
  const [activeMajorItem, setActiveMajorItem] = useState<string>();

  const handleApply = () => {
    handleApplyShumokus();
  };

  const handleClickAnchor = (item: TMajorItem) => {
    const targetElement = document.querySelector(
      `#${item?.majorItemCode.replace(/\s/g, "")}`
    );

    if (targetElement) {
      const y =
        targetElement.getBoundingClientRect().top + window.scrollY - MARGIN_TOP;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
    setActiveMajorItem(item.id);
  };
  return (
    <ShumokuManagementSideBarStyled>
      <AnchorContainerStyled>
        {majorItems?.map((item) => (
          <div
            key={item?.majorItemCode}
            className={
              activeMajorItem === item.id ? "step-item-active" : "step-item"
            }
            onClick={() => handleClickAnchor(item)}
          >
            <span className={`label`} />
            <span className="content">
              <span>{item?.majorItemName}</span>
            </span>
          </div>
        ))}
      </AnchorContainerStyled>
      <Button
        size="large"
        type="primary"
        className="apply-btn"
        onClick={handleApply}
      >
        {TRANSLATION.APPLY}
      </Button>
    </ShumokuManagementSideBarStyled>
  );
};

export { ShumokuManagementSideBar };
