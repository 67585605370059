import styled from "styled-components";

export const VideoModalStyled = styled.div``;

export const VideoModalWrapperStyled = styled.div`
  .video {
    width: 100%;
    height: min(324rem, 324px);

    background-color: #fafafa;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
  }

  .modal-footer {
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    cursor: pointer;

    position: absolute;
    left: 50%;
    transform: translate(-50%, calc(100% + 20px));
    bottom: 0;
  }

  .exercise-index {
    display: flex;
    justify-content: center;
    .exercise-index-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
      width: 190px;
      .icon {
        color: var(--color-primary);
        font-size: min(40rem, 40px);
      }

      .label {
        font-weight: 400;
        font-size: max(16rem, 12px);
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
        white-space: nowrap;
      }

      .content {
        font-weight: 700;
        font-size: min(40rem, 40px);
        color: var(--color-primary);
        text-align: center;
        white-space: nowrap;
      }
    }
  }
`;
