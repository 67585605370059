import styled from "styled-components";

export const MemberConfirmedSurveyStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(91.78deg, #ffeccf 2.16%, #ffc165 103.68%);
  padding: 13px 0;

  .cir {
    fill: #000000d9;
  }
`;
