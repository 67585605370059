import { DatePicker, Form, Select } from "antd";
import { TFilterDashboardRequest } from "features/statistic/statisticApi";
import styled from "styled-components";

export const DashboardFilterFormStyled = styled.div`
  .search-input {
    font-weight: 400;
    font-size: 14px;
    margin-top: 25rem;
    margin-bottom: 5rem;
  }
`;

// ======== SEARCH & FILTER ========
export const FormStyled = styled(Form<TFilterDashboardRequest>)`
  &&& .ant-form-item-control input,
  .ant-form-item-control input::placeholder {
    font-size: var(--font-size-sm);
  }

  &&& .ant-form-item-control input {
    color: var(--color-text-input);
  }

  .ant-form-item-label {
    padding: 0;
    margin-bottom: 8px;

    label {
      min-width: 64px;

      font-size: var(--font-size);
      font-weight: 500;
      color: var(--color-dark);
    }
  }

  .ant-form-item {
    .ant-row {
      align-items: baseline;
    }

    .ant-form-item-control-input-content {
      height: 100%;
    }
  }

  .btn-filter {
    margin-top: 30px;
    width: 100%;
  }
`;

export const SelectStyled = styled(Select)`
  font-size: var(--font-size-sm);
  color: var(--color-text-input);

  .ant-select-arrow {
    color: var(--color-text-input);

    .anticon:not(.ant-select-suffix) {
      pointer-events: none;
    }
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  width: 100%;
  font-size: var(--font-size-sm);
  .ant-picker-cell-disabled {
    background-color: #000000 !important;
  }
`;
