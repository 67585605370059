import styled from "styled-components";

export const MajorItemCreateFormModalStyled = styled.div`
  input {
    color: #000000d9;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    position: absolute;
    right: -14px;
  }
`;
