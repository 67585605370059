import { Button, Col, Form, Input, Row } from "antd";
import { useAppLoading } from "contexts";
import shumokuApi from "features/shumoku/shumokuApi";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import {
  EUpdateMajorItemMasterError,
  TCreateMajorItemMasterReq,
  TMajorItem,
} from "model/shumoku";
import { TRANSLATION } from "pages/MajorItemManagement/MajorItemManagement.translate";
import { FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { TResError, TResponse } from "types/service";
import { MajorItemUpdateFormModalStyled } from "./MajorItemUpdateFormModal.styled";
import { ValidateErrorEntity } from "types/antd";

const UPDATE_SHUMOKU_FIELD = {
  MAJOR_ITEM_CODE: {
    LABEL: TRANSLATION.MAJOR_ITEM_CODE,
    NAME: "majorItemCode",
  },
  MAJOR_ITEM_NAME: {
    LABEL: TRANSLATION.MAJOR_ITEM_NAME,
    NAME: "majorItemName",
  },
};

type TProps = {
  majorItemUpdate: TMajorItem;
  handleOk: () => void;
};

const MajorItemUpdateFormModal: FC<TProps> = ({
  majorItemUpdate,
  handleOk,
}) => {
  const [form] = Form.useForm();
  const { startLoading, stopLoading } = useAppLoading();
  const dispatch = useDispatch();

  const initialValues = {
    majorItemCode: majorItemUpdate?.majorItemCode || "",
    majorItemName: majorItemUpdate?.majorItemName || "",
  };

  const setErrorForField = ({
    fieldName,
    message = "",
  }: {
    fieldName: string;
    message?: string;
  }) => {
    form.getFieldInstance(fieldName).focus();
    form.setFields([{ name: fieldName, errors: [message] }]);
  };
  const showErrors = useCallback((errorCode: string) => {
    switch (errorCode) {
      case EUpdateMajorItemMasterError.MAJOR_ITEM_CODE_IS_EXISTED:
        setErrorForField({
          fieldName: UPDATE_SHUMOKU_FIELD.MAJOR_ITEM_CODE.NAME,
          message: TRANSLATION.MAJOR_ITEM_CODE_EXISTED,
        });
        break;
      case EUpdateMajorItemMasterError.MAJOR_ITEM_CODE_IS_INVALID:
        setErrorForField({
          fieldName: UPDATE_SHUMOKU_FIELD.MAJOR_ITEM_CODE.NAME,
          message: TRANSLATION.MAJOR_ITEM_CODE_INVALID,
        });
        break;
      case EUpdateMajorItemMasterError.MAJOR_ITEM_NAME_IS_EXISTED:
        setErrorForField({
          fieldName: UPDATE_SHUMOKU_FIELD.MAJOR_ITEM_NAME.NAME,
          message: TRANSLATION.MAJOR_ITEM_NAME_EXISTED,
        });
        break;

      default:
        setErrorForField({
          fieldName: "",
          message: "",
        });
        break;
    }
  }, []);

  const handleUpdateError = (errors: TResError[]) => {
    errors.forEach((element: TResError) => {
      showErrors(element.code);
    });
  };
  const onFinish = async (values: any) => {
    if (
      majorItemUpdate.majorItemCode === values.majorItemCode &&
      majorItemUpdate.majorItemName === values.majorItemName
    ) {
      form.resetFields();
      handleOk();
      return;
    }
    startLoading();
    try {
      const { data: updateInfo } = await shumokuApi.updateMajorItemMaster(
        majorItemUpdate.id,
        values
      );
      if (updateInfo) {
        dispatch(shumokuActions.getAllShumokus());
        handleOk();
        form.resetFields();
      }
    } catch (error: any) {
      const errorList = (error?.data as TResponse)?.errors || [];
      if (errorList) {
        handleUpdateError(errorList);
      }
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [majorItemUpdate]);

  const handleFinishFailed = (
    errorInfo: ValidateErrorEntity<TCreateMajorItemMasterReq>
  ) => {
    const firstFiledError = errorInfo.errorFields[0].name[0];
    form.getFieldInstance(firstFiledError)?.focus();
  };

  const trimFieldValue = (filedName: string) => {
    const fieldValue = form.getFieldValue(filedName);
    form.setFieldValue(filedName, fieldValue?.trim());
  };

  return (
    <MajorItemUpdateFormModalStyled>
      <Form
        initialValues={initialValues}
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        form={form}
        onFinishFailed={handleFinishFailed}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name={UPDATE_SHUMOKU_FIELD.MAJOR_ITEM_CODE.NAME}
              label={UPDATE_SHUMOKU_FIELD.MAJOR_ITEM_CODE.LABEL}
              labelAlign="right"
              rules={[
                {
                  required: true,
                  transform: (value) => value.trim(),
                  message: `${TRANSLATION.MAJOR_ITEM_CODE_REQUIRED}`,
                },
                {
                  max: 50,
                  message: `${TRANSLATION.MAJOR_ITEM_CODE_MAX_LENGTH}`,
                },
              ]}
            >
              <Input
                onBlur={() => {
                  trimFieldValue(UPDATE_SHUMOKU_FIELD.MAJOR_ITEM_CODE.NAME);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name={UPDATE_SHUMOKU_FIELD.MAJOR_ITEM_NAME.NAME}
              label={UPDATE_SHUMOKU_FIELD.MAJOR_ITEM_NAME.LABEL}
              rules={[
                {
                  required: true,
                  transform: (value) => value.trim(),
                  message: `${TRANSLATION.MAJOR_ITEM_NAME_REQUIRED}`,
                },
              ]}
            >
              <Input
                onBlur={() => {
                  trimFieldValue(UPDATE_SHUMOKU_FIELD.MAJOR_ITEM_NAME.NAME);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" align="bottom">
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button type="primary" htmlType="submit">
              {/* !Add space end of text => to fix issue `auto add space between 2 chars in button` of antd */}
              {TRANSLATION.SAVE}
              {""}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </MajorItemUpdateFormModalStyled>
  );
};
export { MajorItemUpdateFormModal };
