import { useEffect } from "react";
import { Button } from "antd";
import { getShumokuSectionList } from "helpers/program";
import { useAppSelector } from "hooks";
import { useProgramContext } from "pages/TrainingProgramPage/ProgramContext/ProgramContext";
import { ProgressStepper } from "../ProgressStepper/ProgressStepper";
import { ProgramSideBarStyled } from "./ProgramSideBar.styled";
import { TEXT_TRANSLATE } from "pages/TrainingProgramPage/TrainingProgram.translate";

const ProgramSideBar = () => {
  const { programData } = useAppSelector((state) => ({
    programData: state.program.generatedProgram,
    extensions: state.program.extensions,
  }));

  const { onSaveProgram, setActiveStep } = useProgramContext();

  const sectionList = getShumokuSectionList(programData?.stepsResponse || []);

  useEffect(() => {
    return () => {
      setActiveStep?.(1);
    };
  }, []);

  return (
    <ProgramSideBarStyled className="side-bar-wrapper">
      <div className="progress-bar-wrapper">
        <ProgressStepper stepItems={sectionList} />
      </div>
      <Button className="btn-record" onClick={onSaveProgram}>
        {TEXT_TRANSLATE.BTN_SAVE_PROGRAM}
        {""}
      </Button>
    </ProgramSideBarStyled>
  );
};

export { ProgramSideBar };
