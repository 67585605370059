import { FC } from "react";
import { MemberListContainerStyled } from "./MemberListPage.styled";
import { MemberListForm } from "./components/MemberListForm/MemberListForm";
import { MemberListMain } from "./components/MemberListForm/MemberListMain";

const MemberListPage: FC = () => {
  return (
    <MemberListContainerStyled>
      <MemberListForm />
      <MemberListMain />
    </MemberListContainerStyled>
  );
};

export { MemberListPage };
