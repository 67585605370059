import { TStepItemUI, TStepResponse } from "model/program";

export function getShumokuSectionList(steps: TStepResponse[]): TStepItemUI[] {
  const fixedSectionList: TStepItemUI[] = steps.map((section) => {
    return {
      id: section.stepNumber.toString(),
      label: section.stepNumber.toString(),
      content: section.stepName,
      stepNumber: section.stepNumber,
    };
  });

  const freeSelectionNumber = steps.length + 1;

  const freeSelectionSection: TStepItemUI = {
    id: freeSelectionNumber.toString(),
    label: freeSelectionNumber.toString(),
    content: "フリー選択",
    stepNumber: freeSelectionNumber,
  };

  return [...fixedSectionList, freeSelectionSection];
}
