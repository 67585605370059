import { ShumokuManagementStyled } from "./ShumokuManagement.styled";
import { ShumokuManagementForm } from "./components/ShumokuManagementForm/ShumokuManagementForm";
import { ShumokuManagementMain } from "./components/ShumokuManagementMain/ShumokuManagementMain";

const ShumokuManagement = () => {
  return (
    <ShumokuManagementStyled>
      <ShumokuManagementForm />
      <ShumokuManagementMain />
    </ShumokuManagementStyled>
  );
};

export { ShumokuManagement };
