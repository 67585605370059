import styled from "styled-components";

export const ProgramSideBarStyled = styled.div`
  .progress-bar-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 20rem;
  }

  .btn-record {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-md);
    margin-top: 20rem;
    color: var(--color-white);
    width: 100%;
    height: 100%;
    background: var(--color-primary);

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: var(--color-white);
    }
    span {
      padding: 6rem 0;
    }
  }

  .gen-qrcode {
    width: 100%;
    height: 100%;
    margin-top: 15px;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-md);

    span {
      padding: 6rem 0px;
    }
  }
`;
