import { Button, Col } from "antd";
import images from "constants/images";
import { patternSvgs } from "constants/svgs";
import { RESPONSIVE_SIZE } from "pages/PublicMemberDetailPage/PublicMemberDetailPage.styled";
import styled from "styled-components";

// ========================================== Member Info ==========================================
export const InfoWrapperStyled = styled.div`
  padding: 25px 19px;
  width: 264rem;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--color-black-2);
  background-image: url(${images.PattenMemberDetail});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  color: var(--color-white);

  .btn-check-in {
    margin-top: 25rem;
  }

  .total-check-in-date {
    color: var(--color-white);
    margin-top: 16rem;
    font-weight: 500;
    font-size: var(--font-size-md);
    line-height: 24px;
  }

  .measurement-info {
    margin-top: 24rem;
  }

  @media only screen and (max-width: ${RESPONSIVE_SIZE}) {
    position: relative;
    width: 100%;

    background-image: none;
  }
`;

export const ButtonDetailStyled = styled(Button)`
  min-width: 104rem;

  background-color: #fff1e8;
  color: var(--color-primary);
  border: none;
`;

export const InfoSectionWrapperStyled = styled(Col)`
  .title {
    font-weight: 700;
    color: #b0b0b0;
    font-size: var(--font-size-md);
  }

  .section-content {
    margin-top: max(8px, 8rem);
  }

  .section-list {
    display: flex;
    flex-direction: column;
    gap: max(12px, 12rem);
  }
`;

export const InkImgStyled = styled.div`
  width: 16rem;
  position: fixed;
  top: 0;
  right: 264rem;
  bottom: 0;

  background-image: url(${patternSvgs.MemberDetailInkUrl});
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: ${RESPONSIVE_SIZE}) {
    display: none;
  }
`;

export const AppLogoStyled = styled.img`
  width: 95px;
  height: 72px;
  object-fit: contain;
`;

export const MemberIdStyled = styled.p`
  margin-top: 32rem;

  font-size: 32px;
  text-align: center;
  font-weight: 700;
`;

export const ActionWrapperStyled = styled.div`
  width: 100%;
  margin-top: 43rem;

  display: flex;
  flex-direction: column;
  gap: 16rem;

  button {
    color: var(--color-primary);
  }
`;
