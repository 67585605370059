import { FC, ReactElement } from "react";
import {
  ContentContainerStyled,
  // HeaderStyled,
  LeftSectionStyled,
  ProgramContainerStyled,
  ProgramSecondaryLayoutStyled,
  RightSectionContainerStyled,
  RightSectionStyled,
} from "./ProgramSecondaryLayout.styled";

type TProps = {
  top?: ReactElement;
  left?: ReactElement;
  right?: ReactElement;
};

const ProgramSecondaryLayout: FC<TProps> = ({ left, right }) => {
  return (
    <ProgramSecondaryLayoutStyled>
      <ProgramContainerStyled>
        <ContentContainerStyled>
          <LeftSectionStyled>{left}</LeftSectionStyled>
          <RightSectionContainerStyled className="program-list-container">
            <RightSectionStyled>{right}</RightSectionStyled>
          </RightSectionContainerStyled>
        </ContentContainerStyled>
      </ProgramContainerStyled>
    </ProgramSecondaryLayoutStyled>
  );
};

export { ProgramSecondaryLayout };
