import {
  EQuestionCategory,
  EQuestionCategoryPhaseIntermediate,
  ESurveyPhase,
} from "model/survey";

export const GLOBAL_TRANSLATE = {
  CLOSE: "終了",
  DURATION: "時間",
  NUMBER_OF_REPS: "回数",
  WEIGHT: "体重",
  HEIGHT: "身長",
  BMI: "BMI",
  BODY_FAT: "体脂肪",
  CONFIRM_FINISH_SURVEY: "アンケートを終了してもよろしいでしょうか。",
};

export const SURVEY_CATEGORY_TITLES: {
  [phase in ESurveyPhase]: {
    [category in EQuestionCategory &
      EQuestionCategoryPhaseIntermediate]: string;
  };
} = {
  [ESurveyPhase.PHASE_1]: {
    [EQuestionCategory.PERSONAL_INFO]: "パーソナルデータ",
    [EQuestionCategory.TRAINING_REQUEST]: "トレーニングへの要望",
    [EQuestionCategory.DIET]: "健康履歴",
    [EQuestionCategory.OTHER]: "その他",
  },
  [ESurveyPhase.PHASE_2]: {
    [EQuestionCategoryPhaseIntermediate.TRAINING_REQUEST]: "トレーニングの要望",
    [EQuestionCategoryPhaseIntermediate.INJURIES_HISTORY]: "既往歴",
    [EQuestionCategoryPhaseIntermediate.LAST_TRAINING_PROGRAM]:
      "成果の振り返り",
  },
};
