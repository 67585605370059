import { PageContainerStyled } from "./PublicMemberDetailPage.styled";
import { MemberInfo, MemberStatistic } from "./components";
import { MemberDetailPageContextProvider } from "./contexts/MemberDetailPageContext";

function PublicMemberDetailPage() {
  return (
    <MemberDetailPageContextProvider>
      <PageContainerStyled>
        <MemberStatistic />

        <MemberInfo />
      </PageContainerStyled>
    </MemberDetailPageContextProvider>
  );
}

export { PublicMemberDetailPage };
