import { Col, Row } from "antd";
import { ReactNode } from "react";
import {
  BMICategory,
  BMI_CONFIG_LIST,
  MAX_BMI,
  MIN_BMI,
  NUM_BMI_GROUP,
} from "./BMIChart.constant";
import {
  BMIChartWrapperStyled,
  LegendStyled,
  PointListWrapper,
  PointStyled,
  TagStyled,
  TooltipStyled,
} from "./BMIChart.styled";

function getUserBMIGroup(bmi: number): BMICategory {
  if (bmi >= MAX_BMI) return BMICategory.OBESITY;

  for (const group of Object.keys(BMI_CONFIG_LIST)) {
    const groupKey = group as unknown as BMICategory;
    const points = BMI_CONFIG_LIST[groupKey].points;

    if (bmi >= points[0] && bmi < points[points.length - 1]) {
      return groupKey;
    }
  }

  return BMICategory.THINNESS;
}

function RenderLegend({
  points,
  showEndPoint,
}: {
  points: number[];
  showEndPoint?: boolean;
}) {
  return (
    <PointListWrapper>
      {points.map((point, index) => {
        const isShow = index !== points.length - 1 || showEndPoint;

        return (
          <PointStyled key={`${point}-${index}`}>
            {isShow ? point : ""}
          </PointStyled>
        );
      })}
    </PointListWrapper>
  );
}

type TBMIChartProps = {
  bmi?: number;
  extraContent?: ReactNode;
};

function BMIChart(props: TBMIChartProps) {
  const { bmi: bmiProp = 0, extraContent } = props;

  const bmiValue =
    bmiProp < MIN_BMI ? MIN_BMI : bmiProp > MAX_BMI ? MAX_BMI : bmiProp;
  const bmiGroup = getUserBMIGroup(bmiValue);
  const bmiGroupConfig = BMI_CONFIG_LIST[bmiGroup];
  const bmiDetailTitle = bmiGroupConfig.getDetailTitle(bmiProp);

  return (
    <BMIChartWrapperStyled>
      <Row justify={extraContent ? "space-between" : "center"}>
        <Col className="chart-title">
          BMI:{" "}
          <span
            className="bmi-group-text"
            style={{ color: bmiGroupConfig.tooltipBgColor }}
          >
            {bmiDetailTitle}
          </span>
        </Col>
        <Col>{extraContent}</Col>
      </Row>

      <div className="chart-container">
        {Object.values(BMI_CONFIG_LIST).map((group, index) => {
          const {
            points,
            title,
            bgColor: backgroundColor,
            color,
            borderColor,
            tooltipBgColor,
            tooltipColor = "var(--color-white)",
          } = group;
          const groupLength = points[points.length - 1] - points[0];
          const tooltipFlex = ((bmiValue - points[0]) * 100) / groupLength;

          const isLastGroup = index === NUM_BMI_GROUP - 1;
          const isMaxValueInLastGroup = bmiValue === MAX_BMI && isLastGroup;
          const showToolTip =
            bmiProp > 0 &&
            bmiValue >= points[0] &&
            (bmiValue < points[points.length - 1] || isMaxValueInLastGroup);

          return (
            <TagStyled
              key={`${title}-${index}`}
              flex={groupLength}
              style={{
                backgroundColor,
                color,
                borderColor,
              }}
            >
              {title}
              <LegendStyled
                style={{ textAlign: index === 0 ? "start" : "center" }}
              >
                <RenderLegend points={points} showEndPoint={isLastGroup} />
              </LegendStyled>

              {showToolTip && (
                <TooltipStyled
                  flex={tooltipFlex}
                  color={tooltipColor}
                  background={tooltipBgColor || color}
                >
                  {bmiProp.toFixed(1)}
                </TooltipStyled>
              )}
            </TagStyled>
          );
        })}
      </div>
    </BMIChartWrapperStyled>
  );
}

export { BMIChart };
