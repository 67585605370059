import { VerticalText } from "components";
import images, { AppLogoImage } from "constants/images";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { PATHNAME_LIST } from "constants/pathname";
import {
  ExtraContentStyled,
  LayoutContainerStyled,
  LoginImageContainerStyled,
  MainContentStyled,
  TopContentStyled,
} from "./LoginLayout.styled";

export type TLoginLayoutProps = {
  title: string;
};

const LOGIN_LAYOUT_TEXT = {
  LEFT_SIDE: "トレIニング管理",
  RIGHT_SIDE: "コミットプログラム",
  TERM_AND_CONDITION: "利用規約",
  QNA: "よくある質問",
};

function LoginLayout(props: PropsWithChildren<TLoginLayoutProps>) {
  const { children } = props;

  return (
    <LayoutContainerStyled>
      <TopContentStyled>
        <img src={AppLogoImage} alt="logo" className="logo" />

        <MainContentStyled>
          <div className="left-side">
            <VerticalText text={LOGIN_LAYOUT_TEXT.LEFT_SIDE} />
          </div>

          <div className="content">
            {children}

            <ExtraContentStyled>
              <Link to={PATHNAME_LIST.TERMS_AND_CONDITIONS}>
                {LOGIN_LAYOUT_TEXT.TERM_AND_CONDITION}
              </Link>
              <Link to={PATHNAME_LIST.FAQ}>{LOGIN_LAYOUT_TEXT.QNA}</Link>
            </ExtraContentStyled>
          </div>

          <div className="right-side">
            <VerticalText text={LOGIN_LAYOUT_TEXT.RIGHT_SIDE} />
          </div>
        </MainContentStyled>
      </TopContentStyled>

      <LoginImageContainerStyled>
        <img src={images.LoginImage} alt="login" />
      </LoginImageContainerStyled>
    </LayoutContainerStyled>
  );
}

export { LoginLayout };
