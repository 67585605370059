import { Collapse } from "antd";
import { VerticalText } from "components";
import { DEFAULT_PATHNAME } from "constants/pathname";
import { ArrowLeftIcon, PattenInformationUrl } from "constants/svgs";
import { useAppLocation } from "contexts";
import {
  ForgotPasswordRoute,
  LoginRoute,
  PersonalInfoRoute,
} from "routers/router";
import {
  ButtonBackStyled,
  CollapseStyled,
  LayoutContainerStyled,
  MainContentStyled,
  PageTitleStyled,
} from "./InformationLayout.styled";

const { Panel } = Collapse;

export type TAppInformation = {
  question: string;
  answer: string;
};

type TInformationLayoutProps = {
  title: string;
  data: TAppInformation[];
};

const themeData = {
  bgUrl: PattenInformationUrl,
  color: "var(--color-primary)",
};

function InformationLayout(props: TInformationLayoutProps) {
  const { data, title } = props;

  const { backToPrevPage } = useAppLocation();

  const handleGoToPrevPage = () => {
    backToPrevPage([
      DEFAULT_PATHNAME,
      LoginRoute.path,
      ForgotPasswordRoute.path,
      PersonalInfoRoute.path,
    ]);
  };

  return (
    <LayoutContainerStyled
      style={{
        backgroundImage: `url(${themeData.bgUrl})`,
      }}
    >
      <ButtonBackStyled onClick={handleGoToPrevPage}>
        <ArrowLeftIcon /> 戻る
      </ButtonBackStyled>

      <MainContentStyled>
        <PageTitleStyled style={{ color: themeData.color }}>
          <VerticalText text={title} />
        </PageTitleStyled>

        <CollapseStyled
          accordion
          style={data?.length === 0 ? { borderColor: "white" } : undefined}
        >
          {data.map((record, index) => {
            return (
              <Panel
                header={
                  <div
                    dangerouslySetInnerHTML={{ __html: record.question }}
                  ></div>
                }
                key={index}
              >
                <div dangerouslySetInnerHTML={{ __html: record.answer }}></div>
              </Panel>
            );
          })}
        </CollapseStyled>
      </MainContentStyled>
    </LayoutContainerStyled>
  );
}

export { InformationLayout };
