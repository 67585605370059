import { Tag, TagProps } from "antd";
import styled from "styled-components/macro";

export const BMIChartWrapperStyled = styled.div`
  width: 100%;
  padding: max(16px, 16rem);
  border: 1px solid #dddddd;

  background-color: var(--color-white);

  .chart-container {
    margin-top: 8px;
    display: flex;
    gap: 4px;
  }

  .chart-title {
    font-size: var(--font-size-md);
    font-weight: 500;
    color: var(--color-black-2);

    .bmi-group-text {
      font-weight: 700;
    }
  }
`;

type TTagStyled = TagProps & {
  flex: number;
};

export const TagStyled = styled(Tag)<TTagStyled>`
  height: auto;
  width: 100%;
  margin: 44px 0 21px;

  position: relative;
  flex: ${(props: TTagStyled) => props.flex || 1};

  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;

  :first-child {
    border-radius: 100px 0 0 100px;
  }

  :last-child {
    border-radius: 0 100px 100px 0;
  }
`;

export const LegendStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(calc(100% + 4px));
`;

export const PointListWrapper = styled.div`
  display: flex;
`;

export const PointStyled = styled.span`
  flex: 1;
  color: var(--color-black-2);
  line-height: 17px;

  :first-child {
    text-align: start;
  }

  :last-child {
    text-align: end;
  }
`;

type TTooltipStyled = { flex: number; color: string; background: string };
export const TooltipStyled = styled.div<TTooltipStyled>`
  padding: 6rem 8rem;
  position: absolute;
  top: 0;
  left: ${(props) => `${props.flex}%` || 0};
  transform: translate(-50%, calc(-100% - 10px));

  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  font-weight: 700;
  font-size: var(--font-size-sm);
  border-radius: var(--border-radius);

  ::after {
    content: "";
    width: 8px;
    height: 8px;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);

    border-radius: 0 0 var(--border-radius) 0;
    background-color: ${(props) => props.background};
  }
`;
