import images, { MemberDetailBGImage } from "constants/images";
import styled from "styled-components";

export const MemberNewbieContainerStyled = styled.div`
  background: url(${MemberDetailBGImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .overlay-bg {
    min-height: 100vh;
    min-height: 100dvh;
    padding: 25px 24px;

    background-color: var(--color-black-fade);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    @media only screen and (max-width: 800px) {
      background-image: url(${images.PattenMemberDetail});
    }
  }
`;

export const ButtonBackStyled = styled.div`
  width: fit-content;
  position: absolute;
  top: 25px;
  left: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  font-size: var(--font-size-md);
  font-weight: 700;
  color: var(--color-white);
  cursor: pointer;

  svg {
    fill: var(--color-white);
  }
`;

export const MemberIdStyled = styled.div`
  width: fit-content;
  margin-inline: auto;

  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 48rem;
  font-weight: 700;
  font-size: 24px;

  background: linear-gradient(91.78deg, #ffeccf 2.16%, #ffc165 103.68%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  .id-title {
    line-height: 31.2px;
  }

  .member-id {
    font-size: max(48rem, 32px);
    line-height: 61.4px;
  }
`;

export const AppLogoStyled = styled.img`
  display: block;
  margin-inline: auto;
  margin-top: 11px;

  width: max(133rem, 133px);
  height: max(102rem, 102px);
  object-fit: contain;
`;

export const ActionsWrapperStyled = styled.div`
  width: 100%;
  max-width: 252px;
  margin-top: 50rem;
  margin-inline: auto;

  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

export const ButtonLinkStyled = styled.div`
  width: 1px;
  padding: 26px 26px 24px;

  flex: 1;
  flex-basis: 250px;
  display: flex;
  flex-direction: column;

  align-items: center;
  cursor: pointer;

  background: linear-gradient(
      91.78deg,
      rgba(255, 236, 207, 0.3) 2.16%,
      rgba(255, 193, 101, 0.3) 103.68%
    ),
    linear-gradient(
      91.78deg,
      rgba(255, 236, 207, 0.09) 2.16%,
      rgba(255, 193, 101, 0.09) 103.68%
    );

  position: relative;

  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    91.78deg,
    #ffeccf 2.16%,
    #ffc165 103.68%
  );

  :hover {
    background: linear-gradient(
        91.78deg,
        rgba(255, 236, 207, 0.4) 2.16%,
        rgba(255, 193, 101, 0.4) 103.68%
      ),
      linear-gradient(
        91.78deg,
        rgba(255, 236, 207, 0.12) 2.16%,
        rgba(255, 193, 101, 0.12) 103.68%
      );
  }

  .action-icon {
    height: 56px;
    width: 56px;
    object-fit: contain;
  }

  .action-name {
    margin-top: 22px;
    color: var(--color-white) !important;
    font-weight: 700;
  }

  .action-right {
    margin-top: 16px;
    width: 24px;
    height: 24px;
  }
`;
