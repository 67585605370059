import { AppLogoImage, MemberCofirmImage } from "constants/images";
import { PropsWithChildren } from "react";

import {
  LayoutContainerStyled,
  MememberImageContainerStyled,
  MainContentStyled,
  TopContentStyled,
} from "./SecondaryLayout.styled";
import { useNavigate } from "react-router-dom";
import { PATHNAME_LIST } from "constants/pathname";

export type TMemberLayoutProps = {
  title: string;
};

function SecondaryLayout(props: PropsWithChildren<TMemberLayoutProps>) {
  const { children } = props;
  const navigate = useNavigate();

  return (
    <LayoutContainerStyled>
      <TopContentStyled>
        <img
          src={AppLogoImage}
          alt="logo"
          className="logo"
          onClick={() => navigate(PATHNAME_LIST.HOME)}
        />

        <MainContentStyled>
          <div className="content">{children}</div>
        </MainContentStyled>
      </TopContentStyled>

      <MememberImageContainerStyled>
        <img src={MemberCofirmImage} />
      </MememberImageContainerStyled>
    </LayoutContainerStyled>
  );
}

export { SecondaryLayout };
