export const PERSONAL_INFORMATION_TRANSLATE = {
  //common
  TERM_AND_CONDITIONS: "利用規約",
  FAQ: "よくある質問",
  LOGOUT: "ログアウト",

  // AXTOS_HEADQUARTER
  AXTOS_HEADQUARTER: "アクトス本部",
  STORE_NUMBER: "店番 : ",
  STORE: "店",
  EMPLOYEE_NUMBER: "店員 : ",
  PERSON: "人",
  STORE_MANAGEMENT: "店番管理",
  SURVEY_MASTER_MANAGEMENT: "アンケート（マスター管理）",
  TRAINING_PROGRAM_MASTER_MANAGEMENT: "店舗（マスター管理）",
  SHUMOKU_MASTER_MANAGEMENT: "種目（マスター管理）",

  // STORE_ADMIN
  STORE_NAME: "Axtos Shibuya Gym",
  STORE_ID: "店番ID: ",
  STORE_INFORMATION: "店番情報",
  SHUMOKU_LIST: "種目リスト",

  // MASTER SURVEY MODAL
  SURVEY_NAME_PHASE_2: "アンケート２回目",
  SURVEY_NAME_PHASE_1: "アンケート１回目",
  SELECT_SURVEY_MODAL_TITLE: "アンケート（マスター管理）",
};
