export const TRANSLATION = {
  STORES_SELECTED: "選択店番：",
  NEXT: "次へ",
  APPLY: "適用 ",
  TITLE: "トレーニングメニュー管理",
  NOT_FOUNDED: "店番が見つかりませんでした 。",

  //INPUT
  PLACE_HOLDER: "店番名または店番IDを入力してください",
  //BUTTON
  BTN_APPLY: "適用",
  CHANGED_APPLY: "選択店番に変更内容を適用してもよろしいでしょうか。",
  //MODAL
  MODAL_TITLE: "選択店番に変更内容を適用しました！",
  HOMEPAGE: "ホーム",
  PERSONAL_INFO: "設定管理",
  PLEASE_SELECT: "店番を選択してください。",
  BACK: "戻る",
  YES: "はい",

  //CREATE MODAL
  CREATE_MODAL_TITTLE: "店舗の作成",
  STORE_NUMBER: "店番",
  STORE_NAME: "店名",
  EMAIL: "メールアドレス",
  PASSWORD: "パスワード",
  CONFIRM_PASSWORD: "パスワード確認",
  SAVE: "保存",
  CREATE_STORE_SUCCESS: "店が新規作成された。",

  //VIEW DETAIL MODAL
  VIEW_DETAIL: "詳細表示",

  //MESSAGE ERROR
  STORE_NUMBER_REQUIRED: "店番を入力してください。",
  STORE_NAME_REQUIRED: "店名を入力してください。",
  EMAIL_REQUIRED: "メールアドレスを入力してください。",
  PASSWORD_REQUIRED: "パスワードを入力してください。",
  CONFIRM_PASSWORD_REQUIRED: "パスワード確認を入力してください。",
  STORE_NUMBER_EXISTED: "店番が既に存在している。",
  STORE_NAME_EXISTED: "店名が既に存在している。",
  EMAIL_EXISTED: "メールアドレスが既に存在している。",
  EMAIL_INVALID: "メールアドレスの形式が正しくない。",
  STORE_NUMBER_INVALID: "店番が5数字以下である必要。",
  STORE_NAME_INVALID: "店名が254文字以下である必要。",
  PASSWORD_INVALID: "パスワードが6文字以上である必要。",
  CONFIRM_PASSWORD_INVALID: "パスワード確認がパスワードと一致する必要。",
  ALREADY_STORE: "この店舗が使用中である。削除はできません。",

  //SUCCESS NOTIFICATION
  SUCCESS_NOTIFICATION: "リクエストしたコンテンツは正常に更新されました。",
  DELETE_SUCCESS_NOTIFICATION: "選択された店舗は正常に削除されました。",
  UPDATE_SUCCESS_NOTIFICATION: "選択された店舗は正常に更新されました。",
  CREATE_SUCCESS_NOTIFICATION: "店舗は正常に追加されました。",
  CONFIRM_DELETE: "選択した店舗を削除してもよろしいですか？",

  ALL: "全店",
  ACTIVE_STORE: "オープン",
  INACTIVE_STORE: "クローズ",
  SELECT_ALL: "全選択",
};
