import { Modal, Radio } from "antd";
import styled from "styled-components/macro";

type TButtonCheckInWrapper = {
  size?: string;
  space?: string;
};

const DEFAULT_WIDTH = 153;
const DEFAULT_HEIGHT = 175;
const WIDTH_PER_HEIGHT_RATIO = DEFAULT_WIDTH / DEFAULT_HEIGHT;

function getCssSize(number: number, unit?: string) {
  return `${number}${unit || "rem"}`;
}

function getWidth(props: TButtonCheckInWrapper) {
  const { size = getCssSize(DEFAULT_WIDTH) } = props;
  const minWidth = getCssSize(DEFAULT_WIDTH, "px");

  return `max(${minWidth}, ${size})`;
}

function getHeight(props: TButtonCheckInWrapper) {
  const { size = getCssSize(DEFAULT_WIDTH) } = props;
  const minHeight = getCssSize(DEFAULT_HEIGHT, "px");

  return `max(${minHeight}, calc(${size} / ${WIDTH_PER_HEIGHT_RATIO}))`;
}

function getPadding(props: TButtonCheckInWrapper) {
  const { space = "9rem" } = props;

  const paddingBlock = `max(calc(${space} / ${WIDTH_PER_HEIGHT_RATIO}), 9px)`;
  const paddingInline = `max(${space}, calc(9px * ${WIDTH_PER_HEIGHT_RATIO}))`;

  return `${paddingBlock} ${paddingInline}`;
}

export const ButtonCheckInWrapper = styled.div<TButtonCheckInWrapper>`
  width: ${getWidth};
  height: ${getHeight};
  padding: ${getPadding};

  flex: 0 0 ${getHeight};
  background: linear-gradient(269.37deg, #ff9a0333 5.48%, #ff4d0033 97.06%);
  clip-path: var(--hexagon-clip-path);
  cursor: pointer;

  .layer-1 {
    width: 100%;
    height: 100%;
    padding: ${getPadding};

    background: linear-gradient(269.37deg, #ff9a0380 5.48%, #ff4d0080 97.06%);

    clip-path: var(--hexagon-clip-path);

    .layer-2 {
      width: 100%;
      height: 100%;
      padding: ${getPadding};

      background: linear-gradient(269.37deg, #ff9a03 5.48%, #ff4d00 97.06%);
      color: var(--color-white);
      clip-path: var(--hexagon-clip-path);

      display: flex;
      justify-content: center;
      align-items: center;

      text-align: center;
      font-weight: 700;
      font-size: max(24rem, 24px);
      white-space: pre-wrap;
    }
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    padding: 0;

    .ant-modal-footer {
    }
  }
`;

export const RadioGroupStyled = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: 12px;

  label {
    flex-direction: row-reverse;

    border: 1px solid #dddddd;
    padding: 16rem;

    > span:last-child {
      flex: 1;
      padding: 0;
      overflow-wrap: anywhere;
    }

    .program-info-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      .program-icon {
        width: 48px;
        height: 48px;
        flex: 0 0 48px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: #f5f5f5;
        border: 1px solid #e9e9e9;
        color: var(--color-primary);
        font-size: 20px;

        .anticon {
          flex: 0;
        }
      }

      .program-content {
        color: var(--color-black-2);
        line-height: 24px;
      }
    }

    &.ant-radio-wrapper-checked {
      border-color: var(--color-primary);
      background-color: #fff1e8;

      .program-info-wrapper {
        svg {
          fill: var(--color-white);
        }

        .cir {
          fill: var(--color-white);
        }

        .inner-cir {
          fill: var(--color-primary);
        }

        .program-icon {
          background-color: var(--color-primary);
          border-color: var(--color-primary);
          color: var(--color-white);
        }
      }
    }
  }
`;
