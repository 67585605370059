import { Col, Select } from "antd";
import {
  BMIChart,
  BodyIndexChart,
  ButtonBackPage,
  TBodyIndexChartItem,
} from "components";
import { MONTH_OPTIONS, YEAR_OPTIONS } from "constants/common";
import MESSAGES from "constants/messages";
import { GLOBAL_TRANSLATE } from "constants/translate";
import { useAppLoading } from "contexts";
import dayjs from "dayjs";
import memberApi, { TFilterBodyIndex } from "features/member/memberApi";
import { dayjsLocalFromUTC } from "helpers";
import { showErrorNotification } from "helpers/notification";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MemberDetailsRoute, TMemberUrlParams } from "routers/router";
import {
  MemberBodyIndexPageWrapper,
  StatisticWrapper,
} from "./MemberBodyIndexPage.styled";
import { TrainingHistoryList } from "./components";

function MemberBodyIndexPage() {
  const { memberId = "" } = useParams<TMemberUrlParams>();
  const { startLoading, stopLoading } = useAppLoading();

  const [loadingWeight, setLoadingWeight] = useState(false);
  const [loadingBodyFat, setLoadingBodyFat] = useState(false);

  const [currentBMI, setCurrentBMI] = useState(0);
  const [currentDateWeight, setCurrentDateWeight] = useState(dayjs());
  const [currentDateBodyFat, setCurrentDateBodyFat] = useState(dayjs());

  const [weightHistory, setWeightHistory] = useState<TBodyIndexChartItem[]>([]);
  const [bodyFatHistory, setBodyFatHistory] = useState<TBodyIndexChartItem[]>(
    []
  );

  const [error, setError] = useState(false);

  const parseFormatDate = (date: string) => {
    const monthValue = dayjsLocalFromUTC(date).month() + 1;
    const dateValue = dayjsLocalFromUTC(date).date();
    return `${monthValue}月${dateValue}日`;
  };

  useEffect(() => {
    if (error) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    }
  }, [error]);

  useEffect(() => {
    if (!!loadingWeight || !!loadingBodyFat) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [loadingWeight, loadingBodyFat]);

  useEffect(() => {
    !!memberId &&
      filterWeightBodyIndexInfo(
        memberId,
        currentDateWeight.year(),
        currentDateWeight.month() + 1
      );
  }, [currentDateWeight]);

  useEffect(() => {
    !!memberId &&
      filterBodyFatBodyIndexInfo(
        memberId,
        currentDateBodyFat.year(),
        currentDateBodyFat.month() + 1
      );
  }, [currentDateBodyFat]);

  const filterWeightBodyIndexInfo = async (
    memberId: string,
    year?: number,
    month?: number
  ) => {
    try {
      setLoadingWeight(true);
      const params: TFilterBodyIndex = {
        memberId: memberId,
        year: year,
        month: month,
      };
      setError(false);
      const { data } = await memberApi.filterWeightBodyIndexById(params);

      if (data) {
        const temp = data?.map((obj) => {
          return { ...obj, date: parseFormatDate(obj?.date) };
        });

        setWeightHistory(temp);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoadingWeight(false);
    }
  };

  const filterBodyFatBodyIndexInfo = async (
    memberId: string,
    year?: number,
    month?: number
  ) => {
    try {
      setLoadingBodyFat(true);
      const params: TFilterBodyIndex = {
        memberId: memberId,
        year: year,
        month: month,
      };
      setError(false);
      const { data } = await memberApi.filterBodyFatBodyIndexById(params);

      if (data) {
        const temp = data?.map((obj) => {
          return { ...obj, date: parseFormatDate(obj?.date) };
        });

        setBodyFatHistory(temp);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoadingBodyFat(false);
    }
  };

  return (
    <MemberBodyIndexPageWrapper>
      <ButtonBackPage fallbackPath={MemberDetailsRoute.genPath(memberId)} />

      <StatisticWrapper gutter={[24, 24]}>
        <Col xs={24} xl={8}>
          <h2 className="section-title bmi-title">BMI</h2>
          <BMIChart bmi={currentBMI} />
        </Col>

        <Col xs={24} sm={12} xl={8}>
          <div className="header-row">
            <h2 className="section-title">{GLOBAL_TRANSLATE.WEIGHT} (kg)</h2>

            <div className="panel-wrapper">
              <Select
                className="select-year"
                value={currentDateWeight.year()}
                options={YEAR_OPTIONS}
                onChange={(year) =>
                  setCurrentDateWeight(currentDateWeight.year(year))
                }
              />
              <Select
                className="select-month"
                value={currentDateWeight.month()}
                options={MONTH_OPTIONS}
                onChange={(month) =>
                  setCurrentDateWeight(currentDateWeight.month(month))
                }
              />
            </div>
          </div>

          <BodyIndexChart
            data={weightHistory}
            config={{ xField: "date", yField: "value", yUnit: "kg" }}
          />
        </Col>

        <Col xs={24} sm={12} xl={8}>
          <div className="header-row">
            <h2 className="section-title">体脂肪 (%)</h2>

            <div className="panel-wrapper">
              <Select
                className="select-year"
                value={currentDateBodyFat.year()}
                options={YEAR_OPTIONS}
                onChange={(year) =>
                  setCurrentDateBodyFat(currentDateBodyFat.year(year))
                }
              />
              <Select
                className="select-month"
                value={currentDateBodyFat.month()}
                options={MONTH_OPTIONS}
                onChange={(month) =>
                  setCurrentDateBodyFat(currentDateBodyFat.month(month))
                }
              />
            </div>
          </div>

          <BodyIndexChart
            data={bodyFatHistory}
            config={{ xField: "date", yField: "value", yUnit: "%" }}
          />
        </Col>

        <Col span={24}>
          <div className="header-row">
            <h2 className="section-title">履歴</h2>
          </div>
          <TrainingHistoryList
            setCurrentBMI={setCurrentBMI}
            setError={setError}
          />
        </Col>
      </StatisticWrapper>
    </MemberBodyIndexPageWrapper>
  );
}

export { MemberBodyIndexPage };
