import MESSAGES from "constants/messages";
import { useAppLoading } from "contexts";
import memberApi from "features/member/memberApi";
import { showErrorNotification } from "helpers/notification";
import { InformationLayout, TAppInformation } from "layouts";
import { useEffect, useState } from "react";

function FAQPage() {
  const { startLoading, stopLoading } = useAppLoading();

  const [loadingGetFAQ, setLoadingGetFAQ] = useState(false);
  const [error, setError] = useState(false);
  const [FAQList, setFAQList] = useState<TAppInformation[]>([]);

  useEffect(() => {
    if (error) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    }
  }, [error]);

  useEffect(() => {
    if (loadingGetFAQ) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [loadingGetFAQ]);

  useEffect(() => {
    getFileFAQs();
  }, []);

  const getFileFAQs = async () => {
    try {
      setLoadingGetFAQ(true);
      setError(false);
      const { data } = await memberApi.getFileFAQ();

      if (data) {
        setFAQList(data?.faqs);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoadingGetFAQ(false);
    }
  };

  return <InformationLayout data={FAQList} title="よくある質問" />;
}

export { FAQPage };
