import { Radio } from "antd";
import styled from "styled-components/macro";

export const RadioGroupStyled = styled(Radio.Group)`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  gap: 8rem;
`;

export const RadioContainerStyled = styled.div`
  padding: 12rem 16rem 45rem;
  margin: 0;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32rem;

  cursor: pointer;

  svg {
    fill: var(--color-gray);
    height: 55rem;
  }

  label {
    font-size: var(--font-size-md);
  }

  &.selected {
    background-color: var(--color-selected);
    border-color: var(--color-primary);
    /* font-weight: 700; */

    svg {
      fill: var(--color-primary);
    }
  }
`;
