import { Modal } from "antd";
import { pictureSvgs } from "constants/svgs";
import { TShumoku } from "model/program";
import { FC } from "react";
import {
  VideoModalStyled,
  VideoModalWrapperStyled,
} from "./ShumokuMasterDetail.styled";
import { TRANSLATION } from "../ShumokuMaster.translate";
import { TMajorItem } from "model/shumoku";
import { EditFilled } from "@ant-design/icons";

type TProps = {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  video: TShumoku;
  isShowParams?: boolean;
  majorItem: TMajorItem;
  handleOpenUpdateModal: () => void;
};

const ShumokuDetail: FC<TProps> = ({
  open,
  isShowParams = true,
  handleCancel,
  handleOk,
  video,
  majorItem,
  handleOpenUpdateModal,
}) => {
  const handOpenUpdateModal = () => {
    handleCancel();
    handleOpenUpdateModal();
  };
  return (
    <VideoModalStyled>
      <Modal
        centered
        closable={true}
        maskClosable={true}
        open={open}
        maskStyle={{ background: "rgba(23, 24, 29, 0.9)" }}
        wrapClassName="video-modal-wrapper"
        width={621}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        title={TRANSLATION.MODAL_DETAIL_TITLE}
      >
        <VideoModalWrapperStyled>
          <video
            className="video"
            src={video?.videoUrl}
            controls
            poster={
              !video?.videoUrl ? pictureSvgs.ImagePlaceholderUrl : undefined
            }
          >
            Your browser does not support the video tag.
          </video>

          <div>
            <div className="title">
              <p className="shumoku-name">{video?.shumokuName}</p>
              <div className="edit-button" onClick={handOpenUpdateModal}>
                <EditFilled
                  style={{
                    color: "var(--color-primary)",
                  }}
                />
              </div>
            </div>
            {isShowParams && (
              <div className="modal-content">
                {video?.shumokuCode && <p>種目コード: {video.shumokuCode}</p>}
                {majorItem.majorItemName && (
                  <p>項目: {majorItem.majorItemName}</p>
                )}
                {video?.location && <p>部分: {video.location}</p>}
                {video?.tempo && <p>テンポ: {video?.tempo}</p>}
              </div>
            )}
          </div>
        </VideoModalWrapperStyled>
      </Modal>
    </VideoModalStyled>
  );
};

export { ShumokuDetail };
