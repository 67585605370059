import { MIN_CHECK_IN_TIMES_FOR_NEXT_PHASE } from "constants/common";
import { BMIIcon, BloodIcon, HeightIcon, WeightIcon } from "constants/svgs";
import { getFormattedDateFromUTC } from "helpers";
import { TMemberListItem, TTrainingProgram } from "model/member";
import { PROGRAM_STATUS } from "pages/MemberListPage/MemberListPage.const";
import { TEXT_TRANSLATION } from "pages/MemberListPage/MemberListPage.translate";
import React, { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { MemberDetailsRoute } from "routers/router";
import { MemberStyled } from "./MemberItem.styled";
import { ESurveyPhase } from "model/survey";
import { GLOBAL_TRANSLATE } from "constants/translate";

const formatDate = (date?: string) => {
  if (!date) return "";

  return getFormattedDateFromUTC(date);
};

type TProps = {
  item: TMemberListItem;
};

const checkIsReadyForNextPhase = (programList?: TTrainingProgram[]) => {
  if (!programList || programList.length === 0) return false;

  const totalCheckInTimes = programList.reduce(
    (total: number, program) => total + program.numOfTrainingSession,
    0
  );

  return totalCheckInTimes >= MIN_CHECK_IN_TIMES_FOR_NEXT_PHASE;
};

const MemberItem = (props: TProps, ref: any) => {
  const { item: member } = props;

  const navigate = useNavigate();
  // const birthDay = formatDate(member?.birth);
  const registrationDate = formatDate(member?.createdAt);
  const latestCheckIn = formatDate(member?.latestCheckIn);

  const status = useMemo(() => {
    if (!member?.programs || member.programs.length === 0) {
      return PROGRAM_STATUS.NO_PROGRAM;
    }

    const isReadyForNextPhase = checkIsReadyForNextPhase(member.programs);
    const hasDoSecondSurvey = member.phaseResponses?.find(
      (phase) => phase.phaseNumber === ESurveyPhase.PHASE_2
    );

    if (isReadyForNextPhase && !hasDoSecondSurvey) {
      return PROGRAM_STATUS.NEXT_PHASE;
    }

    return PROGRAM_STATUS.TRAINING;
  }, [member?.programs]);

  const renderProgramStatus = (value: number) => {
    switch (value) {
      case PROGRAM_STATUS.NO_PROGRAM:
        return (
          <div className="no-training">
            {TEXT_TRANSLATION.NO_TRAINING_PROGRAM}
          </div>
        );

      case PROGRAM_STATUS.NEXT_PHASE:
        return <div className="next-phase">{TEXT_TRANSLATION.NEXT_PHASE}</div>;

      case PROGRAM_STATUS.TRAINING:
        return (
          <div className="program-status">
            <span className="label">{TEXT_TRANSLATION.DUE_DATE}</span>
            <span className="content">{latestCheckIn || "-"}</span>
          </div>
        );
      default:
        break;
    }
  };

  if (!member) return <Fragment />;

  const BODY_INDEXES = [
    {
      id: "height",
      icon: <HeightIcon />,
      label: GLOBAL_TRANSLATE.HEIGHT,
      content: `${member?.height} cm`,
    },
    {
      id: "weight",
      icon: <WeightIcon />,
      label: GLOBAL_TRANSLATE.WEIGHT,
      content: `${member?.weight} kg`,
    },
    {
      id: "bmi",
      icon: <BMIIcon />,
      label: GLOBAL_TRANSLATE.BMI,
      content: member?.bmi,
    },
    {
      id: "body-fat",
      icon: <BloodIcon />,
      label: GLOBAL_TRANSLATE.BODY_FAT,
      content: `${member?.bodyFat}%`,
    },
  ];

  return (
    <MemberStyled
      ref={ref}
      onClick={() => navigate(MemberDetailsRoute.genPath(member.id))}
    >
      {renderProgramStatus(status)}

      <div className="member-id">
        <span className="label">{TEXT_TRANSLATION.MEMBER_ID}</span>
        <span className="content">{member.memberAxtosId || " -"}</span>
      </div>

      <div className="date">
        {/* <p className="date__item">
          <span className="label">{TEXT_TRANSLATION.BIRTHDAY}</span>
          <span className="content">
            {birthDay ? birthDay : <span className="no-date">_</span>}
          </span>
        </p>
        <span className="date__item">•</span> */}
        <p className="date__item">
          <span className="label">{TEXT_TRANSLATION.REGISTRATION_DATE}</span>
          <span className="content">{registrationDate}</span>
        </p>
      </div>

      <div className="member-info">
        {BODY_INDEXES.map((item) => (
          <div key={item.id} className="member-info__item">
            <span>{item.icon}</span>
            <div>
              <span className="label">{item.label}</span>
              <span className="content">{item.content}</span>
            </div>
          </div>
        ))}
      </div>
    </MemberStyled>
  );
};

const MemberItemForward = React.forwardRef(MemberItem);

export { MemberItemForward };
