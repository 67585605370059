import { StepIconUrl } from "constants/svgs";
import styled from "styled-components";

export const ShumokuManagementSideBarStyled = styled.div`
  position: fixed;
  top: 178px;
  left: 24px;
  bottom: 0;
  width: 165rem;
  max-height: 78vh;

  .step-item {
    display: flex;
    align-items: center;
    gap: 8rem;
    min-height: 70px;
    cursor: pointer;
    max-width: 90%;
  }
  .step-item-active {
    color: var(--color-primary);
    display: flex;
    align-items: center;
    gap: 8rem;
    min-height: 70px;
    cursor: pointer;
    max-width: 90%;

    .label {
      color: var(--color-primary);
      background: url(${StepIconUrl}) center no-repeat;
      background-size: cover;
      border: 0;
    }
  }
  .label {
    flex-shrink: 0;
    border-radius: 50%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: max(24px, 24rem);
    width: max(24px, 24rem);
    background: var(--color-white);
    border: 1px solid rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.25);
  }

  .content {
    max-width: 90%;
    white-space: break-spaces;
    word-wrap: break-word;
    font-size: var(--font-size-sm);
  }

  .apply-btn {
    width: 100%;
    margin-top: 20px;
  }

  .ant-anchor-link-active {
    .active {
      background: url(${StepIconUrl}) center no-repeat;
      background-size: cover;
      border: 0;
    }
  }

  .ant-anchor-ink.ant-anchor-ink-visible {
    display: none !important;
  }

  .ant-anchor-link {
    position: relative;
    padding-block: 0 !important;
    padding-inline: 0 !important;

    &:not(:first-of-type) {
      &::after {
        content: "";
        position: absolute;
        top: -9px;
        left: 8%;
        width: 2px;
        height: 18px;
        background-color: rgb(240, 240, 240);
      }
    }
  }

  .ant-anchor {
    max-height: 70vh;
    &::before {
      border-inline-start: 0 !important;
    }
  }
`;

export const AnchorContainerStyled = styled.div`
  padding: 10px 16px 4px 16px;
  border: 1px solid #f0f0f0;
  max-height: 70vh;
  overflow-y: auto;

  .anchor-wrapper {
    max-height: 70vh !important;
    display: flex;
  }
`;
