import styled from "styled-components";

export const DietListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12rem;
  margin-top: 16rem;
`;

export const DietListContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .update-btn {
    margin-bottom: 46px;
    margin-top: 60px;
    min-width: 206px;
    margin-inline: auto;

    padding: 8rem;
    height: 100%;

    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bolder);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error-field {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-normal);
    color: var(--color-error);
  }
`;
