import { ThemeConfig } from "antd";

export const themeConfig: ThemeConfig = {
  token: {
    borderRadius: 2,
    borderRadiusLG: 2,
    borderRadiusOuter: 2,
    borderRadiusSM: 2,
    borderRadiusXS: 2,

    colorPrimary: "#ff6b00",
    fontFamily: "NotoSansJP, Arial, Helvetica, Verdana, Tahoma, sans-serif",
  },
};
