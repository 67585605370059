import styled from "styled-components";
import { Typography } from "antd";
import { Link } from "react-router-dom";

export const EmailContainerStyled = styled.div`
  width: 100%;

  .btn-item {
    width: 100%;
    min-width: auto;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bolder);
  }

  .title {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bolder);
    color: var(--color-primary);
    text-align: center;
    margin: 35rem 0;
  }

  .custom-form-item .ant-form-item-explain {
    text-align: left;
  }

  .ant-input-affix-wrapper {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-normal);
  }
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleStyled = styled(Typography.Title)`
  font-size: var(--font-size-md) !important;
  font-weight: var(--font-weight-bold) !important;
  color: var(--color-black) !important;
`;

export const LinkStyled = styled(Link)`
  margin-top: 16rem;
  margin-bottom: -12rem;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-md);
  color: var(--color-primary);
  text-decoration: underline;

  &:hover {
    color: var(--color-primary);
    text-decoration: underline;
  }
`;
