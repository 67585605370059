import { Input, InputRef, RadioChangeEvent } from "antd";
import { checkIsNullOrUndefined } from "helpers";
import { TOptionFormValue } from "model/survey";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { PLACE_HOLDER_TEXT, TOptionsProps } from "../../Question";
import { RadioGroupStyled, RadioStyled } from "./OptionsRadio.styled";
import { EmptyAnswer } from "../EmptyAnswer/EmptyAnswer";

type TOptionsRadioProps = TOptionsProps<TOptionFormValue, TOptionFormValue> & {
  smallSize?: boolean;
};

const OTHER_OPT_VALUE = -1;

function OptionsRadio(props: TOptionsRadioProps) {
  const {
    options,
    onChange,
    value,
    smallSize = false,
    hasOther = false,
    readOnly,
    ...restProps
  } = props;
  const [internalValue, setInternalValue] = useState<string | number>();
  const [otherText, setOtherText] = useState("");

  const otherInputRef = useRef<InputRef>(null);

  const handleChangeExternal = (val: any, _otherText: string) => {
    if (!onChange) return;

    if (checkIsNullOrUndefined(val)) {
      onChange(undefined);
      return;
    }

    if (val === OTHER_OPT_VALUE) {
      onChange({
        ...value,
        answer: [],
        otherAnswer: _otherText,
        checkedOther: true,
      });
      return;
    }

    onChange({
      ...value,
      answer: [val],
      otherAnswer: undefined,
      checkedOther: false,
    });
  };

  const handleChange = (e: RadioChangeEvent) => {
    if (readOnly) return;

    setInternalValue(e.target.value);
    handleChangeExternal(e.target.value, otherText);
  };

  const handleTextOtherChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return;

    const otherValue = e.target.value;

    setOtherText(otherValue);
    handleChangeExternal(OTHER_OPT_VALUE, otherValue);
    setInternalValue(OTHER_OPT_VALUE);
  };

  const handleTextOtherBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return;

    if (!e.target.value.trim()) {
      setOtherText("");
      handleChangeExternal(undefined, "");
      setInternalValue(undefined);
    }
  };

  useEffect(() => {
    if (value?.answer && value?.answer?.length < 1 && !value?.otherAnswer) {
      onChange?.(undefined);
      setInternalValue(undefined);
    }

    if (value?.checkedOther) {
      setOtherText(value.otherAnswer || "");
      setInternalValue(OTHER_OPT_VALUE);
    } else {
      setInternalValue(value?.answer?.[0]);
    }
  }, [value]);

  const selectedValue = value?.answer?.[0] || internalValue;
  const showOtherOption = readOnly ? value?.checkedOther : hasOther;
  const optionList = readOnly ? value?.answer || [] : options;

  if (readOnly && optionList.length === 0 && !showOtherOption) {
    return <EmptyAnswer />;
  }

  return (
    <RadioGroupStyled
      onChange={handleChange}
      value={selectedValue}
      style={{
        flexDirection: smallSize ? "row" : "column",
        gap: smallSize ? "16rem 8rem" : "8rem",
      }}
      {...restProps}
    >
      {optionList.map((opt) => {
        return (
          <RadioStyled key={opt} value={opt}>
            {opt}
          </RadioStyled>
        );
      })}

      {showOtherOption && (
        <RadioStyled
          value={OTHER_OPT_VALUE}
          onClick={() => {
            otherInputRef.current?.focus();
          }}
        >
          <Input
            ref={otherInputRef}
            value={otherText}
            onChange={handleTextOtherChange}
            onBlur={handleTextOtherBlur}
            placeholder={PLACE_HOLDER_TEXT}
            readOnly={readOnly}
            {...restProps}
          />
        </RadioStyled>
      )}
    </RadioGroupStyled>
  );
}

export default OptionsRadio;
