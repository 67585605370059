import { Button, Form } from "antd";
import { TUserLogin } from "model/user";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const LoginContainerStyled = styled.div`
  width: 100%;
`;

export const FormStyled = styled(Form<TUserLogin>)`
  &&& .ant-form-item {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      height: unset;
      color: #000000d9;
    }

    .ant-input-affix-wrapper,
    .ant-input-password {
      border-radius: 2px;
    }

    .ant-form-item-explain {
      font-size: var(--font-size-xs);
    }

    .email-input .ant-input-suffix {
      > span {
        margin: 0;
      }
    }
  }
`;

export const ActionsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonSubmitStyled = styled(Button)`
  &&& {
    border-radius: 2px;
    font-weight: 700;
  }
`;

export const LinkStyled = styled(Link)`
  margin-top: 16px;

  color: var(--color-primary) !important;
  text-decoration: underline !important;
  font-size: var(--font-size-sm);
  text-align: center;
`;

export const EyeIconContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;
