import { ArrowLeftIcon } from "constants/svgs";
import { ButtonBackPageWrapperStyled } from "./ButtonBackPage.styled";
import { useAppLocation } from "contexts";

type TButtonBackPageProps = {
  text?: string;
  fallbackPath: string | string[];
  className?: string;
  onBackPage?: () => void;
};

function ButtonBackPage(props: TButtonBackPageProps) {
  const { text = "戻る", fallbackPath, onBackPage, ...restProps } = props;

  const { backToPrevPage } = useAppLocation();

  const handleBackPage = () => {
    if (onBackPage) {
      onBackPage();
      return;
    }

    backToPrevPage(fallbackPath);
  };

  return (
    <ButtonBackPageWrapperStyled {...restProps} onClick={handleBackPage}>
      <ArrowLeftIcon className="icon-arrow-left" />
      <span>{text}</span>
    </ButtonBackPageWrapperStyled>
  );
}

export { ButtonBackPage };
