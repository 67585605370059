import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { DATE_FORMAT, DEFAULT_PAGE_SIZE } from "constants/common";
import memberApi, { TGetBodyIndex } from "features/member/memberApi";
import { dayjsLocalFromUTC } from "helpers";
import { TGetDataInfinityScroll, useInfinityScroll } from "hooks";
import { TBodyIndexInfo } from "model/member";
import { Fragment, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SpinStyled } from "./TrainingHistoryList.styled";
import { GLOBAL_TRANSLATE } from "constants/translate";

const columns: ColumnsType<TBodyIndexInfo> = [
  {
    title: "日付",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (date) => (
      <strong>{dayjsLocalFromUTC(date).format(DATE_FORMAT)}</strong>
    ),
  },
  {
    title: GLOBAL_TRANSLATE.HEIGHT,
    dataIndex: "height",
    key: "height",
    render: (height) => `${height} cm`,
  },
  {
    title: GLOBAL_TRANSLATE.WEIGHT,
    dataIndex: "weight",
    key: "weight",
    render: (weight) => `${weight} kg`,
  },
  {
    title: GLOBAL_TRANSLATE.BMI,
    dataIndex: "bmi",
    key: "bmi",
  },
  {
    title: GLOBAL_TRANSLATE.BODY_FAT,
    dataIndex: "bodyFat",
    key: "bodyFat",
    render: (bodyFat) => `${bodyFat}%`,
  },
  {
    title: "血圧",
    dataIndex: "bloodPressure",
    key: "bloodPressure",
    render: (_, record) => {
      const maxBloodPressure =
        record?.maxBloodPressure === 0 || record?.maxBloodPressure
          ? `${record?.maxBloodPressure}`
          : "-";

      const minBloodPressure =
        record?.minBloodPressure === 0 || record?.minBloodPressure
          ? `${record?.minBloodPressure}`
          : "-";

      if (maxBloodPressure === "-" && minBloodPressure === "-") return "-";

      return `${maxBloodPressure}/${minBloodPressure}`;
    },
  },
  {
    title: "心拍数",
    dataIndex: "heartbeat",
    key: "heartbeat",
    render: (heartbeat) => {
      return heartbeat === 0 || heartbeat ? `${heartbeat} bpm` : "-";
    },
  },
];

type TTrainingHistoryProps = {
  setCurrentBMI: (bmi: number) => void;
  setError: (error: boolean) => void;
};

function TrainingHistoryList(props: TTrainingHistoryProps) {
  const { setCurrentBMI, setError } = props;
  const { memberId = "" } = useParams();

  const getBodyIndexInfo: TGetDataInfinityScroll<TBodyIndexInfo> = useCallback(
    async (pageNumber: number) => {
      const params: TGetBodyIndex = {
        memberId,
        pageNumber,
        pageSize: DEFAULT_PAGE_SIZE,
      };
      const { data, pagination } = await memberApi.getBodyIndexById(params);

      return {
        data,
        pagination: {
          currentPage: pageNumber,
          hasNext: pagination.hasNext,
        },
      };
    },
    [memberId]
  );

  const {
    data: bodyIndexData,
    TriggerItem,
    error,
    loading,
  } = useInfinityScroll<TBodyIndexInfo>({
    getData: getBodyIndexInfo,
  });

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error]);

  useEffect(() => {
    !!bodyIndexData[0]?.bmi &&
      setCurrentBMI(parseFloat(bodyIndexData[0]?.bmi.toString()) || 0);
  }, [bodyIndexData[0]?.bmi]);

  return (
    <Fragment>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={bodyIndexData}
        pagination={false}
        scroll={{ x: "576px" }}
      />
      {loading && <SpinStyled />}
      <TriggerItem />
    </Fragment>
  );
}

export { TrainingHistoryList };
