import { NotificationModal } from "components/NotificationModal/NotificationModal";
import { DEFAULT_MODAL_PROPS } from "constants/common";
import { PATHNAME_LIST } from "constants/pathname";
import { useAppModal } from "contexts";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useMultistep } from "hooks/useMultistep/useMultistep";
import {
  TMajorItem,
  TShumoku,
  TShumokuReq,
  TUpdateShumokuReq,
} from "model/shumoku";
import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { TRANSLATION } from "../ShumokuManagementPage.translate";
import { ShumokuManagement } from "../components/ShumokuManagement/ShumokuManagement";
import { StoreSelection } from "../components/StoreSelection/StoreSelection";

type TProps = {
  children: ReactElement;
};

type TContext = {
  currentStepIndex: number;
  step: ReactElement | null;
  next: () => void;
  back: () => void;
  goTo: (index: number) => void;
  handleApplyShumokus: () => void;
};

const ShumokuContext = createContext<TContext>({
  currentStepIndex: 0,
  step: <></>,
  next: () => {
    //
  },
  back: () => {
    //
  },
  goTo: (_index: number) => {
    //
  },
  handleApplyShumokus: () => {
    //
  },
});

export const useShumokuContext = () => useContext(ShumokuContext);

const ShumokuProvider: FC<TProps> = ({ children }) => {
  const { currentStepIndex, step, next, back, goTo } = useMultistep([
    <StoreSelection key="1" />,
    <ShumokuManagement key="2" />,
  ]);
  const dispatch = useAppDispatch();
  const { appModal } = useAppModal();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { majorItems, selectedStoreIds, selectedShumokuMaterIds } =
    useAppSelector((state) => state.shumoku);

  const shumokus: TShumokuReq[] = useMemo(() => {
    let shumokuList: TShumoku[] = [];

    majorItems?.map((majorItem: TMajorItem) => {
      shumokuList = [...shumokuList, ...majorItem.shumokus];
    });

    return shumokuList.map((shumoku) => {
      return {
        id: shumoku.id,
        isActive: true,
      };
    });
  }, [majorItems]);

  const shumokusAfterChangeStatus = () => {
    return shumokus.map((shumoku) => {
      if (selectedShumokuMaterIds.includes(shumoku.id)) {
        return { ...shumoku, isActive: false };
      }
      return shumoku;
    });
  };

  const updatedShumokuMasterRequest: TUpdateShumokuReq = {
    storeIds: selectedStoreIds,
    shumokuStatus: shumokusAfterChangeStatus(),
  };

  const handleApplyShumokus = () => {
    appModal.confirm({
      ...DEFAULT_MODAL_PROPS.CONFIRM,
      title: TRANSLATION.CHANGED_APPLY,
      cancelText: "キャンセル",
      onOk: () => {
        dispatch(
          shumokuActions.updateShumokuMaster({
            data: updatedShumokuMasterRequest,
            onSuccess: () => {
              setIsModalOpen(true);
            },
          })
        );
      },
    });
  };

  const navigateToHomePage = () => {
    navigate(PATHNAME_LIST.HOME);
  };

  const navigateToPersonalInfo = () => {
    navigate(PATHNAME_LIST.PERSONAL_INFO);
  };

  return (
    <ShumokuContext.Provider
      value={{
        currentStepIndex,
        step,
        next,
        back,
        goTo,
        handleApplyShumokus,
      }}
    >
      {children}
      <NotificationModal
        open={isModalOpen}
        isTwoBtn
        onCancel={() => setIsModalOpen(false)}
        handleBtn1Click={navigateToHomePage}
        btn1Content={TRANSLATION.HOMEPAGE}
        btn2Content={TRANSLATION.PERSONAL_INFO}
        handleBtn2Click={navigateToPersonalInfo}
        title={TRANSLATION.MODAL_TITLE}
        className="modal-style"
      />
    </ShumokuContext.Provider>
  );
};

export { ShumokuProvider };
