import styled from "styled-components";

export const MenuListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12rem;
  flex: 1;
`;

export const MemberListContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .update-btn {
    margin-bottom: 46px;
    margin-top: 40px;
    min-width: 206px;
    margin-inline: auto;

    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bolder);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error-field {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-normal);
    color: var(--color-error);
  }
`;
