import {
  StatisticWrapperStyled,
  TabWrapperStyled,
} from "./StatisticForHeadquarter.styled";
import { useHomePageContext } from "pages/HomePage/contexts";
import { Col, Row, TabsProps } from "antd";
import { STATISTIC_HOME_TRANSLATE } from "../StatisticHomePage/StatisticHomePage.translate";
import { MemberStatisticDonutChart } from "components";
import { DashboardFilterForm } from "../DashboardFilterForm/DashboardFilterForm";
import { SurveyStatisticDonutChart } from "components/SurveyStatisticDonutChart/SurveyStatisticDonutChart";
import { CheckInColumnChart } from "components";
import { TStatisticalSurveyByQuestion } from "features/statistic/statisticApi";

function StatisticForHeadquarter() {
  const {
    headquarterAdminData: {
      // Member analysis
      memberTrainingStatusAnalysisForHeadquarter,
      memberPhaseStatusAnalysisForHeadquarter,
      memberCheckInTrendingForHeadquarter,
      statisticalSurveyForHeadQuarter,
    },
  } = useHomePageContext();

  const memberStatistics = (
    <Col xs={24} md={24} xxl={12} className="section-container">
      <h2 className="section-header">
        {STATISTIC_HOME_TRANSLATE.TITLE_MEMBER_ANALYSIS}
      </h2>
      <Row gutter={[20, 16]} className="section-content">
        <Col xs={24} md={12} xxl={12}>
          <MemberStatisticDonutChart
            title={
              STATISTIC_HOME_TRANSLATE.MEMBER_TRAINING_STATUS_ANALYSIS_TITLE
            }
            data={memberTrainingStatusAnalysisForHeadquarter}
          />
        </Col>

        <Col xs={24} md={12} xxl={12}>
          <MemberStatisticDonutChart
            title={STATISTIC_HOME_TRANSLATE.MEMBER_PHASE_STATUS_ANALYSIS_TITLE}
            data={memberPhaseStatusAnalysisForHeadquarter}
          />
        </Col>
      </Row>
    </Col>
  );

  const checkInTrendingStatistic = (
    <Col xs={24} md={24} xxl={12}>
      <h2 className="section-header">
        {STATISTIC_HOME_TRANSLATE.TITLE_CHECK_IN_ANALYSIS}
      </h2>
      <Row gutter={[20, 16]}>
        <Col xs={24} md={12}>
          <CheckInColumnChart
            title={STATISTIC_HOME_TRANSLATE.TITLE_ALL_TIME_CHECK_IN_REPORT}
            data={memberCheckInTrendingForHeadquarter}
          />
        </Col>
      </Row>
    </Col>
  );

  const showDashboardTab = (
    <Row gutter={[20, 16]} align="stretch">
      {memberStatistics}
      {checkInTrendingStatistic}
    </Row>
  );

  const renderSurveyPieChart = (
    statisticalQuestion: TStatisticalSurveyByQuestion
  ) => {
    return (
      <Col xs={12} xxl={6} key={statisticalQuestion?.questionName}>
        <SurveyStatisticDonutChart
          questionName={statisticalQuestion?.questionName}
          totalAnswer={statisticalQuestion?.totalAnswer}
          answers={statisticalQuestion?.answers}
        />
      </Col>
    );
  };
  const showSurveyTab = (
    <>
      <h2 className="section-header">
        {STATISTIC_HOME_TRANSLATE.SURVEY_INFORMATION}
      </h2>
      <Row gutter={[20, 16]}>
        {statisticalSurveyForHeadQuarter?.map(
          (statisticalQuestion: TStatisticalSurveyByQuestion) =>
            renderSurveyPieChart(statisticalQuestion)
        )}
      </Row>
    </>
  );

  const tabsOfDashboard: TabsProps["items"] = [
    {
      key: STATISTIC_HOME_TRANSLATE.DASHBOARD_TAB,
      label: STATISTIC_HOME_TRANSLATE.DASHBOARD_TAB,
      children: showDashboardTab,
    },
    {
      key: STATISTIC_HOME_TRANSLATE.SURVEY_TAB,
      label: STATISTIC_HOME_TRANSLATE.SURVEY_TAB,
      children: showSurveyTab,
    },
  ];

  return (
    <StatisticWrapperStyled>
      <DashboardFilterForm />
      <Row gutter={[16, 16]} align="stretch">
        <Col xxl={24}>
          <TabWrapperStyled defaultActiveKey="1" items={tabsOfDashboard} />
        </Col>
      </Row>
    </StatisticWrapperStyled>
  );
}

export { StatisticForHeadquarter };
