import {
  CalendarFilled,
  ClockCircleFilled,
  RestFilled,
  SignalFilled,
} from "@ant-design/icons";
import {
  PROGRAM_MENU_CONFIGS,
  SURVEYS as PROGRAM_SURVEYS,
  ProgramMenuType,
} from "constants/program";
import { useAppSelector } from "hooks";
import { TProgramSv, TProgramSvOptions } from "model/program";
import { ReactElement } from "react";
import { MemberConfirmedSurveyItem } from "../MemberConfirmedSurveyItem/MemberConfirmedSurveyItem";
import { MemberConfirmedSurveyStyled } from "./MemberConfirmedSurvey.styled";

export type TSurveyItem = {
  id: string;
  icon: ReactElement;
  content: string | null;
};

const MemberConfirmedSurvey = () => {
  const { programMenu, frequency, duration, phaseNumber, hasOther } =
    useAppSelector((state) => state.program.programSurvey);

  const programMenuConfig =
    PROGRAM_MENU_CONFIGS[programMenu as ProgramMenuType];

  const frequencyName = () => {
    let value: string | undefined = "";

    PROGRAM_SURVEYS.map((item: TProgramSv) => {
      const matchedFrequency = item.options.find(
        (it: TProgramSvOptions) =>
          item.type === "frequency" && it.value === frequency
      );

      if (matchedFrequency) {
        value = matchedFrequency?.name;
      }
    });
    return value ? value : "";
  };

  const stageName = () => {
    let value: string | undefined = "";

    PROGRAM_SURVEYS.map((item: TProgramSv) => {
      const matchedPhase = item.options.find(
        (it: TProgramSvOptions) =>
          item.type === "phaseNumber" && it.value === phaseNumber
      );

      if (matchedPhase) {
        value = matchedPhase?.name;
      }
    });
    return value ? value : "";
  };

  const durationName = () => {
    let value: string | undefined = "";

    PROGRAM_SURVEYS.map((item: TProgramSv) => {
      const matchedPhase = item.options.find(
        (it: TProgramSvOptions) =>
          item.type === "duration" && it.value === duration
      );

      if (matchedPhase) {
        value = matchedPhase?.name;
      }
    });
    return value ? value : "";
  };

  const SURVEYS: TSurveyItem[] = [
    {
      id: "1",
      icon: programMenuConfig?.icon || <RestFilled />,
      content:
        programMenu === ProgramMenuType.OTHER
          ? hasOther
          : programMenuConfig?.name || "-",
    },
    {
      id: "2",
      icon: <SignalFilled />,
      content: stageName(),
    },
    {
      id: "3",
      icon: <CalendarFilled />,
      content: frequencyName(),
    },
    {
      id: "4",
      icon: <ClockCircleFilled />,
      content: durationName(),
    },
  ];
  return (
    <MemberConfirmedSurveyStyled>
      {SURVEYS.map((item: TSurveyItem) => (
        <MemberConfirmedSurveyItem key={item.id} {...item} />
      ))}
    </MemberConfirmedSurveyStyled>
  );
};

export { MemberConfirmedSurvey };
