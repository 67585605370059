import styled from "styled-components";

export const MemberConfirmationStyled = styled.div`
  padding: 0 121rem;
  text-align: center;
  .date-field {
    width: 100% !important;
  }
  .title {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    margin-bottom: 29rem;
    margin-top: 35rem;
  }

  .back-item {
    position: absolute;
    top: 25px;
    left: 24px;
  }

  .date-wrapper {
    .ant-picker-panel {
      width: 100%;
    }
    .ant-picker-date-panel {
      width: 100%;
      align-items: center;
    }

    .ant-picker-year-panel {
      width: 100%;
    }

    .ant-picker-month-panel {
      width: 100%;
    }

    .ant-picker-decade-panel {
      width: 100%;
    }

    .ant-picker-header {
      width: 100%;
      padding: 0px 15px;

      .ant-picker-header-view {
        flex: 1;
      }
    }

    .ant-picker-body {
      width: 100%;

      .ant-picker-content {
        width: 100%;
      }
    }

    .ant-picker-footer {
      display: none;
    }
  }

  .custom-form-item .ant-form-item-explain {
    text-align: left;
  }

  .ant-input-affix-wrapper,
  .ant-picker-input > input {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-normal);
  }
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn-item {
    width: 100%;
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-md);
  }
`;
