import { TStepResponse } from "model/program";
import React, { ForwardedRef, useMemo } from "react";

import { ProgramVideoList } from "../ProgramVideoList/ProgramVideoList";
import {
  ProgramSectionItemStyled,
  SectionStepper,
} from "./ProgramSectionItem.styled";

type TProps = {
  section: TStepResponse;
  type?: string;
};

const ProgramSectionItem = (props: TProps, ref: ForwardedRef<any>) => {
  const { section, type } = props;
  const sectionStepperComponent = useMemo(() => {
    return (
      <SectionStepper>
        <span className="content">{`${section?.stepName}`}</span>
      </SectionStepper>
    );
  }, [section?.stepName]);

  return (
    <ProgramSectionItemStyled ref={ref}>
      {sectionStepperComponent}
      <ProgramVideoList type={type} videos={section?.shumokus} />
    </ProgramSectionItemStyled>
  );
};

const ProgramSectionItemRef = React.forwardRef(ProgramSectionItem);

export { ProgramSectionItemRef };
