import { useEffect } from "react";

let scrollPosition = 0;
let body: HTMLBodyElement | null;
const DISABLE_SCROLLING_CLASS = "scroll-disabled";

const enable = () => {
  scrollPosition = window.pageYOffset;
  body = document.querySelector("body");

  if (body) {
    body.classList.add(DISABLE_SCROLLING_CLASS);
    body.style.top = `-${scrollPosition}px`;
  }
};

const disable = () => {
  if (!body) return;

  body.classList.remove(DISABLE_SCROLLING_CLASS);
  body.style.removeProperty("top");

  window.scrollTo(0, scrollPosition);
};

const useScrollLock = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      enable();
    } else {
      disable();
    }
  }, [isOpen]);

  useEffect(() => {
    return () => disable();
  }, []);
};

export { useScrollLock };
