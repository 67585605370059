import styled from "styled-components";

export const MemberStyled = styled.div`
  background: var(--color-white);
  width: 100%;
  height: 100%;
  border: 1px solid #dddddd;
  padding: 20rem 16rem;
  cursor: pointer;

  .no-training {
    background-color: #fafafa;
    border: solid 1px var(--color-border);
    width: fit-content;
    border-radius: 2rem;
    padding: 1rem 8rem;
    font-size: var(--font-size-xs);
  }

  .next-phase {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    width: max-content;
    border-radius: 2rem;
    padding: 1rem 8rem;
    color: #52c41a;
    font-size: var(--font-size-xs);
  }

  .program-status {
    background-color: var(--color-orange-light);
    border: solid 1px var(--color-primary);
    width: fit-content;
    border-radius: 2rem;
    padding: 1rem 8rem;

    .label {
      font-size: var(--font-size-xs);
      color: var(--color-primary);
    }
    .content {
      font-size: var(--font-size-xs);
      font-weight: var(--font-weight-bolder);
      color: var(--color-primary);
    }
  }

  .member-id {
    margin-top: 9rem;
    .label {
      font-size: 18px;
      font-weight: var(--font-weight-bolder);
      color: var(--color-black);
    }
    .content {
      font-size: 18px;
      font-weight: var(--font-weight-bolder);
      color: var(--color-black);
    }
  }

  .date {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5rem;
    margin-top: 8rem;
    .date__item {
      display: flex;
      align-items: center;
      font-size: var(--font-size-xs);
      font-weight: var(---font-weight-md);
      color: var(--color-gray-bold);
      .label {
        font-size: var(--font-size-xs);
        font-weight: var(---font-weight-md);
        color: var(--color-gray-bold);
      }
      .content {
        font-size: var(--font-size-xs);
        font-weight: var(--font-weight-bolder);
        color: var(--color-gray-bold);
      }
    }
  }

  .member-info {
    background-color: var(--color-orange-light);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16rem 14rem;
    margin-top: 16rem;

    .member-info__item {
      display: flex;
      gap: 4rem;
      div {
        display: flex;
        flex-direction: column;
      }

      .label {
        font-size: var(--font-size-xs);
        font-weight: 400;
        width: max-content;
      }

      .content {
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-bolder);
        width: max-content;
      }
    }
  }

  .no-date {
    margin-left: 4rem;
    margin-top: -10rem;
    display: flex;
  }
`;

export const MemberLeftStyled = styled.div`
  .avatar {
    width: 89rem;
    height: 89rem;
    object-fit: contain;
    border-radius: 100%;
    overflow: hidden;
  }
`;

export const MemberRightStyled = styled.div`
  .member-top {
    display: flex;
    gap: 30rem;

    .member-name {
      font-weight: var(--font-weight-bold);
    }

    .member-id,
    member-birth {
    }
  }

  .member-main {
    display: flex;
    gap: 80rem;
    .member-items {
      display: flex;
      flex-direction: column;

      .member-item {
        display: flex;
        .member-item__label {
        }
        .member-item__content {
        }
      }
    }
  }
`;
