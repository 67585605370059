import { PlusCircleFilled } from "@ant-design/icons";
import { Col } from "antd";
import { SelectShumokuModal, TSubmitShumokuModalValue } from "components";
import images from "constants/images";
import MESSAGES from "constants/messages";
import { GLOBAL_TRANSLATE } from "constants/translate";
import { useAppLoading, useAppModal } from "contexts";
import { programActions } from "features/program/programSlice";
import { showErrorNotification } from "helpers/notification";
import { useAppSelector } from "hooks";
import { TShumoku } from "model/program";
import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { FreeSelectComponentStyled } from "./FreeSelectComponent.styled";

type TProps = {
  freeId: number;
};

const FreeSelectComponent: FC<TProps> = ({ freeId }) => {
  const { startLoading, stopLoading } = useAppLoading();
  const { showModalSuccess } = useAppModal();
  const { programDetail } = useAppSelector((state) => state.program);
  const dispatch = useDispatch();
  const [searchParams, _setSearchParams] = useSearchParams();
  const programMemberId = searchParams.get("programId");

  const shumokuExtensions = useMemo(() => {
    return programDetail?.shumokuExtensions.map((item: TShumoku) => {
      return {
        Id: item?.id,
        ShumokuSetNumber: item?.shumokuSetNumber,
        ShumokuTimes: item?.shumokuTimes,
      };
    });
  }, [programDetail?.shumokuExtensions]);

  const handleAddShumoku = (value: TSubmitShumokuModalValue) => {
    try {
      startLoading();

      const shumokuExtensionList = [
        ...(shumokuExtensions as unknown as TShumoku[]),
        value as unknown as TShumoku,
      ];

      dispatch(
        programActions.updateProgram({
          data: {
            programMemberId: programMemberId as string,
            extensions: JSON.stringify(shumokuExtensionList),
          },
          onSuccess: () => {
            showModalSuccess({
              title: MESSAGES["SCR-W16-MSG-002"],
              okText: GLOBAL_TRANSLATE.CLOSE,
            });
            dispatch(
              programActions.getProgramDetail({
                data: { programId: programMemberId as string },
              })
            );
          },
        })
      );
    } catch (error: any) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };
  return (
    <Col xs={24} md={12} xl={8}>
      <SelectShumokuModal onSubmit={handleAddShumoku}>
        <FreeSelectComponentStyled>
          <div className="video-index">
            <span className="order-number">{freeId + 1}</span>
          </div>
          <img src={images.FreeShumokuImage} />
          <div className="add-container">
            <span className="icon">
              <PlusCircleFilled />
            </span>
            <span className="text">種目選択</span>
          </div>
        </FreeSelectComponentStyled>
      </SelectShumokuModal>
    </Col>
  );
};

export { FreeSelectComponent };
