import { Fragment } from "react";

function VerticalText({ text }: { text: string }) {
  return (
    <Fragment>
      {text.split("").map((char, index) => {
        return (
          <Fragment key={`${char}-${index}`}>
            {char} <br />
          </Fragment>
        );
      })}
    </Fragment>
  );
}

export { VerticalText };
