import { Button, Modal, Radio, RadioChangeEvent } from "antd";
import { PhaseTag } from "components";
import { CUSTOM_MODAL_CLASS_NAME, DEFAULT_MODAL_PROPS } from "constants/common";
import MESSAGES from "constants/messages";
import { PROGRAM_MENU_CONFIGS, ProgramMenuType } from "constants/program";
import { useAppLoading, useAppModal } from "contexts";
import memberApi, { TCheckInParamsReq } from "features/member/memberApi";
import { getFormattedDateFromUTC, getISOStringDate } from "helpers";
import {
  showErrorNotification,
  showInfoNotification,
} from "helpers/notification";
import { TTrainingProgram } from "model/member";
import { Fragment, useEffect, useState } from "react";
import { ButtonCheckInWrapper, RadioGroupStyled } from "./ButtonCheckIn.styled";

type TButtonCheckInProps = {
  className?: string;
  trainingPrograms?: TTrainingProgram[];
  onCheckInSuccess?: (val: TCheckInParamsReq) => void;
  memberId: string;
};

const BTN_CHECK_IN_TEXT = "チェック\nイン";
const MESSAGE_NOT_HAS_PROGRAM = "トレーニングメニューが存在していません。";

function ButtonCheckIn(props: TButtonCheckInProps) {
  const {
    className,
    trainingPrograms = [],
    onCheckInSuccess,
    memberId,
  } = props;

  const [openModalProgram, setOpenModalProgram] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState("");
  const { appModal } = useAppModal();
  const { startLoading, stopLoading } = useAppLoading();

  // Functions
  const onChange = (e: RadioChangeEvent) => {
    setSelectedProgram(e.target.value);
  };

  const openModalSelectProg = () => {
    if (trainingPrograms.length <= 0) {
      showInfoNotification(MESSAGE_NOT_HAS_PROGRAM);
      return;
    }

    setOpenModalProgram(true);
  };

  const closeModalSelectProg = () => setOpenModalProgram(false);

  const handleCheckIn = async () => {
    if (!selectedProgram) return;

    function handleCheckInFailed() {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    }

    try {
      closeModalSelectProg();
      startLoading();

      const checkInParam: TCheckInParamsReq = {
        memberId,
        programId: selectedProgram,
        checkInDate: getISOStringDate(),
      };

      const res = await memberApi.checkInProgram(checkInParam);

      if (res.succeeded) {
        appModal.success({
          ...DEFAULT_MODAL_PROPS.SUCCESS,
          title: "チェックインが完了しました！",
          okText: "終了",
        });

        onCheckInSuccess?.(checkInParam);
      } else {
        handleCheckInFailed();
      }
    } catch (e) {
      handleCheckInFailed();
    } finally {
      stopLoading();
    }
  };

  // Effects
  useEffect(() => {
    if (trainingPrograms.length > 0) {
      setSelectedProgram(trainingPrograms[0].programMaster.id);
    }
  }, [trainingPrograms]);

  const buttonSubmit = (
    <Button type="primary" onClick={handleCheckIn}>
      チェックイン
    </Button>
  );

  return (
    <Fragment>
      <ButtonCheckInWrapper className={className} onClick={openModalSelectProg}>
        <div className="layer-1">
          <div className="layer-2">{BTN_CHECK_IN_TEXT}</div>
        </div>
      </ButtonCheckInWrapper>

      <Modal
        open={openModalProgram}
        onCancel={closeModalSelectProg}
        centered
        width={572}
        maskClosable={false}
        title="トレーニングメニューを選択してください。"
        className={CUSTOM_MODAL_CLASS_NAME}
        footer={buttonSubmit}
      >
        <RadioGroupStyled onChange={onChange} value={selectedProgram}>
          {trainingPrograms.map((prog) => {
            const {
              index,
              programMenu,
              latestCheckIn: lastCheckInDate,
              programMaster: { id: programMasterId },
              exerciseMenuPurpose,
            } = prog;

            const { name = "Unknown program", icon } =
              PROGRAM_MENU_CONFIGS[programMenu] || {};

            return (
              <Radio key={programMasterId} value={programMasterId}>
                <div className="program-info-wrapper">
                  <div className="program-icon">{icon}</div>
                  <div className="program-content">
                    <p className="program-title">
                      <strong>
                        {programMenu === ProgramMenuType.OTHER
                          ? exerciseMenuPurpose
                          : name}
                      </strong>
                    </p>
                    <PhaseTag
                      phaseIndex={index}
                      style={{ marginBlock: "5px" }}
                    />

                    <p className="program-end-date">
                      トレーニング最終日:{" "}
                      <strong>
                        {lastCheckInDate
                          ? getFormattedDateFromUTC(lastCheckInDate)
                          : "-"}
                      </strong>
                    </p>
                  </div>
                </div>
              </Radio>
            );
          })}
        </RadioGroupStyled>
      </Modal>
    </Fragment>
  );
}

export { ButtonCheckIn };
