import styled from "styled-components";

export type TSurveyStepProps = {
  current?: number;
  maxStep?: number;
};

function SurveyStep(props: TSurveyStepProps) {
  const { maxStep = 0, current = 0 } = props;

  return (
    <StepContainerStyled>
      {Array(maxStep)
        .fill(0)
        ?.map((_, index) => {
          return (
            <StepItemStyled
              key={index}
              style={{
                backgroundColor: index <= current - 1 ? "#ffffff" : "#ffffff50",
              }}
            />
          );
        })}
    </StepContainerStyled>
  );
}

const StepContainerStyled = styled.div`
  display: flex;
  gap: 6rem;
`;

const StepItemStyled = styled.div`
  width: 100%;
  height: 8rem;
  min-height: 8px;
  flex: 1;
`;

export { SurveyStep };
