import { Button, Form, Radio } from "antd";
import { useForm } from "antd/lib/form/Form";
import { NotificationModal } from "components/NotificationModal/NotificationModal";
import MESSAGES from "constants/messages";
import { PROGRAM_SURVEY_TYPES, SURVEYS } from "constants/program";
import { useAppLoading } from "contexts";
import { TProgramSurvey, programActions } from "features/program/programSlice";
import surveyApi from "features/survey/surveyApi";
import { showErrorNotification } from "helpers/notification";
import { useAppDispatch, useAppSelector } from "hooks";
import { TProgramSv, TProgramSvOptions } from "model/program";
import {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TrainingProgramDetailRoute } from "routers/router";
import {
  ProgramDetailEditingMainStyled,
  ProgramEditingContainerStyled,
  ProgramSurveyContainerStyled,
  ProgramSurveyStyled,
  SurveryItemTitleStyled,
  SurveyWrapperStyled,
} from "./ProgramDetailEditingMain.styled";

const TRANSLATIONS = {
  PLEASE_SELECT: "回答を選択してください。",
  TITLE: "トレーニングメニュー更新",
  MODAL_MESSAGE_TITLE: "選択内容を変更しました。",
  MODAL_OK: "終了 ",
};

const PROGRAM_SURVEY_QUESTIONS = {
  PHASE_NUMBER: "1",
  FREQUENCY: "2",
  DURATION: "3",
};

export const PARAMS = {
  MEMBER_ID: "memberId",
  PROGRAM_MEMBER_ID: "programMemberId",
  PHASE_NUMBER: "phaseNumber",
};

const QUESTIONS: string[] = ["phaseNumber", "frequency", "duration"];

type TSelectedOptions = {
  [key in (typeof PROGRAM_SURVEY_QUESTIONS)[keyof typeof PROGRAM_SURVEY_QUESTIONS]]: number;
};

const ProgramDetailEditingMain: FC = () => {
  const [form] = useForm();
  const { startLoading, stopLoading } = useAppLoading();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [selectedOptions, setSelectedOptions] = useState<TSelectedOptions>({});
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [_activeButton, setActiveButton] = useState(false);
  const isFirstRender = useRef(true);

  const programMemberId = searchParams.get(PARAMS.PROGRAM_MEMBER_ID);
  const memberId = searchParams.get(PARAMS.MEMBER_ID) || "";

  const {
    programSurvey: {
      programMenu,
      frequency,
      duration,
      phaseNumber,
      hasOther: exerciseMenuPurpose,
    },
    questionAndAnswerSurvey,
  } = useAppSelector((state) => ({
    programSurvey: state.program.programSurvey,
    questionAndAnswerSurvey: state.program.questionAndAnswerSurvey,
  }));

  const handleOptionChange = (groupId: string, option: number) => {
    setSelectedOptions((prevSelectedOptions: any) => ({
      ...prevSelectedOptions,
      [groupId]: option,
    }));

    setValidationErrors((prevValidationErrors: any) => ({
      ...prevValidationErrors,
      [groupId]: undefined, // Reset the error message when an option is selected
    }));
  };

  const getContainerLayout = (groupId: string) => {
    switch (groupId) {
      case "1":
        return "item--1";
      case "2":
        return "item--2";
      case "3":
        return "item--3";
      default:
        return "";
    }
  };

  const onFinish = () => {
    const newValidationErrors: any = {};

    SURVEYS.forEach((item) => {
      if (!selectedOptions[item.id]) {
        newValidationErrors[item.id] = {
          errors: [{ message: TRANSLATIONS.PLEASE_SELECT }],
        };
      }
    });

    setValidationErrors(newValidationErrors);

    if (Object.keys(newValidationErrors).length > 0) {
      return;
    }

    let data = {};
    Object.entries(selectedOptions).map(([_, value], index) => {
      data = { ...data, [QUESTIONS[index]]: value };
    });

    dispatch(programActions.setProgramSurvey({ data: data as TProgramSurvey }));
  };

  const handleClick = async () => {
    dispatch(
      programActions.updateProgramForMember({
        data: {
          programMemberId: programMemberId as string,
          programBaseRequest: {
            memberId: memberId as string,
            programMenu: programMenu as number,
            frequency,
            duration,
            phaseNumber,
            exerciseMenuPurpose: exerciseMenuPurpose,
          },
        },
        onSuccess: () => {
          handleUpdateSurvey();
          setIsOpenModal(true);
        },
      })
    );
  };

  const handleModalBtnClick = () => {
    navigate(
      TrainingProgramDetailRoute.genPath({
        memberId: memberId as string,
        programId: programMemberId as string,
      }),
      {
        replace: true,
      }
    );
  };

  //update survey
  const programSurveyTypes = [
    { type: PROGRAM_SURVEY_TYPES.FREQUENCY, value: frequency },
    { type: PROGRAM_SURVEY_TYPES.DURATION, value: duration },
  ];

  const updatedSurveyRequest = useMemo(() => {
    return questionAndAnswerSurvey.map((item, index) => {
      let answer: TProgramSvOptions | undefined;

      SURVEYS.find((item: TProgramSv) => {
        if (item.type === programSurveyTypes[index].type) {
          answer = item.options.find((option) => {
            return option.value === programSurveyTypes[index].value;
          });
        }
      });

      let updatedAnswerName = answer?.name;

      if (updatedAnswerName === "1週間に1~3回") {
        updatedAnswerName = "1~3回";
      }
      if (updatedAnswerName === "1週間に4回以上") {
        updatedAnswerName = "4回以上";
      }

      return {
        ...item,
        answer: JSON.stringify([updatedAnswerName]),
        phaseNumber,
      };
    });
  }, [questionAndAnswerSurvey, programSurveyTypes]);

  const handleUpdateSurvey = async () => {
    try {
      startLoading();
      const { succeeded } = await surveyApi.updateSurvey({
        memberId,
        questions: updatedSurveyRequest,
      });

      if (!succeeded) {
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      }
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    setSelectedOptions({
      [PROGRAM_SURVEY_QUESTIONS.PHASE_NUMBER]: phaseNumber,
      [PROGRAM_SURVEY_QUESTIONS.FREQUENCY]: frequency,
      [PROGRAM_SURVEY_QUESTIONS.DURATION]: duration,
    });
  }, [phaseNumber, frequency, duration]);

  useEffect(() => {
    let data = {};
    Object.entries(selectedOptions).map(([_, value], index) => {
      data = { ...data, [QUESTIONS[index]]: value };
    });

    dispatch(programActions.setProgramSurvey({ data: data as TProgramSurvey }));
  }, [selectedOptions]);

  useEffect(() => {
    // Check if the default option has changed
    if (
      (selectedOptions[PROGRAM_SURVEY_QUESTIONS.PHASE_NUMBER] !== phaseNumber ||
        selectedOptions[PROGRAM_SURVEY_QUESTIONS.FREQUENCY] !== frequency ||
        selectedOptions[PROGRAM_SURVEY_QUESTIONS.DURATION] !== duration) &&
      (selectedOptions[PROGRAM_SURVEY_QUESTIONS.PHASE_NUMBER] !== undefined ||
        selectedOptions[PROGRAM_SURVEY_QUESTIONS.FREQUENCY] !== undefined ||
        selectedOptions[PROGRAM_SURVEY_QUESTIONS.DURATION] !== undefined)
    ) {
      setActiveButton(false);
    }
  }, [selectedOptions]);

  useLayoutEffect(() => {
    if (isFirstRender.current) {
      setActiveButton(true);
    }
    isFirstRender.current = false;

    return () => {
      dispatch(
        programActions.setProgramSurvey({
          data: {
            programMenu: null,
            frequency: 0,
            duration: 0,
            phaseNumber: 0,
          } as TProgramSurvey,
        })
      );
    };
  }, []);

  return (
    <ProgramEditingContainerStyled>
      <ProgramDetailEditingMainStyled>
        <Form
          onFinish={onFinish}
          form={form}
          validateMessages={{
            required: TRANSLATIONS.PLEASE_SELECT,
          }}
        >
          <ProgramSurveyContainerStyled>
            <ProgramSurveyStyled>
              {SURVEYS.map((item) => {
                const containerLayout = getContainerLayout(item.id);
                return (
                  <div key={item.id}>
                    <SurveryItemTitleStyled className={`${containerLayout}`}>
                      <span
                        className={`shape ${
                          validationErrors[item.id] ? "hasError" : ""
                        }`}
                      >
                        <span
                          className={`number ${
                            validationErrors[item.id] ? "hasError" : ""
                          }`}
                        >
                          {item.id}
                        </span>
                      </span>
                      <span
                        className={`title ${
                          validationErrors[item.id] ? "hasError" : ""
                        }`}
                      >
                        {item.title}
                      </span>
                    </SurveryItemTitleStyled>
                    <SurveyWrapperStyled className={`${containerLayout} `}>
                      <Radio.Group
                        name={item.title}
                        className={`${containerLayout} radio-group`}
                        value={selectedOptions[item.id]}
                        onChange={(e) =>
                          handleOptionChange(item.id, e.target.value)
                        }
                      >
                        {item.options.map((option) => {
                          const isSelected =
                            selectedOptions[item.id] === option.value;
                          const radioClassName = isSelected
                            ? "radio-item selected"
                            : "radio-item";

                          return (
                            <div key={option.id} className="radio-container">
                              <Radio
                                value={option.value}
                                className={radioClassName}
                                disabled={
                                  item.id ===
                                  PROGRAM_SURVEY_QUESTIONS.PHASE_NUMBER
                                }
                                onClick={() =>
                                  handleOptionChange(item.id, option.value)
                                }
                              >
                                {option.name}
                              </Radio>
                            </div>
                          );
                        })}
                      </Radio.Group>
                      {validationErrors[item.id] && (
                        <div className="error-message">
                          {validationErrors[item.id]?.errors[0].message}
                        </div>
                      )}
                    </SurveyWrapperStyled>
                  </div>
                );
              })}
            </ProgramSurveyStyled>
          </ProgramSurveyContainerStyled>
        </Form>
      </ProgramDetailEditingMainStyled>

      <Button type="primary" className="update-btn" onClick={handleClick}>
        保存{" "}
      </Button>
      <NotificationModal
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        btnContent={TRANSLATIONS.MODAL_OK}
        handleBtnClick={handleModalBtnClick}
        title={TRANSLATIONS.MODAL_MESSAGE_TITLE}
      />
    </ProgramEditingContainerStyled>
  );
};

export { ProgramDetailEditingMain };
