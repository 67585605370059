import styled from "styled-components";

export const HomePageButtonWrapper = styled.div`
  position: fixed;
  bottom: 35px;
  right: 35px;

  cursor: pointer;
  display: grid;
  place-items: center;

  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;

  background: linear-gradient(266deg, #ff9a03 0%, #ff4d00 100%);
`;
