import { TResponseWithPagination } from "types/service";
import { TMemberDetail } from "./member";

export type TStore = {
  id: string;
  storeNumber: string;
  storeName: string;
  isActive: boolean;
};

export type TStoreMore = {
  stores: TStore[];
  hasMore: boolean;
};

export type TShumoku = {
  id: string;
  shumokuName: string;
  shumokuCode: string;
  shumokuSetNumber: number;
  shumokuTimes: string;
  hasVideo: boolean;
  thumbnail: string;
  videoUrl: string;
  location: string;
  tempo: string;
};

type TProgramMasterResponse = {
  shumokuMasterId: string;
  programMasterId: string;
  id: string;
};

export type TShumokuResponse = {
  shumokuId: string;
  shumokuName: string;
  shumokuCode: string;
  thumbnail: string;
  videoUrl: string;
  location: string;
  tempo: string;
  majorItemMasterId?: string;
  programMasters?: TProgramMasterResponse[];
  shumokuStores?: TStore[];
};

export type TMajorItem = {
  id: string;
  majorItemName: string;
  majorItemCode: string;
  index: number;
  shumokus: TShumoku[];
};

export type TShumokuReq = {
  id: string;
  isActive: boolean;
};

export type TUpdateShumokuReq = {
  storeIds: string[];
  shumokuStatus: TShumokuReq[];
};

export type TCreateStoreReq = {
  storeNumber: string;
  storeName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export type TUpdateStoreReq = {
  storeNumber?: string;
  storeName?: string;
  email?: string;
  isActive?: boolean;
};

export type TStoreResponse = {
  storeId: string;
  storeNumber: string;
  storeName: string;
  email: string;
  members: TMemberDetail[];
  isActive: boolean;
};

export type TDeleteStoreRequest = {
  storeIds: string[];
};

export type TDeleteShumokuRequest = {
  shumokuIds: string[];
};

export type TCreateMajorItemMasterReq = {
  majorItemCode: string;
  majorItemName: string;
};

export type TUpdateMajorItemMasterReq = {
  majorItemCode: string;
  majorItemName: string;
};

export enum ECreateStoreError {
  EXISTED_USER = "ExistedUser",
  EXISTED_STORE_NAME = "StoreNameIsExisted",
  EXISTED_STORE_NUMBER = "StoreNumberIsExisted",
}

export enum ECreateShumokuMasterError {
  EXISTED_SHUMOKU_MASTER_CODE = "ShumokuCodeIsExisted",
  EXISTED_SHUMOKU_MASTER_NAME = "ShumokuNameIsExisted",
  INVALID_VIDEO_FORMAT = "VideoExtensionIsNotValid",
  INVALID_THUMBNAIL_FORMAT = "ThumbnailExtensionIsNotValid",
}

export type TShumokusResponse = TResponseWithPagination<TMajorItem[]>;

export enum ECreateMajorItemMasterError {
  MAJOR_ITEM_CODE_IS_EXISTED = "MajorItemCodeIsExisted",
  MAJOR_ITEM_CODE_IS_INVALID = "MajorItemCodeIsInvalid",
  MAJOR_ITEM_NAME_IS_EXISTED = "MajorItemNameIsExisted",
}
export enum EUpdateMajorItemMasterError {
  MAJOR_ITEM_CODE_IS_EXISTED = "MajorItemCodeIsExisted",
  MAJOR_ITEM_CODE_IS_INVALID = "MajorItemCodeIsInvalid",
  MAJOR_ITEM_NAME_IS_EXISTED = "MajorItemNameIsExisted",
}
