import { Button, Modal } from "antd";
import { getShumokuSectionList } from "helpers/program";
import { useAppSelector } from "hooks";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { ProgressStepper } from "../ProgressStepper/ProgressStepper";
import { ProgramSideBarStyled } from "./ProgramSideBar.styled";
import { PATHNAME_LIST } from "constants/pathname";

const TRANSLATION = {
  GENERATE_QRCODE: "QRコードを表示",
};

const ProgramSideBar = () => {
  const stepsResponse =
    useAppSelector((state) => state.program.programDetail?.stepsResponse) || [];
  const userId = useAppSelector((state) => state.auth.user?.id);

  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const memberProgramId = searchParams.get("programId");

  const sectionList = getShumokuSectionList(stepsResponse);

  const handleClick = () => {
    setOpen(true);
  };

  const QRCodeComponent = useMemo(() => {
    const baseUrl = window.location.origin;
    const url = `${baseUrl}${PATHNAME_LIST.PUBLIC_PROGRAM}?userId=${userId}&memberProgramId=${memberProgramId}`;
    return (
      <QRCode
        size={256}
        style={{
          height: "auto",
          maxWidth: "100%",
          width: "100%",
          marginTop: "30px",
        }}
        value={url}
        viewBox={`0 0 256 256`}
      />
    );
  }, [userId, memberProgramId]);

  return (
    <ProgramSideBarStyled className="side-bar-wrapper">
      <div className="progress-bar-wrapper">
        <ProgressStepper stepItems={sectionList} />
      </div>
      <Button type="primary" className="gen-qrcode" onClick={handleClick}>
        {TRANSLATION.GENERATE_QRCODE}
      </Button>
      <Modal
        open={open}
        width={300}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        centered
        footer={false}
        maskClosable={false}
      >
        {QRCodeComponent}
      </Modal>
    </ProgramSideBarStyled>
  );
};

export { ProgramSideBar };
