import { FC, useEffect } from "react";
import { ProgramSecondaryLayout } from "pages/TrainingProgramPage/layout/ProgramSecondaryLayout/ProgramSecondaryLayout";
import { ProgramSideBar } from "../ProgramSideBar/ProgramSideBar";
import { ProgramSectionList } from "./components/ProgramSectionList/ProgramSectionList";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import { PROGRAM_STEPS } from "constants/program";

const navigateOptions: NavigateOptions = {
  replace: true,
};

const TrainingPrograms: FC = () => {
  const [_searchParams, setSearchParams] = useSearchParams({});

  useEffect(() => {
    setSearchParams({ step: PROGRAM_STEPS.STEP_THREE }, navigateOptions);
  }, []);

  return (
    <ProgramSecondaryLayout
      left={<ProgramSideBar />}
      right={<ProgramSectionList />}
    />
  );
};

export { TrainingPrograms };
