import {
  DEFAULT_WIDTH_DESIGN,
  HAS_SHOW_ONBOARDING,
  MAX_WIDTH_DEVICE,
  ONBOARDING_DELAY,
} from "constants/common";
import { AppLocationProvider } from "contexts";
import authApi from "features/auth/authApi";
import { authActions } from "features/auth/authSlice";
import { getAuthToken } from "helpers/auth";
import { useAppDispatch } from "hooks";
import { OnboardingPage } from "pages/OnboardingPage/OnboardingPage";
import { useLayoutEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { AppRouting } from "routers";

function App() {
  const [isLoadingInitData, setIsLoadingInitData] = useState(true);

  const dispatch = useAppDispatch();

  const getCurrentUser = async () => {
    const { accessToken } = getAuthToken();
    const hasShowOnBoarding =
      localStorage.getItem(HAS_SHOW_ONBOARDING) === "true";

    let onBoardingDelay = 0;

    if (!hasShowOnBoarding) {
      onBoardingDelay = ONBOARDING_DELAY;
      localStorage.setItem(HAS_SHOW_ONBOARDING, "true");
    }

    // Not get current user if not has access token
    if (!accessToken) {
      setTimeout(() => {
        setIsLoadingInitData(false);
      }, onBoardingDelay);
      return;
    }

    const delayPromise = new Promise((resolve, _reject) => {
      setTimeout(() => {
        resolve(null);
      }, onBoardingDelay);
    });

    setIsLoadingInitData(true);

    // Await get current user and delay time
    Promise.all([authApi.getCurrentUser(), delayPromise])
      .then(([resUserInfo]) => {
        if (resUserInfo.data) {
          dispatch(
            authActions.updateUserInfo({
              data: resUserInfo.data,
            })
          );
        }
      })
      .catch((_e) => {
        //
      })
      .finally(() => {
        setIsLoadingInitData(false);
      });
  };

  // Reset rem by screen size
  useLayoutEffect(() => {
    function updateRem() {
      const innerWidth = Math.min(
        window.innerWidth,
        MAX_WIDTH_DEVICE,
        document.body.offsetWidth
      ); // (only support for mobile device)

      const vw = innerWidth / DEFAULT_WIDTH_DESIGN;
      document.documentElement.style.fontSize = vw + "px";
    }

    window.addEventListener("resize", updateRem);
    updateRem();
    return () => window.removeEventListener("resize", updateRem);
  }, []);

  useLayoutEffect(() => {
    getCurrentUser();
  }, []);

  if (isLoadingInitData) {
    return <OnboardingPage />;
  }

  return (
    <BrowserRouter>
      <AppLocationProvider>
        <AppRouting />
      </AppLocationProvider>
    </BrowserRouter>
  );
}

export default App;
