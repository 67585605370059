import styled from "styled-components";

export const HomePageWrapperStyled = styled.div`
  min-height: 100vh;
  min-height: 100dvh;

  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
`;
