import { call, put, takeLatest } from "redux-saga/effects";
import { TAction } from "types/common";
import shumokuApi, { TGetAllStoresParams } from "./shumokuApi";
import { shumokuActions } from "./shumokuSlice";
import { TResponseWithPagination } from "types/service";
import {
  TMajorItem,
  TStore,
  TStoreMore,
  TUpdateShumokuReq,
} from "model/shumoku";
import { showErrorNotification } from "helpers/notification";
import MESSAGES from "constants/messages";

export function* getAllStores(action: TAction<TGetAllStoresParams>) {
  try {
    const response: TResponseWithPagination<TStore[]> = yield call(
      shumokuApi.getAllStores,
      action.payload.data
    );

    const hasMore = response.pagination.hasNext;
    const data: TStoreMore = {
      stores: response.data,
      hasMore,
    };

    yield put(shumokuActions.getAllStoresSuccess({ data }));
  } catch (error) {
    yield put(shumokuActions.getAllStoresFailure());
    showErrorNotification(MESSAGES["COM-MSG-002"]);
  }
}

export function* getAllShumokus() {
  try {
    const response: TResponseWithPagination<TMajorItem[]> = yield call(
      shumokuApi.getAllShumokus
    );
    yield put(shumokuActions.getAllShumokusSuccess({ data: response.data }));
  } catch (error) {
    yield put(shumokuActions.getAllShumokusFailure());
    showErrorNotification(MESSAGES["COM-MSG-002"]);
  }
}

export function* updateShumokuMaster(action: TAction<TUpdateShumokuReq>) {
  try {
    yield call(shumokuApi.updateShumokuMaster, action.payload.data);

    yield put(shumokuActions.updateShumokuMasterSuccess());
    action.payload.onSuccess?.();
  } catch (error) {
    yield put(shumokuActions.updateShumokuMasterFailure());
    showErrorNotification(MESSAGES["COM-MSG-002"]);
  }
}

export const shumokuWatcher = [
  takeLatest(shumokuActions.getAllStores, getAllStores),
  takeLatest(shumokuActions.getAllShumokus, getAllShumokus),
  takeLatest(shumokuActions.updateShumokuMaster, updateShumokuMaster),
];
