import { FC } from "react";
import {
  ProgressBarStyled,
  ProgressStepItemStyled,
} from "./ProgressBar.styled";

type TProps = {
  stepNumber?: number;
  currentStepIndex?: number;
};

const ProgressBar: FC<TProps> = ({ stepNumber = 3, currentStepIndex }) => {
  const progressNumber = Array.from({ length: stepNumber }, (_, index) => {
    let className;
    index > (currentStepIndex || 0)
      ? (className = "in-active")
      : (className = "active");
    return <ProgressStepItemStyled key={index} className={className} />;
  });

  return <ProgressBarStyled>{progressNumber}</ProgressBarStyled>;
};

export { ProgressBar };
