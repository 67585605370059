import { EMemberStatus } from "constants/common";
import { TMember } from "model/member";
import { STATUS } from "pages/MemberListPage/MemberListPage.const";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { TAction } from "types/common";
import { TResponse } from "types/service";
import memberApi, { EGetMemberError, TConfirmMemberRes } from "./memberApi";
import { memberActions } from "./memberSlice";

export function* confirmMember(action: TAction<TMember>): any {
  try {
    const { succeeded, data }: TResponse<TConfirmMemberRes> = yield call(
      memberApi.confirmMember,
      action.payload.data
    );

    if (succeeded && data.status === EMemberStatus.EXIST) {
      yield put(memberActions.confirmMemberSuccess());
      action.payload.onSuccess?.(data.memberId);
    } else {
      action.payload.onError?.(EGetMemberError.MEMBER_NOT_FOUND);
      yield put(memberActions.confirmMemberFailure());
    }
  } catch (error: any) {
    yield put(memberActions.confirmMemberFailure());

    const errorCode = (error?.data as TResponse)?.errors?.[0]?.code;
    action.payload.onError?.(errorCode);
  } finally {
    action.payload.onFinal?.();
  }
}

export function* getMembers(action: any): any {
  try {
    const response = yield call(memberApi.getMembers, action.payload.data);
    if (action.payload.data.Status === STATUS.PENDING) {
      const members = response.data;
      const hasPendingMembers = members.length > 0;
      const hasMore = response.pagination.hasNext;
      const isStore = action.payload.data.IsStore;
      const isNotPendingData = members.length === 0;
      const memberInfo = {
        isStore,
        data: members,
        hasMore,
        hasPendingMembers: hasPendingMembers,
        status: STATUS.PENDING,
        isNotPendingData,
      };

      yield put(memberActions.getMembersSuccess(memberInfo));
    }
    if (action.payload.data.Status === STATUS.TRAINING) {
      const members = response.data;
      const hasMore = response.pagination.hasNext;
      const isStore = action.payload.data.IsStore;
      const isNotTrainingData = members.length === 0;
      const memberInfo = {
        isStore,
        data: members,
        hasMore,
        status: STATUS.TRAINING,
        isNotTrainingData,
      };
      yield put(memberActions.getMembersSuccess(memberInfo));
    }

    if (action.payload.data.Status === STATUS.ALL) {
      const members = response.data;
      const hasMore = response.pagination.hasNext;
      const memberInfo = {
        data: members,
        hasMore,
        status: STATUS.ALL,
      };
      yield put(memberActions.getMembersSuccess(memberInfo));
    }
  } catch (error) {
    yield put(memberActions.getMembersFailure());
  }
}

export const memberWatcher = [
  takeEvery(memberActions.confirmMember, confirmMember),
  takeLatest(memberActions.getMembers, getMembers),
];
