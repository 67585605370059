import { Button } from "antd";
import styled from "styled-components";

export const ButtonAddSurveyPartStyled = styled.button`
  width: 64px;
  height: 64px;

  position: fixed;
  right: 24px;
  bottom: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;

  font-size: 28px;

  color: #000000d9;
  background: linear-gradient(91.78deg, #ffeccf 2.16%, #ffc165 103.68%);
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const ButtonActionStyled = styled(Button)`
  width: 100%;
  min-width: 204px;
  padding: 4px;

  font-size: var(--font-size-md);
  text-align: start;

  display: flex;
  align-items: center;
`;
