export const STATISTIC_HOME_TRANSLATE = {
  // TODAY_CHECK_IN_REPORT
  TODAY_CHECK_IN_REPORT_TITLE: "当日のチェックイン",
  TODAY_CHECK_IN_REPORT_LABEL: "前日比",

  // THIS_MONTH_CHECK_IN_REPORT
  THIS_MONTH_CHECK_IN_REPORT_TITLE: "当月のチェックイン",
  THIS_MONTH_CHECK_IN_REPORT_LABEL: "前月比",

  // MEMBER_STATUS_ANALYSIS_TITLE
  MEMBER_TRAINING_STATUS_ANALYSIS_TITLE: "来館状況",
  MEMBER_PHASE_STATUS_ANALYSIS_TITLE: "会員のフェーズ",

  // PENDING MEMBER
  NOT_HAS_ANY_PENDING_MEMBER: "保留中の会員が見つかりませんでした。",

  // Title
  TITLE_CHECK_IN_ANALYSIS: "チェックインデータ",
  TITLE_THIS_WEEK_CHECK_IN_REPORT: "今週",
  TITLE_ALL_TIME_CHECK_IN_REPORT: "トータル",
  TITLE_MEMBER_ANALYSIS: "会員データ",
  TITLE_MEMBER_PENDING: "保留中の会員",

  DASHBOARD_TAB: "ダッシュボード",
  SURVEY_TAB: "アンケート結果",
  SURVEY_INFORMATION: "調査情報",
};
