import { useAppSelector } from "hooks";
import { HomePageWrapperStyled } from "./HomePage.styled";
import {
  HeaderHomePage,
  StatisticForHeadquarter,
  StatisticHomePage,
} from "./components";
import { HomePageContextProvider } from "./contexts";
import { EUserRoles } from "model/user";

function HomePage() {
  const { role } = useAppSelector((state) => state.auth.user) || {};
  const isHeadquarterAdmin = role === EUserRoles.HEADQUARTER;

  return (
    <HomePageContextProvider>
      <HomePageWrapperStyled>
        <HeaderHomePage />

        {isHeadquarterAdmin ? (
          <StatisticForHeadquarter />
        ) : (
          <StatisticHomePage />
        )}
      </HomePageWrapperStyled>
    </HomePageContextProvider>
  );
}

export { HomePage };
