import { Input, InputRef } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { TOptionFormValue, TQuestionOption } from "model/survey";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { PLACE_HOLDER_TEXT, TOptionsProps } from "../../Question";
import {
  CheckBoxGroupStyled,
  CheckboxOtherStyled,
} from "./OptionsMultiple.styled";
import { EmptyAnswer } from "../EmptyAnswer/EmptyAnswer";

type TOptionsMultipleProps = TOptionsProps<TOptionFormValue, TOptionFormValue>;

function OptionsMultiple(props: TOptionsMultipleProps) {
  const { options, onChange, value, hasOther, readOnly, ...restProps } = props;
  const [internalValue, setInternalValue] = useState<CheckboxValueType[]>();
  const [checkedOther, setCheckedOther] = useState(false);
  const [otherText, setOtherText] = useState("");

  const otherInputRef = useRef<InputRef>(null);
  const isBlurring = useRef(false);

  const handleChangeCheckboxGroup = (checkedValue: CheckboxValueType[]) => {
    if (readOnly) return;

    setInternalValue(checkedValue);

    if (onChange) {
      onChange({
        ...value,
        answer: checkedValue as TQuestionOption[],
      });
      return;
    }
  };

  const handleCheckOtherChange = (e: CheckboxChangeEvent) => {
    if (readOnly) return;

    if (isBlurring.current) {
      return;
    }

    setCheckedOther(e.target.checked);

    if (e.target.checked) {
      otherInputRef.current?.focus?.();
    }

    onChange?.({
      ...value,
      checkedOther: e.target.checked,
      otherAnswer: otherText,
    });
  };

  const handleTextOtherChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return;

    const otherValue = e.target.value;

    setOtherText(otherValue);
    setCheckedOther(true);

    onChange?.({
      ...value,
      checkedOther: true,
      otherAnswer: otherValue,
    });
  };

  const handleTextOtherBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return;

    if (!e.target.value.trim()) {
      isBlurring.current = true;
      setTimeout(() => {
        isBlurring.current = false;
      }, 500);

      setCheckedOther(false);
      setOtherText("");
      onChange?.({
        ...value,
        checkedOther: false,
        otherAnswer: "",
      });
    }
  };

  useEffect(() => {
    // Clear value if not check any item
    if (value) {
      const checkboxValue = value ? value.answer : internalValue;
      const hasValueCheckbox = (checkboxValue || []).length > 0;
      const _checkedOther = value.checkedOther || false;
      if (!hasValueCheckbox && !_checkedOther) {
        onChange?.(undefined);
      }
    }
  }, [value, internalValue]);

  // Update internal value by value prop
  useEffect(() => {
    setInternalValue(value?.answer);
    setCheckedOther(value?.checkedOther || false);
  }, [value]);

  // Set default value for other answer
  useEffect(() => {
    setOtherText(value?.otherAnswer || "");
  }, []);

  const isCheckedOther = value?.checkedOther || checkedOther;
  const showOtherOption = !readOnly ? hasOther : value?.checkedOther;
  const optionList = readOnly ? value?.answer || [] : options;

  if (readOnly && optionList.length === 0 && !showOtherOption) {
    return <EmptyAnswer />;
  }

  return (
    <>
      <CheckBoxGroupStyled
        options={optionList.map((opt) => {
          return { label: opt, value: opt };
        })}
        onChange={handleChangeCheckboxGroup}
        value={value?.answer || internalValue}
        {...restProps}
      />

      {/* Check box other */}
      {showOtherOption && (
        <CheckboxOtherStyled
          checked={isCheckedOther}
          onChange={handleCheckOtherChange}
          {...restProps}
        >
          <Input
            ref={otherInputRef}
            placeholder={PLACE_HOLDER_TEXT}
            value={otherText}
            onChange={handleTextOtherChange}
            onBlur={handleTextOtherBlur}
            readOnly={readOnly}
            {...restProps}
          />
        </CheckboxOtherStyled>
      )}
    </>
  );
}

export default OptionsMultiple;
