import images from "constants/images";
import styled from "styled-components";

export const PrgramHeaderContainerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const ProgramHeaderStyled = styled.div`
  background-image: url(${images.ProgramHeaderImage});
  padding: 24px;
`;

export const TopHeader = styled.div`
  display: flex;
  .title {
    flex: 1;
    text-align: center;
    padding-right: 61px;
    color: var(--color-white);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bolder);
  }

  .btn-back {
    color: var(--color-white);
    fill: var(--color-white);
    svg {
      color: var(--color-white) !important;
    }
  }
`;

export const MainHeader = styled.div`
  display: flex;
  justify-content: center;
`;

export const MemberIndex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .survey {
    background: #595959;
    color: var(--color-white);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border: solid 1px #595959;
    border-radius: 2px;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-md);
    &:hover {
      border: solid 1px #595959;
      color: var(--color-white);
    }
  }

  .member-index-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .member-id {
      margin-top: 14px;
      color: var(--color-white);
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bolder);

      background: linear-gradient(91.78deg, #ffeccf 2.16%, #ffc165 103.68%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      .label {
      }
      .content {
      }
    }

    .member-index {
      display: flex;
      align-items: center;
      margin: 12px 0 0 0;

      .member-index-item {
        display: flex;
        align-items: center;

        .icon-label {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        .dot {
          color: #dddddd;
          padding: 0 10rem;
        }

        .icon {
          color: white;
          margin-left: 5px;
          display: flex;
        }
        .label {
          color: var(--color-white);
          font-size: var(--font-size-md);
          font-weight: var(--font-weight-normal);
          margin-left: 5rem;
        }
        .content {
          color: var(--color-white);
          font-size: var(--font-size-md);
          font-weight: var(--font-weight-bolder);
          margin-left: 4rem;
        }
      }
    }
  }
`;
