import { TEXT_TRANSLATE } from "pages/TrainingProgramPage/TrainingProgram.translate";
import { ProgramMainLayout } from "../../layout/ProgramMainLayout/ProgramMainLayout";
import { DietContainerStyled } from "./Diet.styled";
import { DietList } from "./components/DietList/DietList";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import { PROGRAM_STEPS } from "constants/program";
import { useEffect } from "react";

const navigateOptions: NavigateOptions = {
  replace: true,
};

const Diet = () => {
  const [_searchParams, setSearchParams] = useSearchParams({});

  useEffect(() => {
    setSearchParams({ step: PROGRAM_STEPS.STEP_ONE }, navigateOptions);
  }, []);
  return (
    <ProgramMainLayout title={TEXT_TRANSLATE.DIET_TITLE}>
      <DietContainerStyled>
        <DietList />
      </DietContainerStyled>
    </ProgramMainLayout>
  );
};

export { Diet };
