import { FC, useLayoutEffect, useState } from "react";
import { StoreSelectionStyled } from "./StoreSelection.styled";
import { StoreSelectionForm } from "./components/StoreSelectionForm/StoreSelectionForm";
import { StoreList } from "./components/StoreList/StoreList";
import { StoreCreateButton } from "./components/StoreCreate/StoreCreateButton/StoreCreateButton";
import { StoreCreateFormModal } from "./components/StoreCreate/StoreCreateFormModal/StoreCreateFormModal";
import StoreViewDetail from "./components/StoreViewDetail/StoreViewDetail";
import { TRANSLATION } from "pages/ShumokuManagementPage/ShumokuManagementPage.translate";
import { DEFAULT_MODAL_PROPS } from "constants/common";
import { useAppModal } from "contexts";
import { CheckCircleOutlined } from "@ant-design/icons";
import { StoreDeleteButton } from "./components/StoreDelete/StoreDeleteButton/StoreDeleteButton";
import { useScrollLock } from "hooks";

const StoreSelection: FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [idDetailStore, setIdDetailStore] = useState<string>("");
  const { appModal } = useAppModal();
  useScrollLock(openModal || openDetailModal);
  useLayoutEffect(() => {
    const homePageButtonElement = document.querySelector(
      ".home-page-btn"
    ) as HTMLElement;

    if (homePageButtonElement) {
      homePageButtonElement.style.display = "none";
    }

    return () => {
      homePageButtonElement.style.display = "grid";
    };
  });
  const handleOpenCreateModal = () => {
    setOpenModal(!openModal);
  };
  const handleOpenDetail = (id: string) => {
    setIdDetailStore(id);
    setOpenDetailModal(true);
  };
  const handleOpenSuccessModal = (message: string) => {
    appModal.confirm({
      ...DEFAULT_MODAL_PROPS.SUCCESS,
      width: 520,
      title: message,
      okText: TRANSLATION.YES,
      icon: <CheckCircleOutlined style={{ color: "#52C41A" }} />,
      cancelButtonProps: { style: { display: "none" } },
    });
  };

  return (
    <StoreSelectionStyled>
      <StoreSelectionForm />
      <StoreList handleOpenDetail={handleOpenDetail} />
      <StoreCreateButton handleOpenCreateFormModal={handleOpenCreateModal} />
      <StoreDeleteButton
        handleOk={() =>
          handleOpenSuccessModal(TRANSLATION.DELETE_SUCCESS_NOTIFICATION)
        }
      />
      {openModal && (
        <StoreCreateFormModal
          open={openModal}
          handleOk={() => {
            setOpenModal(false);
            handleOpenSuccessModal(TRANSLATION.CREATE_SUCCESS_NOTIFICATION);
          }}
          handleCancel={() => {
            setOpenModal(false);
            setIdDetailStore("");
          }}
        />
      )}
      {openDetailModal && (
        <StoreViewDetail
          idDetailStore={idDetailStore}
          open={openDetailModal}
          handleOk={() => {
            setOpenDetailModal(false);
            handleOpenSuccessModal(TRANSLATION.UPDATE_SUCCESS_NOTIFICATION);
            return;
          }}
          handleCancel={() => {
            setOpenDetailModal(false);
          }}
        />
      )}
    </StoreSelectionStyled>
  );
};

export { StoreSelection };
