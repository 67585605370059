import { NotificationModal } from "components/NotificationModal/NotificationModal";
import { programActions } from "features/program/programSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useMultistep } from "hooks/useMultistep/useMultistep";
import React, {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { MemberDetailsRoute, TrainingProgramDetailRoute } from "routers/router";
import { TEXT_TRANSLATE } from "../TrainingProgram.translate";
import { Diet } from "../components/Diet/Diet";
import { ProgramSurvey } from "../components/ProgramSurvey/ProgramSurvey";
import { TrainingPrograms } from "../components/TrainingPrograms/TrainingPrograms";
import { useAppLoading, useAppLocation } from "contexts";
import { DEFAULT_PATHNAME } from "constants/pathname";
import {
  PROGRAM_MENU_TYPES,
  PROGRAM_SURVEY_TYPES,
  ProgramMenuType,
  SURVEYS,
} from "constants/program";
import { TProgramSv, TProgramSvOptions } from "model/program";
import { showErrorNotification } from "helpers/notification";
import MESSAGES from "constants/messages";
import surveyApi from "features/survey/surveyApi";

type TProgramContext = {
  currentStepIndex: number;
  step: ReactElement | null;
  next: () => void;
  back: () => void;
  sectionRefs?: any[];
  handleButtonClick?: (buttonIndex: number) => void;
  activeStep?: number;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
  onSaveProgram: () => void;
};

type TProps = {
  children: ReactElement;
};

export const ProgramContext = createContext<TProgramContext>({
  currentStepIndex: 0,
  step: <Diet key="1" />,
  next: () => {
    //
  },
  back: () => {
    //
  },
  onSaveProgram: () => {
    //
  },
});

export const useProgramContext = () => useContext(ProgramContext);

const ProgramProvider: React.FC<TProps> = ({ children }) => {
  // State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createdProgramId, setCreatedProgramId] = useState("");
  const [activeStep, setActiveStep] = useState<number>(1); // Initially active button (1st button)
  const [searchParams, _setSearchParams] = useSearchParams({});
  const { backToPrevPage } = useAppLocation();
  const { startLoading, stopLoading } = useAppLoading();
  // Redux
  const {
    programMenu,
    frequency,
    duration,
    phaseNumber,
    questionId,
    hasOther,
  } = useAppSelector((state) => state.program.programSurvey);
  const { programData, extensions, questionAndAnswerSurvey } = useAppSelector(
    (state) => ({
      programData: state.program.generatedProgram,
      extensions: state.program.extensions,
      questionAndAnswerSurvey: state.program.questionAndAnswerSurvey,
    })
  );

  // Hook
  const { memberId = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { currentStepIndex, step, next, back, goTo } = useMultistep([
    <Diet key="1" />,
    <ProgramSurvey key="2" />,
    <TrainingPrograms key="3" />,
  ]);

  // Ref
  const sectionRefs = [1, 2, 3, 4, 5].map(() => useRef<any>()); // Create refs for each section

  // Handlers
  const handleButtonClick = (buttonIndex: number) => {
    setActiveStep(buttonIndex);
    sectionRefs[buttonIndex - 1].current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const handleOnSaveProgram = () => {
    const data = {
      memberId: memberId as string,
      programMenu: programMenu as number,
      frequency,
      duration,
      phaseNumber,
      programId: programData?.id as string,
      exerciseMenuPurpose: exerciseMenuPurpose,
      extensions: JSON.stringify(extensions),
    };
    dispatch(
      programActions.saveProgram({
        data,
        onSuccess: (programId: string) => {
          handleUpdateSurvey();
          setCreatedProgramId(programId);
          setIsModalOpen(true);
        },
      })
    );
  };

  // update survey for program menu
  const surveyAnswer = useMemo(() => {
    let surveyAnswer = "";

    if (programMenu !== ProgramMenuType.OTHER) {
      PROGRAM_MENU_TYPES.find((item) => {
        if (
          programMenu !== ProgramMenuType.OTHER &&
          item.value === programMenu
        ) {
          surveyAnswer = item.name as string;
          return item;
        }
      });
    } else {
      surveyAnswer = hasOther as string;
    }

    return surveyAnswer;
  }, [hasOther, programMenu]);

  const surveyRequest = (surveyAnswer: string) => {
    if (programMenu !== ProgramMenuType.OTHER) {
      return {
        questionId: questionId,
        answer: JSON.stringify([surveyAnswer]),
        phaseNumber,
      };
    } else {
      return {
        questionId: questionId,
        answer: JSON.stringify([]),
        otherAnswer: surveyAnswer,
        phaseNumber,
      };
    }
  };

  //update survey
  const programSurveyTypes = [
    { type: PROGRAM_SURVEY_TYPES.FREQUENCY, value: frequency },
    { type: PROGRAM_SURVEY_TYPES.DURATION, value: duration },
  ];

  const updatedSurveyRequest = useMemo(() => {
    return questionAndAnswerSurvey.map((item, index) => {
      let answer: TProgramSvOptions | undefined;

      SURVEYS.find((item: TProgramSv) => {
        if (item.type === programSurveyTypes[index].type) {
          answer = item.options.find((option) => {
            return option.value === programSurveyTypes[index].value;
          });
        }
      });

      let updatedAnswerName = answer?.name;

      if (updatedAnswerName === "1週間に1~3回") {
        updatedAnswerName = "1~3回";
      }
      if (updatedAnswerName === "1週間に4回以上") {
        updatedAnswerName = "4回以上";
      }

      return {
        ...item,
        answer: JSON.stringify([updatedAnswerName]),
        phaseNumber,
      };
    });
  }, [questionAndAnswerSurvey, programSurveyTypes]);

  const handleUpdateSurvey = async () => {
    const updatedSurvey = [
      surveyRequest(surveyAnswer),
      ...updatedSurveyRequest,
    ];

    try {
      startLoading();
      const { succeeded } = await surveyApi.updateSurvey({
        memberId,
        questions: updatedSurvey,
      });

      if (!succeeded) {
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      }
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  const exerciseMenuPurpose = useMemo(() => {
    if (programMenu !== ProgramMenuType.OTHER) {
      return "";
    } else {
      return hasOther;
    }
  }, [hasOther, programMenu]);

  // navigate
  const navigateToProgramDetails = () => {
    if (createdProgramId && memberId) {
      navigate(
        TrainingProgramDetailRoute.genPath({
          memberId,
          programId: createdProgramId,
        }),
        { replace: true }
      );
    }
  };

  const navigateToMemberDetails = () => {
    if (memberId) {
      backToPrevPage(MemberDetailsRoute.genPath(memberId));
    } else {
      navigate(DEFAULT_PATHNAME, { replace: true });
    }
  };

  //scroll
  let debounceTimer: ReturnType<typeof setTimeout> | null = null;
  let programsElement: HTMLElement | null;
  const THRESHOLD = 100;
  const TIMER = 30;

  const handleScroll = () => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      let firstVisibleSectionIndex = -1;

      sectionRefs.forEach((sectionRef, index) => {
        const section = sectionRef.current;
        if (section) {
          const { top } = section.getBoundingClientRect();
          const containerTop =
            programsElement?.getBoundingClientRect().top || 0;

          if (top >= containerTop && top <= containerTop + THRESHOLD) {
            if (firstVisibleSectionIndex === -1) {
              firstVisibleSectionIndex = index;
            }
          }
        }
      });
      if (firstVisibleSectionIndex !== -1) {
        setActiveStep((prevActiveStep) => {
          const activeButtonIndex = firstVisibleSectionIndex + 1;
          if (prevActiveStep !== activeButtonIndex) {
            return activeButtonIndex;
          }
          return prevActiveStep;
        });
      }
    }, TIMER);
  };

  // Effects
  useEffect(() => {
    programsElement = document.querySelector(".program-list-container");

    if (programsElement) {
      programsElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (programsElement) {
        programsElement.removeEventListener("scroll", handleScroll);
      }
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, [sectionRefs, activeStep, programData]);

  useEffect(() => {
    if (searchParams.get("step")) {
      const step = searchParams.get("step");
      goTo(Number(step));
    }
  }, []);

  return (
    <ProgramContext.Provider
      value={{
        currentStepIndex,
        step,
        next,
        back,
        sectionRefs,
        handleButtonClick,
        activeStep,
        setActiveStep,
        onSaveProgram: handleOnSaveProgram,
      }}
    >
      {children}

      <NotificationModal
        open={isModalOpen}
        isTwoBtn
        onCancel={() => setIsModalOpen(false)}
        handleBtn1Click={navigateToProgramDetails}
        btn1Content={TEXT_TRANSLATE.MODAL_SUCCESS_BUTTON1}
        btn2Content={TEXT_TRANSLATE.MODAL_SUCCESS_BUTTON2}
        handleBtn2Click={navigateToMemberDetails}
        title={TEXT_TRANSLATE.MODAL_SUCCESS_TITLE}
        className="modal-style"
      />
    </ProgramContext.Provider>
  );
};

export { ProgramProvider };
