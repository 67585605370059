import { authWatcher } from "features/auth/authSaga";
import { programWatcher } from "features/program/programSaga";
import { shumokuWatcher } from "features/shumoku/shumokuSaga";
import { all } from "redux-saga/effects";
import { memberWatcher } from "./../features/member/memberSaga";

export function* rootSaga() {
  yield all([
    ...authWatcher,
    ...memberWatcher,
    ...programWatcher,
    ...shumokuWatcher,
  ]);
}
