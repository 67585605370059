import styled from "styled-components";

export const VideoModalStyled = styled.div``;

export const VideoModalWrapperStyled = styled.div`
  .video {
    width: 100%;
    height: min(324rem, 324px);
    margin-top: 3rem;
    background-color: #fafafa;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    position: relative;
    margin-top: 3rem;
  }

  .modal-footer {
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    cursor: pointer;

    position: absolute;
    left: 50%;
    transform: translate(-50%, calc(100% + 20px));
    bottom: 0;
  }

  .modal-content {
    text-align: center;
    font-weight: bold;
  }

  .edit-button {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  .shumoku-name {
    padding: 0 20px;
  }
`;
