import images from "constants/images";
import {
  LineStyled,
  LogoWrapperStyled,
  PageContainerStyled,
  SloganStyled,
  SpinStyled,
} from "./OnboardingPage.styled";
import { HAS_SHOW_ONBOARDING } from "constants/common";

function OnboardingPage() {
  const hasShowOnBoarding =
    localStorage.getItem(HAS_SHOW_ONBOARDING) === "true";

  if (!hasShowOnBoarding) {
    return (
      <PageContainerStyled>
        <LogoWrapperStyled>
          <img src={images.AppLogoSecondary} alt="logo" />
          <LineStyled />
          <SloganStyled>コミットプログラム</SloganStyled>
        </LogoWrapperStyled>
      </PageContainerStyled>
    );
  }

  return <SpinStyled />;
}

export { OnboardingPage };
