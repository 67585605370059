import images from "constants/images";
import { EmptyContentSvg } from "constants/svgs";
import { EmptyContentWrapperStyled } from "./EmptyContent.styled";
import { CSSProperties } from "react";

type TEmptyContentProps = {
  title?: string;
  showLogo?: boolean;
  className?: string;
  titleClassName?: string;
  style?: CSSProperties;
};

function EmptyContent(props: TEmptyContentProps) {
  const { showLogo, title, titleClassName, ...restProps } = props;

  return (
    <EmptyContentWrapperStyled {...restProps}>
      <EmptyContentSvg className="image-no-content" />
      <h2 className={`title ${titleClassName}`}>{title}</h2>
      {showLogo && (
        <div className="app-logo">
          <img alt="logo" src={images.AppLogoGray} className="" />
        </div>
      )}
    </EmptyContentWrapperStyled>
  );
}

export { EmptyContent };
