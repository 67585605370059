import { pictureSvgs } from "constants/svgs";
import styled from "styled-components";

export const SelectShumokuModalStyled = styled.div``;

export const ModalContentStyled = styled.div`
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;

  padding: 24px;
  padding-bottom: 5px;
  margin-left: -24px;
  margin-right: -24px;

  .ant-form {
    display: flex;
    flex-direction: column;
  }

  .error-field {
    border-color: red;
  }
`;

export const OptionItemStyled = styled.div`
  display: flex;
  align-items: center;

  .option-item {
    width: 50px;
    height: 50px;
    background: url(${pictureSvgs.ImagePlaceholderUrl}) #eeeeee;
    background-repeat: no-repeat;
    background-position: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inline-block;
  }
  span {
    margin-left: 5px;
  }
`;
