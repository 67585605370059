import { Col, Row } from "antd";
import { useHomePageContext } from "pages/HomePage/contexts";
import {
  EmptyContentStyled,
  StatisticWrapperStyled,
} from "./StatisticHomePage.styled";
import { STATISTIC_HOME_TRANSLATE } from "./StatisticHomePage.translate";
import { PendingMemberItem } from "./components";
import { CheckInColumnChart, MemberStatisticDonutChart } from "components";
import { TMemberListItem } from "model/member";
import { DashboardFilterFormForStore } from "../DashboardFilterFormForStore/DashboardFilterFormForStore";

const LIMIT_STORE_ITEM = 5;

function StatisticHomePage() {
  const {
    storeAdminData: {
      allTimeCheckInTrending,
      // Member analysis
      memberPhaseStatusAnalysis,
      memberTrainingStatusAnalysis,
      // Other
      loadingPendingMembers,
      pendingMembers,
    },
  } = useHomePageContext();

  const checkInTrendingStatistic = (
    <Col xs={24} md={12} xxl={12}>
      <h2 className="section-header">
        {STATISTIC_HOME_TRANSLATE.TITLE_CHECK_IN_ANALYSIS}
      </h2>
      <Row gutter={[20, 16]}>
        <Col xs={24} md={24} xxl={12}>
          <CheckInColumnChart
            title={STATISTIC_HOME_TRANSLATE.TITLE_ALL_TIME_CHECK_IN_REPORT}
            data={allTimeCheckInTrending}
          />
        </Col>
      </Row>
    </Col>
  );

  const memberStatistics = (
    <Col xs={24} md={24} xxl={12} className="section-container">
      <h2 className="section-header">
        {STATISTIC_HOME_TRANSLATE.TITLE_MEMBER_ANALYSIS}
      </h2>
      <Row gutter={[20, 16]} className="section-content">
        <Col xs={24} md={12} xxl={12}>
          <MemberStatisticDonutChart
            title={
              STATISTIC_HOME_TRANSLATE.MEMBER_TRAINING_STATUS_ANALYSIS_TITLE
            }
            data={memberTrainingStatusAnalysis}
          />
        </Col>

        <Col xs={24} md={12} xxl={12}>
          <MemberStatisticDonutChart
            title={STATISTIC_HOME_TRANSLATE.MEMBER_PHASE_STATUS_ANALYSIS_TITLE}
            data={memberPhaseStatusAnalysis}
          />
        </Col>
      </Row>
    </Col>
  );

  const loadingPendingMembersUI = (
    <Row gutter={[20, 16]}>
      {Array(3)
        .fill(0)
        .map((_, index) => {
          return (
            <Col xs={24} xxl={6} key={index}>
              <PendingMemberItem loading />
            </Col>
          );
        })}
    </Row>
  );

  const pendingMemberItems = (
    <Row gutter={[20, 16]}>
      {pendingMembers.length <= LIMIT_STORE_ITEM
        ? pendingMembers.map((memberItem: TMemberListItem) => {
            return (
              <Col xs={24} xxl={6} key={memberItem.id}>
                <PendingMemberItem data={memberItem} />
              </Col>
            );
          })
        : pendingMembers
            .slice(0, LIMIT_STORE_ITEM)
            .map((memberItem: TMemberListItem) => {
              return (
                <Col xs={24} xxl={6} key={memberItem.id}>
                  <PendingMemberItem data={memberItem} />
                </Col>
              );
            })}
    </Row>
  );

  const emptyContentUI = (
    <EmptyContentStyled
      title={STATISTIC_HOME_TRANSLATE.NOT_HAS_ANY_PENDING_MEMBER}
      titleClassName="no-member-title"
    />
  );

  const pendingMembersUI = (
    <Col xs={24} md={12} xxl={24} className="section-container">
      <h2 className="section-header">
        {STATISTIC_HOME_TRANSLATE.TITLE_MEMBER_PENDING}
      </h2>
      <div className="section-content">
        {loadingPendingMembers
          ? loadingPendingMembersUI
          : pendingMembers.length !== 0
          ? pendingMemberItems
          : emptyContentUI}
      </div>
    </Col>
  );

  const showDashboardTab = (
    <Row gutter={[20, 16]} align="stretch">
      {memberStatistics}
      {checkInTrendingStatistic}
      {pendingMembersUI}
    </Row>
  );

  return (
    <StatisticWrapperStyled>
      <DashboardFilterFormForStore />
      <Row gutter={[20, 16]} align="stretch">
        <Col xxl={24}>{showDashboardTab}</Col>
      </Row>
    </StatisticWrapperStyled>
  );
}

export { StatisticHomePage };
