import styled from "styled-components";

export const PublicProgramPageStyled = styled.div``;
export const ProgramFreeSelectionItemStyled = styled.div``;
export const PublicProgramMainStyled = styled.div`
  padding: 16px;
  margin-top: 63px;
`;

export const PublicProgramHeaderStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  display: grid;
  place-items: center;
  color: var(--color-white);
  background-color: var(--color-primary);
  padding: 16px;

  font-weight: var(--font-weight-bolder);
  font-size: var(--font-size-md);
`;
