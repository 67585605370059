import styled from "styled-components";
import { PolygonWhiteSixEdgeUrl } from "constants/svgs";

export const FreeSelectComponentStyled = styled.div`
  position: relative;
  height: 100%;

  .video-index {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);

    width: 50px;
    height: 50px;
    background: url(${PolygonWhiteSixEdgeUrl}) center no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;

    .order-number {
      position: absolute;
    }
  }

  background: #fafafa;
  border: 1px solid #f0f0f0;
  padding: 30px 0;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .add-container {
    font-weight: 400;
    display: flex;
    algin-items: center;
    gap: 5px;
    .icon {
      color: var(--color-primary);
      > .anticon {
        font-size: 22px;
      }
    }
    .text {
      color: var(--color-black);
      font-size: 14px;
    }
  }
`;
