import { Button, Col, Modal, Row } from "antd";
import { CheckCircle, CloseCircle } from "constants/svgs";
import { FC } from "react";
import { ContentWrapper, TopContent } from "./NotificationModal.styled";
import { EStatus } from "constants/common";

interface IProps {
  title: string;
  messageContent?: string;
  open: boolean;
  onCancel: () => void;
  isTwoBtn?: boolean;
  status?: EStatus;
  btnContent?: string; // for only one button
  btn1Content?: string;
  btn2Content?: string;
  handleBtnClick?: () => void; // for only one button
  handleBtn1Click?: () => void;
  handleBtn2Click?: () => void;
  className?: string;
}

const NotificationModal: FC<IProps> = ({
  title,
  messageContent,
  open,
  onCancel,
  isTwoBtn = false,
  status = EStatus.SUCCESS,
  btnContent,
  btn1Content,
  btn2Content,
  handleBtnClick,
  handleBtn1Click,
  handleBtn2Click,
  className,
}) => {
  const renderButtons = () => {
    switch (isTwoBtn) {
      case true:
        return (
          <Row
            className="btn-wrapper"
            gutter={[8, 8]}
            justify={"end"}
            style={{ width: "100%" }}
          >
            <Col>
              <Button type="default" onClick={() => handleBtn1Click?.()}>
                {btn1Content}
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => handleBtn2Click?.()}>
                {btn2Content}
              </Button>
            </Col>
          </Row>
        );

      case false:
        return (
          <Button className="btn-action" onClick={() => handleBtnClick?.()}>
            {btnContent}
          </Button>
        );
      default:
        break;
    }
  };

  return (
    <Modal
      open={open}
      footer={null}
      closable
      onCancel={onCancel}
      width={454}
      centered
      maskClosable={false}
      className={className}
    >
      <ContentWrapper>
        <TopContent>
          {status === EStatus.SUCCESS ? <CheckCircle /> : <CloseCircle />}
          <p className="title">{title}</p>
        </TopContent>
        <p
          className="message"
          dangerouslySetInnerHTML={{ __html: messageContent as string }}
        ></p>
        {renderButtons()}
      </ContentWrapper>
    </Modal>
  );
};

export { NotificationModal };
