import { CloseOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { EQuestionType } from "model/survey";
import { SURVEY_CREATION_FORM } from "pages/SurveyManagementPage/SurveyManagementPage.constant";
import { useSurveyManagementPageContext } from "pages/SurveyManagementPage/contexts";
import { FocusEvent } from "react";
import {
  InputStyled,
  OptionListStyled,
  OptionWrapperStyled,
} from "./OptionList.styled";
import { OPTION_LIST_TRANSLATE } from "./OptionList.translate";

export type TQuestionType =
  | EQuestionType.MULTIPLE
  | EQuestionType.SINGLE_RADIO
  | EQuestionType.AGE
  | EQuestionType.GENDER;

type TOptionListProps = {
  type: TQuestionType;
  hasOther?: boolean;
  onRemoveOtherOption: () => void;
  questionIndex: number;
  partIndex: number;
  active: boolean;
  isLocked?: boolean;
};

function OptionList(props: TOptionListProps) {
  const {
    type,
    hasOther,
    onRemoveOtherOption,
    questionIndex,
    active,
    isLocked = false,
    partIndex,
  } = props;

  const { surveyForm } = useSurveyManagementPageContext();

  // Reset option text if the user does not enter anything
  const handleOnInputBlur = (optionIndex: number) => {
    return (e: FocusEvent<HTMLInputElement, Element>) => {
      let optionValue = e.target.value.trim();
      const optionFormName = [
        SURVEY_CREATION_FORM.SURVEY_PART.NAME,
        partIndex,
        SURVEY_CREATION_FORM.SURVEY_PART_QUESTIONS.NAME,
        questionIndex,
        SURVEY_CREATION_FORM.OPTIONS.NAME,
        optionIndex,
      ];

      if (!optionValue) {
        optionValue = `選択肢${optionIndex + 1}`;
      }

      surveyForm?.setFieldValue(optionFormName, optionValue);
    };
  };

  const selectInputOption = (optionIndex: number) => {
    return () => {
      const optionInput: HTMLInputElement = surveyForm?.getFieldInstance([
        SURVEY_CREATION_FORM.SURVEY_PART.NAME,
        partIndex,
        SURVEY_CREATION_FORM.SURVEY_PART_QUESTIONS.NAME,
        questionIndex,
        SURVEY_CREATION_FORM.OPTIONS.NAME,
        optionIndex,
      ]);

      optionInput?.select();
    };
  };

  return (
    <OptionListStyled
      type={isLocked ? "locked" : "default"}
      questionType={type}
    >
      <Form.List name={[questionIndex, SURVEY_CREATION_FORM.OPTIONS.NAME]}>
        {(fields, { remove }) => {
          const showButtonDelete = active && fields?.length > 1;

          return fields.map((field) => {
            return (
              <OptionWrapperStyled
                key={field.key}
                type={type}
                onClick={selectInputOption(field.name)}
              >
                <div className="option-type" />
                <Form.Item name={[field.name]} noStyle>
                  <InputStyled
                    className="option-input"
                    readOnly={!active}
                    onBlur={handleOnInputBlur(field.name)}
                    onFocus={selectInputOption(field.name)}
                  />
                </Form.Item>

                {showButtonDelete && (
                  <button
                    onClick={() => remove(field.name)}
                    className="btn-close"
                  >
                    <CloseOutlined />
                  </button>
                )}
              </OptionWrapperStyled>
            );
          });
        }}
      </Form.List>

      {hasOther && (
        <OptionWrapperStyled type={type}>
          <div className="option-type" />
          <InputStyled
            placeholder={OPTION_LIST_TRANSLATE.OPTION_INPUT_PLACEHOLDER}
            readOnly
            className="option-input other-option"
          />

          {active && (
            <button onClick={onRemoveOtherOption} className="btn-close">
              <CloseOutlined />
            </button>
          )}
        </OptionWrapperStyled>
      )}
    </OptionListStyled>
  );
}

export { OptionList };
