import styled from "styled-components";

export const ProgramSecondaryLayoutStyled = styled.div``;

export const ProgramContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
`;

export const ContentContainerStyled = styled.div`
  display: flex;
  flex: 1; /* Allow the content container to take the remaining vertical space */
  overflow: hidden; /* Hide any overflow */
`;

export const LeftSectionStyled = styled.div`
  width: 165rem;
  position: fixed;
  top: 278px;
  bottom: 0;
  left: 24px;
`;

export const RightSectionContainerStyled = styled.div`
  flex: 1;
  margin-top: 278px;
  margin-left: calc(165rem + 24px);
  overflow: auto;
`;

export const RightSectionStyled = styled.div`
  padding-left: 20px;
  padding-right: 24px;
`;
