import { useAppLoading } from "contexts";
import { FC, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  DashboardFilterFormStyled,
  DatePickerStyled,
  FormStyled,
  SelectStyled,
} from "./DashboardFilterForm.styled";
import { Button, Col, DatePickerProps, Form, Row } from "antd";
import { TFilterDashboardRequest } from "features/statistic/statisticApi";
import { DownOutlined } from "@ant-design/icons";
import shumokuApi from "features/shumoku/shumokuApi";
import { useHomePageContext } from "pages/HomePage/contexts";
import { TStore } from "model/shumoku";
import { FILTER_TRANSLATE } from "./DashboardFilterForm.translate";
import dayjs, { ConfigType } from "dayjs";
import {
  DATE_FORMAT_API,
  DatePickerType,
  MONTH_FORMAT,
  WEEK_FORMAT,
} from "constants/common";
import isoWeek from "dayjs/plugin/isoWeek";
import { ExportSurveyButton } from "../ExportSurveyButton/ExportSurveyButton";

const COL_SPAN_LIST = {
  DATE_PICKER: {
    xs: 24,
    sm: 12,
  },
  STORE: {
    xs: 12,
    lg: 8,
  },
  GENDER: {
    xs: 6,
    lg: 8,
  },
  AGE: {
    xs: 6,
    lg: 8,
  },
  TYPE_DATE: {
    xs: 8,
    lg: 8,
  },
  DATE: {
    xs: 8,
    lg: 8,
  },
  FILTER_BUTTON: {
    xs: 4,
    lg: 4,
  },
  EXPORT_BUTTON: {
    xs: 4,
    lg: 4,
  },
};

const initialValuesFormFilter: TFilterDashboardRequest = {
  fromDate: "",
  toDate: "",
  storeNumber: [""],
  gender: "",
  age: [""],
};

const SEARCH_FIELDS = {
  fromDate: "fromDate",
  toDate: "toDate",
  storeNumber: "storeNumber",
  gender: "gender",
  age: "age",
  typeDate: "typeDate",
  date: "date",
};

const genderOptions = [
  { value: "男性", label: "男性" },
  { value: "女性", label: "女性" },
];

const ageOptions = [
  { value: "10代", label: "10代" },
  { value: "20代", label: "20代" },
  { value: "30代", label: "30代" },
  { value: "40代", label: "40代" },
  { value: "50代", label: "50代" },
  { value: "60代", label: "60代" },
  { value: "70代", label: "70代" },
  { value: "80代", label: "80代" },
];

const typeDateOptions = [
  { value: "week", label: `${FILTER_TRANSLATE.WEEK}` },
  { value: "month", label: `${FILTER_TRANSLATE.MONTH}` },
];

interface ISelectOption {
  value: string | number;
  label: string;
}

type PickerType = "week" | "month" | undefined;

const CLASS_NAME_SELECT_BEHIND_HEADER = "common__select--z-index-behind-header";
dayjs.extend(isoWeek);

const DashboardFilterForm: FC = () => {
  const [formFilter] = Form.useForm();
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [storeOptions, setStoreOptions] = useState<ISelectOption[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { startLoading, stopLoading } = useAppLoading();
  const [typeDate, setTypeDate] = useState<PickerType>();
  const { setFilter } = useHomePageContext();

  const customWeekFormat: DatePickerProps["format"] = (value) => {
    return dayjs(value)
      .startOf(DatePickerType.Week)
      .add(1, DatePickerType.Week)
      .format(WEEK_FORMAT);
  };

  const fetchStoresData = async () => {
    try {
      startLoading;
      setIsLoadingStores(true);
      const storeList = await shumokuApi.getAllStoresWithoutFilter();
      if (storeList) {
        const storeOption = storeList.data.map((store: TStore) => {
          return {
            value: store.storeNumber,
            label: `${store.storeNumber}: ${store.storeName}`,
          };
        });
        setStoreOptions(storeOption);
      }
    } catch (error) {
      console.log(error);
    } finally {
      stopLoading();
      setIsLoadingStores(false);
    }
  };

  const getSearchParamValues = useCallback(() => {
    // set default value if not present
    const storeNumber =
      searchParams.getAll(SEARCH_FIELDS.storeNumber).length > 0
        ? searchParams.getAll(SEARCH_FIELDS.storeNumber)
        : [""];
    const age =
      searchParams.getAll(SEARCH_FIELDS.age).length > 0
        ? searchParams.getAll(SEARCH_FIELDS.age)
        : [""];
    const gender = searchParams.get(SEARCH_FIELDS.gender) || "";
    const fromDate = searchParams.get(SEARCH_FIELDS.fromDate) || "";
    const toDate = searchParams.get(SEARCH_FIELDS.toDate) || "";

    const filterValues: TFilterDashboardRequest = {
      fromDate,
      toDate,
      storeNumber,
      gender,
      age,
    };
    return filterValues;
  }, [searchParams]);

  const updateFormValueFirstTime = () => {
    const formValues = getSearchParamValues();
    formFilter.setFieldsValue(formValues);
  };

  const filterChart = useCallback(() => {
    const formValues = getSearchParamValues();
    // clear empty string of storeNumber and age
    if (
      formValues.storeNumber.length === 1 &&
      formValues.storeNumber[0] === ""
    ) {
      formValues.storeNumber = [];
    }

    if (formValues.age.length === 1 && formValues.age[0] === "") {
      formValues.age = [];
    }
    setFilter(formValues);
  }, [getSearchParamValues]);

  useEffect(() => {
    setSearchParams({}, { replace: false });
    fetchStoresData();
    updateFormValueFirstTime();
    formFilter.resetFields();
  }, []);

  const handleChangeStoreNumber = (values: any) => {
    // if select all then clear other option
    if (values[values.length - 1] === "" || values.length === 0) {
      formFilter.setFieldsValue({
        storeNumber: [""],
      });
    } else {
      const filteredValues = values.filter((value: string) => value !== "");
      formFilter.setFieldsValue({
        storeNumber: filteredValues,
      });
    }
  };
  const handleChangeAge = (values: any) => {
    // if select all then clear other option
    if (values[values.length - 1] === "" || values.length === 0) {
      formFilter.setFieldsValue({
        age: [""],
      });
    } else {
      const filteredValues = values.filter((value: string) => value !== "");
      formFilter.setFieldsValue({
        age: filteredValues,
      });
    }
  };

  const handleChangeTypeDate = (values: any) => {
    setTypeDate(values);
  };

  const updateFilterSearchParams = (values: TFilterDashboardRequest) => {
    const storeSelected =
      values.storeNumber.length > 0
        ? values.storeNumber.map((stt) => stt.toString())
        : [];
    const ageSelected =
      values.age.length > 0 ? values.age.map((stt) => stt.toString()) : [];
    setSearchParams(
      (prevSearchParams) => {
        const updatePrevParams = (filedName: string, value?: string) => {
          prevSearchParams.set(filedName, value || "");
        };

        if (storeSelected.length > 0) {
          prevSearchParams.delete(SEARCH_FIELDS.storeNumber);
          storeSelected.forEach((stt) => {
            prevSearchParams.append(SEARCH_FIELDS.storeNumber, stt);
          });
        }
        if (ageSelected.length > 0) {
          prevSearchParams.delete(SEARCH_FIELDS.age);
          ageSelected.forEach((stt) => {
            prevSearchParams.append(SEARCH_FIELDS.age, stt);
          });
        }

        updatePrevParams(SEARCH_FIELDS.gender, values.gender);

        if (values.date && typeDate) {
          const currentDate = dayjs();
          const fromDateFormat = dayjs(values.date)
            .startOf(typeDate)
            .format(DATE_FORMAT_API);
          updatePrevParams(SEARCH_FIELDS.fromDate, fromDateFormat);

          let toDateFormat = dayjs(values.date)
            .endOf(typeDate)
            .format(DATE_FORMAT_API);

          // if toDateFormat after current date then toDateFormat = current date
          if (dayjs(toDateFormat).isAfter(currentDate)) {
            toDateFormat = currentDate.format(DATE_FORMAT_API);
          }
          updatePrevParams(SEARCH_FIELDS.toDate, toDateFormat);
        } else {
          updatePrevParams(SEARCH_FIELDS.fromDate, "");
          updatePrevParams(SEARCH_FIELDS.toDate, "");
        }

        return prevSearchParams;
      },
      { replace: true }
    );
    filterChart();
  };

  const disabledFutureDate = (current: ConfigType) => {
    if (typeDate) {
      const today = dayjs();
      const currentDay = dayjs(current);
      // disable future week
      if (currentDay.isAfter(today.endOf(typeDate))) {
        return true;
      }
      // disable future date in current week
      if (
        currentDay.isSame(today, typeDate) &&
        currentDay.isAfter(today, "day")
      ) {
        return true;
      }
    }
    return false;
  };
  return (
    <DashboardFilterFormStyled>
      <FormStyled
        colon={false}
        onFinish={updateFilterSearchParams}
        form={formFilter}
        initialValues={initialValuesFormFilter}
        layout="vertical"
      >
        <Row gutter={[8, 0]} wrap>
          <Col {...COL_SPAN_LIST.STORE}>
            <Form.Item
              label={FILTER_TRANSLATE.STORE}
              name={SEARCH_FIELDS.storeNumber}
            >
              <SelectStyled
                mode="multiple"
                options={[
                  { label: FILTER_TRANSLATE.ALL, value: "" },
                  ...storeOptions,
                ]}
                loading={isLoadingStores}
                disabled={isLoadingStores}
                notFoundContent={<div />}
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                suffixIcon={<DownOutlined />}
                showSearch
                popupClassName={CLASS_NAME_SELECT_BEHIND_HEADER}
                onChange={handleChangeStoreNumber}
                maxTagCount={"responsive"}
              />
            </Form.Item>
          </Col>
          <Col {...COL_SPAN_LIST.AGE}>
            <Form.Item
              label={FILTER_TRANSLATE.GENDER}
              name={SEARCH_FIELDS.gender}
            >
              <SelectStyled
                options={[
                  { label: FILTER_TRANSLATE.ALL, value: "" },
                  ...genderOptions,
                ]}
                notFoundContent={<div />}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                suffixIcon={<DownOutlined />}
                showSearch
                popupClassName={CLASS_NAME_SELECT_BEHIND_HEADER}
              />
            </Form.Item>
          </Col>
          <Col {...COL_SPAN_LIST.AGE}>
            <Form.Item label={FILTER_TRANSLATE.AGE} name={SEARCH_FIELDS.age}>
              <SelectStyled
                mode="multiple"
                options={[
                  { label: FILTER_TRANSLATE.ALL, value: "" },
                  ...ageOptions,
                ]}
                notFoundContent={<div />}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                suffixIcon={<DownOutlined />}
                showSearch
                popupClassName={CLASS_NAME_SELECT_BEHIND_HEADER}
                onChange={handleChangeAge}
                maxTagCount={"responsive"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 0]} wrap>
          <Col {...COL_SPAN_LIST.TYPE_DATE}>
            <Form.Item
              label={FILTER_TRANSLATE.TYPE_DATE}
              name={SEARCH_FIELDS.typeDate}
            >
              <SelectStyled
                options={[...typeDateOptions]}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                suffixIcon={<DownOutlined />}
                showSearch
                popupClassName={CLASS_NAME_SELECT_BEHIND_HEADER}
                onChange={handleChangeTypeDate}
                placeholder={FILTER_TRANSLATE.SELECT_TYPE_DATE}
              />
            </Form.Item>
          </Col>
          <Col {...COL_SPAN_LIST.DATE}>
            <Form.Item label={FILTER_TRANSLATE.DATE} name={SEARCH_FIELDS.date}>
              <DatePickerStyled
                picker={typeDate}
                disabledDate={disabledFutureDate}
                disabled={!typeDate}
                format={
                  typeDate === DatePickerType.Week
                    ? customWeekFormat
                    : MONTH_FORMAT
                }
              />
            </Form.Item>
          </Col>
          <Col {...COL_SPAN_LIST.FILTER_BUTTON} className="filter-submit">
            <Button type="primary" htmlType="submit" className="btn-filter">
              {FILTER_TRANSLATE.FILTER}
            </Button>
          </Col>
          <Col {...COL_SPAN_LIST.EXPORT_BUTTON}>
            <ExportSurveyButton storeOptions={storeOptions} />
          </Col>
        </Row>
      </FormStyled>
    </DashboardFilterFormStyled>
  );
};

export { DashboardFilterForm };
