export const TRANSLATION = {
  TITLE: "種目（マスター管理）",
  PLEASE_SELECT: "種目選択してください。",
  BACK: "戻る",

  //MODAL
  MODAL_DETAIL_TITLE: "詳細種目",
  MODAL_CREATE_TITLE: "種目を作成",
  MODAL_UPDATE_TITLE: "種目更新",
  SHUMOKU_NAME: "種目名",
  SHUMOKU_CODE: "種目コード",
  MAJOR_ITEM_MASTER: "項目",
  THUMBNAIL: "サムネイル",
  VIDEO: "ビデオ",
  LOCATION: "部分",
  TEMPO: "テンポ",
  UPLOAD_THUMBNAIL: "サムネイルをアップロード",
  UPLOAD_VIDEO: "ビデオをアップロード",
  PLEASE_SELECT_MAJOR_ITEM: "項目を選択",

  SHUMOKU_NAME_REQUIRED: "種目名を入力してください。",
  SHUMOKU_CODE_REQUIRED: "種目コードを入力してください。",
  SHUMOKU_CODE_MAX_LENGTH: "種目コードが36文字以下の数字である必要。",
  MAJOR_ITEM_MASTER_REQUIRED: "項目を選択してください。",
  THUMBNAIL_REQUIRED: "サムネイルをアップロードしてください。",
  VIDEO_REQUIRED: "ビデオをアップロードしてください。",
  SHUMOKU_CODE_EXISTED: "種目コードが既に存在しています。",
  SHUMOKU_NAME_EXISTED: "種目名が既に存在しています。",
  INVALID_VIDEO_FORMAT: "ビデオは.mp4形式でアップロードしてください。",
  INVALID_THUMBNAIL_FORMAT: "画像は.jpg、.png形式でアップロードしてください。",

  SAVE: "保存",
  YES: "はい",
  //SUCCESS NOTIFICATION
  SUCCESS_NOTIFICATION: "リクエストしたコンテンツは正常に更新されました。",
  DELETE_SUCCESS_NOTIFICATION: "選択された種目は正常に削除されました。",
  UPDATE_SUCCESS_NOTIFICATION: "選択した種目は正常に更新されました。",
  CREATE_SUCCESS_NOTIFICATION: "種目は正常に追加されました。",
  CONFIRM_DELETE: "選択した種目を削除してもよろしいですか？",

  MAJOR_ITEM_MANAGEMENT: "大項目マスター管理",
  MAJOR_ITEM_CREATE: "大項目マスター追加",
  MAJOR_ITEM_UPDATE: "大項目マスター更新",
};
