import { Button } from "antd";
import { ButtonBackPage, SelectSurveyModal } from "components";
import {
  ArrowRightIcon,
  ColumnHeightIcon,
  FireIcon,
  GoldIcon,
} from "constants/svgs";
import { GLOBAL_TRANSLATE } from "constants/translate";
import { useAppDispatch, useAppSelector } from "hooks";
import { useProgramContext } from "pages/TrainingProgramPage/ProgramContext";
import { TEXT_TRANSLATE } from "pages/TrainingProgramPage/TrainingProgram.translate";
import { FC, ReactElement } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  MemberDetailsRoute,
  SurveyDetailRoute,
  TMemberUrlParams,
} from "routers/router";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { MemberConfirmedSurvey } from "../TrainingPrograms/components/MemberConfirmedSurvey/MemberConfirmedSurvey";
import {
  MainHeader,
  MemberIndex,
  ProgramHeaderContainerStyled,
  ProgramHeaderStyled,
  TopHeader,
} from "./ProgramHeader.styled";
import { programActions } from "features/program/programSlice";

type TMemberIndex = {
  id: string;
  icon: ReactElement;
  label: string;
  content: string;
  isDot?: boolean;
};

type TProps = {
  currentStepIndex: number;
};

const MAX_STEP = 3;

const ProgramHeader: FC<TProps> = ({ currentStepIndex }) => {
  const {
    memberDetailForCreateProgram: memberDetail,
    memberIndexes: { height, weight, bodyFat },
  } = useAppSelector((state) => state.member);

  const { back, onSaveProgram } = useProgramContext();

  const { memberId = "" } = useParams<TMemberUrlParams>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleOnBackPage = () => {
    back();
    if (currentStepIndex <= 0) {
      navigate(MemberDetailsRoute.genPath?.(memberId) || "#", {
        replace: true,
      });
    }
  };

  const handleViewSurvey = (phaseNumber: number) => {
    const surveyDetailPath = SurveyDetailRoute.genPath({
      memberId,
      phaseNumber,
      step: 0,
    });

    dispatch(programActions.setViewSurvey({ data: true }));
    navigate(surveyDetailPath);
  };

  const MEMBER_INDEX: TMemberIndex[] = [
    {
      id: "m2",
      icon: <ColumnHeightIcon />,
      label: "身長: ",
      content: `${height} cm`,
      isDot: true,
    },
    {
      id: "m1",
      icon: <GoldIcon />,
      label: `${GLOBAL_TRANSLATE.WEIGHT}: `,
      content: `${weight} kg`,
      isDot: true,
    },
    {
      id: "m3",
      icon: <FireIcon />,
      label: "体脂肪: ",
      content: `${bodyFat}%`,
      isDot: false,
    },
  ];

  const renderMemberIndexs = () => {
    return MEMBER_INDEX.map((item) => {
      return (
        <div className="member-index-item" key={item.id}>
          <div className="icon-label">
            <span className="icon">{item.icon}</span>
            <span className="label">{item.label}</span>
          </div>
          <div className="content">{item.content}</div>
          {item.isDot && <span className="dot">•</span>}
        </div>
      );
    });
  };

  const isLastStep = (currentStepIndex as number) + 1 === MAX_STEP;

  return (
    <ProgramHeaderContainerStyled>
      <ProgramHeaderStyled>
        <TopHeader>
          <ButtonBackPage
            fallbackPath={MemberDetailsRoute.genPath(memberId)}
            className="btn-back"
            onBackPage={() => handleOnBackPage()}
          />
          <h2 className="title">{TEXT_TRANSLATE.TITLE}</h2>
          <div
            className="btn-next"
            style={{ visibility: isLastStep ? "visible" : "hidden" }}
            onClick={onSaveProgram}
          >
            <span>{TEXT_TRANSLATE.BTN_SAVE_PROGRAM}</span>
            <ArrowRightIcon />
          </div>
        </TopHeader>

        <MainHeader>
          <MemberIndex>
            <div className="member-index-wrapper">
              <div className="member-id">
                <span className="label">{TEXT_TRANSLATE.LABEL_ID}: </span>
                <span className="content">{memberDetail?.memberAxtosId}</span>
              </div>
              <div className="member-index">{renderMemberIndexs()}</div>
            </div>
            {!isLastStep && (
              <SelectSurveyModal
                surveyList={memberDetail?.phaseResponses}
                onSelect={handleViewSurvey}
                autoSelect
              >
                <Button className="survey">{TEXT_TRANSLATE.VIEW_SURVEY}</Button>
              </SelectSurveyModal>
            )}
          </MemberIndex>
          <ProgressBar currentStepIndex={currentStepIndex} />
        </MainHeader>
      </ProgramHeaderStyled>
      {isLastStep && <MemberConfirmedSurvey />}
    </ProgramHeaderContainerStyled>
  );
};

export { ProgramHeader };
