import { ButtonBackPage, EmptyContent } from "components";
import MESSAGES from "constants/messages";
import { useAppLoading } from "contexts";
import memberApi from "features/member/memberApi";
import { memberActions } from "features/member/memberSlice";
import { programActions } from "features/program/programSlice";
import { showErrorNotification } from "helpers/notification";
import { useAppDispatch, useAppSelector } from "hooks";
import { TMemberDetail } from "model/member";
import { FC, Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MemberDetailsRoute } from "routers/router";
import {
  ProgramProvider,
  useProgramContext,
} from "./ProgramContext/ProgramContext";
import { TrainingProgramContainerStyled } from "./TrainingProgram.styled";
import { ProgramHeader } from "./components/ProgramHeader/ProgramHeader";

const TrainingProgram: FC = () => {
  const [memberError, setMemberError] = useState(false);
  const [loadingMember, setLoadingMember] = useState(true);

  const { currentStepIndex, step } = useProgramContext();
  const { startLoading, stopLoading } = useAppLoading();

  const { memberId = "", phaseNumber = "" } = useParams();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.program);

  useEffect(() => {
    async function getMemberDetail(memberId: string) {
      setLoadingMember(true);
      setMemberError(false);
      try {
        const { data } = await memberApi.getMemberById(memberId);

        const memberIndexes = {
          id: memberId,
          height: data?.height,
          weight: data?.weight,
          bodyFat: data?.bodyFat,
        };

        dispatch(
          memberActions.setMemberIndexes({
            data: memberIndexes,
          })
        );
        dispatch(
          memberActions.updateMemberDetailForCreateProgram({
            data,
          })
        );
        dispatch(
          programActions.setStoreId({
            data: (data as TMemberDetail & { storeId: string })?.storeId,
          })
        );

        dispatch(
          programActions.getProgramSurvey({
            data: {
              memberAxtosId: data?.memberAxtosId,
              phaseNumber: +phaseNumber,
            },
          })
        );
        dispatch(programActions.clearAllExtensions());
      } catch (error) {
        setMemberError(true);
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      } finally {
        setLoadingMember(false);
      }
    }

    getMemberDetail(memberId as string);

    return () => {
      dispatch(
        memberActions.updateMemberDetailForCreateProgram({
          data: null,
        })
      );
      dispatch(
        programActions.setStoreId({
          data: "",
        })
      );
    };
  }, [memberId]);

  useEffect(() => {
    loading ? startLoading() : stopLoading();
    return stopLoading;
  }, [loading]);

  if (loadingMember) return <Fragment />;

  if (memberError) {
    return (
      <TrainingProgramContainerStyled>
        <ButtonBackPage
          fallbackPath={MemberDetailsRoute.genPath(memberId)}
          className="btn-navigate"
        />
        <EmptyContent className="empty-content" />
      </TrainingProgramContainerStyled>
    );
  }

  return (
    <div>
      <ProgramHeader currentStepIndex={currentStepIndex} />
      {step}
    </div>
  );
};

const TrainingProgramPage = () => {
  return (
    <ProgramProvider>
      <TrainingProgram />
    </ProgramProvider>
  );
};

export { TrainingProgramPage };
