import styled from "styled-components";

export const ShumokuMasterDeleteButtonStyle = styled.div`
  position: fixed;
  bottom: 110px;
  right: 35px;

  cursor: pointer;
  display: grid;
  place-items: center;

  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;

  background: var(--color-bg-linear);

  .anticon-delete {
    font-size: 28px;
    color: #ffffff;
  }
`;
