import styled from "styled-components";

export const ProgressStepperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProgressStepStyled = styled.div`
  cursor: pointer;
  .step-item {
    display: flex;
    align-items: center;
    gap: 8rem;
  }
  .label {
    border-radius: 50%;
    color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    width: 30rem;
    background: var(--color-white);
    border: 1px solid rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.25);

    &.active {
      background: #ff6b00;
      color: var(--color-white);
      border: border: 1px solid var(--color-primary);
    }
  }
  .content {
    color: rgba(0, 0, 0, 0.45);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-normal);

    &.active {
      color: rgba(0, 0, 0, 0.85);
      font-weight: var(--font-weight-md)
    }
  }

  .vertical-bar {
    height: 12rem;
    width: 2rem;
    background: rgba(0, 0, 0, 0.06);
    margin: 5rem 0;
    margin-left: 15rem;
  }
`;
