import { Fragment, useEffect, useState } from "react";
import { ShumokuMasterUpdateFormModalStyle } from "./ShumokuMasterUpdateFormModal.styled";
import { FC, useCallback } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  UploadFile,
} from "antd";
import { TRANSLATION } from "pages/ShumokuMaster/ShumokuMaster.translate";
import shumokuApi from "features/shumoku/shumokuApi";
import { TResError, TResponse } from "types/service";
import {
  ECreateShumokuMasterError,
  TMajorItem,
  TShumokuResponse,
} from "model/shumoku";
import { useAppLoading } from "contexts";
import { useAppSelector } from "hooks";
import { UploadOutlined } from "@ant-design/icons";
import {
  convertFileToBinary,
  validateImageFileType,
  validateVideoFileType,
} from "helpers";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import { useDispatch } from "react-redux";
import axios from "axios";

type TProps = {
  open: boolean;
  shumokuUpdateId: string;
  handleOk: () => void;
  handleCancel: () => void;
};

const CREATE_SHUMOKU_FIELD = {
  SHUMOKU_NAME: {
    LABEL: TRANSLATION.SHUMOKU_NAME,
    NAME: "shumokuName",
  },
  SHUMOKU_CODE: {
    LABEL: TRANSLATION.SHUMOKU_CODE,
    NAME: "shumokuCode",
  },
  MAJOR_ITEM_MASTER: {
    LABEL: TRANSLATION.MAJOR_ITEM_MASTER,
    NAME: "majorItemMasterId",
  },
  THUMBNAIL: {
    LABEL: TRANSLATION.THUMBNAIL,
    NAME: "thumbnail",
  },
  VIDEO: {
    LABEL: TRANSLATION.VIDEO,
    NAME: "videoUrl",
  },
  LOCATION: {
    LABEL: TRANSLATION.LOCATION,
    NAME: "location",
  },
  TEMPO: {
    LABEL: TRANSLATION.TEMPO,
    NAME: "tempo",
  },
};

const ShumokuMasterUpdateFormModal: FC<TProps> = ({
  open,
  shumokuUpdateId,
  handleCancel,
  handleOk,
}) => {
  const [form] = Form.useForm();
  const { startLoading, stopLoading } = useAppLoading();
  const { majorItems } = useAppSelector((state) => state.shumoku);
  const [thumbnailList, setThumbnailList] = useState<UploadFile[]>([]);
  const [previewThumbnail, setPreviewThumbnail] = useState<string>("");
  const [videoList, setVideoList] = useState<UploadFile[]>([]);
  const [previewVideo, setPreviewVideo] = useState<string>("");
  const [shumokuUpdate, setShumokuUpdate] = useState<TShumokuResponse>();
  const [isChangeUpdateForm, setIsChangeUpdateForm] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async (id: string) => {
      startLoading();
      try {
        const shumoku = await shumokuApi.getShumokuMasterById(id);
        if (shumoku) {
          setShumokuUpdate(shumoku?.data);
          setPreviewVideo(shumoku.data.videoUrl);
          setPreviewThumbnail(shumoku.data.thumbnail);
        }
      } catch (error: any) {
        console.log(error);
      } finally {
        stopLoading();
      }
    };
    fetchData(shumokuUpdateId);
  }, [shumokuUpdateId]);

  const setErrorForField = ({
    fieldName,
    message = "",
  }: {
    fieldName: string;
    message?: string;
  }) => {
    form.getFieldInstance(fieldName).focus();
    form.setFields([{ name: fieldName, errors: [message] }]);
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  const showErrors = useCallback((errorCode: string) => {
    switch (errorCode) {
      case ECreateShumokuMasterError.EXISTED_SHUMOKU_MASTER_CODE:
        setErrorForField({
          fieldName: CREATE_SHUMOKU_FIELD.SHUMOKU_CODE.NAME,
          message: TRANSLATION.SHUMOKU_CODE_EXISTED,
        });
        break;
      case ECreateShumokuMasterError.EXISTED_SHUMOKU_MASTER_NAME:
        setErrorForField({
          fieldName: CREATE_SHUMOKU_FIELD.SHUMOKU_NAME.NAME,
          message: TRANSLATION.SHUMOKU_NAME_EXISTED,
        });
        break;
      case ECreateShumokuMasterError.INVALID_THUMBNAIL_FORMAT:
        setErrorForField({
          fieldName: CREATE_SHUMOKU_FIELD.THUMBNAIL.NAME,
          message: TRANSLATION.INVALID_THUMBNAIL_FORMAT,
        });
        break;
      case ECreateShumokuMasterError.INVALID_VIDEO_FORMAT:
        setErrorForField({
          fieldName: CREATE_SHUMOKU_FIELD.VIDEO.NAME,
          message: TRANSLATION.INVALID_VIDEO_FORMAT,
        });
        break;

      default:
        setErrorForField({
          fieldName: "",
          message: "",
        });
        break;
    }
  }, []);

  const handleCreateError = (errors: TResError[]) => {
    errors.forEach((element: TResError) => {
      showErrors(element.code);
    });
  };

  const beforeThumbnailUpload = (file: any, fileList: any) => {
    const isValidImage = validateImageFileType(file);
    if (isValidImage) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreviewThumbnail(reader.result as string);
      };
    } else {
      setPreviewThumbnail("");
    }
    setThumbnailList(fileList);
    return false;
  };

  const onThumbnailRemove = () => {
    setThumbnailList([]);
    form.setFields([
      {
        name: CREATE_SHUMOKU_FIELD.THUMBNAIL.NAME,
        value: undefined,
      },
    ]);
    setPreviewThumbnail("");
  };

  const beforeVideoUpload = (file: any, fileList: any) => {
    const isValidImage = validateVideoFileType(file);
    if (isValidImage) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreviewVideo(reader.result as string);
      };
    } else {
      setPreviewVideo("");
    }
    setVideoList(fileList);
    return false;
  };

  const onVideoRemove = () => {
    setVideoList([]);
    form.setFields([
      {
        name: CREATE_SHUMOKU_FIELD.VIDEO.NAME,
        value: undefined,
      },
    ]);
    setPreviewVideo("");
  };

  const handleFormChange = () => {
    setIsChangeUpdateForm(true);
  };

  const onFinish = async (values: any) => {
    // check form unchanged, if true then cancel modal, if false then execute update shumoku
    if (!isChangeUpdateForm) {
      handleOk();
    } else {
      startLoading();
      setIsDisableSubmit(true);
      try {
        let isUpdatedThumbnail = "false";
        let isUpdatedVideo = "false";
        const formData = new FormData();
        formData.append("shumokuCode", values.shumokuCode);
        formData.append("shumokuName", values.shumokuName);
        formData.append("majorItemMasterId", values.majorItemMasterId);
        formData.append("tempo", values.tempo || "");
        formData.append("location", values.location || "");

        //generate pre-sign URL
        const { data } = await shumokuApi.generatePreUrlForUpdateShumoku(
          shumokuUpdateId,
          formData
        );
        const { thumbnail: thumbnailPreSignUrl, videoUrl: videoPreSignUrl } =
          data;

        // check change thumbnail file
        if (
          values.thumbnail !== shumokuUpdate?.thumbnail &&
          thumbnailPreSignUrl
        ) {
          //convert thumbnail to Binary
          const binaryThumbnail = await convertFileToBinary(
            values.thumbnail.fileList[0]?.originFileObj
          );
          await axios.put(thumbnailPreSignUrl, binaryThumbnail);
          isUpdatedThumbnail = "true";
        }

        // check change video file
        if (values.videoUrl !== shumokuUpdate?.videoUrl && videoPreSignUrl) {
          //convert video to Binary
          const binaryVideo = await convertFileToBinary(
            values.videoUrl.fileList[0]?.originFileObj
          );
          await axios.put(videoPreSignUrl, binaryVideo);
          isUpdatedVideo = "true";
        }

        // add param for update Shumoku Master
        formData.append("isUpdatedThumbnail", isUpdatedThumbnail);
        formData.append("isUpdatedVideo", isUpdatedVideo);

        const { data: updateInfo } = await shumokuApi.updateShumokuMasterById(
          shumokuUpdateId,
          formData
        );
        if (updateInfo) {
          dispatch(shumokuActions.getAllShumokus());
          handleOk();
          form.resetFields();
        }
      } catch (e: any) {
        console.log(e);
        const errorList = (e?.data as TResponse)?.errors || [];
        if (errorList) {
          handleCreateError(errorList);
        }
        setIsDisableSubmit(false);
      } finally {
        stopLoading();
        setIsDisableSubmit(false);
      }
    }
  };

  const checkThumbnailUpload = () => {
    if (thumbnailList.length === 0 && !previewThumbnail) {
      return Promise.reject(TRANSLATION.THUMBNAIL_REQUIRED);
    }

    // check image format
    const isValidImage = thumbnailList.some((file: UploadFile) => {
      return validateImageFileType(file);
    });

    if (!isValidImage && !previewThumbnail) {
      return Promise.reject(TRANSLATION.INVALID_THUMBNAIL_FORMAT);
    }

    return Promise.resolve();
  };

  const checkVideoUpload = () => {
    if (videoList.length === 0 && !previewVideo) {
      return Promise.reject(TRANSLATION.VIDEO_REQUIRED);
    }

    // check video format
    const isValidVideo = videoList.some((file: UploadFile) => {
      return validateVideoFileType(file);
    });
    if (!isValidVideo && !previewVideo) {
      return Promise.reject(TRANSLATION.INVALID_VIDEO_FORMAT);
    }

    return Promise.resolve();
  };
  const trimFieldValue = (filedName: string) => {
    const fieldValue = form.getFieldValue(filedName);
    form.setFieldValue(filedName, fieldValue?.trim());
  };
  return (
    <Fragment>
      <ShumokuMasterUpdateFormModalStyle>
        {shumokuUpdate && (
          <Modal
            centered
            open={open}
            maskStyle={{ background: "rgba(23, 24, 29, 0.9)" }}
            width={621}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            title={TRANSLATION.MODAL_UPDATE_TITLE}
            className="store-create-modal"
            maskClosable={false}
            forceRender
            zIndex={1}
          >
            <ShumokuMasterUpdateFormModalStyle>
              <Divider />
              <Form
                initialValues={shumokuUpdate}
                layout="vertical"
                style={{ maxWidth: 600 }}
                onFinish={onFinish}
                form={form}
                onChange={handleFormChange}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name={CREATE_SHUMOKU_FIELD.SHUMOKU_NAME.NAME}
                      label={CREATE_SHUMOKU_FIELD.SHUMOKU_NAME.LABEL}
                      labelAlign="right"
                      rules={[
                        {
                          required: true,
                          transform: (value) => value.trim(),
                          message: `${TRANSLATION.SHUMOKU_NAME_REQUIRED}`,
                        },
                      ]}
                    >
                      <Input
                        onBlur={() => {
                          trimFieldValue(
                            CREATE_SHUMOKU_FIELD.SHUMOKU_NAME.NAME
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={CREATE_SHUMOKU_FIELD.SHUMOKU_CODE.NAME}
                      label={CREATE_SHUMOKU_FIELD.SHUMOKU_CODE.LABEL}
                      rules={[
                        {
                          required: true,
                          transform: (value) => value.trim(),
                          message: `${TRANSLATION.SHUMOKU_CODE_REQUIRED}`,
                        },
                        {
                          max: 36,
                          message: `${TRANSLATION.SHUMOKU_CODE_MAX_LENGTH}`,
                        },
                      ]}
                    >
                      <Input
                        onBlur={() => {
                          trimFieldValue(
                            CREATE_SHUMOKU_FIELD.SHUMOKU_CODE.NAME
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={CREATE_SHUMOKU_FIELD.MAJOR_ITEM_MASTER.NAME}
                      label={CREATE_SHUMOKU_FIELD.MAJOR_ITEM_MASTER.LABEL}
                      rules={[
                        {
                          required: true,
                          message: `${TRANSLATION.MAJOR_ITEM_MASTER_REQUIRED}`,
                        },
                      ]}
                    >
                      <Select onChange={handleFormChange}>
                        {majorItems.length > 0 &&
                          majorItems.map((majorItem: TMajorItem) => {
                            return (
                              <Select.Option
                                key={majorItem.id}
                                value={majorItem.id}
                              >
                                {majorItem.majorItemName}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name={CREATE_SHUMOKU_FIELD.LOCATION.NAME}
                      label={CREATE_SHUMOKU_FIELD.LOCATION.LABEL}
                      labelAlign="right"
                    >
                      <Input
                        onBlur={() => {
                          trimFieldValue(CREATE_SHUMOKU_FIELD.LOCATION.NAME);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={CREATE_SHUMOKU_FIELD.TEMPO.NAME}
                      label={CREATE_SHUMOKU_FIELD.TEMPO.LABEL}
                    >
                      <Input
                        onBlur={() => {
                          trimFieldValue(CREATE_SHUMOKU_FIELD.TEMPO.NAME);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name={CREATE_SHUMOKU_FIELD.THUMBNAIL.NAME}
                      label={CREATE_SHUMOKU_FIELD.THUMBNAIL.LABEL}
                      labelAlign="right"
                      required
                      className="thumbnail"
                      rules={[
                        {
                          validator: checkThumbnailUpload,
                        },
                      ]}
                    >
                      <Upload
                        beforeUpload={beforeThumbnailUpload}
                        onRemove={onThumbnailRemove}
                        className="upload-list-inline"
                        maxCount={1}
                        accept=".png, .jpg"
                        fileList={thumbnailList}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          className="upload-button"
                        >
                          {TRANSLATION.UPLOAD_THUMBNAIL}
                        </Button>
                      </Upload>
                    </Form.Item>
                    {previewThumbnail && (
                      <div className="shumoku-thumbnail">
                        <img src={previewThumbnail} />
                      </div>
                    )}
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={CREATE_SHUMOKU_FIELD.VIDEO.NAME}
                      label={CREATE_SHUMOKU_FIELD.VIDEO.LABEL}
                      required
                      className="video"
                      rules={[
                        {
                          validator: checkVideoUpload,
                        },
                      ]}
                    >
                      <Upload
                        beforeUpload={beforeVideoUpload}
                        onRemove={onVideoRemove}
                        className="upload-list-inline"
                        maxCount={1}
                        accept=".mp4"
                        fileList={videoList}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          className="upload-button"
                        >
                          {TRANSLATION.UPLOAD_VIDEO}
                        </Button>
                      </Upload>
                    </Form.Item>
                    {previewVideo && (
                      <video
                        className="video-preview"
                        src={previewVideo}
                        controls
                      >
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </Col>
                </Row>
                <Divider style={{ marginTop: "10px" }} />
                <Row justify="end" align="bottom">
                  <Form.Item style={{ marginBottom: "0px" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isDisableSubmit}
                    >
                      {/* !Add space end of text => to fix issue `auto add space between 2 chars in button` of antd */}
                      {TRANSLATION.SAVE}
                      {""}
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </ShumokuMasterUpdateFormModalStyle>
          </Modal>
        )}
      </ShumokuMasterUpdateFormModalStyle>
    </Fragment>
  );
};
export { ShumokuMasterUpdateFormModal };
