import { Form, Switch } from "antd";
import { TShumoku } from "model/shumoku";
import React, { FC, useEffect, useState } from "react";
import { ShumokuStyled } from "./Shumoku.styled";
import images from "constants/images";
import { replaceErrorImage } from "helpers";
import { ShumokuVideo } from "components/ShumokuItem/components";
import { PlaySquare } from "constants/svgs";

type TProps = {
  shumoku: TShumoku;
  selectedShumokuIds: Set<string>;
  handleChangeShumokuActive: (id: string, checked: boolean) => void;
};

const FIELD_NAME = {
  STATUS: "status",
};

const Shumoku: FC<TProps> = ({
  shumoku,
  selectedShumokuIds,
  handleChangeShumokuActive,
}) => {
  const [form] = Form.useForm();
  const [openVideo, setOpenVideo] = useState(false);
  const onChange = (checked: boolean) => {
    handleChangeShumokuActive(shumoku.id, checked);
  };

  const handleVideoClick = () => {
    setOpenVideo(true);
  };

  useEffect(() => {
    form.setFieldsValue({
      [FIELD_NAME.STATUS]: selectedShumokuIds.has(shumoku.id) ? false : true,
    });
  }, [selectedShumokuIds]);

  return (
    <Form form={form}>
      <ShumokuStyled>
        <div className="shumoku-name">{shumoku?.shumokuName}</div>
        <div className="shumoku-code">
          <span className="label">番号 : </span>
          <span className="value">{shumoku?.shumokuCode}</span>
        </div>
        <div className="shumoku-thumbnail" onClick={handleVideoClick}>
          <img
            src={shumoku?.thumbnail || images.ThumbnailImage}
            onError={replaceErrorImage}
            alt={shumoku?.shumokuName}
            loading="lazy"
          />
          <div className="thumbnail-overlay">
            <PlaySquare />
          </div>
        </div>
        <Form.Item name={FIELD_NAME.STATUS} valuePropName="checked">
          <Switch className="switch-btn" onChange={onChange} />
        </Form.Item>

        <ShumokuVideo
          open={openVideo}
          handleOk={() => setOpenVideo(true)}
          handleCancel={() => setOpenVideo(false)}
          video={shumoku}
          isShowParams={false}
        />
      </ShumokuStyled>
    </Form>
  );
};

const ShumokuMemo = React.memo(Shumoku);

export { ShumokuMemo };
