import { Button, Modal } from "antd";
import { useAppSelector, useScrollLock } from "hooks";
import { useState } from "react";

import {
  AnchorContainerStyled,
  ShumokuMasterSideBarStyled,
} from "./ShumokuMasterSideBar.styled";
import { MajorItemManagement } from "pages/MajorItemManagement/MajorItemManagement";
import { TRANSLATION } from "../ShumokuMaster.translate";
import { MajorItemCreateFormModal } from "pages/MajorItemManagement/components/MajorItemCreateFormModal/MajorItemCreateFormModal";
import { useAppModal } from "contexts";
import { DEFAULT_MODAL_PROPS } from "constants/common";
import { CheckCircleOutlined } from "@ant-design/icons";
import { TMajorItem } from "model/shumoku";
import { MajorItemUpdateFormModal } from "pages/MajorItemManagement/components/MajorItemUpdateFormModal/MajorItemUpdateFormModal";

export type SideBarItem = {
  key: string;
  href: string;
  title: string;
};

const MARGIN_TOP = 104;

const ShumokuMasterSideBar = () => {
  const { majorItems } = useAppSelector((state) => state.shumoku);
  const { appModal } = useAppModal();
  const [activeMajorItem, setActiveMajorItem] = useState<string>();

  const [isOpenMajorMasterManagement, setIsOpenMajorMasterManagement] =
    useState(false);
  useScrollLock(isOpenMajorMasterManagement);
  const [majorItemUpdate, setMajorItemUpdate] = useState<
    TMajorItem | undefined
  >();
  const [isOpenMajorMasterCreate, setIsOpenMajorMasterCreate] = useState(false);
  const handleOpenSuccessModal = (message: string) => {
    appModal.confirm({
      ...DEFAULT_MODAL_PROPS.SUCCESS,
      width: 520,
      title: message,
      okText: TRANSLATION.YES,
      icon: <CheckCircleOutlined style={{ color: "#52C41A" }} />,
      cancelButtonProps: { style: { display: "none" } },
    });
  };

  const handleClickAnchor = (item: TMajorItem) => {
    const targetElement = document.querySelector(
      `#${item?.majorItemCode.replace(/\s/g, "")}`
    );

    if (targetElement) {
      const y =
        targetElement.getBoundingClientRect().top + window.scrollY - MARGIN_TOP;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
    setActiveMajorItem(item.id);
  };

  const handleClick = () => {
    setIsOpenMajorMasterManagement(true);
  };

  return (
    <ShumokuMasterSideBarStyled id="sidebar">
      <AnchorContainerStyled>
        {majorItems?.map((item) => (
          <div
            key={item?.majorItemCode}
            className={
              activeMajorItem === item.id ? "step-item-active" : "step-item"
            }
            onClick={() => handleClickAnchor(item)}
          >
            <span className={`label`} />
            <span className="content">
              <span>{item?.majorItemName}</span>
            </span>
          </div>
        ))}
      </AnchorContainerStyled>
      <Button
        type="primary"
        className="major-item-management"
        onClick={handleClick}
      >
        {TRANSLATION.MAJOR_ITEM_MANAGEMENT}
      </Button>
      {isOpenMajorMasterManagement && (
        <Modal
          open={isOpenMajorMasterManagement}
          width={621}
          onOk={() => setIsOpenMajorMasterManagement(false)}
          onCancel={() => setIsOpenMajorMasterManagement(false)}
          centered
          footer={false}
          maskClosable={false}
          title={TRANSLATION.MAJOR_ITEM_MANAGEMENT}
        >
          <MajorItemManagement
            handleOpenCreateModal={() => {
              setIsOpenMajorMasterCreate(true);
            }}
            handleOpenUpdateModal={(majorItem) => {
              setIsOpenMajorMasterCreate(true);
              setMajorItemUpdate(majorItem);
            }}
          />
        </Modal>
      )}

      {isOpenMajorMasterCreate && (
        <Modal
          open={isOpenMajorMasterCreate}
          width={454}
          onOk={() => setIsOpenMajorMasterCreate(false)}
          onCancel={() => {
            setIsOpenMajorMasterCreate(false);
            setMajorItemUpdate(undefined);
          }}
          centered
          footer={false}
          maskClosable={false}
          title={
            majorItemUpdate
              ? TRANSLATION.MAJOR_ITEM_UPDATE
              : TRANSLATION.MAJOR_ITEM_CREATE
          }
        >
          {majorItemUpdate ? (
            <MajorItemUpdateFormModal
              majorItemUpdate={majorItemUpdate}
              handleOk={() => {
                setIsOpenMajorMasterCreate(false);
                handleOpenSuccessModal(TRANSLATION.UPDATE_SUCCESS_NOTIFICATION);
                setMajorItemUpdate(undefined);
              }}
            />
          ) : (
            <MajorItemCreateFormModal
              handleOk={() => {
                setIsOpenMajorMasterCreate(false);
                handleOpenSuccessModal(TRANSLATION.CREATE_SUCCESS_NOTIFICATION);
              }}
            />
          )}
        </Modal>
      )}
    </ShumokuMasterSideBarStyled>
  );
};

export { ShumokuMasterSideBar };
