export const MEMBER_CONFIRMATION_TRANSLATE = {
  TITLE: "会員登録",
  TEXT_BUTTON: "検索",
  TEXT_DATE: "YYYY/MM/DD",
  INPUT_PLACEHOLDER: "会員IDを入力してください",
  MODAL_TITILE: "ギマーが見つかりません",
  MODAL_MESSAGE: "このジムは星系には存在しません。もう一度やり直してください。",
  MODAL_TEXT_BUTTON: "検索に戻る",
  MEMBER_ID: "会員ID",
  MEMBER_DATE: "生年月日",
  BACK_ICON: "戻る",
};
