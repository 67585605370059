import { RestFilled, RightOutlined } from "@ant-design/icons";
import { PhaseTag } from "components";
import { PROGRAM_MENU_CONFIGS, ProgramMenuType } from "constants/program";
import { getFormattedDateFromUTC } from "helpers";
import { TTrainingProgram } from "model/member";
import { TrainingProgramWrapperStyled } from "./TrainingProgramItem.styled";

type TTrainingProgramProps = {
  data: TTrainingProgram;
  onClick?: () => void;
};

function TrainingProgramItem(props: TTrainingProgramProps) {
  const { data, onClick } = props;
  const {
    latestCheckIn: lastCheckInDate,
    programMenu,
    index,
    exerciseMenuPurpose,
  } = data;

  const { name = "Unknown program", icon = <RestFilled /> } =
    PROGRAM_MENU_CONFIGS[programMenu] || {};

  return (
    <TrainingProgramWrapperStyled onClick={onClick}>
      <div className="icon-training">{icon}</div>

      <div className="training-info">
        <p className="training-title">
          {programMenu === ProgramMenuType.OTHER ? exerciseMenuPurpose : name}
        </p>
        <PhaseTag phaseIndex={index} style={{ marginBlock: "5px" }} />
        <p className="training-end-date">
          トレーニング最終日:{" "}
          <strong>
            {lastCheckInDate ? getFormattedDateFromUTC(lastCheckInDate) : "-"}
          </strong>
        </p>
      </div>

      <div className="icon-show-detail">
        <RightOutlined />
      </div>
    </TrainingProgramWrapperStyled>
  );
}

export { TrainingProgramItem };
