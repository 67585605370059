import { EmptyContent } from "components";
import styled from "styled-components";
import { Tabs } from "antd";

export const StatisticWrapperStyled = styled.div`
  padding: 32px 24px;

  .section-header {
    margin-bottom: 8px;

    color: #b0b0b0;
    font-weight: 700;
    font-size: var(--font-size-md);
    line-height: 24px;
  }

  .section-container {
    display: flex;
    flex-direction: column;

    .section-content {
      flex: 1;
    }
  }
  .btn-tab {
    border-style: solid;
    border-color: var(--color-primary);
    margin: 30px 0;
    width: 100%;
    color: var(--color-primary);
  }
`;

export const EmptyContentStyled = styled(EmptyContent)`
  padding: max(16rem, 16px);

  border: var(--border-config);
  background-color: var(--color-white);

  justify-content: center;

  .no-member-title {
    color: #000000d9;
    font-size: max(16rem, 16px);
  }
`;

export const TabsStyled = styled(Tabs)`
  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 100%;
    justify-content: center;
  }

  .ant-tabs-nav-wrap::after {
    position: relative !important;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    content: "";
    pointer-events: none;
    width: 0px !important;
  }

  .ant-tabs-nav-wrap::before {
    position: relative !important;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    content: "";
    pointer-events: none;
    width: 0px !important;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }
`;
