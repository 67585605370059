import { LoginPattenUrl } from "constants/svgs";
import styled from "styled-components";

export const LayoutContainerStyled = styled.div`
  min-height: 100vh;
  min-height: 100dvh;

  display: flex;
  flex-direction: column;

  background-color: var(--color-background);
  background-image: url(${LoginPattenUrl});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
`;

export const TopContentStyled = styled.div`
  padding-top: 24rem;
  flex: 1;

  .logo {
    display: block;
    margin: auto;
    height: max(102rem, 102px);
    width: max(133rem, 133px);
    object-fit: contain;
  }
`;

export const BackgroundContainerStyled = styled.div`
  background-color: var(--color-background);
`;

export const MainContentStyled = styled.div`
  margin-top: 15rem;
  padding-inline: 24rem;

  display: flex;
  justify-content: space-between;

  .content {
    flex: 1;
    max-width: calc(466px + 2 * 24rem);
    padding-inline: 24rem;
  }

  .left-side {
    font-size: min(36px, 36rem);
  }

  .right-side {
    font-size: min(40px, 40rem);
  }

  .left-side,
  .right-side {
    height: 0;

    position: relative;
    top: -13rem;

    color: var(--color-primary);
    font-weight: 700;
    text-align: center;
  }

  @media only screen and (max-width: 576px) {
    justify-content: center;

    .left-side,
    .right-side {
      display: none;
    }
  }
`;

export const LoginImageContainerStyled = styled.div`
  width: 100%;
  padding-top: 16rem;

  svg,
  img {
    width: 100%;
    margin-top: auto;
    display: block;
  }
`;

export const ExtraContentStyled = styled.div`
  margin-top: 12px;

  display: flex;
  justify-content: center;

  a {
    padding-inline: 12rem;
    position: relative;

    font-size: var(--font-size-sm);
    font-weight: 500;
    color: var(--color-black) !important;
    text-decoration: underline !important;

    &:not(:last-child) {
      ::after {
        content: "";
        width: 1px;
        height: 16px;
        background-color: var(--color-border);

        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;
