import images from "constants/images";
import styled from "styled-components";

export const ShumokuManagementFormStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;
export const ShumokuManagementFormContainerStyled = styled.div`
  background-image: url(${images.ProgramHeaderImage});
  padding: 32px 24px;
`;
export const ShumokuManagementHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .back-btn,
  .next-btn {
    color: var(--color-white);
    fill: var(--color-white);
  }
`;
export const ShumokuManagementTitleStyled = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: var(--color-white);
`;

export const SelectedStoreStyled = styled.div`
  display: flex;
  align-items: center;

  margin-top: 32px;
  font-weight: 700;
  font-size: 24px;

  .selected-store {
    background: linear-gradient(91.78deg, #ffeccf 2.16%, #ffc165 103.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    margin-left: 10px;
  }
`;

export const ShumokuSearchStyled = styled.div`
  padding: 24rem;
`;
