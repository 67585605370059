import { Column, ColumnConfig } from "@ant-design/plots";
import { numberWithKFormat } from "helpers";
import { TCheckInBarChartValue } from "model/statistic";
import { memo } from "react";
import { CheckInBarChartWrapperStyled } from "./CheckInColumnChart.styled";

type TCheckInBarChartProps = {
  title: string;
  data: TCheckInBarChartValue[];
};

const X_FIELD = "day";
const Y_FIELD = "value";

function CheckInColumnChartInternal(props: TCheckInBarChartProps) {
  const { data, title } = props;

  const maxValue = Math.max(...data.map((item) => item.value || 0));

  const config: ColumnConfig = {
    data,
    xField: X_FIELD,
    yField: Y_FIELD,
    color: "#FFF1E8",
    columnStyle: (_data: TCheckInBarChartValue) => {
      // if (data.day === "Wed") return { fill: "#FF730D", cursor: "pointer" };

      return {
        fill: "#FFF1E8",
        cursor: "pointer",
      };
    },
    columnWidthRatio: 0.8,
    height: 273,
    label: {
      position: "top",
      style: {
        fill: "#FF7A19",
        opacity: 1,
        fontWeight: 500,
      },
      offsetY: 32,
      formatter: (dataItem) => {
        return numberWithKFormat(dataItem.value).toString();
      },
      callback: (value) => {
        return {
          // !If value less than maxValue * 0.25 or less than 0 => show label at top of column
          offsetY: value < maxValue * 0.25 || value <= 0 ? 8 : 34,
        };
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        style: {
          fill: "#BFBFBF",
          opacity: 1,
          fontWeight: 400,
        },
      },
      tickLine: null,
      line: null,
    },
    yAxis: {
      grid: { line: { style: { fill: "#F0F0F0", lineWidth: 0.5 } } },
      label: {
        style: (_item, index, items) => {
          if (index === 0 || index === items.length - 1) {
            return { fill: "#000000D9", fontWeight: 400, fontSize: 12 };
          }

          return { fill: "#D9D9D9", fontWeight: 400, fontSize: 12 };
        },
        formatter: (yLabel) => {
          return numberWithKFormat(parseFloat(yLabel));
        },
      },
    },
    tooltip: false,
  };

  return (
    <CheckInBarChartWrapperStyled>
      <h2 className="chart-title">{title}</h2>

      <Column {...config} />
    </CheckInBarChartWrapperStyled>
  );
}

const CheckInColumnChart = memo(CheckInColumnChartInternal);

export { CheckInColumnChart };
