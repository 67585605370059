import { FC } from "react";
import { StoreDeleteButtonStyle } from "./StoreDeleteButton.styled";
import { DeleteOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "hooks";
import { useAppLoading, useAppModal } from "contexts";
import { DEFAULT_MODAL_PROPS, DEFAULT_PAGE_NUMBER } from "constants/common";
import { TRANSLATION } from "pages/ShumokuManagementPage/ShumokuManagementPage.translate";
import shumokuApi from "features/shumoku/shumokuApi";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import { useSearchParams } from "react-router-dom";

const PAGE_SIZE = 30;

type TProps = {
  handleOk: () => void;
};
const StoreDeleteButton: FC<TProps> = ({ handleOk }) => {
  const { selectedNumber, selectedStoreIds } = useAppSelector(
    (state) => state.shumoku
  );
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("keyword");
  const statusValue = searchParams.get("status");

  const { startLoading, stopLoading } = useAppLoading();

  const handleDeleteStore = async (storeIds: string[]) => {
    try {
      const deletedStore = await shumokuApi.deleteStore({ storeIds: storeIds });
      if (deletedStore) {
        dispatch(shumokuActions.resetStores());
        dispatch(shumokuActions.setSelectedStoreIds({ data: [] }));
        dispatch(shumokuActions.setSelectedNumber({ data: 0 }));
        dispatch(
          shumokuActions.getAllStores({
            data: {
              TextStoreSearch: searchValue as string,
              PageNumber: DEFAULT_PAGE_NUMBER,
              PageSize: PAGE_SIZE,
              ActiveFilter: statusValue as string,
            },
          })
        );
        handleOk();
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const { appModal } = useAppModal();
  const handleClick = async () => {
    if (selectedNumber === 0) {
      appModal.confirm({
        ...DEFAULT_MODAL_PROPS.CONFIRM,
        title: TRANSLATION.PLEASE_SELECT,
        okText: TRANSLATION.BACK,
        cancelButtonProps: { style: { display: "none" } },
      });
    } else {
      // Check validation when delete store
      startLoading();
      const alreadyStoreNumbers = await checkUsedStore(selectedStoreIds);
      stopLoading();

      if (alreadyStoreNumbers.length === 0) {
        appModal.confirm({
          ...DEFAULT_MODAL_PROPS.CONFIRM,
          title: TRANSLATION.CONFIRM_DELETE,
          onOk: () => {
            handleDeleteStore(selectedStoreIds);
          },
        });
      } else {
        let storeNumberDelete = "";
        alreadyStoreNumbers.forEach(
          (storeNumber: string, index: number, array: string[]) => {
            if (index === array.length - 1) {
              storeNumberDelete += `${storeNumber}`;
            } else {
              storeNumberDelete += `${storeNumber}、`;
            }
          }
        );
        const warningMessage = `店舗(${storeNumberDelete}）を削除出来ません、すでに会員が存在しています。`;
        appModal.confirm({
          ...DEFAULT_MODAL_PROPS.CONFIRM,
          title: warningMessage,
          okText: TRANSLATION.BACK,
          cancelButtonProps: { style: { display: "none" } },
        });
      }
    }
  };

  // Check if the store is in use
  const checkUsedStore = async (storeIds: string[]) => {
    const alreadyStoreNumbers = [];
    for (const storeId of storeIds) {
      const store = await shumokuApi.getStoreById(storeId);
      if (store?.data?.members?.length > 0) {
        alreadyStoreNumbers.push(store.data.storeNumber);
      }
    }
    return alreadyStoreNumbers;
  };

  return (
    <StoreDeleteButtonStyle onClick={handleClick}>
      <DeleteOutlined />
    </StoreDeleteButtonStyle>
  );
};
export { StoreDeleteButton };
