import { ShumokuMasterStyled } from "./ShumokuMaster.styled";
import { ShumokuMasterForm } from "./ShumokuMasterForm/ShumokuMasterForm";
import { ShumokuMasterManagementMain } from "./ShumokuMasterManagementMain/ShumokuMasterManagementMain";

const ShumokuMaster = () => {
  return (
    <ShumokuMasterStyled>
      <ShumokuMasterForm />
      <ShumokuMasterManagementMain />
    </ShumokuMasterStyled>
  );
};

export { ShumokuMaster };
