import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { TPagination } from "types/service";

export type TGetDataInfinityScroll<D = any> = (pageNumber: number) => Promise<{
  data: D[];
  pagination: Pick<TPagination, "hasNext" | "currentPage">;
}>;

type TUseInfinityScrollProps<D = any> = {
  getData: TGetDataInfinityScroll<D>;
  offsetTop?: number;
  wrapperId?: string;
};

const DEFAULT_OFFSET_TOP = 200;
const INITIAL_PAGE_NUMBER = 1;

function useInfinityScroll<D = any, W = any>(
  props: TUseInfinityScrollProps<D>
) {
  const { getData, offsetTop = DEFAULT_OFFSET_TOP, wrapperId } = props;

  const [data, setData] = useState<D[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageNumber, setPageNumber] = useState(INITIAL_PAGE_NUMBER);

  const hasNextRef = useRef(true);

  const handleGetData = async () => {
    try {
      setLoading(true);
      setError(false);
      const res = await getData(pageNumber);

      if (res.data) {
        setData((prevState) => [...prevState, ...res.data]);
        setPageNumber(res.pagination.currentPage + 1);
        hasNextRef.current = res.pagination.hasNext;
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const observer = useRef<IntersectionObserver>();
  const wrapperRef = useCallback(
    (node: W & Element) => {
      if (loading || error) return;

      if (observer.current) {
        observer.current.disconnect();
      }

      const wrapperNode = wrapperId
        ? document.getElementById(wrapperId)
        : undefined;

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextRef.current) {
            handleGetData();
          }
        },
        { rootMargin: `${offsetTop}px`, root: wrapperNode }
      );

      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, error, wrapperId, offsetTop]
  );

  const TriggerItem = () => {
    return <div ref={wrapperRef as any} />;
  };

  // Get data for the first time
  useLayoutEffect(() => {
    handleGetData();
  }, []);

  return { data, wrapperRef, loading, error, TriggerItem };
}

export { useInfinityScroll };
