import { DeleteOutlined, LockFilled } from "@ant-design/icons";
import { Button, Col, Divider, Form, Row, Select, Switch } from "antd";
import { useWatch } from "antd/es/form/Form";
import {
  CheckboxGradientIcon,
  DotsSixIcon,
  MenuGradientIcon,
  RadioGradientIcon,
} from "constants/svgs";
import { EQuestionType } from "model/survey";
import { SURVEY_CREATION_FORM } from "pages/SurveyManagementPage/SurveyManagementPage.constant";
import { useSurveyManagementPageContext } from "pages/SurveyManagementPage/contexts";
import { FocusEvent, Fragment, useId } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { TSelectOption } from "types/antd";
import { OptionList } from "../OptionList/OptionList";
import {
  ActionsWrapperStyled,
  InputPlanTextStyled,
  OptionActionsStyled,
  QuestionTitleStyled,
  QuestionTypeOptionStyled,
  QuestionWrapperStyled,
} from "./QuestionCreationItem.styled";
import { QUESTION_CREATION_TRANSLATE } from "./QuestionCreationItem.translate";

const QUESTION_TYPE_OPTIONS: TSelectOption[] = [
  {
    label: <QuestionTypeOptionStyled>単一選択</QuestionTypeOptionStyled>,
    labelWithIcon: (
      <QuestionTypeOptionStyled>
        <RadioGradientIcon />
        単一選択
      </QuestionTypeOptionStyled>
    ),
    value: EQuestionType.SINGLE_RADIO,
  },
  {
    label: <QuestionTypeOptionStyled>複数選択</QuestionTypeOptionStyled>,
    labelWithIcon: (
      <QuestionTypeOptionStyled>
        <CheckboxGradientIcon />
        複数選択
      </QuestionTypeOptionStyled>
    ),
    value: EQuestionType.MULTIPLE,
  },
  {
    label: <QuestionTypeOptionStyled>回答入力</QuestionTypeOptionStyled>,
    labelWithIcon: (
      <QuestionTypeOptionStyled>
        <MenuGradientIcon />
        回答入力
      </QuestionTypeOptionStyled>
    ),
    value: EQuestionType.TEXT,
  },
];

type TQuestionCreationItemProps = {
  isActive?: boolean;
  hideButtonDelete?: boolean;
  questionIndex: number;
  partIndex: number;
  onRemoveQuestion: () => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
};

function QuestionCreationItem(props: TQuestionCreationItemProps) {
  const {
    isActive = false,
    hideButtonDelete = false,
    questionIndex,
    partIndex,
    onRemoveQuestion,
    dragHandleProps,
  } = props;

  const { surveyForm, genQuestionWrapperId, questionErrorList } =
    useSurveyManagementPageContext();

  const keyRef = useId();

  const questionFormName = [
    SURVEY_CREATION_FORM.SURVEY_PART.NAME,
    partIndex,
    SURVEY_CREATION_FORM.SURVEY_PART_QUESTIONS.NAME,
    questionIndex,
  ];

  // Form values
  const hasOtherOption = useWatch(
    [...questionFormName, SURVEY_CREATION_FORM.HAS_OTHER.NAME],
    surveyForm
  );
  const questionType = useWatch(
    [...questionFormName, SURVEY_CREATION_FORM.QUESTION_TYPE.NAME],
    surveyForm
  );
  const isRequired = useWatch(
    [...questionFormName, SURVEY_CREATION_FORM.IS_REQUIRED.NAME],
    surveyForm
  );
  const isLocked = useWatch(
    [...questionFormName, SURVEY_CREATION_FORM.IS_LOCKED.NAME],
    surveyForm
  );
  const questionId = useWatch(
    [...questionFormName, SURVEY_CREATION_FORM.QUESTION_ID.NAME],
    surveyForm
  );
  const questionTitle =
    useWatch(
      [...questionFormName, SURVEY_CREATION_FORM.QUESTION_NAME.NAME],
      surveyForm
    ) || "";
  const options: string[] | undefined = useWatch(
    [...questionFormName, SURVEY_CREATION_FORM.OPTIONS.NAME],
    surveyForm
  );
  const isErrorQuestion =
    questionErrorList.findIndex(
      (q) => q.partIndex === partIndex && q.questionIndex === questionIndex
    ) !== -1;

  // Handlers
  const handleAddNewOptions = () => {
    const optionList: string[] =
      surveyForm?.getFieldValue([
        ...questionFormName,
        SURVEY_CREATION_FORM.OPTIONS.NAME,
      ]) || [];
    const optionIndex = optionList.length + 1;
    surveyForm?.setFieldValue(
      [...questionFormName, SURVEY_CREATION_FORM.OPTIONS.NAME],
      [...optionList, `選択肢${optionIndex}`]
    );

    setTimeout(() => {
      const newOptionInput: HTMLInputElement = surveyForm?.getFieldInstance([
        ...questionFormName,
        SURVEY_CREATION_FORM.OPTIONS.NAME,
        optionList.length,
      ]);

      newOptionInput?.focus();
      newOptionInput?.select();
    });
  };

  const handleAddOtherOption = () => {
    surveyForm?.setFieldValue(
      [...questionFormName, SURVEY_CREATION_FORM.HAS_OTHER.NAME],
      true
    );
  };

  const handleRemoveOtherOption = () => {
    surveyForm?.setFieldValue(
      [...questionFormName, SURVEY_CREATION_FORM.HAS_OTHER.NAME],
      false
    );
  };

  const handleQuestionTypeChange = (type: EQuestionType) => {
    // Set default options for the question if it's type is a option type
    if (
      (type === EQuestionType.MULTIPLE ||
        type === EQuestionType.SINGLE_RADIO) &&
      (!options || options.length < 1)
    ) {
      surveyForm?.setFieldValue(
        [...questionFormName, SURVEY_CREATION_FORM.OPTIONS.NAME],
        ["Default"]
      );
    }
  };

  const handleOnBlurQuestionName = (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    surveyForm?.setFieldValue(
      [...questionFormName, SURVEY_CREATION_FORM.QUESTION_NAME.NAME],
      value.trim()
    );
  };

  // Variables
  const isPlanTextQuestion = questionType === EQuestionType.TEXT;
  const isActiveQuestion = isActive && !isLocked;
  const lockedType = isLocked ? "locked" : "default";
  const allowEditQuestionType = !questionId;

  // Render UI
  const optionRenderUI =
    questionType === EQuestionType.TEXT ? (
      <InputPlanTextStyled
        placeholder="回答を入力してください。"
        readOnly
        size="large"
      />
    ) : (
      <OptionList
        type={questionType}
        onRemoveOtherOption={handleRemoveOtherOption}
        questionIndex={questionIndex}
        hasOther={hasOtherOption === true}
        active={isActiveQuestion}
        isLocked={isLocked}
        partIndex={partIndex}
      />
    );

  const questionBorderColor =
    isActiveQuestion && isErrorQuestion
      ? "var(--color-error)"
      : isActiveQuestion
      ? "var(--color-primary)"
      : "transparent";

  return (
    <QuestionWrapperStyled
      style={{
        borderColor: questionBorderColor,
      }}
      key={keyRef}
      id={genQuestionWrapperId(partIndex, questionIndex)}
    >
      <div>
        <div
          className="draggable-icon"
          {...dragHandleProps}
          style={{
            display: isActiveQuestion ? "block" : "none",
            cursor: "move",
          }}
        >
          <DotsSixIcon />
        </div>

        <QuestionTitleStyled type={lockedType}>
          <div className="question-title-wrapper">
            <div className="question-number-wrapper">
              <span className="question-number">{questionIndex + 1}</span>
            </div>

            {isActiveQuestion ? (
              <Form.Item
                noStyle
                name={[questionIndex, SURVEY_CREATION_FORM.QUESTION_NAME.NAME]}
                rules={[{ required: true }]}
              >
                <input
                  className="question-title-edit"
                  placeholder="新しい質問を入力してください"
                  onBlur={handleOnBlurQuestionName}
                />
              </Form.Item>
            ) : (
              <Fragment>
                <Form.Item
                  name={[
                    questionIndex,
                    SURVEY_CREATION_FORM.QUESTION_NAME.NAME,
                  ]}
                  rules={[{ required: true }]}
                  hidden
                >
                  <input />
                </Form.Item>
                <p className="question-title-view">
                  <span>{questionTitle}</span>
                  {isRequired && <span className="required">*</span>}
                </p>
              </Fragment>
            )}

            {isLocked && <LockFilled className="icon-lock" />}
          </div>

          {isActiveQuestion && allowEditQuestionType && (
            <Form.Item
              noStyle
              name={[questionIndex, SURVEY_CREATION_FORM.QUESTION_TYPE.NAME]}
            >
              <Select
                options={QUESTION_TYPE_OPTIONS}
                style={{ minWidth: "180px" }}
                onChange={handleQuestionTypeChange}
                className="option-type-select"
                // !Text `labelWithIcon` must match with the prop in `QUESTION_TYPE_OPTIONS`
                optionLabelProp="labelWithIcon"
              />
            </Form.Item>
          )}
        </QuestionTitleStyled>

        <div className="option-list">{optionRenderUI}</div>

        {isActiveQuestion && !isPlanTextQuestion && (
          <OptionActionsStyled>
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={12}>
                <Button
                  block
                  onClick={handleAddOtherOption}
                  disabled={hasOtherOption}
                  size="large"
                >
                  {QUESTION_CREATION_TRANSLATE.ADD_OTHER_OPTION}
                </Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  block
                  onClick={handleAddNewOptions}
                  size="large"
                >
                  {QUESTION_CREATION_TRANSLATE.ADD_NEW_OPTION}
                </Button>
              </Col>
            </Row>
          </OptionActionsStyled>
        )}

        <Form.Item
          name={[questionIndex, SURVEY_CREATION_FORM.HAS_OTHER.NAME]}
          hidden
        >
          <input />
        </Form.Item>

        {isActiveQuestion && (
          <ActionsWrapperStyled>
            <p className="error-alert">
              {isErrorQuestion &&
                QUESTION_CREATION_TRANSLATE.REQUIRED_QUESTION_NAME}
            </p>

            {!hideButtonDelete && (
              <Fragment>
                <DeleteOutlined onClick={onRemoveQuestion} />
                <Divider type="vertical" className="divider" />
              </Fragment>
            )}

            <div className="required-toggle">
              <Form.Item
                name={[questionIndex, SURVEY_CREATION_FORM.IS_REQUIRED.NAME]}
                noStyle
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              {QUESTION_CREATION_TRANSLATE.SET_REQUIRED}
            </div>
          </ActionsWrapperStyled>
        )}
      </div>
    </QuestionWrapperStyled>
  );
}

export { QuestionCreationItem };
