import { ReactNode } from "react";
import styled from "styled-components";
import { SurveyStep } from "./SurveyStep";
import { PattenSurveyUrl } from "constants/svgs";

export type TFormTitleProps = {
  formIndex?: number;
  title?: string;
  extraContent?: ReactNode;
  maxStep?: number;
};

function FormTitle(props: TFormTitleProps) {
  const { extraContent, formIndex, title, maxStep } = props;

  return (
    <FormTitleContainerStyled>
      <div className="step-wrapper">
        <SurveyStep current={formIndex} maxStep={maxStep} />

        <div className="title-wrapper">
          <div className="form-number">ステップ{formIndex}</div>
          <div className="extra-content">{extraContent}</div>
        </div>
      </div>
      <p className="form-name">{title}</p>
    </FormTitleContainerStyled>
  );
}

const FormTitleContainerStyled = styled.div`
  padding: 24.5rem 24rem 22rem;

  background-color: var(--color-primary);
  background-image: url(${PattenSurveyUrl});
  background-repeat: no-repeat;
  background-position: 93% 0%;
  background-size: contain;

  color: var(--color-white);
  font-weight: 700;

  .step-wrapper {
    width: 277rem;

    display: flex;
    flex-direction: column;
    gap: 13.5rem;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16rem;

    .form-number {
      line-height: 32px;
    }
  }

  .form-name {
    margin-top: 4rem;

    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  @media only screen and (max-width: 480px) {
    background-image: none;

    .step-wrapper {
      width: 100%;
    }
  }
`;

export { FormTitle };
