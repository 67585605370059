import { Fragment, useEffect } from "react";
import { StoreCreateFormModalStyle } from "./StoreCreateFormModal.styled";
import { FC, useCallback } from "react";
import { Button, Col, Divider, Form, Input, Modal, Row } from "antd";
import { TRANSLATION } from "pages/ShumokuManagementPage/ShumokuManagementPage.translate";
import shumokuApi from "features/shumoku/shumokuApi";
import { TResError, TResponse } from "types/service";
import { ECreateStoreError } from "model/shumoku";
import { useAppLoading } from "contexts";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import { DEFAULT_PAGE_NUMBER } from "constants/common";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppInputNumber } from "components";

type TProps = {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

const CREATE_STORE_FIELD = {
  STORE_NUMBER: {
    LABEL: TRANSLATION.STORE_NUMBER,
    NAME: "storeNumber",
  },
  STORE_NAME: {
    LABEL: TRANSLATION.STORE_NAME,
    NAME: "storeName",
  },
  EMAIL: {
    LABEL: TRANSLATION.EMAIL,
    NAME: "email",
  },
  PASSWORD: {
    LABEL: TRANSLATION.PASSWORD,
    NAME: "password",
  },
  CONFIRM_PASSWORD: {
    LABEL: TRANSLATION.CONFIRM_PASSWORD,
    NAME: "confirmPassword",
  },
};

const initialValues = {
  storeNumber: "",
  storeName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const PAGE_SIZE = 30;

const StoreCreateFormModal: FC<TProps> = ({ open, handleCancel, handleOk }) => {
  const [form] = Form.useForm();
  const { startLoading, stopLoading } = useAppLoading();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("keyword");
  const statusValue = searchParams.get("status");

  const setErrorForField = ({
    fieldName,
    message = "",
  }: {
    fieldName: string;
    message?: string;
  }) => {
    form.getFieldInstance(fieldName).focus();
    form.setFields([{ name: fieldName, errors: [message] }]);
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  const showErrors = useCallback((errorCode: string) => {
    switch (errorCode) {
      case ECreateStoreError.EXISTED_STORE_NUMBER:
        setErrorForField({
          fieldName: CREATE_STORE_FIELD.STORE_NUMBER.NAME,
          message: TRANSLATION.STORE_NUMBER_EXISTED,
        });
        break;
      case ECreateStoreError.EXISTED_STORE_NAME:
        setErrorForField({
          fieldName: CREATE_STORE_FIELD.STORE_NAME.NAME,
          message: TRANSLATION.STORE_NAME_EXISTED,
        });
        break;
      case ECreateStoreError.EXISTED_USER:
        setErrorForField({
          fieldName: CREATE_STORE_FIELD.EMAIL.NAME,
          message: TRANSLATION.EMAIL_EXISTED,
        });
        break;

      default:
        setErrorForField({
          fieldName: "",
          message: "",
        });
        break;
    }
  }, []);

  const handleCreateError = (errors: TResError[]) => {
    errors.forEach((element: TResError) => {
      showErrors(element.code);
    });
  };

  const onFinish = async (values: any) => {
    startLoading();
    try {
      const { data: createInfo } = await shumokuApi.createStore(values);
      if (createInfo) {
        dispatch(shumokuActions.resetStores());
        dispatch(
          shumokuActions.getAllStores({
            data: {
              TextStoreSearch: searchValue as string,
              PageNumber: DEFAULT_PAGE_NUMBER,
              PageSize: PAGE_SIZE,
              ActiveFilter: statusValue as string,
            },
          })
        );
        handleOk();
        form.resetFields();
      }
    } catch (e: any) {
      const errorList = (e?.data as TResponse)?.errors || [];
      if (errorList) {
        handleCreateError(errorList);
      }
    } finally {
      stopLoading();
    }
  };
  const trimFieldValue = (filedName: string) => {
    const fieldValue = form.getFieldValue(filedName);
    form.setFieldValue(filedName, fieldValue?.trim());
  };
  return (
    <Fragment>
      <StoreCreateFormModalStyle>
        <Modal
          centered
          open={open}
          maskStyle={{ background: "rgba(23, 24, 29, 0.9)" }}
          width={621}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          title={TRANSLATION.CREATE_MODAL_TITTLE}
          className="store-create-modal"
          maskClosable={false}
        >
          <StoreCreateFormModalStyle>
            <Divider />
            <Form
              initialValues={initialValues}
              layout="vertical"
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
              form={form}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name={CREATE_STORE_FIELD.STORE_NUMBER.NAME}
                    label={CREATE_STORE_FIELD.STORE_NUMBER.LABEL}
                    labelAlign="right"
                    rules={[
                      {
                        required: true,
                        message: `${TRANSLATION.STORE_NUMBER_REQUIRED}`,
                      },
                      {
                        pattern: /^\d{1,5}$/,
                        message: `${TRANSLATION.STORE_NUMBER_INVALID}`,
                      },
                    ]}
                  >
                    <AppInputNumber />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name={CREATE_STORE_FIELD.STORE_NAME.NAME}
                    label={CREATE_STORE_FIELD.STORE_NAME.LABEL}
                    rules={[
                      {
                        required: true,
                        transform: (value) => value.trim(),
                        message: `${TRANSLATION.STORE_NAME_REQUIRED}`,
                      },
                      {
                        max: 254,
                        message: `${TRANSLATION.STORE_NAME_INVALID}`,
                      },
                    ]}
                  >
                    <Input
                      onBlur={() => {
                        trimFieldValue(CREATE_STORE_FIELD.STORE_NAME.NAME);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name={CREATE_STORE_FIELD.EMAIL.NAME}
                    label={CREATE_STORE_FIELD.EMAIL.LABEL}
                    rules={[
                      {
                        required: true,
                        message: `${TRANSLATION.EMAIL_REQUIRED}`,
                      },
                      {
                        type: "email",
                        message: `${TRANSLATION.EMAIL_INVALID}`,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name={CREATE_STORE_FIELD.PASSWORD.NAME}
                    label={CREATE_STORE_FIELD.PASSWORD.LABEL}
                    rules={[
                      {
                        required: true,
                        message: `${TRANSLATION.PASSWORD_REQUIRED}`,
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={CREATE_STORE_FIELD.CONFIRM_PASSWORD.NAME}
                    label={CREATE_STORE_FIELD.CONFIRM_PASSWORD.LABEL}
                    rules={[
                      {
                        required: true,
                        message: `${TRANSLATION.CONFIRM_PASSWORD_REQUIRED}`,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue(CREATE_STORE_FIELD.PASSWORD.NAME) ===
                              value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(`${TRANSLATION.CONFIRM_PASSWORD_INVALID}`)
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
              <Divider style={{ marginTop: "10px" }} />
              <Row justify="end" align="bottom">
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button type="primary" htmlType="submit">
                    {TRANSLATION.SAVE}
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </StoreCreateFormModalStyle>
        </Modal>
      </StoreCreateFormModalStyle>
    </Fragment>
  );
};
export { StoreCreateFormModal };
