import { TabsProps } from "antd";
import { SURVEY_FORM_NAME } from "pages/SurveyManagementPage/SurveyManagementPage.constant";
import { useSurveyManagementPageContext } from "pages/SurveyManagementPage/contexts";
import { FormStyled, TabsStyled } from "./SurveyPart.styled";
import { SurveyPartItem } from "./components";

function SurveyPart() {
  const {
    surveyForm,
    onSaveSurvey,
    activePartTab,
    onChangePart,
    surveyPartNameList,
    onFinishSurveyFailed,
  } = useSurveyManagementPageContext();

  const items: TabsProps["items"] = surveyPartNameList.map(
    (surveyPartTitle, index) => ({
      key: index.toString(),
      label: `${index + 1}. ${surveyPartTitle || "Unknown title"}`,
      children: <SurveyPartItem surveyPartIndex={index} />,
      forceRender: true,
    })
  );

  return (
    <FormStyled
      form={surveyForm}
      name={SURVEY_FORM_NAME}
      onFinish={onSaveSurvey}
      onFinishFailed={onFinishSurveyFailed}
    >
      <TabsStyled
        activeKey={activePartTab}
        onChange={onChangePart}
        items={items}
      />
    </FormStyled>
  );
}

export { SurveyPart };
