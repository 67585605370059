import { pictureSvgs } from "constants/svgs";
import styled from "styled-components";

export const ShumokuStyled = styled.div`
  width: 100%;
  background: #fafafa;
  border: 1px solid #f0f0f0;

  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 12px;
  padding: 16rem;

  .shumoku-name {
    font-weight: 500;
    font-size: 14px;
    color: var(--color-black);
  }

  .shumoku-code {
    margin-top: 4px;
    margin-bottom: 12px;
    color: var(--color-black);
    .label {
      font-weight: 400;
      font-size: 12px;
    }
    .value {
      font-weight: 700;
      font-size: 12px;
    }
  }

  .shumoku-thumbnail {
    width: max(180rem, 180px);
    height: max(135rem, 135px);
    cursor: pointer;

    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      display: inline-block;

      background: url(${pictureSvgs.ImagePlaceholderUrl}) #eeeeee;
      background-repeat: no-repeat;
      background-position: center;
    }

    .thumbnail-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .switch-btn {
    margin-top: 12px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;
