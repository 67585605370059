export const MIN_BMI = 15;
export const MAX_BMI = 40;

export enum BMICategory {
  THINNESS,
  NORMAL,
  OVERWEIGHT,
  OBESITY,
}

type TGroupValue = {
  title: string;
  points: number[];
  bgColor: string;
  borderColor: string;
  color: string;
  tooltipBgColor: string;
  tooltipColor?: string;
  getDetailTitle: (bmi: number) => string;
};

type TBMIGroupObject = {
  [key in BMICategory]: TGroupValue;
};

export const BMI_CONFIG_LIST: TBMIGroupObject = {
  [BMICategory.THINNESS]: {
    title: "痩せ",
    points: [MIN_BMI, 16, 18.5],
    bgColor: "#E6F7FF",
    borderColor: "#91D5FF",
    color: "#1890FF",
    tooltipBgColor: "#1890FF",
    getDetailTitle: (bmi) => {
      if (bmi === 0) return "";
      if (bmi < 16) return "超低体重";
      return "低体重";
    },
  },
  [BMICategory.NORMAL]: {
    title: "普通体重",
    points: [18.5, 25],
    bgColor: "#F6FFED",
    borderColor: "#B7EB8F",
    color: "#52C41A",
    tooltipBgColor: "#52C41A",
    getDetailTitle: (_bmi) => {
      return "普通体重";
    },
  },
  [BMICategory.OVERWEIGHT]: {
    title: "軽肥満",
    points: [25, 30],
    bgColor: "#FFFBE6",
    borderColor: "#FFE58F",
    color: "#FAAD14",
    tooltipBgColor: "#FADB14",
    tooltipColor: "#000000D9",
    getDetailTitle: (_bmi) => {
      return "肥満（1度）";
    },
  },
  [BMICategory.OBESITY]: {
    title: "肥満",
    points: [30, 35, MAX_BMI],
    bgColor: "#FFF1F0",
    borderColor: "#FFA39E",
    color: "#F5222D",
    tooltipBgColor: "#F5222D",
    getDetailTitle: (bmi) => {
      if (bmi <= 35) return "肥満（2度）";
      if (bmi <= 40) return "肥満（3度）";
      return "肥満（4度）";
    },
  },
};
export const NUM_BMI_GROUP = Object.keys(BMI_CONFIG_LIST).length;
