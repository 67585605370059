export enum EHttpStatusCode {
  OK = 200,
  CREATED = 201,
  NOT_MODIFIED = 304,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  REQUEST_TIMEOUT = 408,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
}

export type TResError = {
  code: string;
  description: string;
};

export type TResponse<D = any> = {
  data: D;
  errors: TResError[];
  succeeded: boolean;
};

export type TResponseWithPagination<D = any> = TResponse<D> & {
  pagination: TPagination;
};

export type TPagination = {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  hasNext: boolean;
  hasPrevious: boolean;
};
