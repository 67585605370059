import { Select } from "antd";
import { TOptionsProps } from "components/Question/Question";

function OptionsSingleSelectBox(props: TOptionsProps) {
  const { options, onChange, value, ...restProps } = props;

  return (
    <Select
      options={options.map((opt) => {
        return { label: opt, value: opt };
      })}
      style={{ width: "100%" }}
      onChange={onChange}
      value={value}
      size="large"
      {...restProps}
    />
  );
}

export default OptionsSingleSelectBox;
