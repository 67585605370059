import styled from "styled-components";

export const MemberStatisticDonutChartWrapperStyled = styled.div`
  height: 100%;
  padding: 16px 16px 31.5px;

  display: flex;
  flex-direction: column;

  border: var(--border-config);
  background-color: var(--color-white);

  .chart-title {
    margin-bottom: 16px;

    color: #000000d9;
    font-size: var(--font-size-md);
    font-weight: 500;
    line-height: 24px;
  }

  .chart-container {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 32px;
  }
`;

export const LegendStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

type TLegendItemStyled = { color?: string };

const getColor = (props: TLegendItemStyled) => {
  return props.color || "var(--color-primary)";
};

export const LegendItemStyled = styled.div<TLegendItemStyled>`
  .legend-item-title {
    position: relative;
    font-size: 12px;

    ::before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      background-color: ${getColor};
      border-radius: 50%;

      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(calc(-100% - 4px), -50%);
    }
  }

  .legend-item-vale {
    margin-right: 8px;
    padding-right: 8px;
    position: relative;

    font-weight: 700;
    line-height: 24px;
    color: ${getColor};

    ::after {
      content: "";
      position: absolute;
      right: 0;

      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 15px;
      background-color: #e4e4e4;
    }
  }

  .legend-item-percent {
    font-size: 12px;
    line-height: 20px;
  }
`;
