import { Radio } from "antd";
import styled from "styled-components";

export const RadioGroupStyled = styled(Radio.Group)`
  width: 100%;

  flex-wrap: wrap;

  display: flex;
  flex-direction: column;
  gap: 16rem 8rem;
`;

export const RadioStyled = styled(Radio)`
  padding: 12rem 16rem;
  margin: 0;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);

  min-width: 150px;

  flex: 1 1 20%;

  line-height: 24px;

  span {
    font-size: var(--font-size-md);
  }

  span:last-child {
    flex: 1;
  }

  &.ant-radio-wrapper-checked,
  &.ant-radio-wrapper-checked input.ant-input {
    background-color: var(--color-selected);
    border-color: var(--color-primary);
    /* font-weight: 700; */
  }

  input.ant-input {
    width: 100% !important;
    height: 23px;
    padding-inline: 0;
    padding: 0;

    border: none;
    border-bottom: 1px solid var(--color-border) !important;
    border-radius: 0;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent;

    font-size: var(--font-size-md);
  }
`;
