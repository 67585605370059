import { Alert } from "antd";
import styled from "styled-components/macro";

export const AlertStyled = styled(Alert)`
  border-radius: var(--border-radius);
  padding: 9px 16px 16px;

  .ant-alert-message {
    font-size: var(--font-size-sm);
  }
`;
