import {
  CalendarFilled,
  ClockCircleFilled,
  RestFilled,
  SignalFilled,
} from "@ant-design/icons";
import { Button } from "antd";
import {
  PROGRAM_MENU_CONFIGS,
  PROGRAM_SURVEY_TYPES,
  ProgramMenuType,
  SURVEYS as SURVEYS_PROGRAM,
} from "constants/program";
import { useAppSelector } from "hooks";
import { TProgramSv, TProgramSvOptions } from "model/program";
import { TRANSLATIONS } from "pages/TrainingProgramDetailPage/TrainingProgramDetail.const";
import { ReactElement, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  MenuTrainingEditingRoute,
  ProgramDetailEditingRoute,
} from "routers/router";
import { DIETS, TDiet } from "../Diet/components/DietList/DietList";
import { MemberConfirmedSurveyItem } from "../MemberConfirmedSurveyItem/MemberConfirmedSurveyItem";
import {
  ButtonGroupStyled,
  MemberConfirmedSurveyContainerStyled,
  MemberConfirmedSurveyStyled,
} from "./MemberConfirmedSurvey.styled";

export type TSurveyItem = {
  id: string;
  icon: ReactElement;
  content: string;
};

const MemberConfirmedSurvey = () => {
  const { programDetail } = useAppSelector((state) => state.program);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const memberId = searchParams.get("memberId");
  const programMemberId = searchParams.get("programId");

  const programMenuConfig =
    programDetail?.programMenu ||
    programDetail?.programMenu === ProgramMenuType.OTHER
      ? PROGRAM_MENU_CONFIGS[+programDetail?.programMenu as ProgramMenuType]
      : { icon: <RestFilled />, name: "-" };

  const getProgramSurveys = useMemo(
    () =>
      (() => {
        const phaseNumberRes = programDetail?.phaseNumber;
        const frequencyRes = programDetail?.frequency;
        const durationRes = programDetail?.duration;
        const dietRes = programDetail?.programMenu;

        let phaseItem: TProgramSvOptions | undefined;
        let frequencyItem: TProgramSvOptions | undefined;
        let durationItem: TProgramSvOptions | undefined;

        const dietItem = DIETS.find((item: TDiet) => item.value === dietRes);

        SURVEYS_PROGRAM.map((item: TProgramSv) => {
          if (item.type === PROGRAM_SURVEY_TYPES.PHASE_NUMBER) {
            phaseItem = item.options.find(
              (item: TProgramSvOptions) => item.value === phaseNumberRes
            );
          }

          if (item.type === PROGRAM_SURVEY_TYPES.FREQUENCY) {
            frequencyItem = item.options.find(
              (item: TProgramSvOptions) => item.value === frequencyRes
            );
          }

          if (item.type === PROGRAM_SURVEY_TYPES.DURATION) {
            durationItem = item.options.find(
              (item: TProgramSvOptions) => item.value === durationRes
            );
          }
        });

        return {
          phase: (phaseItem as TProgramSvOptions)?.name,
          frequency: (frequencyItem as TProgramSvOptions)?.name,
          duration: (durationItem as TProgramSvOptions)?.name,
          diet: (dietItem as TDiet)?.name,
        };
      })(),
    [
      programDetail?.id,
      programDetail?.phaseNumber,
      programDetail?.frequency,
      programDetail?.duration,
      programDetail?.programMenu,
    ]
  );

  const SURVEYS: TSurveyItem[] = [
    {
      id: "1",
      icon: programMenuConfig?.icon,
      content:
        programDetail?.programMenu === ProgramMenuType.OTHER
          ? (programDetail?.exerciseMenuPurpose as string)
          : programMenuConfig?.name,
    },
    {
      id: "2",
      icon: <SignalFilled />,
      content: getProgramSurveys.phase,
    },
    {
      id: "3",
      icon: <CalendarFilled />,
      content: getProgramSurveys.frequency,
    },
    {
      id: "4",
      icon: <ClockCircleFilled />,
      content: getProgramSurveys.duration,
    },
  ];

  return (
    <MemberConfirmedSurveyContainerStyled>
      <MemberConfirmedSurveyStyled>
        {SURVEYS.map((item: TSurveyItem) => (
          <MemberConfirmedSurveyItem key={item.id} {...item} />
        ))}
      </MemberConfirmedSurveyStyled>

      <ButtonGroupStyled>
        <Button
          className="btn-item"
          onClick={() =>
            navigate(
              MenuTrainingEditingRoute.genPath({
                memberId: memberId || "",
                programMemberId: programMemberId || "",
                phaseNumber: programDetail?.phaseNumber || 0,
              }),
              { replace: true }
            )
          }
        >
          {TRANSLATIONS.EDITING_MENU_BTN}
        </Button>
        <Button
          className="btn-item"
          onClick={() =>
            navigate(
              ProgramDetailEditingRoute.genPath({
                memberId: memberId || "",
                programMemberId: programMemberId || "",
                phaseNumber: programDetail?.phaseNumber || 0,
              }),
              { replace: true }
            )
          }
        >
          {TRANSLATIONS.EDITING_DETAIL_BTN}
        </Button>
      </ButtonGroupStyled>
    </MemberConfirmedSurveyContainerStyled>
  );
};

export { MemberConfirmedSurvey };
