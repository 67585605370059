import images from "constants/images";
import styled from "styled-components";

export const ShumokuMasterContentStyled = styled.div`
  padding: 0 14rem;
  margin-left: calc(165rem + 24px);
  margin-top: 104px;
`;

export const MajorItemContainerStyled = styled.div`
  &:last-child {
    padding-bottom: 24px;
  }
`;

export const MajorItemStepper = styled.div`
  padding: 16px;
  margin: 24px 0;
  border: solid 1px var(--color-primary);
  background: #fff1e8 url(${images.ProgramSection}) center no-repeat;
  background-size: cover;
  background-position: 100% center;

  .label,
  .content {
    color: var(--color-primary);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bolder);
  }
`;
