import styled from "styled-components";

export const BodyIndexChartWrapperStyled = styled.div`
  padding: max(15px, 15rem);

  border: 1px solid #dddddd;
  background-color: var(--color-white);

  .extra-content {
    margin-top: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
