export enum EUserRoles {
  ADMIN = "StoreAdmin",
  HEADQUARTER = "AxtosHeadquarters",
}

export enum EAccessRole {
  AllUsers = "AllUsers",
  UnLoginUser = "UnLoginUser",
}

type TUserStore = {
  id: string;
  storeName: string;
  storeNumber: string;
};

export type TUser = {
  email: string;
  id: string;
  role: EUserRoles;
  fullName: string;
  store: TUserStore;
};

export type TUserLogin = {
  email: string;
  password: string;
};
