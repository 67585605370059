import { Skeleton, Tag } from "antd";
import { MIN_CHECK_IN_TIMES_FOR_NEXT_PHASE } from "constants/common";
import { TMemberListItem } from "model/member";
import { ESurveyPhase } from "model/survey";
import { CSSProperties } from "react";
import { PendingMemberItemWrapperStyled } from "./PendingMemberItem.styled";
import { useNavigate } from "react-router-dom";
import { MemberDetailsRoute } from "routers/router";

type TPendingMemberItemProps = {
  data?: TMemberListItem;
  loading?: boolean;
};

enum EMemberTrainingStatus {
  READY_FOR_NEXT_PHASE,
  NEED_CREATE_PROGRAM,
}

const MEMBER_STATUS_CONFIGS = {
  [EMemberTrainingStatus.READY_FOR_NEXT_PHASE]: {
    color: "#52C41A",
    backgroundColor: "#F6FFED",
    borderColor: "#B7EB8F",
  } as CSSProperties,
  [EMemberTrainingStatus.NEED_CREATE_PROGRAM]: {
    color: "#000000D9",
    backgroundColor: "#FAFAFA",
    borderColor: "#D9D9D9",
  } as CSSProperties,
};

const MEMBER_STATUS_LABEL = {
  [EMemberTrainingStatus.READY_FOR_NEXT_PHASE]: "次のサーベイへ進む",
  [EMemberTrainingStatus.NEED_CREATE_PROGRAM]:
    "トレーニングメニューを持っていません",
};

function getMemberTrainingStatus(
  member?: TMemberListItem
): EMemberTrainingStatus {
  if (!member || !member.programs || member.programs?.length === 0) {
    return EMemberTrainingStatus.NEED_CREATE_PROGRAM;
  }

  const totalCheckInTimes = member.programs.reduce(
    (total: number, program) => total + program.numOfTrainingSession,
    0
  );

  const isReadyForNextPhase =
    totalCheckInTimes >= MIN_CHECK_IN_TIMES_FOR_NEXT_PHASE;
  const hasDoSecondSurvey = member.phaseResponses?.find(
    (phase) => phase.phaseNumber === ESurveyPhase.PHASE_2
  );

  if (isReadyForNextPhase && !hasDoSecondSurvey) {
    return EMemberTrainingStatus.READY_FOR_NEXT_PHASE;
  }

  return EMemberTrainingStatus.NEED_CREATE_PROGRAM;
}

function PendingMemberItem(props: TPendingMemberItemProps) {
  const { data, loading } = props;

  const navigate = useNavigate();

  const memberStatus = getMemberTrainingStatus(data);

  const navigateToMemberDetailPage = () => {
    if (data?.id) {
      navigate(MemberDetailsRoute.genPath(data.id));
    }
  };

  if (loading) {
    return (
      <PendingMemberItemWrapperStyled onClick={navigateToMemberDetailPage}>
        <Skeleton active paragraph={{ rows: 1 }} />
      </PendingMemberItemWrapperStyled>
    );
  }

  return (
    <PendingMemberItemWrapperStyled onClick={navigateToMemberDetailPage}>
      <Tag
        className="member-status"
        style={MEMBER_STATUS_CONFIGS[memberStatus]}
      >
        {MEMBER_STATUS_LABEL[memberStatus]}
      </Tag>

      <p className="member-id">会員ID:{data?.memberAxtosId || "-"}</p>
    </PendingMemberItemWrapperStyled>
  );
}

export { PendingMemberItem };
