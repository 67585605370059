import { Form, Tabs } from "antd";
import { TSurveyCreation } from "model/survey";
import styled from "styled-components";

export const FormStyled = styled(Form<TSurveyCreation>)`
  flex: 1;
  height: 100%;
  overflow: hidden;
`;

export const TabsStyled = styled(Tabs)`
  height: 100%;

  .ant-tabs-nav {
    margin-bottom: 0;
    margin-inline: 24px;

    ::before {
      width: 100vw;
      transform: translateX(-24px);
    }

    .ant-tabs-nav-wrap {
      width: 100%;

      .ant-tabs-nav-list {
        margin-inline: auto;

        .ant-tabs-tab {
          justify-content: center;
          flex: 1;

          &.ant-tabs-tab-active {
            font-weight: 700;
          }
        }
      }
    }
  }

  .ant-tabs-content-holder {
    flex: 1;
    overflow-y: scroll;
  }
`;
