import { DatePicker, Select } from "antd";
import styled from "styled-components";

export const ExportSurveyButtonStyled = styled.div`
  .btn-export {
    border-style: solid;
    border-color: var(--color-primary);
    margin-top: 30px;
    width: 100%;
    color: var(--color-primary);
  }
`;

export const SelectStyled = styled(Select)`
  font-size: var(--font-size-sm);
  color: var(--color-text-input);

  .ant-select-arrow {
    color: var(--color-text-input);

    .anticon:not(.ant-select-suffix) {
      pointer-events: none;
    }
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  width: 100%;
  font-size: var(--font-size-sm);
  .ant-picker-cell-disabled {
    background-color: #000000 !important;
  }
`;
