import { ButtonBackPage } from "components";
import { ColumnHeightIcon, FireIcon, GoldIcon } from "constants/svgs";
import { useAppSelector } from "hooks";
import { FC, ReactElement } from "react";
import { useSearchParams } from "react-router-dom";
import { MemberDetailsRoute } from "routers/router";
import { MemberConfirmedSurvey } from "../MemberConfirmedSurvey/MemberConfirmedSurvey";
import {
  MainHeader,
  MemberIndex,
  PrgramHeaderContainerStyled,
  ProgramHeaderStyled,
  TopHeader,
} from "./ProgramHeader.styled";
import { GLOBAL_TRANSLATE } from "constants/translate";
import { DEFAULT_PATHNAME } from "constants/pathname";
import { TRANSLATIONS } from "pages/TrainingProgramDetailPage/TrainingProgramDetail.const";

type TMemberIndex = {
  id: string;
  icon: ReactElement;
  label: string;
  content: string;
  isDot?: boolean;
};

type TProps = {
  currentStepIndex?: number;
};

const ProgramHeader: FC<TProps> = () => {
  const {
    memberIndexes: { height, weight, bodyFat },
  } = useAppSelector((state) => state.member);
  const { memberAxtosId } =
    useAppSelector((state) => state.member.memberDetailForDetailProgram) || {};

  const [searchParams, _setSearchParams] = useSearchParams();
  const memberId = searchParams.get("memberId");

  const MEMBER_INDEX: TMemberIndex[] = [
    {
      id: "m2",
      icon: <ColumnHeightIcon />,
      label: "身長: ",
      content: `${height} cm`,
      isDot: true,
    },
    {
      id: "m1",
      icon: <GoldIcon />,
      label: `${GLOBAL_TRANSLATE.WEIGHT}: `,
      content: `${weight} kg`,
      isDot: true,
    },
    {
      id: "m3",
      icon: <FireIcon />,
      label: "体脂肪: ",
      content: `${bodyFat}%`,
      isDot: false,
    },
  ];

  const renderMemberIndexs = () => {
    return MEMBER_INDEX.map((item) => {
      return (
        <div className="member-index-item" key={item.id}>
          <div className="icon-label">
            <span className="icon">{item.icon}</span>
            <span className="label">{item.label}</span>
          </div>
          <div className="content">{item.content}</div>
          {item.isDot && <span className="dot">•</span>}
        </div>
      );
    });
  };

  return (
    <PrgramHeaderContainerStyled>
      <ProgramHeaderStyled>
        <TopHeader>
          <ButtonBackPage
            fallbackPath={
              memberId ? MemberDetailsRoute.genPath(memberId) : DEFAULT_PATHNAME
            }
            className="btn-back"
          />
          <h2 className="title">{TRANSLATIONS.TITLE}</h2>
        </TopHeader>
        <MainHeader>
          <MemberIndex>
            <div className="member-index-wrapper">
              <div className="member-id">
                <span className="label">{TRANSLATIONS.LABEL_ID}: </span>
                <span className="content">{memberAxtosId}</span>
              </div>
              <div className="member-index">{renderMemberIndexs()}</div>
            </div>
          </MemberIndex>
        </MainHeader>
      </ProgramHeaderStyled>
      <MemberConfirmedSurvey />
    </PrgramHeaderContainerStyled>
  );
};

export { ProgramHeader };
