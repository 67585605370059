import styled from "styled-components";

export const StoreCreateFormModalStyle = styled.div`
  .title {
    font-weight: 500;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
  }

  input {
    color: #000000d9;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    position: absolute;
    right: -14px;
  }
`;
