import { AxiosResponse } from "axios";
import { axiosAuthInstance, axiosInstance } from "configs/axiosInstance";
import { TUser, TUserLogin } from "model/user";
import { TResetPassword } from "pages/ResetPassword/ResetPassword.type";
import { TResponse } from "types/service";

const authApi = {
  login: async (userLogin: TUserLogin) => {
    const url = "/login";

    const body: TUserLogin = { ...userLogin, email: userLogin.email.trim() };

    const response: TResponse<TTokenRes> = await axiosAuthInstance.post(
      url,
      body
    );
    return response;
  },

  getCurrentUser: async () => {
    const url = "user/get-current-user";

    const response: AxiosResponse<TUser> = await axiosInstance.get(url);

    return response;
  },

  refreshToken: async (params: TRefreshTokenParams) => {
    const url = "/refresh-token";

    const response: TResponse<TTokenRes> = await axiosInstance.post(
      url,
      params
    );

    return response;
  },

  logout: async () => {
    const url = "/logout";
    const response: AxiosResponse = await axiosInstance.post(url);

    return response;
  },

  forgotPassword: async (email: string) => {
    const url = "/forgot-password";
    const response: AxiosResponse<TForgotPasswordRes> =
      await axiosInstance.post(url, { email });
    return response;
  },
  resetPassword: async (_data: TResetPassword) => {
    const url = "/confirm-reset-password";
    const response: AxiosResponse<TResetPasswordRes> = await axiosInstance.post(
      url,
      _data
    );

    return response;
  },
};

export type TTokenRes = {
  accessToken: string;
  tokenType: string;
  expiresTime: string;
  expiresIn: number;
  refreshToken: string;
};

export enum ELoginError {
  NotExistUser = "NotExistUser",
  WrongPassword = "WrongPassword",
}

type TRefreshTokenParams = {
  accessToken: string;
  refreshToken: string;
};

export type TForgotPasswordRes = {
  success: boolean;
  errors: any[];
};

export type TResetPasswordRes = {
  success: boolean;
  errors: any[];
};

export default authApi;
