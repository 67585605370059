export const PAGINATION = {
  PAGE_NUMBER: 1,
  PAGE_SIZE: 10,
};

export const STATUS = {
  PENDING: 0,
  TRAINING: 1,
  ALL: 2,
};

export const PROGRAM_STATUS = {
  NO_PROGRAM: 0,
  NEXT_PHASE: 1,
  TRAINING: 2,
};

export const KEY_TAB = {
  PENDING: "1",
  TRAINING: "2",
};
