import styled from "styled-components";

export const StoreItemStyled = styled.div`
  &.active {
    background: #fff1e8;
    border: 1px solid #ff6b00;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20rem 16rem;
  background: var(--color-white);
  border: 1px solid #dddddd;
  .store-name {
    font-weight: var(--font-weight-bolder);
    font-size: 18px;
    color: var(--color-black);
    cursor: pointer;
    display: inline;
  }
  .store-id {
    font-weight: var(--font-weight-md);
    font-size: var(--font-size-xs);
    color: #bebebe;
    margin-top: 8rem;
  }
  .active-store {
    background-color: var(--color-bg-success);
    border: 1px solid #b7eb8f;
    width: max-content;
    border-radius: 2rem;
    padding: 1rem 8rem;
    color: #52c41a;
    font-size: var(--font-size-xs);
    margin-left: 4rem;
    display: inline;
  }
  .inactive-store {
    background-color: var(--color-orange-light);
    border: solid 1px var(--color-primary);
    width: max-content;
    border-radius: 2rem;
    padding: 1rem 8rem;
    color: var(--color-primary);
    font-size: var(--font-size-xs);
    margin-left: 4rem;
    display: inline;
  }
`;
