import { Modal } from "antd";
import { ModalStaticFunctions } from "antd/es/modal/confirm";
import { DEFAULT_MODAL_PROPS } from "constants/common";
import { PropsWithChildren, createContext, useContext } from "react";

type TModalContext = {
  appModal: Omit<ModalStaticFunctions, "warn">;
  showModalSuccess: (props: TCustomModalSuccessProps) => void;
};

type TCustomModalSuccessProps = {
  title: string;
  okText?: string;
  onOk?: () => void;
};

const initialModalValues: TModalContext = {
  appModal: Modal,
  showModalSuccess: (_props: TCustomModalSuccessProps) => {
    //
  },
};

const ModalContext = createContext<TModalContext>(initialModalValues);

const useAppModal = () => useContext(ModalContext);

function ModalContextProvider(props: PropsWithChildren) {
  const [appModal, contextHolder] = Modal.useModal();

  const { children } = props;

  const showModalSuccess = ({
    title,
    okText,
    onOk,
  }: TCustomModalSuccessProps) => {
    appModal.success({
      ...DEFAULT_MODAL_PROPS.SUCCESS,
      title,
      okText,
      onOk,
    });
  };

  return (
    <ModalContext.Provider value={{ appModal, showModalSuccess }}>
      {contextHolder}
      {children}
    </ModalContext.Provider>
  );
}

export { ModalContextProvider, useAppModal };
