import images from "constants/images";
import styled from "styled-components";

export const HeaderStyled = styled.div`
  background: url(${images.HomePageBG});
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;

  .menu-wrapper {
    min-height: 306px;
    padding: 17px 24px 32px;

    background: url(${images.HomePageImage});
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;

    .app-logo {
      width: 59px;
      height: 45.25px;
      object-fit: contain;
    }

    .welcome-text {
      margin-top: 12px;

      font-weight: 700;
      font-size: max(24px, 24rem);
      line-height: 36px;
    }

    .store-number {
      margin-top: 4px;
      margin-bottom: 16px;

      font-weight: 500;
      font-size: max(16px, 16rem);
      line-height: 21px;
      color: var(--color-white);
    }

    .menu-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  @media only screen and (max-width: 767px) {
    .menu-wrapper {
      background: none;

      .menu-container {
        padding: 17px 24px;

        .app-logo {
          display: block;
          margin-inline: auto;
        }

        .welcome-text,
        .store-number {
          text-align: center;
        }
      }
    }
  }
`;

export const ButtonLinkStyled = styled.div`
  width: 100%;
  padding: 6px 16px;

  display: flex;
  align-items: center;
  gap: 8px;

  background: linear-gradient(
    91.78deg,
    rgba(255, 236, 207, 0.2) 2.16%,
    rgba(255, 193, 101, 0.2) 103.68%
  );

  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    91.78deg,
    #ffeccf 2.16%,
    #ffc165 103.68%
  );
  backdrop-filter: blur(5px);
  border-radius: var(--border-radius);

  cursor: pointer;

  .menu-item-icon {
    width: 16px;
    height: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--color-white);
    font-size: max(16px, 16rem);
  }

  .menu-item-label {
    line-height: 24px;
    color: var(--color-white);
    font-weight: 500;
    font-size: max(13px, 13rem);
    flex: 1;
  }

  .menu-item-redirect {
    color: var(--color-white);
  }
`;
