import { Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import MESSAGES from "constants/messages";
import { EyeCloseIcon, EyeOpenIcon } from "constants/svgs";
import { useAppLoading } from "contexts";
import authApi, { ELoginError } from "features/auth/authApi";
import { authActions } from "features/auth/authSlice";
import { saveAuthToken } from "helpers/auth";
import { showErrorNotification } from "helpers/notification";
import { useAppDispatch } from "hooks";
import { TUserLogin } from "model/user";
import { Fragment, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DEFAULT_PATHNAME, PATHNAME_LIST } from "constants/pathname";
import { TResponse } from "types/service";
import {
  ActionsContainerStyled,
  ButtonSubmitStyled,
  EyeIconContainerStyled,
  FormStyled,
  LinkStyled,
  LoginContainerStyled,
} from "./LoginPage.styled";
import { LOGIN_TRANSLATE } from "./LoginPage.translate";
import { checkPreLoginPath } from "helpers";

const LOGIN_FIELD = {
  EMAIL: {
    LABEL: LOGIN_TRANSLATE.USERNAME_LABEL,
    NAME: "email",
    PLACEHOLDER: LOGIN_TRANSLATE.USERNAME_PLACEHOLDER,
  },
  PASSWORD: {
    LABEL: LOGIN_TRANSLATE.PASSWORD_LABEL,
    NAME: "password",
    PLACEHOLDER: LOGIN_TRANSLATE.PASSWORD_PLACEHOLDER,
  },
};

function LoginPage() {
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [allowClearEmail, setAllowClearEmail] = useState(false);
  const [allowClearPassword, setAllowClearPassword] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { startLoading, stopLoading } = useAppLoading();
  const [form] = useForm<TUserLogin>();

  const setErrorForField = ({
    filedName,
    message = "",
  }: {
    filedName: string;
    message?: string;
  }) => {
    form.getFieldInstance(filedName).focus();
    form.setFields([{ name: filedName, errors: [message] }]);
  };

  const handleLoginError = useCallback((errorCode: string) => {
    switch (errorCode) {
      case ELoginError.NotExistUser:
        setErrorForField({
          filedName: LOGIN_FIELD.EMAIL.NAME,
          message: MESSAGES["SCR-W01-MSG-001"],
        });
        break;

      case ELoginError.WrongPassword:
        setErrorForField({
          filedName: LOGIN_FIELD.PASSWORD.NAME,
          message: MESSAGES["SCR-W01-MSG-002"],
        });
        break;

      default:
        setErrorForField({
          filedName: LOGIN_FIELD.EMAIL.NAME,
          message: "",
        });
        showErrorNotification(MESSAGES["COM-MSG-002"]);
        break;
    }
  }, []);

  const onFinish = async (userLogin: TUserLogin) => {
    form.setFields(
      Object.keys(userLogin).map((key) => {
        return {
          name: key,
          errors: undefined,
        };
      })
    );

    startLoading();
    try {
      const { data: authInfo } = await authApi.login(userLogin);

      if (authInfo) {
        saveAuthToken({
          accessToken: authInfo.accessToken,
          refreshToken: authInfo.refreshToken,
        });

        const resUserInfo = await authApi.getCurrentUser();
        if (resUserInfo.data) {
          dispatch(
            authActions.updateUserInfo({
              data: resUserInfo.data,
              onSuccess: () => {
                const preLoginPath = location?.state?.from?.pathname;
                const searchParam = location?.state?.from?.search;
                if (preLoginPath) {
                  const isPreLoginPath = checkPreLoginPath(preLoginPath);
                  if (isPreLoginPath) {
                    navigate(`${preLoginPath}${searchParam}`);
                    return;
                  }
                }
                navigate(DEFAULT_PATHNAME, {
                  replace: true,
                });
              },
            })
          );
        }
      }
    } catch (e: any) {
      const firstError = (e?.data as TResponse)?.errors?.[0] || {};
      handleLoginError(firstError.code);
    } finally {
      stopLoading();
    }
  };

  const onFinishFailed = (_errorInfo: any) => {
    //
  };

  const handleFormChange = () => {
    const values = form.getFieldsValue();

    if (Object.values(values).every((field) => !!field)) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  };

  return (
    <LoginContainerStyled>
      <FormStyled
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
        form={form}
      >
        <Form.Item
          label={LOGIN_FIELD.EMAIL.LABEL}
          name={LOGIN_FIELD.EMAIL.NAME}
        >
          <Input
            placeholder={LOGIN_FIELD.EMAIL.PLACEHOLDER}
            allowClear={allowClearEmail}
            onChange={handleFormChange}
            onFocus={() => {
              // allow user clear input when focus this field
              setAllowClearEmail(true);
              form.getFieldInstance(LOGIN_FIELD.EMAIL.NAME).focus();
            }}
            suffix={<Fragment />}
            onBlur={() => setAllowClearEmail(false)}
            className="email-input"
          />
        </Form.Item>

        <Form.Item
          label={LOGIN_FIELD.PASSWORD.LABEL}
          name={LOGIN_FIELD.PASSWORD.NAME}
        >
          <Input.Password
            placeholder={LOGIN_FIELD.PASSWORD.PLACEHOLDER}
            onChange={handleFormChange}
            allowClear={allowClearPassword}
            onFocus={() => {
              // allow user clear input when focus this field
              setAllowClearPassword(true);
              form.getFieldInstance(LOGIN_FIELD.PASSWORD.NAME).focus();
            }}
            onBlur={() => setAllowClearPassword(false)}
            iconRender={(visible) => (
              <EyeIconContainerStyled>
                {visible ? <EyeCloseIcon /> : <EyeOpenIcon />}
              </EyeIconContainerStyled>
            )}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <ActionsContainerStyled>
            <ButtonSubmitStyled
              block
              type="primary"
              htmlType="submit"
              disabled={!allowSubmit}
              size="large"
            >
              {LOGIN_TRANSLATE.BTN_LOGIN}
            </ButtonSubmitStyled>
            <LinkStyled to={PATHNAME_LIST.FORGOT_PASSWORD}>
              {LOGIN_TRANSLATE.BTN_FORGOT_PASSWORD}
            </LinkStyled>
          </ActionsContainerStyled>
        </Form.Item>
      </FormStyled>
    </LoginContainerStyled>
  );
}

export { LoginPage };
