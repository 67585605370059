import styled from "styled-components";

export const SurveyPartHeaderStyled = styled.div`
  margin: 24px;
  padding-block: 12px;

  display: flex;

  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  background-color: #fff1e8;

  .part-title-number {
    padding: 0px 8px;

    position: relative;
    background-color: var(--color-primary);
    transform: translateX(-8px);

    color: var(--color-white);
    white-space: nowrap;
    line-height: 22px;

    border-radius: 2px 2px 2px 0px;

    &::after {
      content: "";

      width: 0px;
      height: 0px;

      position: absolute;
      left: 0;
      bottom: 0;

      border-right: 7px solid #00000040;
      border-bottom: 8px solid transparent;

      transform: translateY(100%);
    }
  }

  input {
    padding-block: 0;
    padding-left: 4px;

    border: none;
    background-color: transparent;
    outline: none;
    box-shadow: none !important;

    color: var(--color-primary);
    font-weight: 700;

    &::placeholder {
      color: #ff6b0080;
      font-weight: 500;
    }
  }

  .edit-icon {
    margin-right: 26px;
    font-size: 18px;
    cursor: pointer;
  }
`;

export const QuestionWrapperStyled = styled.div`
  padding: 24px 8px 128px;
`;
