import { Button } from "antd";
import { NotificationModal } from "components/NotificationModal/NotificationModal";
import { ProgramMenuType, TProgramMenuType } from "constants/program";
import { TProgramSurvey, programActions } from "features/program/programSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  PARAMS,
  TRANSLATIONS,
} from "pages/MenuTrainingEditingPage/MenuTrainingEditingPage.const";
import {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TrainingProgramDetailRoute } from "routers/router";
import { MenuItem } from "../MenuItem/MenuItem";
import { MemberListContainerStyled, MenuListStyled } from "./MenuList.styled";
import surveyApi from "features/survey/surveyApi";
import { useAppLoading } from "contexts";
import { showErrorNotification } from "helpers/notification";
import MESSAGES from "constants/messages";
import { AppleIcon, ArmIcon, OtherIcon, PersonIcon } from "constants/svgs";
import { ThunderboltFilled, ToolFilled } from "@ant-design/icons";
import { EditableComponent } from "components";

const MenuList: FC = () => {
  const dispatch = useAppDispatch();
  const { startLoading, stopLoading } = useAppLoading();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeProgramMenuItem, setActiveProgramMenuItem] = useState<
    number | null
  >(null);
  const [_activeButton, setActiveButton] = useState(false);
  const [isEditAnswer, setIsEditAnswer] = useState(false);
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState<boolean>(false);
  const isFirstRender = useRef(true);
  const {
    programSurvey: {
      programMenu,
      frequency,
      duration,
      phaseNumber,
      hasOther,
      questionId,
    },
  } = useAppSelector((state) => ({
    memberAxtosId: state.member.memberIndexes.memberAxtosId,
    programSurvey: state.program.programSurvey,
  }));

  const programMemberId = searchParams.get(PARAMS.PROGRAM_MEMBER_ID);
  const memberId = searchParams.get(PARAMS.MEMBER_ID) || "";

  const handleSelectingProgramMenu = (programMenu: number) => {
    if (activeProgramMenuItem === programMenu) return;

    setActiveButton(false);
    setActiveProgramMenuItem(programMenu);

    dispatch(
      programActions.setProgramSurvey({
        data: { programMenu: programMenu } as TProgramSurvey,
      })
    );
  };

  const PROGRAM_MENU_TYPES: TProgramMenuType[] = [
    {
      id: ProgramMenuType.Diet,
      icon: <AppleIcon />,
      name: "ダイエット",
      value: ProgramMenuType.Diet,
    },
    {
      id: ProgramMenuType.BodyMakeup,
      icon: <ThunderboltFilled />,
      name: "ボディメイク（シェイプアップ）",
      value: ProgramMenuType.BodyMakeup,
    },
    {
      id: ProgramMenuType.BulkUp,
      icon: <ArmIcon />,
      name: "ボディメイク（筋肥大）",
      value: ProgramMenuType.BulkUp,
    },
    {
      id: ProgramMenuType.MuscleStrength,
      icon: <PersonIcon />,
      name: "姿勢の改善",
      value: ProgramMenuType.MuscleStrength,
    },
    {
      id: ProgramMenuType.HealthMaintenanceAndPromotion,
      icon: <ToolFilled />,
      name: "健康維持促進",
      value: ProgramMenuType.HealthMaintenanceAndPromotion,
    },
    {
      id: ProgramMenuType.OTHER,
      icon: <OtherIcon />,
      name: (
        <EditableComponent
          isEditAnswer={isEditAnswer}
          setIsEditAnswer={setIsEditAnswer}
          answer={answer}
          setAnswer={setAnswer}
        />
      ),
      value: ProgramMenuType.OTHER,
    },
  ];

  const surveyAnswer = useMemo(() => {
    let surveyAnswer = "";

    if (programMenu !== ProgramMenuType.OTHER) {
      PROGRAM_MENU_TYPES.find((item) => {
        if (
          programMenu !== ProgramMenuType.OTHER &&
          item.value === programMenu
        ) {
          surveyAnswer = item.name as string;
          return item;
        }
      });
    } else {
      surveyAnswer = hasOther as string;
    }

    return surveyAnswer;
  }, [hasOther, programMenu]);

  const exerciseMenuPurpose = useMemo(() => {
    if (programMenu !== ProgramMenuType.OTHER) {
      return "";
    } else {
      return hasOther;
    }
  }, [hasOther, programMenu]);

  const surveyRequest = (surveyAnswer: string) => {
    if (programMenu !== ProgramMenuType.OTHER) {
      return {
        questionId: questionId,
        answer: JSON.stringify([surveyAnswer]),
        phaseNumber,
      };
    } else {
      return {
        questionId: questionId,
        answer: JSON.stringify([]),
        otherAnswer: surveyAnswer,
        phaseNumber,
      };
    }
  };

  const handleUpdateSurvey = async () => {
    try {
      startLoading();
      const { succeeded } = await surveyApi.updateSurvey({
        memberId,
        questions: [surveyRequest(surveyAnswer)],
      });

      if (!succeeded) {
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      }
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  const handleClick = async () => {
    if (
      !hasOther &&
      programMenu === ProgramMenuType.OTHER &&
      answer.trim() === ""
    ) {
      setError(true);
      return;
    }

    dispatch(
      programActions.updateProgramForMember({
        data: {
          programMemberId: programMemberId as string,
          programBaseRequest: {
            memberId: memberId as string,
            programMenu: programMenu as number,
            frequency,
            duration,
            phaseNumber,
            exerciseMenuPurpose: exerciseMenuPurpose,
          },
        },
        onSuccess: () => {
          handleUpdateSurvey();
          setIsOpenModal(true);
        },
      })
    );
  };

  const handleModalBtnClick = () => {
    navigate(
      TrainingProgramDetailRoute.genPath({
        memberId: memberId as string,
        programId: programMemberId as string,
      }),
      {
        replace: true,
      }
    );
  };

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    } else if (programMenu || programMenu === 0) {
      setActiveProgramMenuItem(programMenu);
    }

    if (programMenu !== ProgramMenuType.OTHER) {
      setError(false);
    }

    if (programMenu === ProgramMenuType.OTHER && !answer.trim()) {
      setError(false);
    }
  }, [programMenu]);

  useLayoutEffect(() => {
    if (isFirstRender.current) {
      setActiveButton(true);
    }
    isFirstRender.current = false;

    return () => {
      dispatch(
        programActions.setProgramSurvey({
          data: { programMenu: null, hasOther: "" } as TProgramSurvey,
        })
      );
    };
  }, []);

  useEffect(() => {
    if (hasOther) {
      setError(false);
    }
  }, [hasOther]);

  return (
    <MemberListContainerStyled>
      <MenuListStyled>
        {PROGRAM_MENU_TYPES.map((item: TProgramMenuType) => (
          <MenuItem
            item={item}
            key={item.id}
            active={activeProgramMenuItem}
            setActive={setActiveProgramMenuItem}
            handleSelectProgramMenu={handleSelectingProgramMenu}
          />
        ))}
        {error && <p className="error-field">回答を選択してください。</p>}
      </MenuListStyled>
      <Button
        type="primary"
        className="update-btn"
        disabled={isEditAnswer}
        onClick={handleClick}
      >
        保存{" "}
      </Button>
      <NotificationModal
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        btnContent={TRANSLATIONS.MODAL_OK}
        handleBtnClick={handleModalBtnClick}
        title={TRANSLATIONS.MODAL_MESSAGE_TITLE}
      />
    </MemberListContainerStyled>
  );
};

export { MenuList };
