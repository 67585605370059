import { Button, Row } from "antd";
import styled from "styled-components";

export const SurveyContainerStyled = styled.div`
  height: 100vh;
  height: 100dvh;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
`;

export const SurveyFormWrapperStyled = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: hidden;

  > div {
    height: 100%;
  }
`;

export const SurveyHeaderStyled = styled(Row)`
  padding: clamp(12px, 24rem, 24px);

  .screen-title,
  button {
    font-weight: 700;
    font-size: var(--font-size-md);
  }
`;

export const ButtonDirectionStyled = styled(Button)`
  padding: 0;
  background-color: transparent !important;

  cursor: pointer;
  color: ${(props) => (props.disabled ? "var(--color-disabled)" : "")};
  fill: ${(props) => (props.disabled ? "var(--color-disabled)" : "")};

  font-size: var(--font-size-md);

  display: flex;
  align-items: center;
  gap: 12px;

  @media only screen and (max-width: 480px) {
    .btn-text {
      display: none;
    }
  }
`;

export const ButtonSubmitEditStyled = styled(Button)`
  padding: 0;

  font-weight: 500 !important;
  font-size: var(--font-size-md);
  border: none;

  color: var(--color-primary);
  cursor: pointer;
  box-shadow: none;
`;
