import axios from "axios";
import { REQUEST_TIME_OUT } from "constants/common";
import authApi from "features/auth/authApi";
import { getAuthToken, removeAuthToken, saveAuthToken } from "helpers/auth";
import { EHttpStatusCode } from "types/service";

const requestBaseURL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;

type TRequestFailed = {
  resolve: (token: string) => void;
  reject: (error: Error) => void;
};

let isRefreshing = false;
let failedQueue: TRequestFailed[] = [];

const recallFailedRequests = (error: any, token: string) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

export const axiosInstance = axios.create({
  baseURL: requestBaseURL,
  timeout: REQUEST_TIME_OUT,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Expose-Headers": "x-pagination",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    const { accessToken } = getAuthToken();
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  },

  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    const pagination = response.headers["x-pagination"];
    if (pagination) {
      //
    }
    return response.data;
  },
  async (error) => {
    const { status } = error.response;
    const originalRequest = error.config;

    switch (status) {
      case EHttpStatusCode.BAD_REQUEST: // TODO: Define what to do on 400
        break;

      case EHttpStatusCode.FORBIDDEN: // TODO: Define what to do on 403
        break;

      case EHttpStatusCode.UNAUTHORIZED: // TODO: Define what to do on 401
        try {
          if (!originalRequest._retry) {
            // save current failed request in "failedQueue" for recall after
            if (isRefreshing) {
              return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject });
              })
                .then((accessToken) => {
                  originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                  return axiosInstance(originalRequest);
                })
                .catch((err) => {
                  return Promise.reject(err);
                });
            }

            // call refresh token if first req in failed queue
            isRefreshing = true;
            originalRequest._retry = true;
            const { data: authTokens } = await authApi.refreshToken(
              getAuthToken()
            );
            const { accessToken } = authTokens;

            saveAuthToken(authTokens);
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;

            // recall remain requests
            isRefreshing = false;
            recallFailedRequests(null, accessToken);
            return axiosInstance(originalRequest);
          } else {
            removeAuthToken();
          }
        } catch (err) {
          // reject remain requests
          recallFailedRequests(err, "");
          removeAuthToken();
          location.reload();
        }
        break;

      case EHttpStatusCode.REQUEST_TIMEOUT: // TODO: Define what to do on 408
        break;

      case EHttpStatusCode.INTERNAL_SERVER_ERROR:
        break;
    }

    return Promise.reject(error.response);
  }
);

// axiosAuthInstance
export const axiosAuthInstance = axios.create({
  baseURL: requestBaseURL,
  timeout: REQUEST_TIME_OUT,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosAuthInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    return Promise.reject(error.response);
  }
);
