import { Descriptions } from "antd";
import styled from "styled-components";

export const DescriptionsWrapper = styled(Descriptions)`
  .ant-descriptions-view table {
    table-layout: auto;
  }

  .ant-descriptions-row > td {
    padding-bottom: 0;
  }

  .icon-edit {
    color: var(--color-primary);
  }

  .ant-descriptions-item-content {
    gap: 8px;
  }

  .input-wrapper {
    color: rgba(0, 0, 0, 0.25);
  }

  .active-value {
    color: #17181d;
    font-size: 16px;
    font-weight: 500;
  }

  .inputEdit {
    color: #17181d;
    font-size: 14px;
    font-weight: 500;
  }

  .ant-input:placeholder-shown {
    text-overflow: unset;
  }
`;
