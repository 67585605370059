import { ButtonBackPage } from "components";
import { SURVEY_FORM_NAME } from "pages/SurveyManagementPage/SurveyManagementPage.constant";
import { PersonalInfoRoute } from "routers/router";
import { HeaderWrapperStyled } from "./SurveyHeader.styled";

function SurveyHeader() {
  return (
    <HeaderWrapperStyled>
      <div className="btn-action">
        <ButtonBackPage fallbackPath={PersonalInfoRoute.path} />
      </div>
      <h1 className="survey-page-title">アンケート（マスター管理）</h1>
      <div className="btn-action btn-save-container">
        <button className="btn-save" form={SURVEY_FORM_NAME} type="submit">
          保存
        </button>
      </div>
    </HeaderWrapperStyled>
  );
}

export { SurveyHeader };
