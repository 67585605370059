import styled from "styled-components";

export const ShumokuMasterUpdateFormModalStyle = styled.div`
  .title {
    font-weight: 500;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
  }

  input {
    color: #000000d9;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    position: absolute;
    right: -14px;
  }

  .video-preview {
    width: 100%;
  }

  .shumoku-thumbnail {
    width: max(20rem, 210px);
    cursor: pointer;

    position: relative;

    img {
      width: 100%;
      aspect-ratio: 4/3;
      object-fit: cover;

      display: inline-block;

      background-repeat: no-repeat;
      background-position: center;
    }

    .thumbnail-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .upload-button {
    background-color: var(--color-orange-light);
    border-color: var(--color-orange-light);
    color: var(--color-primary);
  }
`;
