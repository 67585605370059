import styled from "styled-components";

export const StoreNotFoundStyled = styled.div`
  flex: 1;
  height: 100%;
  text-align: center;
  background: #f2f2f2;

  img {
    margin-top: 100rem;
    max-width: 437px;
    width: clamp(100px, 70%, 437px);
  }

  .message {
    margin-top: 60rem;
    font-weight: var(--font-weight-md);
    font-size: var(--font-size-lg);
  }

  .logo {
    margin: 100rem 0 70rem 0;
  }
`;
