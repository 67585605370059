export const MEMBER_TRANSLATE = {
  BMI: "BMI",
  BODY_FAT: "体脂肪",
  TRAINING_PROGRAM: "トレーニングメニュー",
  TRAINING_HISTORY: "トレーニング履歴",
  START_TRAINING_DATE: "登録日",
  END_TRAINING_DATE: "トレーニング最終日",

  BTN_DETAIL: "詳細を見る",
  BTN_EDIT_BODY_INDEX: "測定結果変更",

  TITLE_EDIT_BODY_INDEX: "測定結果変更",
  TITLE_VIEW_SURVEY: "アンケート表示",
};
