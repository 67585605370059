import { FC } from "react";
import { MenuTrainingMainStyled } from "./MenuTrainingMain.styled";
import { MenuList } from "./components/MenuList/MenuList";

const MenuTrainingMain: FC = () => {
  return (
    <MenuTrainingMainStyled>
      <MenuList />
    </MenuTrainingMainStyled>
  );
};

export { MenuTrainingMain };
