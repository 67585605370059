import styled from "styled-components";

export const PendingMemberItemWrapperStyled = styled.div`
  padding: 20px 16px;
  height: 100%;

  border: var(--border-config);
  background-color: var(--color-white);

  cursor: pointer;

  .member-status {
    padding: 0px 8px;

    line-height: 20px;
    max-width: 100%;
    white-space: normal;
  }

  .member-id {
    margin-top: 7px;

    font-weight: 700;
    font-size: 18px;
    line-height: 23.4px;
  }
`;
