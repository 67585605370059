import { EQuestionType } from "model/survey";
import styled from "styled-components";
import { TQuestionType } from "./OptionList";

type TOptionListStyled = {
  type: "default" | "locked";
  questionType: TQuestionType;
};

const OPTION_FLEX_DIRECTION = {
  [EQuestionType.MULTIPLE]: "column",
  [EQuestionType.SINGLE_RADIO]: "column",
  [EQuestionType.GENDER]: "row",
  [EQuestionType.AGE]: "row",
};

export const OptionListStyled = styled.div<TOptionListStyled>`
  display: flex;
  flex-direction: ${(props: TOptionListStyled) =>
    OPTION_FLEX_DIRECTION[props.questionType] || ""};
  gap: 8px;
  flex-wrap: wrap;

  pointer-events: ${(props: TOptionListStyled) =>
    props.type === "locked" ? "none" : "all"};

  input {
    color: ${(props: TOptionListStyled) =>
      props.type === "locked" ? "#bfbfbf" : ""};
    background-color: transparent;
  }

  .option-type {
    background-color: ${(props: TOptionListStyled) =>
      props.type === "locked" ? "#f5f5f5" : "transparent"};
  }
`;

type TOptionWrapperStyled = {
  type: TQuestionType;
};

export const OptionWrapperStyled = styled.div<TOptionWrapperStyled>`
  padding: 12px 16px;
  min-width: 150px;

  flex: ${(props: TOptionWrapperStyled) =>
    props.type === EQuestionType.AGE ? "1 1 20%" : 1};
  display: flex;
  align-items: center;
  gap: 8px;

  border: 1px solid var(--color-gray);
  border-radius: var(--border-radius);

  .option-type {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;

    border-radius: ${(props: TOptionWrapperStyled) =>
      props.type === EQuestionType.MULTIPLE ? "var(--border-radius)" : "50%"};
    border: 1px solid var(--color-border);
  }

  .option-input {
    flex: 1;
    height: 24px;
    width: 100%;
  }

  .btn-close {
    padding: 0;

    border: none;
    outline: none;
    font-size: 20px;
    line-height: 20px;
    color: #00000040;

    background-color: transparent;
    cursor: pointer;
  }
`;

export const InputStyled = styled.input`
  line-height: 24px;
  font-size: var(--font-size-md);

  border: none;
  border-radius: 0;
  outline: none;

  &.other-option {
    border-bottom: 1px solid var(--color-gray);
    pointer-events: none;
  }
`;
