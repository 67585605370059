import { createSlice } from "@reduxjs/toolkit";
import { TMember, TMemberDetail, TMemberListItem } from "model/member";
import { STATUS } from "pages/MemberListPage/MemberListPage.const";
import { TAction } from "types/common";

type TMemberIndexes = {
  memberAxtosId: string;
  height: number;
  weight: number;
  bodyFat: number;
};

type stateType = {
  loading: boolean;
  member: TMember;
  membersPending: TMemberListItem[];
  membersTraining: TMemberListItem[];
  allMembers: TMemberListItem[];
  hasMore: boolean;
  hasPendingMembers: boolean;
  isExist: boolean | null;
  status: string;
  isOnChangeValue: boolean;
  isNotBothTabData: boolean;
  isNotTrainingData: boolean;
  isNotPendingData: boolean;
  memberIndexes: TMemberIndexes;
  memberDetailForCreateProgram: TMemberDetail | null;
  memberDetailForDetailProgram: TMemberDetail | null;
};

export type TMemberConfirmType = {
  type: string;
  payload: {
    data: TMember;
    onSuccess?: () => void;
    onError?: () => void;
  };
};

const initialState: stateType = {
  loading: false,
  member: {
    memberId: "",
    birthday: "",
  },
  membersPending: [],
  membersTraining: [],
  allMembers: [],
  hasMore: true,
  hasPendingMembers: true,
  isExist: true,
  status: "",
  isOnChangeValue: false,
  isNotBothTabData: false,
  isNotTrainingData: false,
  isNotPendingData: false,
  memberIndexes: {
    memberAxtosId: "",
    height: 0,
    weight: 0,
    bodyFat: 0,
  },
  memberDetailForCreateProgram: null,
  memberDetailForDetailProgram: null,
};

const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    confirmMember(state, _action: TAction<TMember>) {
      state.loading = true;
    },
    confirmMemberSuccess(state) {
      state.loading = false;
    },
    confirmMemberFailure(state) {
      state.loading = false;
    },
    setIsMemberExist(state, action) {
      state.isExist = action.payload;
    },
    getMembers(state, _action) {
      state.loading = true;
    },
    getMembersSuccess(state, action) {
      state.loading = false;

      if (action.payload.status === STATUS.PENDING) {
        if (!action.payload.isStore) {
          state.hasPendingMembers = action.payload.hasPendingMembers;
          return;
        }

        state.membersPending = [
          ...state.membersPending,
          ...action.payload.data,
        ];
        (state.hasPendingMembers = action.payload.hasPendingMembers),
          (state.hasMore = action.payload.hasMore);
        state.isNotPendingData = action.payload.isNotPendingData;
      }

      if (action.payload.status === STATUS.TRAINING) {
        if (!action.payload.isStore) {
          return;
        }

        state.membersTraining = [
          ...state.membersTraining,
          ...action.payload.data,
        ];
        state.hasMore = action.payload.hasMore;
        state.isNotTrainingData = action.payload.isNotTrainingData;
        state.isNotBothTabData =
          state.isNotPendingData && state.isNotTrainingData;
      }

      if (action.payload.status === STATUS.ALL) {
        state.allMembers = [...state.allMembers, ...action.payload.data];
        state.hasMore = action.payload.hasMore;
      }
    },
    resetMembers(state) {
      state.membersPending = [];
      state.membersTraining = [];
      state.allMembers = [];
    },

    getMembersFailure(state) {
      state.loading = false;
    },

    setIsOnChangeValue(state, { payload }) {
      state.isOnChangeValue = payload.data;
    },

    setMemberIndexes(state, { payload }: TAction) {
      state.memberIndexes = payload.data;
    },

    // Member detail for create program
    updateMemberDetailForCreateProgram(
      state,
      { payload }: TAction<TMemberDetail | null>
    ) {
      state.memberDetailForCreateProgram = payload.data;
    },

    // Member detail for create program
    updateMemberDetailForDetailProgram(
      state,
      { payload }: TAction<TMemberDetail | null>
    ) {
      state.memberDetailForDetailProgram = payload.data;
    },
  },
});

const { actions, reducer } = memberSlice;

export { actions as memberActions, reducer as memberReducer };
