import {
  ColumnHeightOutlined,
  FireOutlined,
  GoldOutlined,
} from "@ant-design/icons";
import { ButtonBackPage } from "components";
import { GLOBAL_TRANSLATE } from "constants/translate";
import { useAppSelector } from "hooks";
import { PARAMS } from "pages/MenuTrainingEditingPage/MenuTrainingEditingPage.const";
import { FC, ReactElement } from "react";
import { useSearchParams } from "react-router-dom";
import { TrainingProgramDetailRoute } from "routers/router";
import {
  MemberInfoStyled,
  ProgramHeaderStyled,
  TopHeaderStyled,
} from "./ProgramHeader.styled";

type TMemberIndex = {
  id: string;
  icon: ReactElement;
  label: string;
  content: string;
  isDot?: boolean;
};

type TProps = {
  headerTitle: string;
};

const ProgramHeader: FC<TProps> = ({ headerTitle }) => {
  const [searchParams] = useSearchParams();
  const memberId = searchParams.get(PARAMS.MEMBER_ID);
  const programMemberId = searchParams.get(PARAMS.PROGRAM_MEMBER_ID);
  const {
    memberIndexes: { height, weight, bodyFat, memberAxtosId },
  } = useAppSelector((state) => state.member);

  const MEMBER_INDEX: TMemberIndex[] = [
    {
      id: "m2",
      icon: <ColumnHeightOutlined />,
      label: "身長: ",
      content: `${height} cm`,
      isDot: true,
    },
    {
      id: "m1",
      icon: <GoldOutlined />,
      label: `${GLOBAL_TRANSLATE.WEIGHT}: `,
      content: `${weight} kg`,
      isDot: true,
    },
    {
      id: "m3",
      icon: <FireOutlined />,
      label: "体脂肪: ",
      content: `${bodyFat}%`,
      isDot: false,
    },
  ];

  const renderMemberIndexes = () => {
    return MEMBER_INDEX.map((item) => {
      return (
        <div className="member-index-item" key={item.id}>
          <div className="icon-label">
            <span className="icon">{item.icon}</span>
            <span className="label">{item.label}</span>
          </div>
          <div className="content">{item.content}</div>
          {item.isDot && <span className="dot">•</span>}
        </div>
      );
    });
  };

  return (
    <ProgramHeaderStyled>
      <TopHeaderStyled>
        <ButtonBackPage
          fallbackPath={TrainingProgramDetailRoute.genPath({
            memberId: memberId as string,
            programId: programMemberId as string,
          })}
        />
        <h1 className="title">{headerTitle}</h1>
      </TopHeaderStyled>

      <MemberInfoStyled>
        <div className="member-id">会員ID：{memberAxtosId}</div>
        <div className="member-info-group">{renderMemberIndexes()}</div>
      </MemberInfoStyled>
    </ProgramHeaderStyled>
  );
};

export { ProgramHeader };
