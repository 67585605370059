import styled from "styled-components";

export const HeaderWrapperStyled = styled.div`
  padding: 25px 24px;

  display: flex;
  align-items: flex-start;
  gap: 16rem;

  .btn-action {
    flex: 0 1 64px;
  }

  .survey-page-title {
    flex: 2;

    color: #1b1d26;
    font-weight: 700;
    font-size: var(--font-size-md);
    text-align: center;
  }

  .btn-save {
    padding: 0;
    margin-left: auto;
    display: block;

    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    font-size: var(--font-size-md);
    font-weight: 500;
    color: var(--color-primary);
    text-align: right;
  }
`;
