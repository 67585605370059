import { Row } from "antd";
import { DEFAULT_PAGE_NUMBER } from "constants/common";
import { useAppLoading } from "contexts";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { TStore } from "model/shumoku";
import { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { StoreItemMemo } from "../StoreItem/StoreItem";
import { StoreNotFound } from "../StoreNotFound/StoreNotFound";
import { StoreListStyled } from "./StoreList.styled";

const PAGE_SIZE = 30;

type TProps = {
  handleOpenDetail: (id: string) => void;
};

const StoreList: FC<TProps> = ({ handleOpenDetail }) => {
  const dispatch = useAppDispatch();
  const { loading, hasMore, stores, selectedStoreIds } = useAppSelector(
    (state) => state.shumoku
  );
  const { startLoading, stopLoading } = useAppLoading();
  const [searchParams] = useSearchParams();

  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const observer = useRef<any>();

  const searchValue = searchParams.get("keyword");
  const statusValue = searchParams.get("status");

  //Refs
  const lastStoreItemRef = useCallback(
    (node: any) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          if (loading) return;
          setPageNumber((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading]
  );

  // Handlers
  const handleChange = (storeId: string) => {
    const updatedIdToSelected = new Set(selectedIds);

    if (updatedIdToSelected.has(storeId)) {
      updatedIdToSelected.delete(storeId);
    } else {
      updatedIdToSelected.add(storeId);
    }
    setSelectedIds(updatedIdToSelected);

    const totalSelectedStores = updatedIdToSelected.size;
    dispatch(shumokuActions.setSelectedNumber({ data: totalSelectedStores }));
    dispatch(
      shumokuActions.setSelectedStoreIds({ data: [...updatedIdToSelected] })
    );

    const storesIdsSet = new Set<string>();
    stores?.forEach((store: TStore) => {
      storesIdsSet.add(store.id);
    });
    if (isSuperset(updatedIdToSelected, storesIdsSet)) {
      dispatch(shumokuActions.setSelectedAll({ data: true }));
    } else {
      dispatch(shumokuActions.setSelectedAll({ data: false }));
    }
  };

  const isSuperset = (set1: Set<string>, set2: Set<string>) => {
    return [...set2].every((element) => set1.has(element));
  };

  useEffect(() => {
    if (searchValue || statusValue) setPageNumber(DEFAULT_PAGE_NUMBER);
  }, [searchValue, statusValue]);

  useEffect(() => {
    dispatch(
      shumokuActions.getAllStores({
        data: {
          TextStoreSearch: searchValue as string,
          PageNumber: pageNumber,
          PageSize: PAGE_SIZE,
          ActiveFilter: statusValue as string,
        },
      })
    );
  }, [pageNumber, searchValue, statusValue]);

  useEffect(() => {
    return () => {
      dispatch(shumokuActions.resetStores());
    };
  }, []);

  useEffect(() => {
    setSelectedIds(new Set(selectedStoreIds));
  }, [selectedStoreIds]);

  // Side Effects
  useEffect(() => {
    loading ? startLoading() : stopLoading();
    return stopLoading;
  }, [loading]);

  if (!loading && stores.length === 0) {
    return <StoreNotFound />;
  }

  return (
    <Fragment>
      {stores.length > 0 && (
        <StoreListStyled>
          <Row gutter={[24, 24]}>
            {stores.map((item: TStore, index: number) => {
              if (stores.length === index + 1) {
                return (
                  <StoreItemMemo
                    ref={lastStoreItemRef}
                    key={item.id}
                    item={item}
                    selectedIds={selectedIds}
                    handleChange={handleChange}
                    handleOpenDetail={handleOpenDetail}
                  />
                );
              } else {
                return (
                  <StoreItemMemo
                    key={item.id}
                    item={item}
                    selectedIds={selectedIds}
                    handleChange={handleChange}
                    handleOpenDetail={handleOpenDetail}
                  />
                );
              }
            })}
          </Row>
        </StoreListStyled>
      )}
    </Fragment>
  );
};

export { StoreList };
