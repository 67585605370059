import { Checkbox, Form } from "antd";
import { TMajorItem, TShumoku } from "model/shumoku";
import React, { FC, useEffect, useState } from "react";
import images from "constants/images";
import { replaceErrorImage } from "helpers";
import { PlaySquare } from "constants/svgs";
import { ShumokuItemStyled } from "./ShumokuItem.styled";
import { ShumokuDetail } from "../ShumokuMasterDetail/ShumokuMasterDetail";

type TProps = {
  shumoku: TShumoku;
  selectedShumokuIds: Set<string>;
  majorItem: TMajorItem;
  handleChangeShumokuActive: (id: string) => void;
  handleOpenUpdateForm: (shumoku: TShumoku) => void;
};

const FIELD_NAME = {
  STATUS: "status",
};

const ShumokuItem: FC<TProps> = ({
  shumoku,
  selectedShumokuIds,
  majorItem,
  handleChangeShumokuActive,
  handleOpenUpdateForm,
}) => {
  const [form] = Form.useForm();
  const [openVideo, setOpenVideo] = useState(false);
  const onChange = () => {
    handleChangeShumokuActive(shumoku.id);
  };

  const handleVideoClick = () => {
    setOpenVideo(true);
  };

  const handleOpenUpdateModal = () => {
    handleOpenUpdateForm(shumoku);
  };

  useEffect(() => {
    form.setFieldsValue({
      [FIELD_NAME.STATUS]: selectedShumokuIds.has(shumoku.id) ? false : true,
    });
  }, [selectedShumokuIds]);

  return (
    <Form form={form}>
      <ShumokuItemStyled>
        <div className="shumoku-title">
          <div className="shumoku-checkbox">
            <Checkbox onChange={onChange} />
          </div>
        </div>
        <div className="shumoku-name" onClick={handleVideoClick}>
          {shumoku?.shumokuName}
        </div>

        <div className="shumoku-code">
          <span className="label">番号 : </span>
          <span className="value">{shumoku?.shumokuCode}</span>
        </div>
        <div className="shumoku-thumbnail" onClick={handleVideoClick}>
          <img
            src={shumoku?.thumbnail || images.ThumbnailImage}
            onError={replaceErrorImage}
            alt={shumoku?.shumokuName}
            loading="lazy"
          />
          <div className="thumbnail-overlay">
            <PlaySquare />
          </div>
        </div>

        <ShumokuDetail
          open={openVideo}
          handleOk={() => setOpenVideo(true)}
          handleCancel={() => setOpenVideo(false)}
          video={shumoku}
          majorItem={majorItem}
          isShowParams={true}
          handleOpenUpdateModal={handleOpenUpdateModal}
        />
      </ShumokuItemStyled>
    </Form>
  );
};

const ShumokuItemMemo = React.memo(ShumokuItem);

export { ShumokuItemMemo };
