import { SURVEY_CATEGORY_TITLES } from "constants/translate";
import { TGetSurveyDetailRes } from "features/survey/surveyApi";
import {
  SURVEY_PART_LIST_CONFIGS,
  getQuestionListByCategory,
  getQuestionListByCategoryForEditAndDetail,
} from "helpers/survey";
import { TQuestionByCategory } from "layouts";
import { ESurveyPhase, TQuestionCategory, TSurveyValues } from "model/survey";

// =============================================================================
export function groupQuestionsByCategoryForEdit({
  phase,
  questionList,
}: {
  questionList: TGetSurveyDetailRes[];
  phase: ESurveyPhase;
}): TQuestionByCategory[] {
  return SURVEY_PART_LIST_CONFIGS[phase].map((category) => ({
    name: category,
    questions: getQuestionListByCategory({
      questionList: questionList.map((q) => q.question),
      category,
    }),
    title: (SURVEY_CATEGORY_TITLES[phase] as any)[category],
  }));
}

// =============================================================================
export function getSurveyValuesForEdit(
  questionList: TGetSurveyDetailRes[],
  phase: ESurveyPhase
): TSurveyValues {
  return (
    (SURVEY_PART_LIST_CONFIGS[phase] as TQuestionCategory[]) || []
  ).reduce(
    (result: TSurveyValues, category) => ({
      ...result,
      [category]: getQuestionListByCategoryForEditAndDetail({
        questionList,
        category: category,
      }),
    }),
    {}
  );
}
