import { RightOutlined } from "@ant-design/icons";
import { TProgramMenuType } from "constants/program";
import { FC } from "react";
import { DietItemStyled } from "./DietItem.styled";

type TProps = {
  item: TProgramMenuType;
  active: number;
  setActive: React.Dispatch<React.SetStateAction<number>>;
  handleSelectProgramMenu: (programMenu: number) => void;
};

const DietItem: FC<TProps> = ({
  item,
  active,
  setActive,
  handleSelectProgramMenu,
}) => {
  // const { next } = useProgramContext();
  // const dispatch = useAppDispatch();
  // const { previousMenu } = useAppSelector((state) => state.program);

  // const handleClick = () => {
  //   dispatch(programActions.setPreviousMenu(item?.value));

  //   if (previousMenu !== item?.value) {
  //     dispatch(programActions.clearAllExtensions());
  //   }
  //   dispatch(
  //     programActions.setProgramSurvey({
  //       data: { programMenu: item?.value } as TProgramSurvey,
  //     })
  //   );
  //   next();
  // };

  const className = active === item.id ? "active" : "";

  const handleMenuItemClick = () => {
    setActive(item.id);
    handleSelectProgramMenu(item.id);
  };

  return (
    <DietItemStyled className={className} onClick={handleMenuItemClick}>
      <div className="name-item">
        <div className="icon">
          <span>{item?.icon}</span>
        </div>
        <div>{item?.name}</div>
      </div>
      <RightOutlined />
    </DietItemStyled>
  );
};

export { DietItem };
