import styled from "styled-components";

export const CheckInTimeItemWrapperStyled = styled.div`
  padding: 16px 24px;

  border: var(--border-config);
  background-color: var(--color-white);

  display: flex;
  gap: 8px;

  .info-container {
    flex: 1;

    .title {
      font-size: 12px;
      line-height: 20px;
      color: #00000073;
    }

    .statistic-wrapper {
      margin-top: 4px;

      display: flex;
      align-items: center;
      gap: 16px;

      .check-in-number {
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        color: #000000d9;
      }

      .statistic-info {
        flex: 1;
        font-size: 12px;
        line-height: 20px;

        .percent-info {
          color: #52c41a;

          &.decrease {
            color: #f5222d;
          }
        }

        .statistic-mode {
          color: #000000d9;
        }
      }
    }
  }

  .calendar-wrapper {
    position: relative;
    width: 64px;
    height: 64px;

    .calendar-icon {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .calendar-number {
      position: absolute;
      bottom: 12%;
      left: 50%;
      transform: translateX(-50%);
      color: var(--color-primary);
      width: 100%;
      word-wrap: break-word;
      text-align: center;

      font-weight: 700;
      font-size: 16px;
    }
  }
`;
