import { CSSProperties, Fragment } from "react";
import { PhaseTagStyled } from "./PhaseTag.styled";

type TPhaseTagProps = {
  phaseIndex?: number;
  style?: CSSProperties;
};

function getPhaseStyle(phaseIndex?: number) {
  switch (phaseIndex) {
    case 0:
      return {
        color: "var(--color-white)",
        backgroundColor: `#40A9FF`,
      };

    case 1:
      return {
        color: "#000000D9",
        backgroundColor: "#FADB14",
      };

    default:
      return {
        color: "var(--color-white)",
        backgroundColor: `var(--color-primary)`,
      };
  }
}

function getPhaseText(phaseIndex?: number) {
  if (typeof phaseIndex === "number") {
    return `アンケート結果（${phaseIndex + 1}回目）`;
  }

  return "アンケート結果";
}

function PhaseTag(props: TPhaseTagProps) {
  const { phaseIndex, style } = props;

  if (typeof phaseIndex !== "number") return <Fragment />;

  const extendStyle = getPhaseStyle(phaseIndex);
  const phaseLabel = getPhaseText(phaseIndex);

  return (
    <PhaseTagStyled style={{ ...extendStyle, ...style }}>
      {phaseLabel}
    </PhaseTagStyled>
  );
}

export { PhaseTag };
