import { DirectionComponent } from "components";
import { ArrowLeftIcon, ArrowRightIcon, HomeIcon } from "constants/svgs";
import { useAppSelector } from "hooks";
import { useShumokuContext } from "pages/ShumokuManagementPage/ShumokuContext/ShumokuContext";
import { TRANSLATION } from "pages/ShumokuManagementPage/ShumokuManagementPage.translate";
import { FC } from "react";
import {
  SelectedStoreStyled,
  ShumokuManagementFormContainerStyled,
  ShumokuManagementFormStyled,
  ShumokuManagementHeaderStyled,
  ShumokuManagementTitleStyled,
} from "./ShumokuManagementForm.styled";

const ShumokuManagementForm: FC = () => {
  const { handleApplyShumokus, back } = useShumokuContext();
  const { selectedNumber } = useAppSelector((state) => state.shumoku);

  const handleBackPage = () => {
    back();
  };

  const handleApply = () => {
    handleApplyShumokus();
  };

  return (
    <ShumokuManagementFormStyled>
      <ShumokuManagementFormContainerStyled>
        <ShumokuManagementHeaderStyled>
          <DirectionComponent
            name="戻る"
            iconPrev={<ArrowLeftIcon />}
            className="back-btn"
            callback={handleBackPage}
          />
          <ShumokuManagementTitleStyled>
            {TRANSLATION.TITLE}
          </ShumokuManagementTitleStyled>
          <DirectionComponent
            name={TRANSLATION.APPLY}
            iconNext={<ArrowRightIcon />}
            className="next-btn"
            callback={handleApply}
          />
        </ShumokuManagementHeaderStyled>

        <SelectedStoreStyled>
          <HomeIcon />
          <div className="selected-store">
            <span>{TRANSLATION.STORES_SELECTED}</span>
            <span className="store-number">{selectedNumber}件 </span>
          </div>
        </SelectedStoreStyled>
      </ShumokuManagementFormContainerStyled>
    </ShumokuManagementFormStyled>
  );
};

export { ShumokuManagementForm };
