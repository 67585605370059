import styled from "styled-components";
import { ResetPasswordPattenUrl } from "constants/svgs";

export const LayoutContainerStyled = styled.div`
  min-height: 100vh;
  min-height: 100dvh;
  background: url(${ResetPasswordPattenUrl}) no-repeat fixed -35rem bottom;
  background-size: contain;
`;

export const TopLayoutStyled = styled.div`
  padding-top: 18px;
  text-align: center;
  .logo {
    max-width: 115px;
  }
  .title {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bolder);
    color: var(--color-primary);

    margin: 35px 0 24px 0;
  }
`;

export const MainLayoutStyled = styled.div`
  padding: 0 151rem;
`;

export const ExtraContentStyled = styled.div`
  margin-top: 12px;

  display: flex;
  justify-content: center;

  a {
    padding-inline: 12rem;
    position: relative;

    font-size: var(--font-size-md);
    color: var(--color-black) !important;
    text-decoration: underline !important;

    &:not(:last-child) {
      ::after {
        content: "";
        width: 1px;
        height: 16px;
        background-color: var(--color-border);

        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;
