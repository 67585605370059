import { Fragment, FC, useEffect, useState, useCallback } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Switch,
  Typography,
} from "antd";
import { StoreViewDetailStyled } from "./StoreViewDetail.styled";
import { TRANSLATION } from "pages/ShumokuManagementPage/ShumokuManagementPage.translate";
import shumokuApi from "features/shumoku/shumokuApi";
import {
  ECreateStoreError,
  TStoreResponse,
  TUpdateStoreReq,
} from "model/shumoku";
import { EditFilled } from "@ant-design/icons";
import { useAppLoading } from "contexts";
import { TResError, TResponse } from "types/service";
import { useAppDispatch } from "hooks";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import { DEFAULT_PAGE_NUMBER } from "constants/common";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

const PAGE_SIZE = 30;

type TProps = {
  idDetailStore: string;
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

const StoreViewDetail: FC<TProps> = ({
  idDetailStore,
  open,
  handleOk,
  handleCancel,
}) => {
  const [form] = Form.useForm();
  const [detailStore, setDetailStore] = useState<TStoreResponse>();
  const [isUpdateStoreName, setIsUpdateStoreName] = useState(false);
  const [isUpdateEmail, setIsUpdateEmail] = useState(false);
  const [isUpdateActive, setIsUpdateActive] = useState(false);
  const { startLoading, stopLoading } = useAppLoading();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("keyword");
  const statusValue = searchParams.get("status");

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const response = await shumokuApi.getStoreById(idDetailStore);
        setDetailStore(response.data);
      } catch (error: any) {
        console.log(error);
      } finally {
        stopLoading();
      }
    };
    fetchData();
  }, [idDetailStore]);

  const setErrorForField = ({
    fieldName,
    message = "",
  }: {
    fieldName: string;
    message?: string;
  }) => {
    form.getFieldInstance(fieldName).focus();
    form.setFields([{ name: fieldName, errors: [message] }]);
  };

  const showErrors = useCallback((errorCode: string) => {
    switch (errorCode) {
      case ECreateStoreError.EXISTED_STORE_NAME:
        setErrorForField({
          fieldName: "storeName",
          message: TRANSLATION.STORE_NAME_EXISTED,
        });
        break;
      case ECreateStoreError.EXISTED_USER:
        setErrorForField({
          fieldName: "email",
          message: TRANSLATION.EMAIL_EXISTED,
        });
        break;

      default:
        setErrorForField({
          fieldName: "",
          message: "",
        });
        break;
    }
  }, []);

  const handleCreateError = (errors: TResError[]) => {
    errors.forEach((element: TResError) => {
      showErrors(element.code);
    });
  };

  const onFinish = async (values: TUpdateStoreReq) => {
    if (isUpdateEmail || isUpdateStoreName || isUpdateActive) {
      let updateStore: TUpdateStoreReq = {};

      //check null storeName and email
      if (values.storeName && values.email) {
        updateStore = {
          storeName: values.storeName,
          email: values.email,
          isActive: values.isActive,
        };
      }

      if (Object.keys(updateStore).length !== 0) {
        startLoading();
        try {
          const { data: updateInfo } = await shumokuApi.updateStore(
            idDetailStore,
            updateStore
          );
          if (updateInfo) {
            handleOk();
            dispatch(shumokuActions.resetStores());
            dispatch(
              shumokuActions.getAllStores({
                data: {
                  TextStoreSearch: searchValue as string,
                  PageNumber: DEFAULT_PAGE_NUMBER,
                  PageSize: PAGE_SIZE,
                  ActiveFilter: statusValue as string,
                },
              })
            );
            form.resetFields();
          }
        } catch (e: any) {
          const errorList = (e?.data as TResponse)?.errors || [];
          if (errorList) {
            handleCreateError(errorList);
          }
        } finally {
          stopLoading();
        }
      }
    } else {
      handleCancel();
    }
  };
  const trimFieldValue = (filedName: string) => {
    const fieldValue = form.getFieldValue(filedName);
    form.setFieldValue(filedName, fieldValue?.trim());
  };
  return (
    <Fragment>
      <StoreViewDetailStyled>
        {detailStore && (
          <Modal
            open={open}
            centered
            maskStyle={{ background: "rgba(23, 24, 29, 0.9)" }}
            width={621}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            title={TRANSLATION.VIEW_DETAIL}
            maskClosable={false}
          >
            <StoreViewDetailStyled>
              <Divider />
              <Form
                initialValues={detailStore}
                layout="vertical"
                style={{ maxWidth: 600 }}
                form={form}
                onFinish={onFinish}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="storeNumber"
                      label={TRANSLATION.STORE_NUMBER}
                      labelAlign="right"
                    >
                      <Text strong>{detailStore.storeNumber}</Text>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="isActive" label={TRANSLATION.ACTIVE_STORE}>
                      <Switch
                        className="switch-btn"
                        defaultChecked={detailStore.isActive}
                        onClick={() => setIsUpdateActive(true)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="storeName"
                      label={TRANSLATION.STORE_NAME}
                      rules={
                        isUpdateStoreName
                          ? [
                              {
                                required: true,
                                transform: (value) => value.trim(),
                                message: `${TRANSLATION.STORE_NAME_REQUIRED}`,
                              },
                              {
                                max: 254,
                                message: `${TRANSLATION.STORE_NAME_INVALID}`,
                              },
                            ]
                          : []
                      }
                    >
                      {isUpdateStoreName ? (
                        <Input
                          onBlur={() => {
                            trimFieldValue("storeName");
                          }}
                        />
                      ) : (
                        <Fragment>
                          <Text strong>{detailStore.storeName}</Text>
                          <EditFilled
                            style={{
                              marginLeft: "6px",
                              color: "var(--color-primary)",
                            }}
                            onClick={() => setIsUpdateStoreName(true)}
                          />
                        </Fragment>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      label={TRANSLATION.EMAIL}
                      rules={
                        isUpdateEmail
                          ? [
                              {
                                required: true,
                                message: `${TRANSLATION.EMAIL_REQUIRED}`,
                              },
                              {
                                type: "email",
                                message: `${TRANSLATION.EMAIL_INVALID}`,
                              },
                            ]
                          : []
                      }
                    >
                      {isUpdateEmail ? (
                        <Input />
                      ) : (
                        <Fragment>
                          <Text strong>{detailStore.email}</Text>
                          <EditFilled
                            style={{
                              marginLeft: "6px",
                              color: "var(--color-primary)",
                            }}
                            onClick={() => setIsUpdateEmail(true)}
                          />
                        </Fragment>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Divider style={{ marginTop: "10px" }} />
                <Row justify="end" align="bottom">
                  <Form.Item style={{ marginBottom: "0px" }}>
                    <Button type="primary" htmlType="submit">
                      {TRANSLATION.SAVE}
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </StoreViewDetailStyled>
          </Modal>
        )}
      </StoreViewDetailStyled>
    </Fragment>
  );
};

export default StoreViewDetail;
