import { SURVEY_CATEGORY_TITLES } from "constants/translate";
import { TGetSurveyDetailRes } from "features/survey/surveyApi";
import { TQuestionByCategory } from "layouts";
import {
  EQuestionCategory,
  EQuestionCategoryPhaseIntermediate,
  EQuestionType,
  TQuestion,
  TQuestionCategory,
  TQuestionCreation,
  TQuestionForSubmit,
  TSurveyPartCreation,
  TSurveyValues,
  ESurveyPhase,
  TQuestionFormItem,
} from "model/survey";

export const SURVEY_PART_LIST_CONFIGS = {
  [ESurveyPhase.PHASE_1]: [
    EQuestionCategory.PERSONAL_INFO,
    EQuestionCategory.TRAINING_REQUEST,
    EQuestionCategory.DIET,
    EQuestionCategory.OTHER,
  ],
  [ESurveyPhase.PHASE_2]: [
    EQuestionCategoryPhaseIntermediate.TRAINING_REQUEST,
    EQuestionCategoryPhaseIntermediate.INJURIES_HISTORY,
    EQuestionCategoryPhaseIntermediate.LAST_TRAINING_PROGRAM,
  ],
};

// For getting survey
export function getQuestionListByCategory({
  category,
  questionList,
}: {
  questionList: TQuestion[];
  category: TQuestionCategory;
}): TQuestion[] {
  const filteredQuestionList = questionList.filter(
    (question) => question.category === category
  );

  const sortedCategory = filteredQuestionList.sort(
    (qA, qB) => qA.order - qB.order
  );

  return sortedCategory;
}

export function getQuestionListByCategoryForEditAndDetail({
  category,
  questionList,
}: {
  questionList: TGetSurveyDetailRes[];
  category: TQuestionCategory;
}): TQuestionFormItem[] {
  const filteredQuestionList = questionList.reduce(
    (result: TQuestionFormItem[], q) => {
      const { answer, otherAnswer, question } = q;
      if (question.category === category) {
        // Remove duplicate answer
        const removedDuplicateAnswer = [...new Set(answer)];

        // Only has value if question `has allow chose other option`
        let otherAnswerText = question.hasOther ? otherAnswer : "";

        let clearedAnswer = removedDuplicateAnswer;

        // Only keep answers exists in options
        if (question.type !== EQuestionType.TEXT) {
          clearedAnswer = removedDuplicateAnswer.filter((a) =>
            question.options?.includes(a)
          );
        }

        if (
          question.type === EQuestionType.SINGLE_RADIO &&
          clearedAnswer.length !== 0
        ) {
          clearedAnswer = [clearedAnswer[0]];
          otherAnswerText = "";
        }

        // Check if has answer or not
        const hasAnswer =
          question.type !== EQuestionType.TEXT
            ? clearedAnswer.length !== 0 || !!otherAnswerText
            : clearedAnswer.length !== 0;

        const formattedQuestion: TQuestionFormItem = {
          questionInfo: q.question,
          questionAnswer: hasAnswer
            ? {
                answer: clearedAnswer,
                checkedOther: !!otherAnswerText,
                otherAnswer: otherAnswerText,
              }
            : undefined,
        };

        return [...result, formattedQuestion];
      }
      return result;
    },
    []
  );

  const sortedCategory = filteredQuestionList.sort(
    (qA, qB) => qA.questionInfo.order - qB.questionInfo.order
  );

  return sortedCategory;
}

export function splitQuestionsByCategory({
  phaseNumber,
  questionList,
}: {
  questionList: TQuestion[];
  phaseNumber: ESurveyPhase;
}): TQuestionByCategory[] {
  return SURVEY_PART_LIST_CONFIGS[phaseNumber].map((category) => ({
    name: category,
    questions: getQuestionListByCategory({
      questionList,
      category,
    }),
    title: (SURVEY_CATEGORY_TITLES[phaseNumber] as any)[category],
  }));
}

export function getSurveyDataForEditingMode({
  questions,
  surveyPhase,
}: {
  questions: TQuestion[];
  surveyPhase: ESurveyPhase;
}): TSurveyPartCreation[] {
  const surveyPartList: TSurveyPartCreation[] = [];

  const partList = SURVEY_PART_LIST_CONFIGS[surveyPhase] || [];

  for (let i = 0; i < partList.length; i++) {
    const category = partList[i] as EQuestionCategory &
      EQuestionCategoryPhaseIntermediate;

    surveyPartList.push({
      title: SURVEY_CATEGORY_TITLES[surveyPhase][category],
      category,
      questions: getQuestionListByCategory({
        category: partList[i],
        questionList: questions,
      }),
    });
  }

  return surveyPartList;
}

// For submitting survey
export function mappingSurveyForSubmit(
  survey: TSurveyValues,
  phaseNumber?: number
): TQuestionForSubmit[] {
  const questionList: TQuestionForSubmit[] = [];
  for (const category of Object.keys(survey)) {
    const questionListByCategory =
      survey[category as keyof TSurveyValues] || [];

    for (let i = 0; i < questionListByCategory.length; i++) {
      const { questionInfo, questionAnswer = {} } = questionListByCategory[i];

      questionList.push({
        questionId: questionInfo.id,
        answer: JSON.stringify(questionAnswer.answer || []),
        otherAnswer:
          questionAnswer.checkedOther === true
            ? questionAnswer.otherAnswer
            : undefined,
        phaseNumber: phaseNumber as number,
      });
    }
  }

  return questionList;
}

export function mappingSurveyMasterForSubmitting({
  phaseNumber,
  surveyPartList,
}: {
  surveyPartList: TSurveyPartCreation[];
  phaseNumber: number;
}): TQuestionCreation[] {
  const questionList = [];

  for (let i = 0; i < surveyPartList.length; i++) {
    const questionsInCurrentPart: TQuestionCreation[] = surveyPartList[
      i
    ].questions.map((question, index) => {
      const clearedDuplicateOptions = [...new Set(question.options)];

      return {
        ...question,
        order: index + 1,
        questionId: question.id ? question.id : undefined,
        id: undefined,
        phaseNumber,
        options: JSON.stringify(
          question.type === EQuestionType.TEXT ? [] : clearedDuplicateOptions
        ),
      };
    });

    questionList.push(...questionsInCurrentPart);
  }

  return questionList;
}
