import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditFilled,
} from "@ant-design/icons";
import { Descriptions, Input } from "antd";
import { ProgramMenuType } from "constants/program";
import { TProgramSurvey, programActions } from "features/program/programSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import React, { FC, SyntheticEvent, useEffect } from "react";
import { DescriptionsWrapper } from "./EditableComponent.styled";

type TProps = {
  isEditAnswer: boolean;
  setIsEditAnswer: React.Dispatch<React.SetStateAction<boolean>>;
  answer: string;
  setAnswer: React.Dispatch<React.SetStateAction<string>>;
};

const EditableComponent: FC<TProps> = ({
  isEditAnswer,
  setIsEditAnswer,
  answer,
  setAnswer,
}) => {
  const dispatch = useAppDispatch();
  const { hasOther: otherAnswer, programMenu } = useAppSelector(
    (state) => state.program.programSurvey
  );

  const data = [
    {
      id: "other answer",
      label: "other answer",
      value: otherAnswer,
      placeholder: "回答を入力してください。",
      editable: isEditAnswer,
      setEditable: () => {
        if (!isEditAnswer) {
          setAnswer(otherAnswer || "");
        }
        setIsEditAnswer(!isEditAnswer);
      },
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setAnswer(event?.target?.value.trim());
      },
      onBlur: (_event: SyntheticEvent) => {
        // handle blur later
      },
      onOk: async () => {
        dispatch(
          programActions.setProgramSurvey({
            data: { hasOther: answer } as TProgramSurvey,
          })
        );
        setIsEditAnswer(!isEditAnswer);
        try {
          // handle ok
        } catch (error: any) {
          // handle error
        }
      },
      onClose: () => {
        setAnswer("");
        setIsEditAnswer(!isEditAnswer);
      },
    },
  ];

  const EditDescription = (value: any) => {
    return (
      <Descriptions.Item
        key={"description " + value.id}
        className={!value?.editable ? "" : "descriptionItemWrapper"}
      >
        <div className="input-wrapper">
          {!value?.editable ? (
            value.value ? (
              <p className="active-value">{value.value}</p>
            ) : (
              "回答を入力してください。"
            )
          ) : (
            <Input
              defaultValue={value?.value || ""}
              className={`inputEdit ${value?.error ? "errorEdit" : ""}`}
              onChange={value?.onChange ? value.onChange : null}
              onBlur={value?.onBlur ? value.onBlur : null}
              placeholder={value?.placeholder && value.placeholder}
            />
          )}
        </div>
        {!value?.editable ? (
          <EditFilled
            className="icon-edit"
            onClick={() => {
              if (value?.setEditable) {
                value?.setEditable();
              }
            }}
          />
        ) : (
          <>
            <CheckCircleOutlined
              className="icon-edit"
              onClick={() => {
                if (value?.onOk) {
                  value?.onOk();
                }
              }}
            />
            <CloseCircleOutlined
              className="icon-close"
              onClick={() => {
                if (value?.onClose) {
                  value?.onClose();
                }
              }}
            />
          </>
        )}
      </Descriptions.Item>
    );
  };

  useEffect(() => {
    if (programMenu !== ProgramMenuType.OTHER) {
      setIsEditAnswer(false);
    }
  }, [programMenu]);
  return (
    <DescriptionsWrapper>
      {data.map((value) => {
        return EditDescription(value);
      })}
    </DescriptionsWrapper>
  );
};

export { EditableComponent };
