import { ButtonBackPage, EmptyContent } from "components";
import MESSAGES from "constants/messages";
import memberApi from "features/member/memberApi";
import { memberActions } from "features/member/memberSlice";
import { showErrorNotification } from "helpers/notification";
import { useAppDispatch, useAppSelector } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MemberDetailsRoute } from "routers/router";
import { ProgramProvider } from "./ProgramContext/ProgramContext";
import { NoContentContainerStyled } from "./TrainingProgramDetail.styled";
import { ProgramHeader } from "./components/ProgramHeader/ProgramHeader";
import { ProgramSectionList } from "./components/ProgramSectionList/ProgramSectionList";
import { ProgramSideBar } from "./components/ProgramSideBar/ProgramSideBar";
import { ProgramSecondaryLayout } from "./layout/ProgramSecondaryLayout/ProgramSecondaryLayout";
import { DEFAULT_PATHNAME } from "constants/pathname";
import { programActions } from "features/program/programSlice";
import { TMemberDetail } from "model/member";

const TrainingProgramDetail = () => {
  const [loadingMember, setLoadingMember] = useState(true);
  const [hasGetMemberError, setHasGetMemberError] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const memberId = queryParams.get("memberId");
  const dispatch = useAppDispatch();
  const { hasGetProgramError } = useAppSelector((state) => state.program);

  useEffect(() => {
    async function getMemberDetail(memberId: string) {
      try {
        const { data } = await memberApi.getMemberById(memberId);

        const memberIndexes = {
          id: memberId,
          height: data?.height,
          weight: data?.weight,
          bodyFat: data?.bodyFat,
        };

        dispatch(
          memberActions.setMemberIndexes({
            data: memberIndexes,
          })
        );
        dispatch(
          memberActions.updateMemberDetailForDetailProgram({
            data,
          })
        );
        dispatch(
          programActions.setStoreId({
            data: (data as TMemberDetail & { storeId: string })?.storeId,
          })
        );
      } catch (error) {
        showErrorNotification(MESSAGES["COM-MSG-002"]);
        setHasGetMemberError(true);
      } finally {
        setLoadingMember(false);
      }
    }

    if (memberId) {
      setLoadingMember(true);
      setHasGetMemberError(false);
      getMemberDetail(memberId as string);
    } else {
      setHasGetMemberError(true);
      setLoadingMember(false);
    }

    return () => {
      dispatch(
        memberActions.updateMemberDetailForDetailProgram({
          data: null,
        })
      );
      dispatch(
        programActions.setStoreId({
          data: "",
        })
      );
    };
  }, [memberId]);

  if (loadingMember) return <Fragment />;

  if (hasGetMemberError || hasGetProgramError)
    return (
      <NoContentContainerStyled>
        <ButtonBackPage
          fallbackPath={
            memberId ? MemberDetailsRoute.genPath(memberId) : DEFAULT_PATHNAME
          }
          className="btn-navigate"
        />
        <EmptyContent />
      </NoContentContainerStyled>
    );

  return (
    <div>
      <ProgramHeader />
      <ProgramSecondaryLayout
        left={<ProgramSideBar />}
        right={<ProgramSectionList />}
      />
    </div>
  );
};

const TrainingProgramDetailPage = () => {
  return (
    <ProgramProvider>
      <TrainingProgramDetail />
    </ProgramProvider>
  );
};

export { TrainingProgramDetailPage };
