import styled from "styled-components";

export const EmptyContentWrapperStyled = styled.div`
  height: 100%;
  width: 100%;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 26px;

  .image-no-content {
    width: 100%;
    height: fit-content;
    max-width: 289px;
  }

  .title {
    font-weight: 500;
    font-size: max(24rem, 24px);
  }

  .app-logo {
    flex: 1;
    width: 121px;
    height: 92px;
    object-fit: contain;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;
