import { Col, Row } from "antd";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { TMajorItem, TShumoku } from "model/shumoku";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ShumokuMemo } from "../Shumoku/Shumoku";
import {
  MajorItemContainerStyled,
  MajorItemStepper,
  ShumokuManagementContentStyled,
} from "./ShumokuManagementContent.styled";

const ShumokuManagementContent = () => {
  const { majorItems, selectedShumokuMaterIds } = useAppSelector(
    (state) => state.shumoku
  );
  const dispatch = useAppDispatch();

  const [selectedShumokuIds, setSelectedShumokuIds] = useState<Set<string>>(
    new Set()
  );

  const handleChangeShumokuActive = useCallback(
    (shumokuId: string, checked: boolean) => {
      setSelectedShumokuIds((prevSelectedShumokuIds): any => {
        const updatedSelectedShumokuIds = new Set(prevSelectedShumokuIds);

        if (checked) {
          if (updatedSelectedShumokuIds.has(shumokuId)) {
            updatedSelectedShumokuIds.delete(shumokuId);
          }
        } else if (updatedSelectedShumokuIds.has(shumokuId)) {
          return updatedSelectedShumokuIds;
        } else {
          updatedSelectedShumokuIds.add(shumokuId);
        }

        return updatedSelectedShumokuIds;
      });
    },
    [selectedShumokuIds]
  );

  useEffect(() => {
    dispatch(
      shumokuActions.setSelectedShumokuMasterIds({
        data: [...selectedShumokuIds],
      })
    );
  }, [selectedShumokuIds]);

  useEffect(() => {
    if (selectedShumokuIds.size === 0) {
      setSelectedShumokuIds(new Set(selectedShumokuMaterIds));
    }
  }, []);

  const getMajorItems = useMemo(() => {
    return majorItems.map((item: TMajorItem) => {
      return (
        <MajorItemContainerStyled
          key={item?.majorItemCode}
          id={item?.majorItemCode}
        >
          <MajorItemStepper>
            <span className="content">{item?.majorItemName}</span>
          </MajorItemStepper>
          <Row gutter={[20, 20]}>
            {item?.shumokus?.map((shumoku: TShumoku) => (
              <Col xs={24} sm={12} xl={8} xxl={6} key={shumoku.shumokuCode}>
                <ShumokuMemo
                  shumoku={shumoku}
                  selectedShumokuIds={selectedShumokuIds}
                  handleChangeShumokuActive={handleChangeShumokuActive}
                />
              </Col>
            ))}
          </Row>
        </MajorItemContainerStyled>
      );
    });
  }, [majorItems]);
  return (
    <ShumokuManagementContentStyled>
      {getMajorItems}
    </ShumokuManagementContentStyled>
  );
};

export { ShumokuManagementContent };
