import { Col } from "antd";
import { ArrowLeftIcon, ArrowRightIcon } from "constants/svgs";
import { TQuestion, TQuestionFormItem } from "model/survey";
import { Fragment, useEffect, useState } from "react";
import {
  ButtonDirectionStyled,
  ButtonSubmitEditStyled,
  SurveyContainerStyled,
  SurveyFormWrapperStyled,
  SurveyHeaderStyled,
} from "./SurveyLayout.styled";
import { SURVEY_LAYOUT_TRANSLATE } from "./SurveyLayout.translate";
import { SurveyForm } from "./components";
import { TFormTitleProps } from "./components/FormTitle";

export type TQuestionByCategory = {
  name: string;
  questions: TQuestion[];
  title: string;
};

type TSurveyLayoutProps<V = any> = {
  onBackPage: () => void;
  onSaveSurvey: (values: V) => void;
  onFinishSurvey: (values: V) => void;
  categoryList: TQuestionByCategory[];
  surveyValues?: V;
  pageTitle?: string;
  initialStep?: number;
  singleStepMode?: boolean;
};

export type TFormProps<V = any> = {
  initialValues?: V;
  onNext?: (values: V) => void;
} & Omit<TFormTitleProps, "extraContent">;

function SurveyLayout<V = any>(props: TSurveyLayoutProps<V>) {
  const {
    onBackPage,
    onSaveSurvey,
    categoryList,
    surveyValues = {},
    onFinishSurvey,
    pageTitle = SURVEY_LAYOUT_TRANSLATE.SCREEN_TITLE,
    initialStep,
    singleStepMode,
  } = props;

  const [current, setCurrent] = useState(initialStep || 0);

  // Handlers
  const handleBackStep = () => {
    if (singleStepMode || current <= 0) {
      onBackPage();
      return;
    }

    setCurrent((val) => Math.max(0, val - 1));
  };

  const saveSurvey = async (values: any) => {
    onSaveSurvey(values);
  };

  const handleNextStep = ({
    categoryName,
    values,
    isLastCategory,
  }: {
    values: TQuestionFormItem[];
    categoryName: string;
    isLastCategory: boolean;
  }) => {
    if (singleStepMode) {
      onFinishSurvey({ [categoryName]: values } as V);
      return;
    }

    setCurrent((val) => Math.min(totalStep - 1, val + 1));

    const newValues: V = {
      ...(surveyValues as V),
      [categoryName]: values,
    };

    saveSurvey(newValues);

    if (isLastCategory) {
      onFinishSurvey(newValues);
    }
  };

  // Partial UI
  const surveyHeader = (
    <SurveyHeaderStyled justify="space-between" align="middle">
      <Col>
        <ButtonDirectionStyled onClick={handleBackStep} type="text">
          <ArrowLeftIcon />
          <span className="btn-text">{SURVEY_LAYOUT_TRANSLATE.PREV_STEP}</span>
        </ButtonDirectionStyled>
      </Col>

      <Col>
        <p className="screen-title">{pageTitle}</p>
      </Col>

      <Col>
        {singleStepMode ? (
          <ButtonSubmitEditStyled htmlType="submit" form={`survey-${current}`}>
            {SURVEY_LAYOUT_TRANSLATE.SUBMIT_SINGLE_MODE}
          </ButtonSubmitEditStyled>
        ) : (
          <ButtonDirectionStyled
            type="text"
            htmlType="submit"
            form={`survey-${current}`}
          >
            <span className="btn-text">
              {current >= categoryList.length - 1
                ? SURVEY_LAYOUT_TRANSLATE.SUBMIT
                : SURVEY_LAYOUT_TRANSLATE.NEXT_STEP}
            </span>
            <ArrowRightIcon />
          </ButtonDirectionStyled>
        )}
      </Col>
    </SurveyHeaderStyled>
  );

  const totalStep = categoryList.length;

  useEffect(() => {
    if (initialStep !== undefined) {
      if (initialStep < 0) {
        setCurrent(0);
      } else {
        setCurrent(initialStep);
      }
    }
  }, [initialStep]);

  return (
    <SurveyContainerStyled>
      {surveyHeader}

      <SurveyFormWrapperStyled>
        {categoryList.map((category, index) => {
          const { name = "", questions = [], title = "" } = category || {};
          const isLastCategory = index === categoryList.length - 1;

          if (singleStepMode && index !== current)
            return <Fragment key={index} />;

          return (
            <div
              key={`${category?.name}-${index}`}
              style={{ display: current === index ? "block" : "none" }}
            >
              <SurveyForm
                onNext={(values) =>
                  handleNextStep({ categoryName: name, isLastCategory, values })
                }
                initialValues={(surveyValues as V)?.[name as keyof V] as any}
                questions={questions}
                formIndex={index + 1}
                title={title}
                formName={`survey-${index}`}
                maxStep={totalStep}
                submitText={
                  singleStepMode
                    ? SURVEY_LAYOUT_TRANSLATE.SUBMIT_SINGLE_MODE
                    : isLastCategory
                    ? SURVEY_LAYOUT_TRANSLATE.SUBMIT
                    : SURVEY_LAYOUT_TRANSLATE.NEXT_STEP
                }
              />
            </div>
          );
        })}
      </SurveyFormWrapperStyled>
    </SurveyContainerStyled>
  );
}

export { SurveyLayout };
