import styled from "styled-components";

export const StoreSelectionFormStyled = styled.div`
  padding: 24rem;
  .search-input {
    font-weight: 400;
    font-size: 14px;
    margin-top: 25rem;
    margin-bottom: 5rem;
  }
`;

export const StoreSelectionHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .next-btn {
    color: var(--color-primary);
    fill: var(--color-primary);
  }
`;

export const SelectedItemStyled = styled.div`
  display: flex;
  align-items: center;

  .vertical-bar {
    width: 1px;
    height: 24px;
    background-color: var(--color-black);
    margin: 0 16rem;
  }

  font-weight: 700;
  font-size: 16px;
  color: #1b1d26;

  .selected-store {
    color: rgba(0, 0, 0, 0.25);

    &.active {
      color: #52c41a;
    }
  }
`;

export const StoreFilterStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;
