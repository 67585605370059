import { Calendar } from "antd";
import styled from "styled-components/macro";

export const CalendarStyled = styled(Calendar)`
  .ant-picker-body {
    padding: 8px !important;

    .ant-picker-content {
      height: auto;
    }

    thead {
      th {
        height: 30px;
      }
    }

    tbody {
      tr {
        td {
          height: 30px;
          cursor: default;
          padding: 3px;
          pointer-events: none;
        }
      }
    }
  }
`;

export const HeaderWrapperStyled = styled.div`
  padding: 12px 8px;

  display: flex;
  justify-content: space-between;
  color: var(--color-black);

  .legend-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;

    .checked-in,
    .today {
      color: var(--color-black);
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-normal);

      ::before {
        content: "";
        display: inline-block;
        width: max(20rem, 20px);
        height: max(10rem, 10px);

        border-radius: var(--border-radius);
        border: 1px solid var(--color-primary);

        transform: translateY(15%);
      }
    }

    .checked-in::before {
      background-color: var(--color-primary);
    }
  }

  .panel-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    .select-year,
    .select-month {
      .ant-select-selector {
        padding: 0px 12px;
        color: var(--color-black);
      }

      .ant-select-arrow {
        color: #000000d9;
        font-size: 9px;
      }

      .ant-select-selection-item {
        padding-inline-end: 16px !important;
      }
    }

    .select-year {
      min-width: 74px;
    }

    .select-month {
      min-width: 64px;
    }
  }
`;

export const DateCellStyled = styled.div`
  border-radius: var(--border-radius);
  height: 100%;
  cursor: default;

  display: flex;
  justify-content: center;
  align-items: center;

  &.checked-in {
    background-color: var(--color-primary);
    font-weight: 700;
    color: var(--color-white);
  }

  &.today {
    border: 1px solid var(--color-primary);
  }
`;
