import { Form } from "antd";
import { SURVEY_CREATION_FORM } from "pages/SurveyManagementPage/SurveyManagementPage.constant";
import { useSurveyManagementPageContext } from "pages/SurveyManagementPage/contexts";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { QuestionCreationItem } from "../QuestionCreationItem/QuestionCreationItem";
import { QuestionWrapperStyled } from "./SurveyPartItem.styled";

type TSurveyPartItemProps = {
  surveyPartIndex: number;
};

function SurveyPartItem(props: TSurveyPartItemProps) {
  const { surveyPartIndex } = props;

  const {
    activeQuestionIndex,
    updateActiveQuestionIndex,
    onReorderQuestion,
    activePartTab,
  } = useSurveyManagementPageContext();

  const nameForQuestionList = [
    SURVEY_CREATION_FORM.SURVEY_PART.NAME,
    surveyPartIndex,
    SURVEY_CREATION_FORM.SURVEY_PART_QUESTIONS.NAME,
  ];

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    onReorderQuestion({
      newIndex: result.destination.index,
      oldIndex: result.source.index,
      partIndex: surveyPartIndex,
    });
  };

  const draggableQuestionList = (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={`survey-part-${surveyPartIndex}`}>
        {(provided, _snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <Form.List name={nameForQuestionList}>
              {(fields, { remove }) => (
                <>
                  {fields.map(({ key, name }, index) => {
                    const isActive =
                      Number(activePartTab) === surveyPartIndex &&
                      activeQuestionIndex === name;

                    return (
                      <Draggable
                        key={key}
                        draggableId={key.toString()}
                        index={index}
                        isDragDisabled={!isActive}
                      >
                        {(provided, _snapshot) => (
                          <div
                            onClick={() => updateActiveQuestionIndex(name)}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <QuestionCreationItem
                              isActive={isActive}
                              questionIndex={name}
                              partIndex={surveyPartIndex}
                              onRemoveQuestion={() => remove(name)}
                              dragHandleProps={provided.dragHandleProps}
                              hideButtonDelete={fields.length <= 1}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </>
              )}
            </Form.List>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <div>
      <QuestionWrapperStyled>{draggableQuestionList}</QuestionWrapperStyled>
    </div>
  );
}

export { SurveyPartItem };
