import { Row } from "antd";
import styled from "styled-components/macro";

export const MemberListContainerStyled = styled.div`
  height: 100vh;
  height: 100dvh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
`;

export const MemberListTopStyled = styled.div`
  padding: 24px 24px 16px;
  background-color: var(--color-white);
`;

export const TopHeader = styled.div`
  display: flex;

  .title {
    flex: 1;
    text-align: center;
    padding-right: 61px;
    font-weight: 700;
    font-size: var(--font-size-md);
  }
`;

export const FieldsWrapper = styled.div`
  margin-top: 24px;

  .form-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .custom-form-item {
    flex: 1;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-picker {
    width: 100%;
  }
`;

export const MemberListMainStyled = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .ant-tabs {
    height: 100%;
    overflow: hidden;

    .ant-tabs-nav {
      margin-bottom: 0;
      background-color: var(--color-white);

      .ant-tabs-nav-operations {
        display: none;
      }

      .ant-tabs-nav-list {
        width: 100%;

        .ant-tabs-tab {
          margin: 0;
          flex: 1 1 1px;
          justify-content: center;
        }
      }
    }

    .ant-tabs-content-holder {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
`;

export const MemberListBySearchWrapperStyled = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ListRowStyled = styled(Row)`
  padding: 24px;
`;

export const SearchResultContainer = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
