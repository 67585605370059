import { Tabs } from "antd";
import styled from "styled-components/macro";

export const SurveyDetailPageWrapper = styled.div`
  height: 100vh;
  height: 100dvh;
  background-color: var(--color-white);

  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const HeaderStyled = styled.div`
  padding: 19px 24px 23px;

  display: flex;
  align-items: center;
  gap: 12px;

  .icon-back {
    cursor: pointer;
    font-size: 16px;
  }

  .page-title {
    flex: 1;
    font-weight: 500;
    font-size: var(--font-size-md);
  }

  .actions-wrapper {
    display: flex;
    gap: 8px;
  }

  .icon-arrow-left {
    width: 18px;
    height: 14px;

    cursor: pointer;
  }

  @media only screen and (max-width: 576px) {
    .page-title {
      display: none;
    }

    .actions-wrapper {
      margin-left: auto;
    }
  }
`;

export const TabsStyled = styled(Tabs)`
  flex: 1;
  overflow: hidden;

  .ant-tabs-nav {
    margin-bottom: 0;
    margin-inline: 24px;

    ::before {
      width: 100vw;
      transform: translateX(-24px);
    }

    .ant-tabs-nav-wrap {
      width: 100%;

      .ant-tabs-nav-list {
        margin-inline: auto;

        .ant-tabs-tab {
          justify-content: center;
          flex: 1;

          &.ant-tabs-tab-active {
            font-weight: 700;
          }
        }
      }
    }
  }

  .ant-tabs-content-holder {
    padding: 27px 24px;
    flex: 1;
    overflow-y: auto;
  }

  .form-view {
    padding: 0;
  }
`;
