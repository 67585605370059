import styled from "styled-components";

export const MeasurementInfoWrapperStyled = styled.div`
  width: 100%;
  padding: max(16px, 16rem);
  background-color: #ffffff1a;

  display: flex;
  flex-direction: column;
  gap: 16.5rem;
`;

export const MeasurementItemStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .icon {
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 24px;
      color: #ffbe8f;
    }
  }

  .title {
    flex: 1;
    font-size: var(--font-size-sm);
  }

  .value {
    font-size: 18px;
    font-weight: 700;
  }
`;
