import styled from "styled-components";

export const ProgramHeaderStyled = styled.div``;
export const TopHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;

  .title {
    flex: 1;
    text-align: center;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bolder);
    padding-right: 62px;
  }
`;

export const MemberInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .member-id {
    color: var(--color-primary);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bolder);
  }

  .member-info-group {
    display: flex;
    align-items: center;
    margin: 12px 0 24px 0;

    .member-index-item {
      display: flex;
      align-items: center;

      .icon-label {
        display: flex;
        align-items: center;
        gap: 6px;
      }

      .dot {
        color: #333;
        padding: 0 10rem;
      }

      .icon {
        color: #ff6b00;
        margin-left: 5px;
        display: flex;
      }
      .label {
        color: var(--color-black);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-normal);
        margin-left: 5rem;
      }
      .content {
        color: var(--color-black);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-bolder);
        margin-left: 4rem;
      }
    }
  }
`;
