import { Spin } from "antd";
import { OnboardingImage } from "constants/images";
import styled from "styled-components/macro";

export const SpinStyled = styled(Spin)`
  &&& {
    height: 100vh;
    height: 100dvh;

    max-height: unset;
  }
`;

export const PageContainerStyled = styled.div`
  min-height: 100vh;
  min-height: 100dvh;
  padding: 53px;

  background-image: url(${OnboardingImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoWrapperStyled = styled.div`
  display: flex;

  img {
    height: 102px;
    width: 133px;
    margin-inline: auto;
  }

  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const LineStyled = styled.div`
  height: auto;
  width: 1px;
  background-color: #ffffff;

  margin-inline: 60px;
`;

export const SloganStyled = styled.p`
  align-self: center;

  font-size: 38px;
  font-weight: 700;
  text-align: center;
  color: var(--color-logo);

  background: linear-gradient(91.78deg, #ffeccf 2.16%, #ffc165 103.68%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media only screen and (max-width: 480px) {
    margin-top: 43rem;
  }
`;
