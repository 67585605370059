import { FC } from "react";
import { TSurveyItem } from "../MemberConfirmedSurvey/MemberConfirmedSurvey";
import { MemberConfirmedSurveyItemStyled } from "./MemberConfirmedSurveyItem.styled";

const MemberConfirmedSurveyItem: FC<TSurveyItem> = ({ icon, content }) => {
  return (
    <MemberConfirmedSurveyItemStyled>
      <span className="icon">{icon}</span>
      <span className="content">{content}</span>
    </MemberConfirmedSurveyItemStyled>
  );
};

export { MemberConfirmedSurveyItem };
