import { LOCATION_STACK_TEXT } from "constants/common";
import { useAppLoading } from "contexts";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Location,
  NavigationType,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";

type TAppLocation = Location & {
  navigationType?: NavigationType;
};

type TAppLocationMetaData = {
  locationStack: TAppLocation[];
  canNavigateBack: boolean;
  prevPage?: TAppLocation;
  navigationType?: NavigationType;
};

type TAppLocationProviderValue = {
  getAppLocationMetaData: () => TAppLocationMetaData;
  backToPrevPage: (fallbackPath: string | string[]) => void;
  getLastIndexInLocationStack: (pathname: string) => number;
};

const initialAppLocationValue: TAppLocationProviderValue = {
  getAppLocationMetaData: () => {
    return {
      locationStack: [],
      canNavigateBack: false,
    };
  },
  backToPrevPage: () => {
    //
  },
  getLastIndexInLocationStack: () => {
    return -1;
  },
};

const AppLocationContext = createContext<TAppLocationProviderValue>(
  initialAppLocationValue
);

function useAppLocation() {
  return useContext(AppLocationContext);
}

function getLocationStack(): TAppLocation[] {
  const locationStack = sessionStorage.getItem(LOCATION_STACK_TEXT);
  return locationStack ? JSON.parse(locationStack) : [];
}

function setLocationStack(locationStack: TAppLocation[]) {
  sessionStorage.setItem(LOCATION_STACK_TEXT, JSON.stringify(locationStack));
}

function AppLocationProvider(props: PropsWithChildren) {
  const { children } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const { stopLoading } = useAppLoading();

  const firstTimeRenderRef = useRef(true);

  //   Functions
  const getAppLocationMetaData = useCallback((): TAppLocationMetaData => {
    const prevPage = getPrevPage();

    return {
      prevPage: prevPage,
      canNavigateBack: !!prevPage,
      locationStack: getLocationStack(),
      navigationType: navigationType,
    };
  }, [navigationType]);

  const getPrevPage = () => {
    const locationStack = getLocationStack();
    const stackLength = locationStack.length;
    return locationStack[stackLength - 2];
  };

  const backToPrevPage = (fallbackPath: string | string[]) => {
    const prevPage = getPrevPage();
    const previousPath = prevPage
      ? `${prevPage.pathname}${prevPage.search}`
      : "";

    if (typeof fallbackPath === "string") {
      if (previousPath === fallbackPath) {
        navigate(-1);
        return;
      }

      navigate(fallbackPath, { replace: true });
      return;
    }

    if (fallbackPath.includes(previousPath)) {
      navigate(-1);
      return;
    }

    navigate(fallbackPath[0], { replace: true });
  };

  const getLastIndexInLocationStack = (pathname: string | string[]) => {
    const stack = getLocationStack();

    for (let i = stack.length - 1; i >= 0; i--) {
      if (typeof pathname === "string") {
        if (stack[i].pathname === pathname) return i;
      } else {
        if (pathname.includes(stack[i].pathname)) return i;
      }
    }

    return -1;
  };

  //   Effects
  useEffect(() => {
    if (firstTimeRenderRef.current) {
      firstTimeRenderRef.current = false;
    } else {
      const _location: TAppLocation = { ...location, navigationType };
      const locationStack = getLocationStack();
      // Update locationStack
      switch (navigationType) {
        case NavigationType.Pop:
          locationStack.pop();
          break;
        case NavigationType.Push:
          locationStack.push(_location);
          break;
        case NavigationType.Replace:
          locationStack.pop();
          locationStack.push(_location);
          break;
        default:
          break;
      }
      setLocationStack(locationStack);
    }
  }, [location.key]);

  // Stop loading when location changes
  useEffect(() => {
    return () => {
      stopLoading();
    };
  }, [location.pathname]);

  // Add first page to locationStack
  useEffect(() => {
    const _location: TAppLocation = { ...location, navigationType };
    const locationStack = getLocationStack();
    locationStack.push(_location);

    setLocationStack(locationStack);
  }, []);

  return (
    <AppLocationContext.Provider
      value={{
        getAppLocationMetaData,
        backToPrevPage,
        getLastIndexInLocationStack,
      }}
    >
      {children}
    </AppLocationContext.Provider>
  );
}

export { AppLocationProvider, useAppLocation };
