import styled from "styled-components";

export const ProgressBarStyled = styled.div`
  color: var(--color-white);
  display: flex;
  align-items: center;
  gap: 20rem;
  height: 8rem;
  width: 100%;

  .active {
    background: var(--color-white);
  }

  .in-active {
    background: #595959;
  }
`;

export const ProgressStepItemStyled = styled.div`
  height: 100%;
  width: 100%;
  background: var(--color-white);
`;
