import styled from "styled-components";

export const ProgramMainLayoutStyled = styled.div`
  height: 100vh;
  height: 100dvh;
  padding: 24rem;
  margin-top: 200px;

  .title {
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-md);
    color: #b0b0b0;
  }
`;
