import { Button, DatePicker, DatePickerProps, Form, Input } from "antd";
import { DirectionComponent } from "components/DirectionComponent/DirectionComponent";
import { NotificationModal } from "components/NotificationModal/NotificationModal";
import { EStatus } from "constants/common";
import MESSAGES from "constants/messages";
import { PATHNAME_LIST } from "constants/pathname";
import { ArrowLeftIcon } from "constants/svgs";
import { useAppLoading, useAppLocation } from "contexts";
import dayjs from "dayjs";
import { memberActions } from "features/member/memberSlice";
import { TMember } from "model/member";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MemberDetailsRoute, MemberNewbieRoute } from "routers/router";
import {
  ButtonWrapperStyled,
  MemberConfirmationStyled,
} from "./MemberConfirmation.styled";
import { MEMBER_CONFIRMATION_TRANSLATE } from "./MemberConfirmation.translate";

import { ConfigProvider } from "antd";
import frFR from "antd/locale/fr_FR";
import "dayjs/locale/ja"; // Import Japanese locale
import weekday from "dayjs/plugin/weekday";
import { EGetMemberError } from "features/member/memberApi";
import { showErrorNotification } from "helpers/notification";

dayjs.extend(weekday);
dayjs.locale("ja");

const DATE_FORMAT_OPTIONS = {
  YYYY_MM_DD: "YYYY/MM/DD",
  YYYY_M_D: "YYYY/M/D",
  YYYY_MM_D: "YYYY/MM/D",
  YYYY_M_DD: "YYYY/M/DD",
  YYYYMMDD: "YYYYMMDD",
};

const formatDateString = (inputString: string) => {
  if (!inputString) {
    return "";
  }
  const date = dayjs(inputString, DATE_FORMAT_OPTIONS.YYYY_MM_DD);
  const formattedDate = date.format(DATE_FORMAT_OPTIONS.YYYYMMDD);
  return formattedDate;
};

const MEMBER_FIELDS = {
  MEMBER_ID: {
    NAME: "MemberID",
  },
  MEMBER_DATE: {
    NAME: "MemberDate",
  },
};

const MemberConfirmation: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { startLoading, stopLoading } = useAppLoading();
  const { backToPrevPage } = useAppLocation();

  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gymerId, setGymerId] = useState("");
  const [date, setDate] = useState("");
  const [inputError, setInputError] = useState(false); // Track the input error status
  const [errorMessage, setErrorMessage] = useState(MESSAGES["SCR-W02-MSG-001"]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleBtnClick = () => {
    handleCancel();
  };

  const handleSearch = () => {
    const MEMBER_LENGTH = 8;
    const memberIdTrimmed = gymerId.trim();
    if (memberIdTrimmed.length !== MEMBER_LENGTH) {
      setInputError(true); // Set the input error status
      return;
    }

    const data: TMember = {
      memberId: memberIdTrimmed,
      birthday: date,
    };

    startLoading();
    dispatch(
      memberActions.confirmMember({
        data,
        onSuccess: (memberId) => {
          if (memberId) {
            navigate(MemberDetailsRoute.genPath(memberId));
          } else {
            navigate(MemberNewbieRoute.genPath(memberIdTrimmed));
          }
        },
        onError: (errorCode?: string) => {
          if (!errorCode) {
            showErrorNotification(MESSAGES["COM-MSG-002"]);
          } else {
            if (errorCode === EGetMemberError.FORBIDDEN_STORE) {
              setErrorMessage(MESSAGES["COM-MSG-004"]);
              showModal();
            } else {
              setErrorMessage(MESSAGES["SCR-W02-MSG-001"]);
              showModal();
            }
          }
        },
        onFinal: () => stopLoading(),
      })
    );
  };

  const allowSearch = useMemo(() => {
    return !!gymerId && !!date;
  }, [gymerId, date]);

  const handleInputChange = (e: any) => {
    setGymerId(e.target.value);
    setInputError(false); // Reset the input error status
  };

  const onChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    const formattedString = formatDateString(dateString);
    setDate(formattedString);
  };

  const handleDirection = () => {
    backToPrevPage(PATHNAME_LIST.HOME);
  };

  const inputRef = useRef<any>(null);
  const popupContainerRef = useRef<any>(null);

  useEffect(() => {
    const handleWindowResize = () => {
      const inputElement = inputRef.current;
      const popupContainerElement = popupContainerRef.current;

      if (inputElement && popupContainerElement) {
        const inputWidth = popupContainerElement.offsetWidth;
        const popupPicker = popupContainerElement.querySelector(
          ".ant-picker-dropdown"
        ) as HTMLElement;
        if (popupPicker) {
          popupPicker.style.width = `${inputWidth}px`;
        }
      }
    };

    handleWindowResize(); // Set initial width

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <ConfigProvider locale={frFR}>
      <MemberConfirmationStyled>
        <DirectionComponent
          name={MEMBER_CONFIRMATION_TRANSLATE.BACK_ICON}
          iconPrev={<ArrowLeftIcon />}
          callback={handleDirection}
          className="back-item"
        />
        <h2 className="title">{MEMBER_CONFIRMATION_TRANSLATE.TITLE}</h2>
        <Form
          form={form}
          layout="vertical"
          size="large"
          onFinish={handleSearch}
        >
          <Form.Item
            label={MEMBER_CONFIRMATION_TRANSLATE.MEMBER_ID}
            name={MEMBER_FIELDS.MEMBER_ID.NAME}
            validateStatus={inputError ? "error" : ""}
            help={
              inputError
                ? MESSAGES["COM-MSG-003"](
                    MEMBER_CONFIRMATION_TRANSLATE.MEMBER_ID,
                    8
                  )
                : ""
            }
            className="custom-form-item"
          >
            <Input
              placeholder={MEMBER_CONFIRMATION_TRANSLATE.INPUT_PLACEHOLDER}
              onChange={handleInputChange}
              allowClear
            />
          </Form.Item>
          <Form.Item label={MEMBER_CONFIRMATION_TRANSLATE.MEMBER_DATE}>
            <div className="date-wrapper" ref={popupContainerRef}>
              <DatePicker
                onChange={onChangeDate}
                className="date-field"
                placeholder={MEMBER_CONFIRMATION_TRANSLATE.TEXT_DATE}
                getPopupContainer={() => popupContainerRef.current}
                ref={inputRef}
                format={[
                  DATE_FORMAT_OPTIONS.YYYY_MM_DD,
                  DATE_FORMAT_OPTIONS.YYYY_M_D,
                  DATE_FORMAT_OPTIONS.YYYY_MM_D,
                  DATE_FORMAT_OPTIONS.YYYY_M_DD,
                ]}
                allowClear
                inputReadOnly={false}
              />
            </div>
          </Form.Item>
          <Form.Item className="verify-button-wrapper">
            <ButtonWrapperStyled>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-item"
                disabled={!allowSearch}
              >
                {MEMBER_CONFIRMATION_TRANSLATE.TEXT_BUTTON}
              </Button>
            </ButtonWrapperStyled>
          </Form.Item>
        </Form>
        <NotificationModal
          messageContent={errorMessage}
          open={isModalOpen}
          onCancel={handleCancel}
          btnContent={MEMBER_CONFIRMATION_TRANSLATE.MODAL_TEXT_BUTTON}
          handleBtnClick={handleBtnClick}
          title={MEMBER_CONFIRMATION_TRANSLATE.TITLE}
          status={EStatus.ERROR}
        />
      </MemberConfirmationStyled>
    </ConfigProvider>
  );
};

export { MemberConfirmation };
