import images from "constants/images";
import { StoreNotFoundStyled } from "./StoreNotFound.styled";
import { TRANSLATION } from "pages/ShumokuManagementPage/ShumokuManagementPage.translate";
import { GrayLogo } from "constants/svgs";

const StoreNotFound = () => {
  return (
    <StoreNotFoundStyled>
      <img src={images.StoreNotFounded} loading="lazy" />
      <p className="message">{TRANSLATION.NOT_FOUNDED}</p>
      <GrayLogo className="logo" />
    </StoreNotFoundStyled>
  );
};

export { StoreNotFound };
