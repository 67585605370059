import styled from "styled-components";

const HexagonWidthPerHeight = 44 / 37;

export const QuestionTitleStyled = styled.div`
  margin-bottom: 16rem;

  display: flex;
  align-items: baseline;
  gap: 8rem;

  .question-content {
    font-weight: 700;
    font-size: var(--font-size-md);
  }

  .question-number-wrapper {
    width: 44px;
    height: calc(44px / ${HexagonWidthPerHeight});
    padding: 1px calc(1px * ${HexagonWidthPerHeight});

    flex: 0 0 44px;

    clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);

    background-color: #e7e7e7;

    .question-number {
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 700;

      clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
      background-color: #f5f5f5;
    }
  }

  &.has-error {
    color: var(--color-error);

    .question-number-wrapper {
      background-color: var(--color-error);
    }
  }
`;
