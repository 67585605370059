import styled from "styled-components";

export const ButtonBackPageWrapperStyled = styled.div`
  width: fit-content;

  display: flex;
  align-items: center;
  gap: 12px;

  cursor: pointer;
  font-weight: 700;

  .icon-arrow-left {
    width: 18px;
    height: 14px;
  }
`;
