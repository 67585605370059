import images from "constants/images";
import styled from "styled-components";

export const ProgramSectionItemStyled = styled.div``;

export const SectionStepper = styled.div`
  padding: 16rem;
  border: solid 1px var(--color-primary);
  background: #fff1e8 url(${images.ProgramSection}) center no-repeat;
  background-size: cover;
  background-position: 100% center;

  .label,
  .content {
    color: var(--color-primary);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bolder);
  }
`;
