import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "configs/axiosInstance";
import { HEADER_FORM_DATA } from "constants/common";
import {
  TCreateMajorItemMasterReq,
  TDeleteShumokuRequest,
  TDeleteStoreRequest,
  TMajorItem,
  TShumokuResponse,
  TStore,
  TStoreResponse,
  TUpdateMajorItemMasterReq,
  TUpdateShumokuReq,
  TUpdateStoreReq,
} from "model/shumoku";
import { TResponseWithPagination } from "types/service";

export type TGetAllStoresParams = {
  TextStoreSearch?: string;
  PageNumber: number;
  PageSize: number;
  ActiveFilter?: string;
};

const shumokuApi = {
  getAllStores: async (params: TGetAllStoresParams) => {
    const config: AxiosRequestConfig = {
      params: {
        TextStoreSearch: params.TextStoreSearch,
        PageNumber: params.PageNumber,
        PageSize: params.PageSize,
        ActiveFilter: params.ActiveFilter,
      },
    };

    const URL = "get-all-store";
    const response: TResponseWithPagination<TStore[]> = await axiosInstance.get(
      URL,
      config
    );
    return response;
  },

  getAllStoresWithoutFilter: async () => {
    const URL = "get-all-store-without-filter";
    const response: TResponseWithPagination<TStore[]> = await axiosInstance.get(
      URL
    );
    return response;
  },

  getAllShumokus: async () => {
    const URL = "get-all-shumokus";
    const response: TResponseWithPagination<TMajorItem[]> =
      await axiosInstance.get(URL);
    return response;
  },

  getShumokuMasterById: async (id: string) => {
    const URL = `get-shumoku-by-id?id=${id}`;
    const response: TResponseWithPagination<TShumokuResponse> =
      await axiosInstance.get(URL);
    return response;
  },

  updateShumokuMaster: async (data: TUpdateShumokuReq) => {
    const URL = "/update-shumokus-master";
    const response: TResponseWithPagination<string> = await axiosInstance.post(
      URL,
      data
    );
    return response;
  },

  createShumokuMaster: async (data: FormData) => {
    const URL = "/create-shumoku-master";
    const response: TResponseWithPagination<string> = await axiosInstance.post(
      URL,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  },

  generatePreUrlForCreateShumoku: async (data: FormData) => {
    const URL = "/generate-pre-url-create";
    const response: TResponseWithPagination<TShumokuResponse> =
      await axiosInstance.post(URL, data, {
        headers: HEADER_FORM_DATA,
      });
    return response;
  },

  generatePreUrlForUpdateShumoku: async (id: string, data: FormData) => {
    const URL = `/generate-pre-url-update?id=${id}`;
    const response: TResponseWithPagination<TShumokuResponse> =
      await axiosInstance.post(URL, data, {
        headers: HEADER_FORM_DATA,
      });
    return response;
  },

  updateShumokuMasterById: async (id: string, data: FormData) => {
    const URL = `/update-shumoku-by-id?id=${id}`;
    const response: TResponseWithPagination<string> = await axiosInstance.post(
      URL,
      data,
      {
        headers: HEADER_FORM_DATA,
      }
    );
    return response;
  },

  deleteShumoku: async (data: TDeleteShumokuRequest) => {
    const URL = "/delete-shumoku-by-id";
    const response: TResponseWithPagination<string> = await axiosInstance.post(
      URL,
      data
    );
    return response;
  },

  createStore: async (data: TUpdateShumokuReq) => {
    const URL = "/create-store";
    const response: TResponseWithPagination<string> = await axiosInstance.post(
      URL,
      data
    );
    return response;
  },

  getStoreById: async (id: string) => {
    const URL = "get-store-by-id";
    const response: TResponseWithPagination<TStoreResponse> =
      await axiosInstance.get(URL, {
        params: { storeId: id },
      });
    return response;
  },

  updateStore: async (id: string, data: TUpdateStoreReq) => {
    const URL = `/update-store?storeId=${id}`;
    const response: TResponseWithPagination<TStoreResponse> =
      await axiosInstance.put(URL, data);
    return response;
  },

  deleteStore: async (data: TDeleteStoreRequest) => {
    const URL = "/delete-store";
    const response: TResponseWithPagination<string> = await axiosInstance.post(
      URL,
      data
    );
    return response;
  },

  createMajorItemMaster: async (data: TCreateMajorItemMasterReq) => {
    const URL = "/create-major-item-master";
    const response: TResponseWithPagination<string> = await axiosInstance.post(
      URL,
      data
    );
    return response;
  },

  deleteMajorItemMaster: async (id: string) => {
    const URL = `/delete-major-item-master?id=${id}`;
    const response: TResponseWithPagination<string> =
      await axiosInstance.delete(URL);
    return response;
  },

  updateMajorItemMaster: async (
    id: string,
    data: TUpdateMajorItemMasterReq
  ) => {
    const URL = `/update-major-item-master?majorItemId=${id}`;
    const response: TResponseWithPagination<TUpdateMajorItemMasterReq> =
      await axiosInstance.put(URL, data);
    return response;
  },
};

export default shumokuApi;
