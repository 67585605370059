import { DirectionComponent } from "components";
import { ArrowLeftIcon } from "constants/svgs";
import { FC, Fragment } from "react";
import {
  ShumokuMasterFormContainerStyled,
  ShumokuMasterFormStyled,
  ShumokuMasterHeaderStyled,
  ShumokuMasterTitleStyled,
} from "./ShumokuMasterForm.styled";
import { TRANSLATION } from "../ShumokuMaster.translate";
import { useNavigate } from "react-router-dom";
import { PATHNAME_LIST } from "constants/pathname";

const ShumokuMasterForm: FC = () => {
  const navigate = useNavigate();

  const handleBackPage = () => {
    navigate(PATHNAME_LIST.PERSONAL_INFO);
  };

  return (
    <ShumokuMasterFormStyled>
      <ShumokuMasterFormContainerStyled>
        <ShumokuMasterHeaderStyled>
          <DirectionComponent
            name="戻る"
            iconPrev={<ArrowLeftIcon />}
            className="back-btn"
            callback={handleBackPage}
          />
          <ShumokuMasterTitleStyled>
            {TRANSLATION.TITLE}
          </ShumokuMasterTitleStyled>
          <Fragment></Fragment>
        </ShumokuMasterHeaderStyled>
      </ShumokuMasterFormContainerStyled>
    </ShumokuMasterFormStyled>
  );
};

export { ShumokuMasterForm };
