import { DEFAULT_PATHNAME } from "constants/pathname";
import { useAppSelector } from "hooks";
import { PropsWithChildren, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";

function UnLoginRoute(props: PropsWithChildren) {
  const { children } = props;
  const location = useLocation();
  const user = useAppSelector((state) => state.auth.user);
  const { role: userRole } = user || {};

  const isFirstRenderRef = useRef(true);

  const navigateProps = {
    replace: true,
    state: { from: location },
  };

  if (isFirstRenderRef.current && userRole) {
    isFirstRenderRef.current = false;
    return <Navigate to={DEFAULT_PATHNAME} {...navigateProps} />;
  }

  isFirstRenderRef.current = false;
  return children as JSX.Element;
}

export default UnLoginRoute;
