import { AutoComplete, Button, Form, Modal, Select, Spin } from "antd";
import { AppInputNumber } from "components";
import {
  ARRAY_SELECT_OPTION,
  DEFAULT_DEBOUNCE_TIME,
  MAX_LENGTH_REPS,
  MAX_LENGTH_SETS,
  getNumberInString,
  getTextOnly,
} from "constants/common";
import images from "constants/images";
import MESSAGES from "constants/messages";
import programApi from "features/program/programApi";
import { replaceErrorImage } from "helpers";
import { TShumoku } from "model/program";
import {
  Fragment,
  ReactElement,
  cloneElement,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import {
  ModalContentStyled,
  OptionItemStyled,
  SelectShumokuModalStyled,
} from "./SelectShumokuModal.style";
import { useAppSelector } from "hooks";

export type TSubmitShumokuModalValue = Partial<TShumoku>;

type TSelectShumokuModalProps = {
  onSubmit: (props: TSubmitShumokuModalValue) => void;
  initialShumoku?: TShumoku;
  children: ReactElement;
};

type TFormValues = {
  option: string;
  rep: string | number;
  search: string;
  sets: string | number;
};

const FIELD_NAMES = {
  REP: "rep",
  SETS: "sets",
  SEARCH: "search",
  OPTION: "option",
};

const TRANSLATION = {
  NO_DATA: "を入力してくださ",
  NOT_PROPER: "オプションの結果を選択してください",
  MESSAGE_REQUIRE_SHUMOKU_NAME: MESSAGES["COM-MSG-001"]("種目"),
  MESSAGE_REQUIRE_SHUMOKU_REP: MESSAGES["COM-MSG-001"]("回数"),
  MESSAGE_REQUIRE_SHUMOKU_SETS: MESSAGES["COM-MSG-001"]("セット"),
};

function SelectShumokuModal(props: TSelectShumokuModalProps) {
  const { onSubmit, initialShumoku, children } = props;

  // State
  const [searchText, setSearchText] = useState("");
  const [shumokuOptions, setShumokuOptions] = useState<TShumoku[]>([]);
  const [loadingShumokuOptions, setLoadingShumokuOptions] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const isSelectItem = useRef(false);

  // Form
  const [form] = Form.useForm();
  const formId = useId();

  // Redux
  const { storeId } = useAppSelector((state) => state.program);

  // Variables
  const formName = `shumoku-form-${formId}`;

  // Handlers
  const transformShumokuList = (shumokus: TShumoku[][]) => {
    let shumokuList: TShumoku[] = [];

    shumokus.map((item) => {
      shumokuList = [...shumokuList, ...item];
    });

    return shumokuList;
  };

  const handleSearch = async (value: string) => {
    try {
      setLoadingShumokuOptions(true);

      const { data } = await programApi.getShumoku(value.trim(), storeId);
      const transformedShumokuList = transformShumokuList(
        data as unknown as TShumoku[][]
      );

      setShumokuOptions(transformedShumokuList);
    } catch (e) {
      //
    } finally {
      setLoadingShumokuOptions(false);
    }
  };

  const handleModalCancel = () => {
    // Clear the search text and data source when the Modal is closed
    setSearchText("");
    setShumokuOptions([]);
    form.resetFields();

    setOpenModal(false);
  };
  const handleFocus = () => {
    setIsOpenDropdown(true);
  };

  const handleBlur = () => {
    setIsOpenDropdown(false);
  };

  const handleClearShumoku = () => {
    form.setFieldValue(FIELD_NAMES.SEARCH, "");
    setSearchText("");
    setIsOpenDropdown(true);
  };

  const handleChange = () => {
    if (isSelectItem.current) {
      return;
    }
    setIsOpenDropdown(true);
  };

  const handleSelect = (item: TShumoku) => {
    setSearchText(item.shumokuName);
    form.setFieldValue(FIELD_NAMES.SEARCH, item.shumokuName);
    setIsOpenDropdown(false);
    isSelectItem.current = true;
  };

  const handleFormSubmit = async (values: TFormValues) => {
    const shumokuSetNumber = parseInt(values?.sets as string);
    const shumokuTimes = values?.rep + values?.option;

    // Using the matched shumoku in the options as a selected one
    const selectedShumoku = shumokuOptions.find(
      (item) => item.shumokuName.trim() === values?.search.trim()
    );

    if (!selectedShumoku) {
      form.setFields([
        {
          name: FIELD_NAMES.SEARCH,
          errors: [TRANSLATION.NOT_PROPER],
        },
      ]);
      return;
    }

    try {
      const data: TSubmitShumokuModalValue = {
        ...selectedShumoku,
        shumokuSetNumber,
        shumokuTimes,
      };

      onSubmit(data);
      setOpenModal(false);
    } catch (error: any) {
      // Handle form validation errors
      form.setFields([
        {
          name: FIELD_NAMES.SEARCH,
          errors: [error.message],
        },
      ]);
    }
  };

  // Effects
  useEffect(() => {
    if (openModal && initialShumoku) {
      form.setFieldsValue({
        [FIELD_NAMES.SEARCH]: initialShumoku.shumokuName,
        [FIELD_NAMES.SETS]: initialShumoku.shumokuSetNumber,
        [FIELD_NAMES.REP]: getNumberInString(
          initialShumoku.shumokuTimes as string
        ),
        [FIELD_NAMES.OPTION]: getTextOnly(
          initialShumoku?.shumokuTimes as string
        ),
      });
      setSearchText(initialShumoku.shumokuName || "");
    } else {
      const shumokuUnit = form.getFieldValue(FIELD_NAMES.OPTION);

      if (!shumokuUnit) {
        form.setFieldsValue({
          [FIELD_NAMES.OPTION]: ARRAY_SELECT_OPTION[0].value,
        });
      }
    }
  }, [initialShumoku, openModal]);

  // Effects
  useEffect(() => {
    if (!openModal) return;
    isSelectItem.current = false;

    const timerId = setTimeout(() => {
      handleSearch(searchText);
    }, DEFAULT_DEBOUNCE_TIME);

    return () => clearTimeout(timerId);
  }, [searchText, openModal]);

  // Partial render
  const renderOption = (item: TShumoku) => {
    // Highlight the matched text in the dropdown options
    const matchIndex = item.shumokuName
      .toLowerCase()
      .indexOf(searchText.toLowerCase());
    const title = (
      <span>
        {item.shumokuName.substr(0, matchIndex)}
        <span style={{ fontWeight: "bold", color: "var(--color-primary)" }}>
          {item.shumokuName.substr(matchIndex, searchText.length)}
        </span>
        {item.shumokuName.substr(matchIndex + searchText.length)}
      </span>
    );

    const majorItemName = (
      <span style={{ fontWeight: "bold" }}>({item.majorItemName})</span>
    );

    return (
      <AutoComplete.Option
        key={item.id}
        value={item.shumokuName}
        style={{ background: "var(--color-white)" }}
      >
        <OptionItemStyled onClick={() => handleSelect(item)}>
          <div className="option-item">
            <img
              src={item.thumbnail || images.ThumbnailImage}
              alt="Thumbnail"
              onError={replaceErrorImage}
            />
          </div>
          <span>
            {title} {majorItemName}
          </span>
        </OptionItemStyled>
      </AutoComplete.Option>
    );
  };

  const renderNoDataOption = (
    <AutoComplete.Option key="no-data" disabled>
      データなし
    </AutoComplete.Option>
  );

  const loadingOptionUI = (
    <AutoComplete.Option key="loading" disabled>
      <Spin
        style={{
          marginInline: "auto",
          display: "block",
        }}
      />
    </AutoComplete.Option>
  );

  const buttonSubmitUI = (
    <Button key="btn-submit" type="primary" htmlType="submit" form={formName}>
      種目を追加
    </Button>
  );

  return (
    <Fragment>
      {cloneElement(children, {
        onClick: () => {
          children.props.onClick?.();
          setOpenModal(true);
        },
      })}

      <SelectShumokuModalStyled>
        <Modal
          open={openModal}
          centered
          title="種目選択"
          onCancel={handleModalCancel}
          maskClosable={false}
          footer={[buttonSubmitUI]}
          width={600}
        >
          <ModalContentStyled>
            <Form form={form} name={formName} onFinish={handleFormSubmit}>
              {/* Shumoku name */}
              <Form.Item
                name={FIELD_NAMES.SEARCH}
                rules={[
                  {
                    required: true,
                    message: TRANSLATION.MESSAGE_REQUIRE_SHUMOKU_NAME,
                  },
                ]}
              >
                <AutoComplete
                  allowClear
                  open={isOpenDropdown}
                  dropdownMatchSelectWidth={false}
                  onSearch={(value) => setSearchText(value.trim())}
                  placeholder="種目を検索してください"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className={
                    form.getFieldError(FIELD_NAMES.SEARCH)?.length
                      ? "error-field"
                      : ""
                  }
                  defaultActiveFirstOption={true}
                  onClear={handleClearShumoku}
                >
                  {loadingShumokuOptions
                    ? loadingOptionUI
                    : shumokuOptions.length > 0
                    ? shumokuOptions.map(renderOption)
                    : renderNoDataOption}
                </AutoComplete>
              </Form.Item>

              {/* Reps */}
              <div style={{ display: "flex" }}>
                <div style={{ flex: "1 1 30%" }}>
                  <Form.Item name={FIELD_NAMES.OPTION}>
                    <Select options={ARRAY_SELECT_OPTION} />
                  </Form.Item>
                </div>
                <div style={{ flex: "1 1 70%" }}>
                  <Form.Item
                    name={FIELD_NAMES.REP}
                    rules={[
                      {
                        required: true,
                        message: TRANSLATION.MESSAGE_REQUIRE_SHUMOKU_REP,
                      },
                    ]}
                  >
                    <AppInputNumber
                      placeholder="数字で入力してください"
                      onlyNumber
                      maxLength={MAX_LENGTH_REPS}
                    />
                  </Form.Item>
                </div>
              </div>

              {/* Sets */}
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    flex: "1 1 30%",
                    border: "solid 1px #D9D9D9",
                    paddingLeft: "10px",
                    paddingTop: "3px",
                    background: "#FAFAFA",
                    height: "32px",
                  }}
                >
                  セット
                </div>
                <div style={{ flex: "1 1 70%" }}>
                  <Form.Item
                    name={FIELD_NAMES.SETS}
                    rules={[
                      {
                        required: true,
                        message: TRANSLATION.MESSAGE_REQUIRE_SHUMOKU_SETS,
                      },
                    ]}
                  >
                    <AppInputNumber
                      placeholder="数字で入力してください"
                      onlyNumber
                      maxLength={MAX_LENGTH_SETS}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </ModalContentStyled>
        </Modal>
      </SelectShumokuModalStyled>
    </Fragment>
  );
}

export { SelectShumokuModal };
