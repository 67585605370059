import styled from "styled-components";
import { Typography } from "antd";

export const ResetPasswordContainerStyled = styled.div``;

export const LogoStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 155px;
  margin-top: 25px;
  svg {
    width: 100%;
    max-height: 126px;
  }
`;

export const PasswordContainerStyled = styled.div`
  width: 100%;

  .btn-item {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
  }

  .custom-form-item .ant-form-item-explain {
    text-align: left;
  }

  .ant-input-password {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-normal);
  }
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleStyled = styled(Typography.Title)`
  font-size: var(--font-size-md) !important;
  font-weight: var(--font-weight-bold) !important;
  color: var(--color-black) !important;
`;

export const EyeIconContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;
