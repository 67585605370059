import { FC, ReactElement, useEffect } from "react";
import { ProgramMainLayoutStyled } from "./ProgramMainLayout.styled";

type TProps = {
  children: ReactElement;
  title?: string;
};

const ProgramMainLayout: FC<TProps> = ({ children, title }) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <ProgramMainLayoutStyled>
      <h2 className="title">{title}</h2>
      {children}
    </ProgramMainLayoutStyled>
  );
};

export { ProgramMainLayout };
