import { CreateIcon } from "constants/svgs";
import { Fragment, FC } from "react";
import { StoreCreateButtonStyle } from "./StoreCreateButton.styled";

type TProps = {
  handleOpenCreateFormModal: () => void;
};

const StoreCreateButton: FC<TProps> = ({ handleOpenCreateFormModal }) => {
  const handleClick = () => {
    handleOpenCreateFormModal();
  };

  return (
    <Fragment>
      <StoreCreateButtonStyle>
        <CreateIcon onClick={handleClick} />
      </StoreCreateButtonStyle>
    </Fragment>
  );
};
export { StoreCreateButton };
