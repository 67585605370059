import { createSlice } from "@reduxjs/toolkit";
import { TAction } from "types/common";
import {
  TMajorItem,
  TStore,
  TStoreMore,
  TUpdateShumokuReq,
} from "./../../model/shumoku";
import { TGetAllStoresParams } from "./shumokuApi";

type stateType = {
  loading: boolean;
  hasMore: boolean;
  selectAll: boolean;
  stores: TStore[];
  majorItems: TMajorItem[];
  selectedNumber: number;
  selectedStoreIds: string[];
  selectedShumokuMaterIds: string[];
};

const initialState: stateType = {
  loading: false,
  hasMore: false,
  selectAll: false,
  stores: [],
  majorItems: [],
  selectedNumber: 0,
  selectedStoreIds: [],
  selectedShumokuMaterIds: [],
};

const shumokuSlice = createSlice({
  name: "shumoku",
  initialState,
  reducers: {
    getAllStores(state, _action: TAction<TGetAllStoresParams>) {
      state.loading = true;
    },
    getAllStoresSuccess(state, action: TAction<TStoreMore>) {
      state.loading = false;
      state.stores = [...state.stores, ...action.payload.data.stores];
      state.hasMore = action.payload.data.hasMore;
    },
    getAllStoresFailure(state) {
      state.loading = false;
    },

    resetStores(state) {
      state.stores = [];
    },

    setSelectedNumber(state, action: TAction<number>) {
      state.selectedNumber = action.payload.data;
    },

    setSelectedStoreIds(state, action: TAction<string[]>) {
      state.selectedStoreIds = action.payload.data;
    },

    setSelectedShumokuMasterIds(state, action: TAction<string[]>) {
      state.selectedShumokuMaterIds = action.payload.data;
    },

    getAllShumokus(state) {
      state.loading = true;
    },
    getAllShumokusSuccess(state, action: TAction<TMajorItem[]>) {
      state.loading = false;
      state.majorItems = action.payload.data;
    },
    getAllShumokusFailure(state) {
      state.loading = false;
    },

    updateShumokuMaster(state, _action: TAction<TUpdateShumokuReq>) {
      state.loading = true;
    },
    updateShumokuMasterSuccess(state) {
      state.loading = false;
    },
    updateShumokuMasterFailure(state) {
      state.loading = false;
    },
    setSelectedAll(state, action: TAction<boolean>) {
      state.selectAll = action.payload.data;
    },
  },
});

const { actions, reducer } = shumokuSlice;

export { actions as shumokuActions, reducer as shumokuReducer };
