import styled from "styled-components/macro";

export const ButtonContainerStyled = styled.div`
  width: auto;
  padding: 16rem;

  background: var(--color-white);
  border: 1px solid #dddddd;

  display: flex;
  align-items: center;
  cursor: pointer;

  .iconLeftContainer {
    padding: 12px;
    width: 48px;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid #e9e9e9;
    background-color: #f5f5f5;
    color: var(--color-primary);
    font-size: 24px;
  }

  .titleContainer {
    flex: 1;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
  }

  .title {
    display: flex;
    color: var(--color-black);
    font-weight: 700;
    font-size: var(--font-size-md);
  }

  .iconRightContainer {
    color: var(--color-black);
    font-size: 16px;
  }
`;
