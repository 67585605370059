import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { CheckboxOptionType, ModalFuncProps } from "antd";
import dayjs from "dayjs";
import { PATHNAME_LIST } from "./pathname";

// empty string
export const EMPTY_STRING = "-";

// Time
export const REQUEST_TIME_OUT = 30000;
export const ONBOARDING_DELAY = 1000;

// Size
export const MAX_WIDTH_DEVICE = 1024;
export const DEFAULT_WIDTH_DESIGN = 768;
export const MODAL_CONFIRM_WIDTH = 460;

// Default value
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 1;
export const MIN_CHECK_IN_TIMES_FOR_NEXT_PHASE = 16;
export const MAX_LENGTH_REPS = 2;
export const MAX_LENGTH_SETS = 1;
export const DEFAULT_DEBOUNCE_TIME = 250;

// String
export const DATE_FORMAT = "YYYY/MM/DD";
export const ACCESS_TOKEN_TEXT = "accessToken";
export const REFRESH_TOKEN_TEXT = "refreshToken";
export const DEFAULT_SURVEY_PHASE_TEXT = "defaultSurveyPhase";
export const LOCATION_STACK_TEXT = "locationStack";
export const HAS_SHOW_ONBOARDING = "hasShowOnBoarding";
export const CONFIRM_MODAL_CLASS_NAME = "modal-confirm-custom";
export const CUSTOM_MODAL_CLASS_NAME = "modal-common-custom";
export const CUSTOM_TEXT_GRADIENT_CLASS_NAME = "custom-text-gradient";
export const DEFAULT_GUID = "00000000-0000-0000-0000-000000000000";
export const DATE_FORMAT_API = "YYYY-MM-DD";
export const MONTH_FORMAT = "YYYY-MM";
export const WEEK_FORMAT = "YYYY年-W週目";

// RegEx
export const NUMBER_REGEX = /^([0-9]{0,}[.])?[0-9]{0,}$/;
export const NUMBER_ONLY_REGEX = /^[0-9]{0,}$/;
export const NUMBER_REGEX_2_DECIMAL = /^[0-9]*(\.[0-9]{0,2})?$/;

// File Format
export const IMAGE_FORMAT = ["image/png", "image/jpg", "image/jpeg"];
export const VIDEO_FORMAT = ["video/mp4"];
// Other
export const DEFAULT_MODAL_PROPS = {
  CONFIRM: {
    centered: true,
    width: MODAL_CONFIRM_WIDTH,
    okText: "はい",
    cancelText: "いいえ",
    icon: <ExclamationCircleOutlined />,
    className: CONFIRM_MODAL_CLASS_NAME,
  } as ModalFuncProps,
  SUCCESS: {
    centered: true,
    width: MODAL_CONFIRM_WIDTH,
    okText: "Yes",
    icon: <CheckCircleOutlined />,
    className: CONFIRM_MODAL_CLASS_NAME,
  } as ModalFuncProps,
};

export enum EStatus {
  SUCCESS = "success",
  ERROR = "error",
}

export enum EMemberStatus {
  EXIST = "1",
  NOT_EXIST = "0",
}

export const TYPE_SHUMOKU_TIMES = {
  // number of repetitions
  NUMBER_OF_REPS: "回数",
  // times
  TIMES: "回",
  // duration
  SECOND: "秒",
  MINUTE: "分",
};

export const SELECT_OPTIONS_LABEL = {
  REPS: "回数",
  MINUTES: "分",
  SECONDS: "秒",
};

export const SELECT_OPTIONS_VALUE = {
  REPS: "回",
  MINUTES: "分",
  SECONDS: "秒",
};

export const ARRAY_SELECT_OPTION = [
  {
    value: SELECT_OPTIONS_VALUE.REPS,
    label: SELECT_OPTIONS_LABEL.REPS,
  },
  {
    value: SELECT_OPTIONS_VALUE.MINUTES,
    label: SELECT_OPTIONS_LABEL.MINUTES,
  },
  {
    value: SELECT_OPTIONS_VALUE.SECONDS,
    label: SELECT_OPTIONS_LABEL.SECONDS,
  },
];

const RANGE_YEAR = 5;
export const YEAR_OPTIONS: CheckboxOptionType[] = Array(RANGE_YEAR)
  .fill(0)
  .map((_m, index) => {
    const yearValue = dayjs().year() - RANGE_YEAR + index + 1;
    return {
      label: `${yearValue}年`,
      value: yearValue,
    };
  });
export const MONTH_OPTIONS: CheckboxOptionType[] = Array(12)
  .fill(0)
  .map((_m, index) => ({
    label: `${index + 1}月`,
    value: index,
  }));

export const getNumberInString = (str: string) => {
  const PATTERN = /\d+/;
  if (!str) return "";

  const matches = str.match(PATTERN);
  if (!matches) return "";

  return matches[0];
};

export const getStringInNumber = (str: string) => {
  const PATTERN = /[A-Za-z]+/g;
  if (!str) return "";

  const matches = str.match(PATTERN);
  if (!matches) return "";

  return matches.join("");
};

export const getTextOnly = (str: string) => {
  const PATTERN = /[0-9]/g;
  if (!str) return;

  const textOnly = str.replace(PATTERN, "");
  return textOnly;
};

export const HEADER_FORM_DATA = {
  "Content-Type": "multipart/form-data",
  "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTIONS",
};

export const PUBLIC_MEMBER_DETAIL_ACTION_PAGES = [
  PATHNAME_LIST.TRAINING_PROGRAM,
  PATHNAME_LIST.TRAINING_PROGRAM_DETAIL,
  PATHNAME_LIST.SURVEY_PHASE_INTERMEDIATE,
  PATHNAME_LIST.MEMBER_DETAIL,
];

export enum DatePickerType {
  Week = "week",
  Month = "month",
}
