import styled from "styled-components";

export const MemberConfirmedSurveyItemStyled = styled.div`
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-md);
  padding: 0 24rem;

  display: flex;
  align-items: center;

  .icon {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      fill: #000000d9;
    }
  }

  .content {
    margin-left: 4rem;
  }

  &:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
`;
