import { Area, AreaConfig, PlotEvent } from "@ant-design/plots";
import { CSSProperties, ReactNode, useId, useRef } from "react";
import { BodyIndexChartWrapperStyled } from "./BodyIndexChart.styled";

export type TBodyIndexChartItem = {
  date: string;
  value: number;
  [key: string]: any;
};

type TBodyIndexChartProps = {
  data: TBodyIndexChartItem[];
  config: { yField?: string; xField?: string; yUnit: string; height?: number };
  extraContent?: ReactNode;
};

function getDataRange(data: TBodyIndexChartItem[], yField: string, offset = 0) {
  if (data.length < 1) return [0, 0];

  const valueList = data.map((d) => d[yField]).sort((a, b) => a - b);

  return [valueList[0] - offset, valueList[valueList.length - 1] + offset];
}

function formatData(data: TBodyIndexChartItem[]): TBodyIndexChartItem[] {
  if (data.length < 1) return [];

  if (data.length === 1) {
    return [{ ...data[0], date: "" }, data[0]];
  }

  return data;
}

function BodyIndexChart(props: TBodyIndexChartProps) {
  const {
    data,
    config: { xField = "date", yField = "value", yUnit, height = 212 },
    extraContent,
  } = props;

  const id = useId();
  const chartClassNameRef = useRef(
    `body-index-chart-${id.replaceAll(":", "")}`
  );

  const [min, max] = getDataRange(data, yField);
  const step = (max - min) / data.length;
  const interval = step < 1 ? 0.5 : Math.round(step);
  const minValue =
    data.length > 0 ? Math.max(0, Math.floor(min - interval)) : 0;
  const maxValue = data.length > 0 ? Math.ceil(max + interval) : 1;

  const config: AreaConfig = {
    // ============ Plot Container ============
    renderer: "canvas", // canvas or svg
    height: height,
    // appendPadding: [0, 0, 0, 0],
    animation: false,

    // ============ Data Mapping ============
    data: formatData(data),
    xField: xField,
    yField: yField,

    // ============ Graphic Style ============
    areaStyle: {
      fill: "l(270) 0:#ff6b0020 1:#ff6b00",
      fillOpacity: 1,
    },
    line: {
      style: {
        lineWidth: 0,
      },
    },
    color: "#ff6b00", // theme color

    // ============ Plot Components ============
    xAxis: {
      range: [0, 0.85],
      line: {
        style: {
          width: 0,
          opacity: 0,
        },
      },
      grid: {
        line: {
          style: {
            stroke: "#F5F5F5",
            lineWidth: 1,
          },
        },
      },
      tickLine: null,
      label: {
        offsetY: 4,
        style: {
          fill: "#A3A3A3",
          fontSize: 12,
        },
      },
    },
    yAxis: {
      max: maxValue,
      min: minValue,
      tickInterval: interval,
      grid: {
        line: {
          style: {
            stroke: "#F0F0F0",
            lineWidth: 1,
            lineDash: [4, 5],
          },
        },
      },
      label: {
        style: {
          fill: "#A3A3A3",
          fontSize: 12,
        },
      },
    },
    tooltip: {
      showContent: true,
      customContent(_, data: any) {
        const value = data?.[0]?.value;
        return `${value}${yUnit}`;
      },
      follow: false,
      position: "top",
      offset: 14,
      domStyles: {
        "g2-tooltip": {
          padding: "6px 8px",
          backgroundColor: "var(--color-primary)",
          borderRadius: "var(--border-radius)",
          color: "var(--color-white)",
          fontFamily: "var(--font-family)",
          fontSize: "12px",
          lineHeight: "20px",
          opacity: "0",
        } as CSSProperties,
        "g2-tooltip-marker": {
          zIndex: "1000",
        } as CSSProperties,
      },
      showNil: true,
      shared: false,
      showCrosshairs: true,
      crosshairs: {
        line: { style: { stroke: "#17181D", strokeOpacity: 1 } },
      },
      showMarkers: true,
      marker: {
        fill: "#ffffff",
        stroke: "#ff6b00",
        lineWidth: 2.5,
      },
    },
    onEvent: (_chart, event: PlotEvent) => {
      // ! Using settimeout for case tooltip not show but want to get `tooltipDom`
      // ! Cause `antd chart` auto remove tooltip after move out chart
      setTimeout(() => {
        const tooltipDom: any = document.querySelector(
          `.${chartClassNameRef.current} .g2-tooltip`
        );
        if (event.type === "tooltip:show") {
          const { x: left, y: top } = event.data?.items?.[0] || {};
          if (tooltipDom) {
            tooltipDom.style.top = `${top}px`;
            tooltipDom.style.left = `${left}px`;
            tooltipDom.style.transform = "translate(-50%, calc(-100% - 16px))";
            tooltipDom.style.opacity = "1";
            tooltipDom.style.transition = "none";
          }
        }
      }, 0);
    },
  };

  return (
    <BodyIndexChartWrapperStyled>
      <Area {...config} className={chartClassNameRef.current} />
      <div className="extra-content">{extraContent}</div>
    </BodyIndexChartWrapperStyled>
  );
}

export { BodyIndexChart };
