import styled from "styled-components";

export const ComponentWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .direct-name {
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-md);
  }
  &: hover {
    cursor: pointer;
  }
`;
