import { PageContainerStyled } from "./MemberDetailPage.styled";
import { MemberInfo, MemberStatistic } from "./components";
import { MemberDetailPageContextProvider } from "./contexts/MemberDetailPageContext";

function MemberDetailPage() {
  return (
    <MemberDetailPageContextProvider>
      <PageContainerStyled>
        <MemberStatistic />

        <MemberInfo />
      </PageContainerStyled>
    </MemberDetailPageContextProvider>
  );
}

export { MemberDetailPage };
