export const TEXT_TRANSLATE = {
  TITLE: "トレーニングメニューを作成",
  DIET_TITLE: "トレーニングメニュー選択",
  SURVEY_TITLE: "SURVEY",
  LABEL_ID: "会員ID",
  VIEW_SURVEY: "アンケート表示",
  MODAL_SUCCESS_BUTTON1: "メニュー表示",
  MODAL_SUCCESS_BUTTON2: "会員詳細",
  MODAL_SUCCESS_TITLE: "トレーニングメニューを作成しました！",
  BTN_SAVE_PROGRAM: "保存",
};
