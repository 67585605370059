import { Modal } from "antd";
import styled from "styled-components";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    padding: 0;

    .ant-modal-close {
      width: 16px;
      height: 16px;

      top: 20rem;
      right: 24rem;

      > span {
        line-height: 16px;
      }
    }

    .ant-modal-header {
      margin: 0;
      padding: 16rem 24rem;

      font-weight: 500;
      border-bottom: 1px solid #f0f0f0;
    }
  }
`;
