import { Checkbox, Col } from "antd";
import { TStore } from "model/shumoku";
import React from "react";
import { StoreItemStyled } from "./StoreItem.styled";
import { TRANSLATION } from "pages/ShumokuManagementPage/ShumokuManagementPage.translate";

type TProps = {
  item: TStore;
  selectedIds: Set<unknown>;
  setSelectedIds?: React.Dispatch<React.SetStateAction<Set<unknown>>>;
  handleChange: (id: string) => void;
  handleOpenDetail: (id: string) => void;
};

const StoreItem = (props: TProps, ref: any) => {
  const { item, selectedIds, handleChange, handleOpenDetail } = props;

  let className = "";
  if (selectedIds.has(item.id)) {
    className = "active";
  }

  return (
    <Col xs={24} sm={12} xl={8}>
      <StoreItemStyled ref={ref} className={className}>
        <div className="store-info">
          <div
            className="store-name"
            onClick={() => {
              handleOpenDetail(item.id);
            }}
          >
            {item.storeName}
          </div>
          {item.isActive === true ? (
            <p className="active-store">{TRANSLATION.ACTIVE_STORE}</p>
          ) : (
            <p className="inactive-store">{TRANSLATION.INACTIVE_STORE}</p>
          )}
          <div className="store-id">店番ID: {item.storeNumber}</div>
        </div>
        <div className="store-check">
          <Checkbox
            checked={selectedIds.has(item.id)}
            onChange={() => handleChange(item.id)}
          />
        </div>
      </StoreItemStyled>
    </Col>
  );
};

const StoreItemMemo = React.memo(React.forwardRef(StoreItem));

export { StoreItemMemo };
