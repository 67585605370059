import { Collapse } from "antd";
import styled from "styled-components";

export const LayoutContainerStyled = styled.div`
  background-position: top right;
  background-size: min(100%, 768px);
  background-repeat: no-repeat;

  padding: 25px 20px 25px 24px;
`;

export const MainContentStyled = styled.div`
  margin-top: 135rem;

  display: flex;
  flex-direction: row;
  gap: 40rem;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const ButtonBackStyled = styled.div`
  cursor: pointer;
  width: fit-content;
  font-size: var(--font-size-md);
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const PageTitleStyled = styled.p`
  font-weight: 700;
  font-size: clamp(32px, 40rem, 40px);
  color: #17181d;

  @media only screen and (max-width: 480px) {
    text-align: center;

    br {
      display: none;
    }
  }
`;

export const CollapseStyled = styled(Collapse)`
  flex: 1;
  height: fit-content;

  &&& .ant-collapse-header,
  .ant-collapse-content {
    color: #333333;
  }
`;
