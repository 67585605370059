import { SurveyManagementPageStyled } from "./SurveyManagementPage.styled";
import { ButtonAddSurveyPart, SurveyHeader, SurveyPart } from "./components";
import { SurveyManagementPageContextProvider } from "./contexts";

function SurveyManagementPage() {
  return (
    <SurveyManagementPageContextProvider>
      <SurveyManagementPageStyled>
        <SurveyHeader />

        <SurveyPart />

        <ButtonAddSurveyPart />
      </SurveyManagementPageStyled>
    </SurveyManagementPageContextProvider>
  );
}

export { SurveyManagementPage };
