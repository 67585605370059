import { Tag } from "antd";
import styled from "styled-components";

export const PhaseTagStyled = styled(Tag)`
  padding: 1px 8px;

  font-weight: 400;
  line-height: 20px;
  border: none;
  background-color: var(--color-primary);
  color: var(--color-white);
`;
