import { TProgramMenuType } from "constants/program";
import { FC } from "react";
import { MenuItemStyled } from "./MenuItem.styled";

type TProps = {
  item: TProgramMenuType;
  active: number | null;
  setActive: React.Dispatch<React.SetStateAction<number | null>>;
  handleSelectProgramMenu: (programMenu: number) => void;
};

const MenuItem: FC<TProps> = ({
  item,
  active,
  setActive,
  handleSelectProgramMenu,
}) => {
  const className = active === item.id ? "active" : "";

  const handleMenuItemClick = () => {
    setActive(item.id);
    handleSelectProgramMenu(item.id);
  };
  return (
    <MenuItemStyled className={className} onClick={handleMenuItemClick}>
      <div className="name-item">
        <div className="icon">
          <span>{item?.icon}</span>
        </div>
        <div>{item?.name}</div>
      </div>
    </MenuItemStyled>
  );
};

export { MenuItem };
