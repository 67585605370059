import { Button, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { Question } from "components";
import { DEFAULT_GUID } from "constants/common";
import { TFormProps } from "layouts";
import { SURVEY_LAYOUT_TRANSLATE } from "layouts/SurveyLayout/SurveyLayout.translate";
import { TQuestion, TQuestionFormItem } from "model/survey";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { FormTitle } from "../FormTitle";
import {
  ButtonSubmitContainerStyled,
  FormContainerStyled,
  FormStyled,
} from "./SurveyForm.styled";

export type TSurveyFormValue = {
  questionList: TQuestionFormItem[];
};

type TSurveyFormProps = TFormProps<TQuestionFormItem[]> & {
  questions: TQuestion[];
  formName?: string;
  submitText?: string;
  readOnly?: boolean;
  formClassName?: string;
};

type TDependQuestion = {
  dependAnswer: string;
  dependQuestionId: string;
  blockQuestionId: string;
};

// !Name for form must be the same with prop's name in type TSurveyFormValue, TQuestionFormItem
const FORM_ITEM_NAME = {
  QUESTION_LIST: "questionList",
  QUESTION_INFO: "questionInfo",
  QUESTION_ANSWER: "questionAnswer",
  QUESTION_IS_REQUIRED: "isRequired",
  QUESTION_IS_DISABLED: "disabled",
};

function countAnsweredQuestion(values?: TQuestionFormItem[]) {
  if (!values) return 0;

  const answeredList = values.filter((ques) => !!ques.questionAnswer);

  return answeredList.length;
}

function getDependQuestions(questions: TQuestionFormItem[]): TDependQuestion[] {
  return questions
    .filter(
      (q) =>
        q.questionInfo.dependQuestionId &&
        q.questionInfo.dependQuestionId !== DEFAULT_GUID &&
        q.questionInfo.dependAnswer
    )
    .map((q) => ({
      dependAnswer: q.questionInfo.dependAnswer,
      dependQuestionId: q.questionInfo.dependQuestionId,
      blockQuestionId: q.questionInfo.id,
    }));
}

function SurveyForm(props: TSurveyFormProps) {
  const {
    onNext,
    initialValues,
    questions,
    formIndex,
    title,
    maxStep,
    formName,
    submitText,
    readOnly,
    formClassName,
  } = props;

  const [form] = useForm<TSurveyFormValue>();
  const [totalAnswered, setTotalAnswered] = useState(0);
  // !State using when want to force render component
  const [_, setRenderCounter] = useState(0);

  const dependQuestionListRef = useRef<TDependQuestion[]>([]);

  const handleFinish = (values: TSurveyFormValue) => {
    onNext?.(values.questionList);
  };

  const onQuestionChange = () => {
    const values = form.getFieldsValue();
    setTotalAnswered(countAnsweredQuestion(values.questionList));
  };

  // Update `is required` of the question depending on other question
  const handleWhenDependQuestionChange = () => {
    const { questionList }: TSurveyFormValue = form.getFieldsValue();

    for (let i = 0; i < dependQuestionListRef.current.length; i++) {
      const { blockQuestionId, dependAnswer, dependQuestionId } =
        dependQuestionListRef.current[i];

      const dependQuestion = questionList.find(
        (q) => q.questionInfo.id === dependQuestionId
      );
      const blockQuestionIndex = questionList.findIndex(
        (q) => q.questionInfo.id === blockQuestionId
      );

      // skip if has no data
      if (!dependQuestion || blockQuestionIndex === -1) continue;

      const isAnswerYes =
        dependQuestion.questionAnswer?.answer?.includes(dependAnswer);

      // Update isRequired and disabled status for block question
      form.setFields([
        {
          name: [
            FORM_ITEM_NAME.QUESTION_LIST,
            blockQuestionIndex,
            FORM_ITEM_NAME.QUESTION_INFO,
          ],
          value: {
            ...questionList[blockQuestionIndex].questionInfo,
            isRequired: typeof isAnswerYes === "boolean" ? isAnswerYes : false,
            disabled: isAnswerYes === false,
          } as TQuestion,
        },
      ]);

      // !Re-render form to show required mark
      setRenderCounter((prev) => prev + 1);

      // Clear error and update value for blocked question
      form.setFields([
        {
          name: [
            FORM_ITEM_NAME.QUESTION_LIST,
            blockQuestionIndex,
            FORM_ITEM_NAME.QUESTION_ANSWER,
          ],
          errors: [],
          value:
            isAnswerYes === false
              ? undefined
              : questionList[blockQuestionIndex].questionAnswer,
        },
      ]);
    }
  };

  // Effects
  useEffect(() => {
    let formValues: TQuestionFormItem[] = initialValues || [];

    if (formValues.length === 0 && questions.length !== 0) {
      formValues = questions.map((ques) => ({
        questionInfo: ques,
      }));
    }

    form.setFieldValue([FORM_ITEM_NAME.QUESTION_LIST], formValues);
    setTotalAnswered(countAnsweredQuestion(formValues));

    // Update depend question list
    dependQuestionListRef.current = getDependQuestions(formValues);
    // update block question required mark
    handleWhenDependQuestionChange();
  }, [initialValues]);

  const totalQuestion = useMemo(() => questions.length, [questions]);

  return (
    <FormContainerStyled>
      {!readOnly && (
        <FormTitle
          formIndex={formIndex}
          title={title}
          extraContent={`${totalAnswered}/${totalQuestion}`}
          maxStep={maxStep}
        />
      )}

      <FormStyled
        onFinish={handleFinish}
        form={form}
        name={formName}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
        className={formClassName}
        onChange={handleWhenDependQuestionChange}
      >
        <Form.List name={FORM_ITEM_NAME.QUESTION_LIST}>
          {(fields) => (
            <Fragment>
              {fields.map((field, index) => {
                const question: TQuestion = form.getFieldValue([
                  FORM_ITEM_NAME.QUESTION_LIST,
                  field.name,
                  FORM_ITEM_NAME.QUESTION_INFO,
                ]);

                return (
                  <Fragment key={index}>
                    {/* Add a hidden form item to save question info */}
                    <Form.Item
                      name={[field.name, FORM_ITEM_NAME.QUESTION_INFO]}
                      hidden
                    >
                      <input />
                    </Form.Item>

                    <Form.Item
                      key={question.id}
                      name={[field.name, FORM_ITEM_NAME.QUESTION_ANSWER]}
                      rules={[
                        {
                          required: question.isRequired,
                          message:
                            SURVEY_LAYOUT_TRANSLATE.MESSAGE_QUESTION_REQUIRED,
                        },
                      ]}
                    >
                      <Question
                        key={question.id}
                        data={question}
                        index={index + 1}
                        onChange={() => onQuestionChange()}
                        readOnly={readOnly}
                        disabled={question.disabled}
                      />
                    </Form.Item>
                  </Fragment>
                );
              })}
            </Fragment>
          )}
        </Form.List>
      </FormStyled>

      {!readOnly && (
        <ButtonSubmitContainerStyled>
          <Button htmlType="submit" type="primary" form={formName}>
            {submitText || SURVEY_LAYOUT_TRANSLATE.NEXT_STEP}
          </Button>
        </ButtonSubmitContainerStyled>
      )}
    </FormContainerStyled>
  );
}

export { SurveyForm };
