import { FC } from "react";
import { ShumokuMasterDeleteButtonStyle } from "./ShumokuMasterDeleteButton.styled";
import { DeleteOutlined } from "@ant-design/icons";
import { useAppLoading, useAppModal } from "contexts";
import { DEFAULT_MODAL_PROPS } from "constants/common";
import { TRANSLATION } from "pages/ShumokuMaster/ShumokuMaster.translate";
import shumokuApi from "features/shumoku/shumokuApi";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import { useDispatch } from "react-redux";

type TProps = {
  selectedIds: Set<string> | undefined;
  handleOk: () => void;
};

const ShumokuMasterDeleteButton: FC<TProps> = ({ selectedIds, handleOk }) => {
  const { appModal } = useAppModal();
  const { startLoading, stopLoading } = useAppLoading();
  const dispatch = useDispatch();

  const handleClick = async () => {
    if (selectedIds?.size === 0 || !selectedIds) {
      appModal.confirm({
        ...DEFAULT_MODAL_PROPS.CONFIRM,
        title: TRANSLATION.PLEASE_SELECT,
        okText: TRANSLATION.BACK,
        cancelButtonProps: { style: { display: "none" } },
      });
    } else {
      startLoading();
      //check Shumoku has already been added to the Store and Programs
      const alreadyShumukuId = await checkAlreadyShumoku(selectedIds);
      stopLoading();

      if (alreadyShumukuId.size === 0) {
        appModal.confirm({
          ...DEFAULT_MODAL_PROPS.CONFIRM,
          title: TRANSLATION.CONFIRM_DELETE,
          onOk: () => {
            handleDeleteShumoku(selectedIds);
          },
        });
      } else {
        // display message error
        const alreadyShumukuIdList = Array.from(alreadyShumukuId);
        let shumokuCodeDelete = "";
        alreadyShumukuIdList.forEach(
          (storeNumber: string, index: number, array: string[]) => {
            if (index === array.length - 1) {
              shumokuCodeDelete += `${storeNumber}`;
            } else {
              shumokuCodeDelete += `${storeNumber}、`;
            }
          }
        );
        const warningMessage = `${shumokuCodeDelete}ストアは既に、ストア/プログラムに追加されたので、削除出来ません`;
        appModal.confirm({
          ...DEFAULT_MODAL_PROPS.CONFIRM,
          title: warningMessage,
          okText: TRANSLATION.BACK,
          cancelButtonProps: { style: { display: "none" } },
        });
      }
    }
  };

  const handleDeleteShumoku = async (shumokuDeleteIds: Set<string>) => {
    startLoading();
    const shumokuIds = Array.from(shumokuDeleteIds);
    try {
      const deletedStore = await shumokuApi.deleteShumoku({
        shumokuIds,
      });
      if (deletedStore) {
        dispatch(shumokuActions.getAllShumokus());

        handleOk();
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      startLoading();
    }
  };

  // Check the store is in use
  const checkAlreadyShumoku = async (shumokuIds: Set<string>) => {
    const alreadyShumuku = new Set<string>();
    try {
      for (const shumokuId of shumokuIds) {
        const shumoku = await shumokuApi.getShumokuMasterById(shumokuId);
        const shumokuStores = shumoku?.data?.shumokuStores;
        const programMasters = shumoku?.data?.programMasters;

        if (shumokuStores && shumokuStores?.length > 0) {
          alreadyShumuku.add(shumoku.data?.shumokuCode);
        }
        if (programMasters && programMasters?.length > 0) {
          alreadyShumuku.add(shumoku.data?.shumokuCode);
        }
      }
    } catch (error: any) {
      console.log(error);
    }
    return alreadyShumuku;
  };

  return (
    <ShumokuMasterDeleteButtonStyle onClick={handleClick}>
      <DeleteOutlined />
    </ShumokuMasterDeleteButtonStyle>
  );
};
export { ShumokuMasterDeleteButton };
