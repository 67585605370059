import { MemberConfirmPattenUrl } from "constants/svgs";
import styled from "styled-components";

export const LayoutContainerStyled = styled.div`
  min-height: 100vh;
  min-height: 100dvh;
  position: relative;

  display: flex;
  flex-direction: column;

  background-color: var(--color-background);
  background-image: url(${MemberConfirmPattenUrl});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
`;

export const TopContentStyled = styled.div`
  padding-top: 18rem;
  flex: 1;

  .logo {
    display: block;
    margin: auto;
    height: 87rem;
    cursor: pointer;
  }
`;

export const BackgroundContainerStyled = styled.div`
  background-color: var(--color-background);
`;

export const MainContentStyled = styled.div`
  margin-top: 14rem;

  display: flex;
  justify-content: center;

  .content {
    flex: 1;
    margin-inline: 63rem;
  }
`;

export const MememberImageContainerStyled = styled.div`
  width: 100%;
  margin-top: -50rem;
  display: flex;
  img {
    width: 100%;
    object-fit: contain;
  }
`;
