import styled from "styled-components";

export const MajorItemManagementStyled = styled.div`
  min-height: 440px;
`;

export const MajorItemTableStyled = styled.div`
  margin-top: 10rem;
  .ant-table
    .ant-table-container
    .ant-table-content
    table
    thead.ant-table-thead
    .ant-table-cell {
    background-color: var(--color-primary-fade);
    color: var(--color-white);
  }
  .action-button {
    color: var(--color-primary);
    margin: 0 5px;
  }
  .major-item-code {
    max-width: 240px;
    word-wrap: break-word;
  }
  .major-item-name {
    max-width: 160px;
    word-wrap: break-word;
  }
`;
