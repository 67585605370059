import { Button, Col } from "antd";
import styled from "styled-components";

export const RESPONSIVE_SIZE = "700px";

export const PageContainerStyled = styled.div`
  min-height: 100vh;
  min-height: 100dvh;

  display: flex;
  padding-right: 264rem;

  @media only screen and (max-width: ${RESPONSIVE_SIZE}) {
    flex-direction: column-reverse;
    padding-right: 0;
  }
`;

// ========================================== Member statistic ==========================================
export const StatisticWrapperStyled = styled.div`
  flex: 1;
  padding: 24px;
  padding-right: 36px;
  background-color: #f4f4f4;

  .main-content {
    margin-top: 24rem;
    margin-bottom: 8px;
  }

  @media only screen and (max-width: ${RESPONSIVE_SIZE}) {
    padding-right: 24px;

    .btn-back {
      position: absolute;
      z-index: 1;
      top: 25px;
      left: 24px;

      color: var(--color-white);
      fill: var(--color-white);
    }
  }
`;

export const ButtonDetailStyled = styled(Button)`
  min-width: 104rem;

  background-color: #fff1e8;
  color: var(--color-primary);
  border: none;
`;

export const InfoSectionWrapperStyled = styled(Col)`
  .title {
    font-weight: 700;
    color: #b0b0b0;
    font-size: var(--font-size-md);
  }

  .section-content {
    margin-top: max(8px, 8rem);
  }

  .section-list {
    display: flex;
    flex-direction: column;
    gap: max(12px, 12rem);
  }
`;
