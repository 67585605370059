import { ThunderboltFilled, ToolFilled } from "@ant-design/icons";
import { ProgramMenuType } from "constants/program";
import { AppleIcon, ArmIcon, PersonIcon } from "constants/svgs";
import { ReactElement } from "react";
import { DietItem } from "../DietItem/DietItem";
import { DietListStyled } from "./DietList.styled";

export type TDiet = {
  id: number;
  icon: ReactElement;
  name: string;
  value: number;
};

export const DIETS = [
  {
    id: ProgramMenuType.Diet,
    icon: <AppleIcon />,
    name: "ダイエット",
    value: ProgramMenuType.Diet,
  },
  {
    id: ProgramMenuType.BodyMakeup,
    icon: <ThunderboltFilled />,
    name: "シェイプアップ",
    value: ProgramMenuType.BodyMakeup,
  },
  {
    id: ProgramMenuType.BulkUp,
    icon: <ArmIcon />,
    name: "筋肥大",
    value: ProgramMenuType.BulkUp,
  },
  {
    id: ProgramMenuType.MuscleStrength,
    icon: <PersonIcon />,
    name: "姿勢の改善",
    value: ProgramMenuType.MuscleStrength,
  },
  {
    id: ProgramMenuType.HealthMaintenanceAndPromotion,
    icon: <ToolFilled />,
    name: "健康維持促進",
    value: ProgramMenuType.HealthMaintenanceAndPromotion,
  },
];

const DietList = () => {
  return (
    <DietListStyled>
      {DIETS.map((item) => (
        <DietItem item={item} key={item.id} />
      ))}
    </DietListStyled>
  );
};

export { DietList };
