import { UploadFile } from "antd";
import {
  DATE_FORMAT,
  IMAGE_FORMAT,
  VIDEO_FORMAT,
  PUBLIC_MEMBER_DETAIL_ACTION_PAGES,
} from "constants/common";
import images from "constants/images";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { MouseEvent } from "react";
dayjs.extend(utc);

export function checkIsNullOrUndefined(val: any) {
  return val === null || val === undefined;
}

export function dayjsLocalFromUTC(date?: any) {
  return dayjs(date, { utc: true }).local();
}

export function getFormattedDateFromUTC(date?: any, format = DATE_FORMAT) {
  return dayjs(date, { utc: true }).local().format(format);
}

export function getISOStringDate(date?: any): string {
  return dayjs(date).toISOString();
}

export function calculateBMI({
  height,
  weight,
}: {
  height?: number;
  weight?: number;
}) {
  if (!height || !weight) return 0;

  const heightInMeter = height / 100;
  return weight / (heightInMeter * heightInMeter);
}

export function toFixedNumber(value: any, numDigitsAfterComma = 2) {
  const number = parseFloat(value);

  const power = Math.pow(10, numDigitsAfterComma);

  if (!isNaN(number) && typeof number === "number") {
    if (number % 1 === 0) return number;

    return Math.round(number * power) / power;
  }

  return value;
}

export function numberWithCommas(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberWithKFormat(number: number) {
  return Math.abs(number) > 999
    ? Math.sign(number) * toFixedNumber(Math.abs(number) / 1000, 1) + "k"
    : Math.sign(number) * Math.abs(number);
}

export function replaceErrorImage({
  currentTarget,
}: MouseEvent<HTMLImageElement>) {
  currentTarget.onerror = null; // prevents looping
  currentTarget.src = images.ThumbnailImage;
}

export const validateFileType = (
  { type }: UploadFile,
  allowedTypes?: string[]
) => {
  if (!allowedTypes) {
    return true;
  }

  if (type) {
    return allowedTypes.includes(type);
  }
};

export const validateImageFileType = (file: UploadFile) => {
  const isAllowedType = validateFileType(file, IMAGE_FORMAT);
  if (!isAllowedType) {
    return false;
  }
  return true;
};

export const validateVideoFileType = (file: UploadFile) => {
  const isAllowedType = validateFileType(file, VIDEO_FORMAT);
  if (!isAllowedType) {
    return false;
  }
  return true;
};

export const checkPreLoginPath = (prePathLogin: string) => {
  const prePathLoginStarts = prePathLogin.split("/")[1];
  for (const path of PUBLIC_MEMBER_DETAIL_ACTION_PAGES) {
    if (path.includes(prePathLoginStarts)) {
      return true;
    }
  }
  return false;
};

export const convertFileToBinary = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const binaryData = reader.result;
      resolve(binaryData);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};
