import styled from "styled-components";

export const SurveyItemWrapperStyled = styled.div`
  padding: 16px;

  display: flex;
  align-items: center;
  gap: 12px;

  border: var(--border-config);
  color: var(--color-black-2);
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 12px;
  }

  .icon-survey-wrapper {
    width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #f5f5f5;
    border: 1px solid #e9e9e9;

    svg {
      font-size: 22px;
    }
  }

  .survey-info {
    flex: 1;

    .survey-name {
      font-weight: 700;
      font-size: var(--font-size-md);
    }

    .survey-date {
      font-size: 13px;
    }
  }

  .icon-right-wrapper {
    svg {
      font-size: 16px;
    }
  }
`;
