import { useAppSelector } from "hooks";
import {
  MajorItemManagementStyled,
  MajorItemTableStyled,
} from "./MajorItemManagement.styled";
import { TRANSLATION } from "./MajorItemManagement.translate";
import { Button, Space } from "antd";
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { TMajorItem } from "model/shumoku";
import { useState } from "react";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { useAppModal } from "contexts";
import { DEFAULT_MODAL_PROPS } from "constants/common";
import shumokuApi from "features/shumoku/shumokuApi";
import { useDispatch } from "react-redux";
import { shumokuActions } from "features/shumoku/shumokuSlice";

type TProps = {
  handleOpenCreateModal: () => void;
  handleOpenUpdateModal: (editMajorItem: TMajorItem) => void;
};

const DEFAULT_PAGINATION_PROPS = { current: 1, pageSize: 5 };

const MajorItemManagement = (props: TProps) => {
  const { handleOpenCreateModal, handleOpenUpdateModal } = props;
  const { majorItems } = useAppSelector((state) => state.shumoku);
  const [pagination, setPagination] = useState<TablePaginationConfig>(
    DEFAULT_PAGINATION_PROPS
  );
  const { appModal } = useAppModal();
  const dispatch = useDispatch();

  const handleDeleteMajorItem = async (id: string) => {
    try {
      const deletedMajorItem = await shumokuApi.deleteMajorItemMaster(id);
      if (deletedMajorItem) {
        dispatch(shumokuActions.getAllShumokus());
        appModal.confirm({
          ...DEFAULT_MODAL_PROPS.SUCCESS,
          width: 520,
          title: TRANSLATION.DELETE_SUCCESS_NOTIFICATION,
          okText: TRANSLATION.YES,
          icon: <CheckCircleOutlined style={{ color: "#52C41A" }} />,
          cancelButtonProps: { style: { display: "none" } },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmDeleteMajorItem = (shumokuDelete: TMajorItem) => {
    if (shumokuDelete?.shumokus?.length > 0) {
      const warningMessage = `大項目マスター(${shumokuDelete.majorItemName}）を削除出来ません、すでに種目が存在しています。`;
      appModal.confirm({
        ...DEFAULT_MODAL_PROPS.CONFIRM,
        title: warningMessage,
        okText: TRANSLATION.BACK,
        cancelButtonProps: { style: { display: "none" } },
      });
    } else {
      appModal.confirm({
        ...DEFAULT_MODAL_PROPS.CONFIRM,
        title: TRANSLATION.CONFIRM_DELETE,
        onOk: () => {
          handleDeleteMajorItem(shumokuDelete.id);
        },
      });
    }
  };

  const handlePaginationChange = (newPagination: TablePaginationConfig) => {
    setPagination(newPagination);
  };

  const columns: ColumnsType<TMajorItem> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: "50px",
      render: (_, __, index) =>
        ((pagination?.current || 1) - 1) * (pagination?.pageSize || 10) +
        index +
        1,
    },
    {
      title: `${TRANSLATION.MAJOR_ITEM_CODE}`,
      dataIndex: "majorItemCode",
      key: "majorItemCode",
      width: "240px",
      className: "major-item-code",
    },
    {
      title: `${TRANSLATION.MAJOR_ITEM_NAME}`,
      dataIndex: "majorItemName",
      key: "majorItemName",
      width: "160px",
      className: "major-item-name",
    },
    {
      title: `${TRANSLATION.ACTION}`,
      key: "action",
      width: "100px",
      align: "center",
      render: (_, record) => (
        <Space>
          <EditOutlined
            className="action-button"
            onClick={() => handleOpenUpdateModal(record)}
          />
          <DeleteOutlined
            className="action-button"
            onClick={() => handleConfirmDeleteMajorItem(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <MajorItemManagementStyled>
      <Button
        type="primary"
        className="major-item-management"
        onClick={handleOpenCreateModal}
      >
        <FileAddOutlined />
        {TRANSLATION.MAJOR_ITEM_CREATE}
      </Button>

      <MajorItemTableStyled>
        <Table
          rowKey="id"
          bordered
          columns={columns}
          dataSource={majorItems}
          pagination={{
            ...pagination,
          }}
          onChange={handlePaginationChange}
        />
      </MajorItemTableStyled>
    </MajorItemManagementStyled>
  );
};

export { MajorItemManagement };
