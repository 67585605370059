import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { AlertStyled } from "./MemberAction.styled";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import {
  SurveyPhaseIntermediateRoute,
  TrainingProgramRoute,
} from "routers/router";
import { programActions } from "features/program/programSlice";
import { useDispatch } from "react-redux";
import { useMemberDetailPageContext } from "pages/PublicMemberDetailPage/contexts";

export enum EMemberActionStatus {
  NEED_CREATE_PROGRAM,
  NEED_DO_SURVEY_PHASE_2,
  NEED_CREATE_PROGRAM_PHASE_2,
}

type TMemberActionProps = {
  type: EMemberActionStatus;
  memberId: string;
};

const ACTION_CONFIGS = {
  [EMemberActionStatus.NEED_CREATE_PROGRAM]: {
    icon: <ExclamationCircleOutlined style={{ color: "#FF6B00" }} />,
    message: "トレーニングメニューが存在していません。",
    buttonText: "新しいメニューを作成",
    color: "#FF6B00",
    borderColor: "#FF6B00",
    bgColor: "#FFF1E8",
  },
  [EMemberActionStatus.NEED_DO_SURVEY_PHASE_2]: {
    icon: <CheckCircleOutlined style={{ color: "#52C41A" }} />,
    message: "次のアンケートへ進む",
    buttonText: "アンケート実施",
    color: "#52C41A",
    borderColor: "#B7EB8F",
    bgColor: "#F6FFED",
  },
  [EMemberActionStatus.NEED_CREATE_PROGRAM_PHASE_2]: {
    icon: <ExclamationCircleOutlined style={{ color: "#FF6B00" }} />,
    message: "新しいメニューを作成してください。",
    buttonText: "作成",
    color: "#FF6B00",
    borderColor: "#FF6B00",
    bgColor: "#FFF1E8",
  },
};

function MemberAction(props: TMemberActionProps) {
  const { type, memberId } = props;
  const { message, buttonText, icon, color, borderColor, bgColor } =
    ACTION_CONFIGS[type];

  const { phaseNumber } = useMemberDetailPageContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleActionClick = () => {
    if (type === EMemberActionStatus.NEED_DO_SURVEY_PHASE_2) {
      navigate(SurveyPhaseIntermediateRoute.genPath({ memberId }));
    }

    if (
      type === EMemberActionStatus.NEED_CREATE_PROGRAM ||
      type === EMemberActionStatus.NEED_CREATE_PROGRAM_PHASE_2
    ) {
      dispatch(
        programActions.setProgramSurvey({
          data: {
            programMenu: 0,
            frequency: 0,
            duration: 0,
            phaseNumber: 0,
            hasOther: null,
            questionId: "",
          },
        })
      );
      dispatch(programActions.setViewSurvey({ data: false }));
      dispatch(programActions.setIsKeepValue({ data: false }));
      navigate(TrainingProgramRoute.genPath({ memberId, phaseNumber }));
    }
  };

  return (
    <AlertStyled
      message={message}
      description={
        <Button
          type="primary"
          onClick={handleActionClick}
          style={{ backgroundColor: color }}
        >
          {buttonText}
        </Button>
      }
      icon={icon}
      showIcon
      style={{ borderColor, backgroundColor: bgColor }}
    />
  );
}

export { MemberAction };
