import { useAppSelector, useScrollLock } from "hooks";
import { TBodyIndexInfo, TMemberDetail } from "model/member";
import { Fragment, ReactElement, cloneElement, useState } from "react";
import { ModalStyled } from "./BodyIndexModal.styled";
import { BodyIndexForm } from "./components";
import { useNavigate } from "react-router-dom";
import { PATHNAME_LIST } from "constants/pathname";

export type TBodyIndexModalProps = {
  onSubmit: (value: TBodyIndexInfo) => void;
  children: ReactElement;
  defaultOpen?: boolean;
  title: string;
  submitText?: string;
  initialValues?: TBodyIndexInfo;
  memberDetail?: TMemberDetail | undefined;
};

function BodyIndexModal(props: TBodyIndexModalProps) {
  const {
    children = <Fragment />,
    defaultOpen = false,
    title,
    onSubmit,
    memberDetail,
    ...restProps
  } = props;

  const [openModal, setOpenModal] = useState(defaultOpen);
  useScrollLock(openModal);
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  const { role: userRole } = user || {};

  const handleOpenModal = () => {
    // Not has user info => login page
    if (!userRole && memberDetail) {
      navigate(
        PATHNAME_LIST.MEMBER_DETAIL.replace(":memberId", memberDetail.id)
      );
    }
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const handleSubmit = (value: TBodyIndexInfo) => {
    onSubmit(value);
    handleCloseModal();
  };

  return (
    <Fragment>
      {cloneElement(children, {
        onClick: () => {
          children.props.onClick?.();
          handleOpenModal();
        },
      })}

      <ModalStyled
        open={openModal}
        onCancel={handleCloseModal}
        title={title}
        footer={null}
        centered
        destroyOnClose
        maskClosable={false}
        width={572}
      >
        <BodyIndexForm onSubmit={handleSubmit} {...restProps} />
      </ModalStyled>
    </Fragment>
  );
}

export { BodyIndexModal };
