import { ReactNode } from "react";
import { ButtonContainerStyled } from "./ProfileButtonItem.styled";
import { RestFilled, RightOutlined } from "@ant-design/icons";

type TProfileButtonItemProps = {
  iconLeft?: ReactNode;
  title: string;
  onClick?: () => void;
  className?: string;
  iconRight?: ReactNode;
};

const ProfileButtonItem = (props: TProfileButtonItemProps) => {
  const {
    title,
    onClick,
    className,
    iconLeft = <RestFilled />,
    iconRight = <RightOutlined />,
  } = props;

  return (
    <ButtonContainerStyled className={className} onClick={onClick}>
      <div className="iconLeftContainer">{iconLeft}</div>
      <div className="titleContainer">
        <span className="title">{title}</span>
      </div>
      <div className="iconRightContainer">{iconRight}</div>
    </ButtonContainerStyled>
  );
};

export { ProfileButtonItem };
