import { PageWrapper } from "components";
import { TLoginLayoutProps } from "layouts";
import { EAccessRole, EUserRoles } from "model/user";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import UnLoginRoute from "./UnLoginRoute";
import { appRoutes } from "./router";

export type TRouteType = {
  id: string;
  path: string;
  accessRoles?: EUserRoles[] | EAccessRole;
  accessHomePage?: boolean;
  fallbackPath?: string; // Redirect to this path if user do not has permission access page

  component: React.ElementType | null;
  layout?: React.ElementType;
  layoutProps?: { [key in keyof Partial<TLoginLayoutProps>]: any };
  title: string;
};

export type TRouteDynamicPathType<P = any> = TRouteType & {
  genPath: (params: P) => string;
};

function renderRoute(route: TRouteType) {
  const {
    component: Component,
    accessRoles,
    accessHomePage,
    layout: Layout,
    fallbackPath,
    layoutProps,
  } = route;

  const componentUI = (
    <PageWrapper title={route.title} accessHomePage={accessHomePage}>
      {Layout ? (
        <Layout {...layoutProps}>{Component && <Component />}</Layout>
      ) : (
        Component && <Component />
      )}
    </PageWrapper>
  );

  // not has "access roles" => page is public
  if (!accessRoles) {
    return <Route key={route.id} path={route.path} element={componentUI} />;
  }

  // Un-login route
  if (accessRoles === EAccessRole.UnLoginUser) {
    return (
      <Route
        key={route.id}
        path={route.path}
        element={<UnLoginRoute>{componentUI}</UnLoginRoute>}
      />
    );
  }

  return (
    <Route
      key={route.id}
      path={route.path}
      element={
        <AuthRoute accessRoles={accessRoles} fallbackPath={fallbackPath}>
          {componentUI}
        </AuthRoute>
      }
    />
  );
}

export function AppRouting() {
  return (
    <Routes>
      {appRoutes.map((route) => {
        return renderRoute(route);
      })}
    </Routes>
  );
}
