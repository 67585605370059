import { Button } from "antd";
import { SelectSurveyModal } from "components";
import MESSAGES from "constants/messages";
import { useAppLoading, useAppLocation } from "contexts";
import surveyApi, {
  TGetSurveyDetailByPhaseRes,
} from "features/survey/surveyApi";
import { getFormattedDateFromUTC } from "helpers";
import { showErrorNotification } from "helpers/notification";
import { TSurveyPhaseResponse } from "model/member";
import { EQuestionCategory, ESurveyPhase } from "model/survey";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  MemberDetailsRoute,
  SurveyEditRoute,
  TSurveyDetailUrlParams,
  TrainingProgramRoute,
} from "routers/router";
import { getSurveyTabItems } from "./SurveyDetailPage.helper";
import {
  HeaderStyled,
  SurveyDetailPageWrapper,
  TabsStyled,
} from "./SurveyDetailPage.styled";
import { SURVEY_DETAIL_TRANSLATE } from "./SurveyDetailPage.translate";
import { ArrowLeftIcon } from "constants/svgs";
import { useAppDispatch } from "hooks";
import { TProgramSurvey, programActions } from "features/program/programSlice";
import memberApi from "features/member/memberApi";

const PAGE_TITLE = {
  [ESurveyPhase.PHASE_1]: "アンケート結果（1回目）",
  [ESurveyPhase.PHASE_2]: "アンケート結果（2回目）",
};

const DEFAULT_SELECTED_STEP = "0";

type TSurveyDetailUrlParamsString = {
  [key in keyof TSurveyDetailUrlParams]?: string;
};

const SEARCH_PARAM_NAMES = {
  PHASE_NUMBER: "phaseNumber",
  STEP: "step",
};

function SurveyDetailPage() {
  const { memberId = "" } = useParams<TSurveyDetailUrlParamsString>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedPhase, setSelectedPhase] = useState(ESurveyPhase.PHASE_1);
  const [selectedStep, setSelectedStep] = useState(DEFAULT_SELECTED_STEP);
  const [surveyData, setSurveyData] = useState<TGetSurveyDetailByPhaseRes>({
    phaseBeginner: [],
    phaseIntermediate: [],
  });

  const { backToPrevPage } = useAppLocation();
  const { startLoading, stopLoading } = useAppLoading();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Functions
  const handleBackPreviousPage = () => {
    backToPrevPage([
      MemberDetailsRoute.genPath(memberId),
      ...Array(3)
        .fill(0)
        .map((_, index) =>
          TrainingProgramRoute.genPath({
            memberId,
            phaseNumber: selectedPhase,
            step: index,
          })
        ),
    ]);
  };

  const getSurveyDetail = async (memberId: string) => {
    try {
      startLoading();
      //check authorization of store admin
      const { data: member } = await memberApi.getMemberById(memberId);

      const { data, succeeded } = await surveyApi.getSurveyDetailByMember(
        member.id
      );

      if (succeeded && data) {
        setSurveyData(data);
      }
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  const handleChangePhase = (phase: ESurveyPhase) => {
    if (phase === selectedPhase) return;

    setSelectedPhase(phase);
    setSelectedStep(DEFAULT_SELECTED_STEP);
  };

  const handleClickEditSurvey = async () => {
    try {
      const phaseNumber = searchParams.get(SEARCH_PARAM_NAMES.PHASE_NUMBER);
      const step = searchParams.get(SEARCH_PARAM_NAMES.STEP);

      if (phaseNumber === null || step === null) {
        return;
      }
      //check permission of store admin
      const { data: memberDetail } = await memberApi.getMemberById(memberId);

      if (memberDetail) {
        navigate(
          SurveyEditRoute.genPath({
            memberId,
            phaseNumber: parseInt(phaseNumber),
            step: parseInt(step),
          })
        );
      }
    } catch (error) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    }
  };

  // Effects
  // Get initial data
  useEffect(() => {
    if (memberId) {
      getSurveyDetail(memberId);
    }

    return () => {
      dispatch(programActions.setViewSurvey({ data: false }));
      dispatch(
        programActions.setProgramSurvey({
          data: { programMenu: null, hasOther: "" } as TProgramSurvey,
        })
      );
    };
  }, [memberId]);

  // Update phase/step from search params in first time
  useLayoutEffect(() => {
    const _selectedStep = searchParams.get(SEARCH_PARAM_NAMES.STEP);
    const _selectedPhase = searchParams.get(SEARCH_PARAM_NAMES.PHASE_NUMBER);
    if (
      _selectedPhase &&
      Object.values(ESurveyPhase).includes(parseInt(_selectedPhase))
    ) {
      setSelectedPhase(parseInt(_selectedPhase));
    }

    if (_selectedStep !== null && _selectedStep !== undefined) {
      setSelectedStep(_selectedStep);
    }
  }, []);

  // Change phase/step in search params
  useEffect(() => {
    setSearchParams(
      (prevSearchParams) => {
        prevSearchParams.set(
          SEARCH_PARAM_NAMES.PHASE_NUMBER,
          selectedPhase.toString()
        );
        prevSearchParams.set(SEARCH_PARAM_NAMES.STEP, selectedStep);

        return prevSearchParams;
      },
      { replace: true }
    );
  }, [selectedPhase, selectedStep]);

  // Variables
  const surveyList = useMemo(() => {
    const _surveyList: TSurveyPhaseResponse[] = [];

    const {
      phaseBeginner,
      phaseIntermediate,
      phaseBeginnerUpdatedAt,
      phaseIntermediateUpdatedAt,
    } = surveyData;
    if (phaseIntermediate?.length !== 0) {
      _surveyList.push({
        phaseNumber: ESurveyPhase.PHASE_2,
        updatedAt: getFormattedDateFromUTC(phaseIntermediateUpdatedAt),
      });
    }

    if (phaseBeginner?.length !== 0) {
      _surveyList.push({
        phaseNumber: ESurveyPhase.PHASE_1,
        updatedAt: getFormattedDateFromUTC(phaseBeginnerUpdatedAt),
      });
    }

    return _surveyList;
  }, [surveyData]);

  const surveyTabItems = useMemo(() => {
    return getSurveyTabItems(surveyData, selectedPhase);
  }, [surveyData, selectedPhase]);

  return (
    <SurveyDetailPageWrapper>
      <HeaderStyled>
        <ArrowLeftIcon
          className="icon-arrow-left"
          onClick={handleBackPreviousPage}
        />
        <p className="page-title">{PAGE_TITLE[selectedPhase]}</p>

        <div className="actions-wrapper">
          {surveyList.length > 1 && (
            <SelectSurveyModal
              onSelect={handleChangePhase}
              surveyList={surveyList}
            >
              <Button className="btn-change-survey">
                {SURVEY_DETAIL_TRANSLATE.BTN_CHANGE_SURVEY}
              </Button>
            </SelectSurveyModal>
          )}
          <Button
            type="primary"
            className="btn-edit-survey"
            onClick={handleClickEditSurvey}
          >
            {SURVEY_DETAIL_TRANSLATE.BTN_EDIT}
          </Button>
        </div>
      </HeaderStyled>

      <TabsStyled
        items={surveyTabItems}
        activeKey={selectedStep}
        onChange={(activeKey: string) => {
          setSelectedStep(activeKey as EQuestionCategory);
        }}
      />
    </SurveyDetailPageWrapper>
  );
}

export { SurveyDetailPage };
