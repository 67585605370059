import { FC, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  DashboardFilterFormStyled,
  DatePickerStyled,
  FormStyled,
  SelectStyled,
} from "./DashboardFilterFormForStorestyled";
import { Button, Col, DatePickerProps, Form, Row } from "antd";
import { TFilterDashboardForStoreRequest } from "features/statistic/statisticApi";
import { DownOutlined } from "@ant-design/icons";
import { useHomePageContext } from "pages/HomePage/contexts";
import { FILTER_TRANSLATE } from "./DashboardFilterFormForStore.translate";
import dayjs, { ConfigType } from "dayjs";
import {
  DATE_FORMAT_API,
  DatePickerType,
  MONTH_FORMAT,
  WEEK_FORMAT,
} from "constants/common";
import isoWeek from "dayjs/plugin/isoWeek";

const COL_SPAN_LIST = {
  DATE_PICKER: {
    xs: 24,
    sm: 12,
  },
  GENDER: {
    xs: 8,
    lg: 8,
  },
  AGE: {
    xs: 8,
    lg: 8,
  },
  TYPE_DATE: {
    xs: 8,
    lg: 8,
  },
  DATE: {
    xs: 8,
    lg: 8,
  },
  FILTER_BUTTON: {
    xs: 4,
    lg: 4,
  },
  EXPORT_BUTTON: {
    xs: 4,
    lg: 4,
  },
};

const initialValuesFormFilter: TFilterDashboardForStoreRequest = {
  fromDate: "",
  toDate: "",
  gender: "",
  age: [""],
};

const SEARCH_FIELDS = {
  fromDate: "fromDate",
  toDate: "toDate",
  gender: "gender",
  age: "age",
  typeDate: "typeDate",
  date: "date",
};

const genderOptions = [
  { value: "男性", label: "男性" },
  { value: "女性", label: "女性" },
];

const ageOptions = [
  { value: "10代", label: "10代" },
  { value: "20代", label: "20代" },
  { value: "30代", label: "30代" },
  { value: "40代", label: "40代" },
  { value: "50代", label: "50代" },
  { value: "60代", label: "60代" },
  { value: "70代", label: "70代" },
  { value: "80代", label: "80代" },
];

const typeDateOptions = [
  { value: "week", label: `${FILTER_TRANSLATE.WEEK}` },
  { value: "month", label: `${FILTER_TRANSLATE.MONTH}` },
];

type PickerType = DatePickerType.Week | DatePickerType.Month | undefined;

dayjs.extend(isoWeek);

const DashboardFilterFormForStore: FC = () => {
  const [formFilter] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [typeDate, setTypeDate] = useState<PickerType>();
  const { setFilterForStoreAdmin } = useHomePageContext();

  const customWeekFormat: DatePickerProps["format"] = (value) => {
    return dayjs(value)
      .startOf(DatePickerType.Week)
      .add(1, DatePickerType.Week)
      .format(WEEK_FORMAT);
  };

  const getSearchParamValues = useCallback(() => {
    const age =
      searchParams.getAll(SEARCH_FIELDS.age).length > 0
        ? searchParams.getAll(SEARCH_FIELDS.age)
        : [""];
    const gender = searchParams.get(SEARCH_FIELDS.gender) || "";
    const fromDate = searchParams.get(SEARCH_FIELDS.fromDate) || "";
    const toDate = searchParams.get(SEARCH_FIELDS.toDate) || "";

    const filterValues: TFilterDashboardForStoreRequest = {
      fromDate,
      toDate,
      gender,
      age,
    };
    return filterValues;
  }, [searchParams]);

  const updateFormValueFirstTime = () => {
    const formValues = getSearchParamValues();
    formFilter.setFieldsValue(formValues);
  };

  const filterChart = useCallback(() => {
    const formValues = getSearchParamValues();
    // clear empty string of age
    if (formValues.age.length === 1 && formValues.age[0] === "") {
      formValues.age = [];
    }
    setFilterForStoreAdmin(formValues);
  }, [getSearchParamValues]);

  useEffect(() => {
    setSearchParams({}, { replace: false });
    updateFormValueFirstTime();
    formFilter.resetFields();
  }, []);

  const handleChangeAge = (ageOptions: any) => {
    // if select all then clear other option
    if (ageOptions[ageOptions.length - 1] === "" || ageOptions.length === 0) {
      formFilter.setFieldsValue({
        age: [""],
      });
    } else {
      const ageFilteredValues = ageOptions.filter(
        (value: string) => value !== ""
      );
      formFilter.setFieldsValue({
        age: ageFilteredValues,
      });
    }
  };

  const handleChangeTypeDate = (typeDate: any) => {
    setTypeDate(typeDate);
  };

  const updateFilterSearchParams = (
    filteredValues: TFilterDashboardForStoreRequest
  ) => {
    const ageSelected =
      filteredValues.age.length > 0
        ? filteredValues.age.map((stt) => stt.toString())
        : [];
    setSearchParams(
      (prevSearchParams) => {
        const updatePrevParams = (filedName: string, value?: string) => {
          prevSearchParams.set(filedName, value || "");
        };

        if (ageSelected.length > 0) {
          prevSearchParams.delete(SEARCH_FIELDS.age);
          ageSelected.forEach((stt) => {
            prevSearchParams.append(SEARCH_FIELDS.age, stt);
          });
        }

        updatePrevParams(SEARCH_FIELDS.gender, filteredValues.gender);

        if (filteredValues.date && typeDate) {
          const currentDate = dayjs();
          const fromDateFormat = dayjs(filteredValues.date)
            .startOf(typeDate)
            .format(DATE_FORMAT_API);
          updatePrevParams(SEARCH_FIELDS.fromDate, fromDateFormat);

          let toDateFormat = dayjs(filteredValues.date)
            .endOf(typeDate)
            .format(DATE_FORMAT_API);

          // if toDateFormat after current date then toDateFormat = current date
          if (dayjs(toDateFormat).isAfter(currentDate)) {
            toDateFormat = currentDate.format(DATE_FORMAT_API);
          }
          updatePrevParams(SEARCH_FIELDS.toDate, toDateFormat);
        } else {
          updatePrevParams(SEARCH_FIELDS.fromDate, "");
          updatePrevParams(SEARCH_FIELDS.toDate, "");
        }

        return prevSearchParams;
      },
      { replace: true }
    );
    filterChart();
  };

  const disabledFutureDate = (current: ConfigType) => {
    if (typeDate) {
      const today = dayjs();
      const currentDay = dayjs(current);
      // disable future week
      if (currentDay.isAfter(today.endOf(typeDate))) {
        return true;
      }
      // disable future date in current week
      if (
        currentDay.isSame(today, typeDate) &&
        currentDay.isAfter(today, "day")
      ) {
        return true;
      }
    }
    return false;
  };
  return (
    <DashboardFilterFormStyled>
      <FormStyled
        colon={false}
        onFinish={updateFilterSearchParams}
        form={formFilter}
        initialValues={initialValuesFormFilter}
        layout="vertical"
      >
        <Row gutter={[8, 0]} wrap>
          <Col {...COL_SPAN_LIST.AGE}>
            <Form.Item
              label={FILTER_TRANSLATE.GENDER}
              name={SEARCH_FIELDS.gender}
            >
              <SelectStyled
                options={[
                  { label: FILTER_TRANSLATE.ALL, value: "" },
                  ...genderOptions,
                ]}
                notFoundContent={<div />}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                suffixIcon={<DownOutlined />}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col {...COL_SPAN_LIST.AGE}>
            <Form.Item label={FILTER_TRANSLATE.AGE} name={SEARCH_FIELDS.age}>
              <SelectStyled
                mode="multiple"
                options={[
                  { label: FILTER_TRANSLATE.ALL, value: "" },
                  ...ageOptions,
                ]}
                notFoundContent={<div />}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                suffixIcon={<DownOutlined />}
                showSearch
                onChange={handleChangeAge}
                maxTagCount={"responsive"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 0]} wrap>
          <Col {...COL_SPAN_LIST.TYPE_DATE}>
            <Form.Item
              label={FILTER_TRANSLATE.TYPE_DATE}
              name={SEARCH_FIELDS.typeDate}
            >
              <SelectStyled
                options={[...typeDateOptions]}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                suffixIcon={<DownOutlined />}
                showSearch
                onChange={handleChangeTypeDate}
                placeholder={FILTER_TRANSLATE.SELECT_TYPE_DATE}
              />
            </Form.Item>
          </Col>
          <Col {...COL_SPAN_LIST.DATE}>
            <Form.Item label={FILTER_TRANSLATE.DATE} name={SEARCH_FIELDS.date}>
              <DatePickerStyled
                picker={typeDate}
                disabledDate={disabledFutureDate}
                disabled={!typeDate}
                format={
                  typeDate === DatePickerType.Week
                    ? customWeekFormat
                    : MONTH_FORMAT
                }
              />
            </Form.Item>
          </Col>
          <Col {...COL_SPAN_LIST.FILTER_BUTTON} className="filter-submit">
            <Button type="primary" htmlType="submit" className="btn-filter">
              {FILTER_TRANSLATE.FILTER}
            </Button>
          </Col>
        </Row>
      </FormStyled>
    </DashboardFilterFormStyled>
  );
};

export { DashboardFilterFormForStore };
