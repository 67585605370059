import { AxiosRequestConfig } from "axios";
import { TBodyIndexChartItem } from "components";
import { axiosInstance } from "configs/axiosInstance";
import { EMemberStatus } from "constants/common";
import {
  TBodyIndexInfo,
  TFileFAQsResponse,
  THistoryCheckIn,
  TMember,
  TMemberDetail,
  TMemberListItem,
  TTotalEmployeesResponse,
  TTotalStoreResponse,
} from "model/member";
import { TResponse, TResponseWithPagination } from "types/service";

const memberApi = {
  confirmMember: async (member: TMember) => {
    const url = "/get-member-status";
    const response: TResponse<TConfirmMemberRes> = await axiosInstance.post(
      url,
      member
    );
    return response;
  },

  getMembers: async (params: TMemberParams) => {
    const config: AxiosRequestConfig = {
      params: {
        MemberId: params.MemberId,
        Birth: params.Birth,
        Status: params.Status,
        PageNumber: params.PageNumber,
        PageSize: params.PageSize,
        // StoreNumber: params.StoreNumber,
      },
    };

    const url = "get-all-member";
    const response: TResponseWithPagination<TMemberListItem[]> =
      await axiosInstance.get(url, config);
    return response;
  },

  checkInProgram: async (data: TCheckInParamsReq) => {
    const url = "check-in";
    const response: TResponse = await axiosInstance.post(url, data);
    return response;
  },

  getMemberById: async (memberId: string) => {
    const url = "get-member-by-id";
    const config: AxiosRequestConfig = {
      params: { memberId },
    };

    const response: TResponse<TMemberDetail> = await axiosInstance.get(
      url,
      config
    );

    return response;
  },

  getMemberByJwt: async (data: string) => {
    const url = `get-member-by-id-with-jwt?data=${data}`;

    const response: TResponse<TMemberDetail> = await axiosInstance.get(url);

    return response;
  },

  getHistoryCheckInById: async (memberId: string) => {
    const url = "get-history-check-in";
    const config: AxiosRequestConfig = {
      params: { memberId },
    };

    const response: TResponse<TGetHistoryCheckInRes> = await axiosInstance.get(
      url,
      config
    );

    return response;
  },

  addBodyIndexRecord: async (memberId: string, data: TBodyIndexInfo) => {
    const url = "update-body-index";

    const config: AxiosRequestConfig = {
      params: { id: memberId },
    };

    const response: TResponse<TBodyIndexInfo> = await axiosInstance.put(
      url,
      data,
      config
    );

    return response;
  },

  getBodyIndexById: async (data: TGetBodyIndex) => {
    const url = "get-body-indexs";
    const config: AxiosRequestConfig = {
      params: data,
    };

    const response: TResponseWithPagination<TBodyIndexInfo[]> =
      await axiosInstance.get(url, config);

    return response;
  },

  filterWeightBodyIndexById: async (data: TFilterBodyIndex) => {
    const url = "filter-weight";
    const config: AxiosRequestConfig = {
      params: data,
    };

    const response: TResponseWithPagination<TBodyIndexChartItem[]> =
      await axiosInstance.get(url, config);

    return response;
  },

  filterBodyFatBodyIndexById: async (data: TFilterBodyIndex) => {
    const url = "filter-body-fat";
    const config: AxiosRequestConfig = {
      params: data,
    };

    const response: TResponseWithPagination<TBodyIndexChartItem[]> =
      await axiosInstance.get(url, config);

    return response;
  },

  getTotalStore: async () => {
    const url = "get-total-store";

    const response: TResponseWithPagination<TTotalStoreResponse> =
      await axiosInstance.get(url);

    return response;
  },

  getTotalEmployees: async () => {
    const url = "get-total-employees";

    const response: TResponseWithPagination<TTotalEmployeesResponse> =
      await axiosInstance.get(url);

    return response;
  },

  getFileFAQ: async () => {
    const url = "get-file-FAQs";

    const response: TResponseWithPagination<TFileFAQsResponse> =
      await axiosInstance.get(url);

    return response;
  },

  getHistoryCheckInByJwt: async (data: string) => {
    const url = `get-history-check-in-with-jwt?data=${data}`;

    const response: TResponse<TGetHistoryCheckInRes> = await axiosInstance.get(
      url
    );

    return response;
  },
};

export type TMemberParams = {
  StoreNumber?: string;
  MemberId?: string;
  Birth?: string;
  Status?: number;
  PageNumber: number;
  PageSize: number;
};

export type TConfirmMemberRes = {
  error: null;
  memberId?: string;
  result: boolean;
  status: EMemberStatus;
};

export type TGetHistoryCheckInRes = {
  history: THistoryCheckIn[];
};

export type TCheckInParamsReq = {
  programId: string;
  memberId: string;
  checkInDate: string;
};

export enum EGetMemberError {
  MEMBER_NOT_FOUND = "MemberNotFound",
  FORBIDDEN_STORE = "ForbiddenStore",
  INVALID_REQUEST = "InvalidRequest",
}

export type TGetBodyIndex = {
  memberId: string;
  pageNumber?: number;
  pageSize?: number;
};

export type TFilterBodyIndex = {
  memberId: string;
  year?: number;
  month?: number;
};

export default memberApi;
