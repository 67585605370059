import { AppstoreFilled } from "@ant-design/icons";
import { PERSONAL_INFORMATION_TRANSLATE } from "./PersonalInfoPage.translate";
import { PATHNAME_LIST } from "constants/pathname";
import { ReactNode } from "react";
import { PersonIcon } from "constants/svgs";

type TPersonalInfoMenuItem = {
  key: string;
  title: string;
  iconLeft: ReactNode;
  className: string;
  pathname: string;
};

export const AXTOS_HEADQUARTER_BUTTONS: TPersonalInfoMenuItem[] = [
  // {
  //   key: "store_management",
  //   title: PERSONAL_INFORMATION_TRANSLATE.STORE_MANAGEMENT,
  //   iconLeft: <HomeFilled style={{ color: "#FF6B00", fontSize: "24px" }} />,
  //   className: "firstButton",
  //   pathname: "",
  // },
  // {
  //   key: "survey_master_management",
  //   title: PERSONAL_INFORMATION_TRANSLATE.SURVEY_MASTER_MANAGEMENT,
  //   iconLeft: <FileFilled style={{ color: "#FF6B00", fontSize: "24px" }} />,
  //   className: "",
  //   pathname: "",
  // },
  {
    key: "training_program_master_management",
    title: PERSONAL_INFORMATION_TRANSLATE.TRAINING_PROGRAM_MASTER_MANAGEMENT,
    iconLeft: <AppstoreFilled style={{ color: "#FF6B00", fontSize: "24px" }} />,
    className: "firstButton",
    pathname: PATHNAME_LIST.SHUMOKU_MANAGEMENT,
  },
  {
    key: "shumoku_master_management",
    title: PERSONAL_INFORMATION_TRANSLATE.SHUMOKU_MASTER_MANAGEMENT,
    iconLeft: <PersonIcon style={{ color: "#FF6B00", fontSize: "24px" }} />,
    className: "firstButton",
    pathname: PATHNAME_LIST.SHUMOKU_MASTER_MANAGEMENT,
  },
];

export const STORE_ADMIN_BUTTONS: TPersonalInfoMenuItem[] = [
  // {
  //   key: "store_information",
  //   title: PERSONAL_INFORMATION_TRANSLATE.STORE_INFORMATION,
  //   iconLeft: <HomeFilled style={{ color: "#FF6B00", fontSize: "24px" }} />,
  //   className: "firstButton",
  //   pathname: "",
  // },
  // {
  //   key: "shumoku_list",
  //   title: PERSONAL_INFORMATION_TRANSLATE.SHUMOKU_LIST,
  //   iconLeft: <DatabaseFilled style={{ color: "#FF6B00", fontSize: "24px" }} />,
  //   className: "firstButton",
  //   pathname: "",
  // },
];
