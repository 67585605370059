import { Pie, PieConfig } from "@ant-design/plots";
import {
  LegendItemStyled,
  LegendStyled,
  MemberStatisticDonutChartWrapperStyled,
} from "./MemberStatisticDonutChart.styled";
import { numberWithCommas, toFixedNumber } from "helpers";
import { TMemberStatusDonutChartValue } from "model/statistic";
import { memo } from "react";

type TMemberStatisticDonutChartProps = {
  data: TMemberStatusDonutChartValue[];
  title: string;
};

const DEFAULT_EMPTY_DATA: TMemberStatusDonutChartValue[] = [
  { color: "#D9D9D9", status: "No data", value: 0 },
];

function MemberStatisticDonutChartInternal(
  props: TMemberStatisticDonutChartProps
) {
  const { data, title } = props;

  const total = data.reduce(
    (sum, currentData) => sum + (currentData.value || 0),
    0
  );

  const filteredData = data.filter((val) => !!val.value);
  const dataForDisplay =
    filteredData.length > 0 ? filteredData : DEFAULT_EMPTY_DATA;

  const filteredColorForChartConfigs = dataForDisplay.map((item) => item.color);
  const colorForLegendConfigs = data.map((item) => item.color);

  const config: PieConfig = {
    data: dataForDisplay,
    height: 169,
    width: 169,
    angleField: "value",
    colorField: "status",
    radius: 1,
    innerRadius: 0.6,
    label: false,
    statistic: {
      title: false,
      content: {
        customHtml: () => {
          const displayValue = numberWithCommas(total);

          // Auto wrap line when `displayValue` has more than 3 digits
          return `<div class="member-statistic-content ${
            displayValue.length >= 3 ? "wrap-line" : ""
          }">
            <span class="total-member" style="padding: 0">${displayValue}</span><span class="unit" style="display: inline">人</span>
          </div>`;
        },
      },
    },
    tooltip: false,
    color: filteredColorForChartConfigs,
    legend: false,
    animation: false,
  };

  return (
    <MemberStatisticDonutChartWrapperStyled>
      <h2 className="chart-title">{title}</h2>

      <div className="chart-container">
        <Pie {...config} />

        <LegendStyled>
          {data.map((item, index) => {
            const percent = toFixedNumber(((item.value || 0) * 100) / total);
            return (
              <LegendItemStyled
                key={index}
                color={colorForLegendConfigs[index]}
              >
                <p className="legend-item-title">{item.status}</p>
                <p>
                  <span className="legend-item-vale">
                    {numberWithCommas(item.value || 0)}
                  </span>
                  <span className="legend-item-percent">{percent || 0}%</span>
                </p>
              </LegendItemStyled>
            );
          })}
        </LegendStyled>
      </div>
    </MemberStatisticDonutChartWrapperStyled>
  );
}

const MemberStatisticDonutChart = memo(MemberStatisticDonutChartInternal);

export { MemberStatisticDonutChart };
