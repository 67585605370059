import Input from "antd/es/input/Input";
import { ButtonBackPage, DirectionComponent } from "components";
import { DEFAULT_MODAL_PROPS } from "constants/common";
import { PATHNAME_LIST } from "constants/pathname";
import { ArrowRightIcon } from "constants/svgs";
import { useAppModal } from "contexts";
import { shumokuActions } from "features/shumoku/shumokuSlice";
import { useAppDispatch, useAppSelector, useDebounce } from "hooks";
import { useShumokuContext } from "pages/ShumokuManagementPage/ShumokuContext/ShumokuContext";
import { TRANSLATION } from "pages/ShumokuManagementPage/ShumokuManagementPage.translate";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  SelectedItemStyled,
  StoreFilterStyled,
  StoreSelectionFormStyled,
  StoreSelectionHeaderStyled,
} from "./StoreSelectionForm.styled";
import { Checkbox, Radio, RadioChangeEvent } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const DEBOUNCE_TIMER = 500;
const StoreSelectionForm: FC = () => {
  const { appModal } = useAppModal();
  const { next } = useShumokuContext();
  const { selectedNumber, stores, selectAll } = useAppSelector(
    (state) => state.shumoku
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [_searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedAllStore, setSelectedAllStore] = useState(selectAll);
  const debounceSearch = useDebounce(searchText, DEBOUNCE_TIMER);
  const debounceStatusFilter = useDebounce(statusFilter, DEBOUNCE_TIMER);

  const activeStoreNumber = useMemo(() => {
    return selectedNumber > 0 ? "active" : "";
  }, [selectedNumber]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    setSearchText(value);
  };

  const handleStatusChange = (e: RadioChangeEvent) => {
    setStatusFilter(e.target.value);
  };

  const handleSelectAllStore = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setSelectedAllStore(checked);
    dispatch(shumokuActions.setSelectedAll({ data: checked }));
    if (checked) {
      const updatedIdToSelected = new Set<string>();
      stores.forEach((store) => {
        updatedIdToSelected.add(store.id);
      });
      const totalSelectedStores = updatedIdToSelected.size;
      dispatch(shumokuActions.setSelectedNumber({ data: totalSelectedStores }));
      dispatch(
        shumokuActions.setSelectedStoreIds({ data: [...updatedIdToSelected] })
      );
    } else {
      dispatch(shumokuActions.setSelectedNumber({ data: 0 }));
      dispatch(shumokuActions.setSelectedStoreIds({ data: [] }));
    }
  };

  useEffect(() => {
    setSelectedAllStore(selectAll);
  }, [selectAll]);

  useEffect(() => {
    dispatch(shumokuActions.setSelectedAll({ data: false }));
  }, [stores]);

  const handleNextPage = () => {
    if (selectedNumber === 0) {
      appModal.confirm({
        ...DEFAULT_MODAL_PROPS.CONFIRM,
        title: TRANSLATION.PLEASE_SELECT,
        okText: TRANSLATION.BACK,
        cancelButtonProps: { style: { display: "none" } },
      });
      return;
    }
    next();
  };

  const handleBackPage = () => {
    navigate(PATHNAME_LIST.PERSONAL_INFO, { replace: true });
  };

  useEffect(() => {
    dispatch(shumokuActions.resetStores());

    const newSearchParams = new URLSearchParams(_searchParams.toString());
    if (debounceSearch) {
      newSearchParams.set("keyword", debounceSearch);
    } else {
      newSearchParams.delete("keyword");
    }
    if (debounceStatusFilter) {
      newSearchParams.set("status", debounceStatusFilter);
    } else {
      newSearchParams.delete("status");
    }

    setSearchParams(newSearchParams, { replace: false });
  }, [debounceSearch, debounceStatusFilter]);

  return (
    <StoreSelectionFormStyled>
      <StoreSelectionHeaderStyled>
        <ButtonBackPage
          onBackPage={handleBackPage}
          fallbackPath={PATHNAME_LIST.HOME}
        />

        <SelectedItemStyled>
          {/* <div>{TRANSLATION.SELECT_STORE}</div> */}
          {/* <div className="vertical-bar"></div> */}
          <div className={`selected-store ${activeStoreNumber}`}>
            <span className="seleted-name">{TRANSLATION.STORES_SELECTED}</span>
            <span className="selected-numbers">{selectedNumber}件</span>
          </div>
        </SelectedItemStyled>

        <DirectionComponent
          name={TRANSLATION.NEXT}
          iconNext={<ArrowRightIcon />}
          callback={handleNextPage}
          className="next-btn"
        />
      </StoreSelectionHeaderStyled>

      <Input
        size="large"
        className="search-input"
        allowClear
        onChange={handleChange}
        placeholder={TRANSLATION.PLACE_HOLDER}
      />

      <StoreFilterStyled>
        <Radio.Group onChange={handleStatusChange} value={statusFilter}>
          <Radio value={""}>{TRANSLATION.ALL}</Radio>
          <Radio value={"true"}>{TRANSLATION.ACTIVE_STORE}</Radio>
          <Radio value={"false"}>{TRANSLATION.INACTIVE_STORE}</Radio>
        </Radio.Group>
        <Checkbox checked={selectedAllStore} onChange={handleSelectAllStore}>
          {TRANSLATION.SELECT_ALL}
        </Checkbox>
      </StoreFilterStyled>
    </StoreSelectionFormStyled>
  );
};

export { StoreSelectionForm };
