import { PlusOutlined } from "@ant-design/icons";
import { useSurveyManagementPageContext } from "pages/SurveyManagementPage/contexts";
import { ButtonAddSurveyPartStyled } from "./ButtonAddSurveyPart.styled";

function ButtonAddSurveyPart() {
  const { addNewQuestion, activePartTab } = useSurveyManagementPageContext();

  return (
    <ButtonAddSurveyPartStyled
      onClick={() => addNewQuestion(parseInt(activePartTab))}
    >
      <PlusOutlined />
    </ButtonAddSurveyPartStyled>
  );
}

export { ButtonAddSurveyPart };
