import { DEFAULT_MODAL_PROPS } from "constants/common";
import MESSAGES from "constants/messages";
import { useAppLoading, useAppLocation, useAppModal } from "contexts";
import surveyApi from "features/survey/surveyApi";
import { showErrorNotification } from "helpers/notification";
import { mappingSurveyForSubmit } from "helpers/survey";
import { SurveyLayout, TQuestionByCategory } from "layouts";
import { TSurveyValues } from "model/survey";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SurveyDetailRoute, TSurveyEditUrlParams } from "routers/router";
import {
  getSurveyValuesForEdit,
  groupQuestionsByCategoryForEdit,
} from "./SurveyEditPage.helper";
import { GLOBAL_TRANSLATE } from "constants/translate";
import { useAppDispatch } from "hooks";
import { programActions } from "features/program/programSlice";

const SurveyEditLayout = SurveyLayout<TSurveyValues>;
type TSurveyEditUrlParamsString = {
  [key in keyof TSurveyEditUrlParams]: string;
};

function SurveyEditPage() {
  const [surveyValues, setSurveyValues] = useState<TSurveyValues>();
  const [questionByCategory, setQuestionByCategory] = useState<
    TQuestionByCategory[]
  >([]);

  const { startLoading, stopLoading } = useAppLoading();
  const { appModal } = useAppModal();
  const { backToPrevPage } = useAppLocation();

  const dispatch = useAppDispatch();

  const {
    memberId = "",
    phaseNumber = "",
    step = "0",
  } = useParams<TSurveyEditUrlParamsString>();
  // Handlers
  const submitSurvey = async (values: TSurveyValues) => {
    try {
      startLoading();
      const formattedSurvey = mappingSurveyForSubmit(values, +phaseNumber);

      const { succeeded } = await surveyApi.updateSurvey({
        memberId,
        questions: formattedSurvey,
      });
      if (succeeded) {
        handleBackPage();
      } else {
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      }
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  const handleFinishSurvey = (values: TSurveyValues) => {
    appModal.confirm({
      ...DEFAULT_MODAL_PROPS.CONFIRM,
      title: GLOBAL_TRANSLATE.CONFIRM_FINISH_SURVEY,
      onOk: () => {
        dispatch(programActions.setViewSurvey({ data: false }));
        dispatch(programActions.setIsKeepValue({ data: true }));
        submitSurvey(values);
      },
    });
  };

  const getInitialData = async () => {
    try {
      startLoading();
      const { data: questionList } =
        await surveyApi.getSurveyDetailByPhaseNumber({
          memberId,
          phaseType: +phaseNumber,
        });

      const _questionByCategory = groupQuestionsByCategoryForEdit({
        questionList,
        phase: +phaseNumber,
      });
      const _surveyValues = getSurveyValuesForEdit(questionList, +phaseNumber);
      setQuestionByCategory(_questionByCategory);
      setSurveyValues(_surveyValues);
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  const handleBackPage = () => {
    backToPrevPage(
      SurveyDetailRoute.genPath({
        memberId,
        phaseNumber: parseInt(phaseNumber),
        step: parseInt(step),
      })
    );
  };

  const handleSaveSurvey = (values: TSurveyValues) => {
    setSurveyValues(values);
  };

  // Effects
  useEffect(() => {
    getInitialData();
  }, []);

  const initialStep = Math.min(
    parseInt(step) || 0,
    questionByCategory.length - 1
  );

  return (
    <SurveyEditLayout
      categoryList={questionByCategory}
      onBackPage={handleBackPage}
      onSaveSurvey={handleSaveSurvey}
      surveyValues={surveyValues}
      onFinishSurvey={handleFinishSurvey}
      initialStep={initialStep}
      singleStepMode
    />
  );
}

export { SurveyEditPage };
