import styled from "styled-components/macro";

export const TrainingProgramWrapperStyled = styled.div`
  padding: max(16px, 16rem);
  border: var(--border-config);

  display: flex;
  gap: 12px;
  align-items: center;

  background-color: var(--color-white);
  cursor: pointer;

  .icon-training {
    width: max(48px, 48rem);
    height: max(48px, 48rem);

    background-color: #f5f5f5;
    border: 1px solid #e9e9e9;
    color: var(--color-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: max(18px, 18rem);
      height: max(18px, 18rem);
    }
  }

  .training-info {
    flex: 1;
    color: #17181d;
    line-height: 24px;

    .training-title {
      font-size: var(--font-size-md);
      font-weight: 700;
      overflow-wrap: anywhere;
    }

    .training-end-date {
      font-size: 13px;
    }
  }
`;
