import { Dispatch, FC, SetStateAction } from "react";
import {
  ProgressStepStyled,
  ProgressStepperStyled,
} from "./ProgressStepper.styled";
import { useProgramContext } from "pages/TrainingProgramPage/ProgramContext/ProgramContext";

type StepItemType = {
  id: string;
  label: string;
  content: string;
  stepNumber: number;
};

type ProgressStepperType = {
  stepItems: StepItemType[];
};

type ProgressStepType = {
  label: string;
  content: string;
  stepNumber: number;
  totalStep: number;
  activeStep: number | null | undefined;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};

const ProgressStep: FC<ProgressStepType> = ({
  label,
  content,
  stepNumber,
  totalStep,
  activeStep,
}) => {
  const { handleButtonClick } = useProgramContext();
  const handleClick = () => {
    handleButtonClick?.(stepNumber as number);
  };

  return (
    <ProgressStepStyled onClick={handleClick}>
      <div className="step-item">
        <span className={`label ${activeStep === stepNumber ? "active" : ""}`}>
          {label}
        </span>
        <span
          className={`content ${activeStep === stepNumber ? "active" : ""}`}
        >
          {content}
        </span>
      </div>
      {stepNumber !== totalStep ? <div className="vertical-bar" /> : ""}
    </ProgressStepStyled>
  );
};

const ProgressStepper: FC<ProgressStepperType> = ({ stepItems }) => {
  const { activeStep, setActiveStep } = useProgramContext();
  return (
    <ProgressStepperStyled>
      {stepItems.map((step: StepItemType) => (
        <ProgressStep
          key={step?.id}
          label={step?.label}
          content={step?.content}
          stepNumber={step?.stepNumber}
          activeStep={activeStep}
          setActiveStep={setActiveStep as Dispatch<SetStateAction<number>>}
          totalStep={stepItems.length}
        />
      ))}
    </ProgressStepperStyled>
  );
};

export { ProgressStepper };
