import { Button, Form, Input } from "antd";
import { NotificationModal } from "components/NotificationModal/NotificationModal";
import MESSAGES from "constants/messages";
import { EyeCloseIcon, EyeOpenIcon } from "constants/svgs";
import { useAppLoading } from "contexts";
import { authActions } from "features/auth/authSlice";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "redux/store";
import { PATHNAME_LIST } from "constants/pathname";
import {
  ButtonWrapperStyled,
  EyeIconContainerStyled,
  PasswordContainerStyled,
} from "./ResetPassword.styled";

const TEXT_TRANSLATION = {
  NEW_PASSWORD: "新しいパスワード",
  NEW_PASSWORD_PLACEHOLDER: "新しいパスワードを入力してください",
  RETYPE_PASSWORD: "新しいパスワード（確認）",
  PLACEHOLDER_RETYPE_PASSWORD:
    "確認のために新しいパスワードをもう一度入力してください",
  MESSAGE_NEW_PASSWORD: "パスワードは 8 文字以上で、大文字が 1 文字必要です",
  SUBMIT: "送信",
  PASSWORD_RULE: "パスワードは 8 文字以上で、大文字が 1 文字必要です",
  REPASSWORD_RULE:
    "パスワードの再入力が正しくありません。もう一度お試しください",
  MODAL_TITLE: "パスワードを正常に変更!",
  TEXT_BTN: "ログインに戻る",
};

export const passwordValidator = (_: unknown, value: string) => {
  const TEXT_LENGTH = 8;
  const regexPattern = /[A-Z]/;

  if (value.length < TEXT_LENGTH || !regexPattern.test(value)) {
    return Promise.reject(TEXT_TRANSLATION.PASSWORD_RULE);
  }
  return Promise.resolve();
};

export const confirmPasswordValidator = (
  _: unknown,
  value: string,
  allValues: any
) => {
  if (value !== allValues.newPassword) {
    return Promise.reject(TEXT_TRANSLATION.REPASSWORD_RULE);
  }
  return Promise.resolve();
};

const ResetPasswordPage: FC = () => {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const { startLoading, stopLoading } = useAppLoading();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.auth.loading);

  const [isResetPassword, setIsResetPassword] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputError, setInputError] = useState(false); // Track the input error status

  const refreshToken = searchParams.get("token");
  const email = searchParams.get("email");

  isLoading ? startLoading() : stopLoading();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handeOnSuccess = () => {
    showModal();
  };

  const handleSubmit = async () => {
    const { newPassword, retypePassword } = form.getFieldsValue([
      "newPassword",
      "retypePassword",
    ]);

    if (retypePassword !== newPassword) {
      setInputError(true);
      return;
    }

    dispatch(
      authActions.resetPassword({
        data: {
          email,
          password: newPassword,
          confirmPassword: retypePassword,
          token: refreshToken,
        },
        onSuccess: () => handeOnSuccess(),
      })
    );
  };

  const handleBtnClick = () => {
    navigate(PATHNAME_LIST.LOGIN);
  };

  const handleOnFieldsChange = () => {
    const value = form.getFieldsValue(["newPassword", "retypePassword"]);

    if (value.newPassword && value.retypePassword) {
      setIsResetPassword(false);
    } else {
      setIsResetPassword(true);
    }
  };

  const handleInputChange = () => {
    setInputError(false);
  };
  return (
    <PasswordContainerStyled>
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={handleSubmit}
        onFieldsChange={handleOnFieldsChange}
      >
        <Form.Item label={TEXT_TRANSLATION.NEW_PASSWORD} name="newPassword">
          <Input.Password
            placeholder={TEXT_TRANSLATION.NEW_PASSWORD_PLACEHOLDER}
            iconRender={(visible) => (
              <EyeIconContainerStyled>
                {visible ? <EyeCloseIcon /> : <EyeOpenIcon />}
              </EyeIconContainerStyled>
            )}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={TEXT_TRANSLATION.RETYPE_PASSWORD}
          name="retypePassword"
          validateStatus={inputError ? "error" : ""}
          help={inputError ? MESSAGES["SCR-W05-MSG-002"] : ""}
          className="custom-form-item"
        >
          <Input.Password
            placeholder={TEXT_TRANSLATION.PLACEHOLDER_RETYPE_PASSWORD}
            iconRender={(visible) => (
              <EyeIconContainerStyled>
                {visible ? <EyeCloseIcon /> : <EyeOpenIcon />}
              </EyeIconContainerStyled>
            )}
            allowClear
            onChange={handleInputChange}
          />
        </Form.Item>
        <Form.Item className="verify-button-wrapper">
          <ButtonWrapperStyled>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-item"
              disabled={isResetPassword}
            >
              {TEXT_TRANSLATION.SUBMIT}
            </Button>
          </ButtonWrapperStyled>
        </Form.Item>
      </Form>
      <NotificationModal
        open={isModalOpen}
        onCancel={handleCancel}
        btnContent={TEXT_TRANSLATION.TEXT_BTN}
        title={TEXT_TRANSLATION.MODAL_TITLE}
        handleBtnClick={handleBtnClick}
      />
    </PasswordContainerStyled>
  );
};

export { ResetPasswordPage };
