import { Col, Row } from "antd";
import { useAppSelector } from "hooks";
import { TMajorItem, TShumoku } from "model/shumoku";
import { useCallback, useMemo, useState, FC, useEffect } from "react";
import { ShumokuItemMemo } from "../ShumokuItem/ShumokuItem";
import {
  MajorItemContainerStyled,
  MajorItemStepper,
  ShumokuMasterContentStyled,
} from "./ShumokuMasterContent.styled";

type TProps = {
  changeSelectedIds: (selectedIds: Set<string>) => void;
  handleOpenUpdateForm: (shumoku: TShumoku) => void;
};

const ShumokuMasterContent: FC<TProps> = ({
  changeSelectedIds,
  handleOpenUpdateForm,
}) => {
  const { majorItems } = useAppSelector((state) => state.shumoku);

  const [selectedShumokuIds, setSelectedShumokuIds] = useState<Set<string>>(
    new Set()
  );

  const handleChangeShumokuActive = useCallback((shumokuId: string) => {
    setSelectedShumokuIds((prevSelectedShumokuIds): any => {
      const updatedSelectedShumokuIds = new Set(prevSelectedShumokuIds);
      if (updatedSelectedShumokuIds.has(shumokuId)) {
        updatedSelectedShumokuIds.delete(shumokuId);
      } else {
        updatedSelectedShumokuIds.add(shumokuId);
      }
      return updatedSelectedShumokuIds;
    });
  }, []);

  useEffect(() => {
    changeSelectedIds(selectedShumokuIds);
  }, [selectedShumokuIds]);

  // clear selected id list when refresh shumoku list
  useEffect(() => {
    setSelectedShumokuIds(new Set());
  }, [majorItems]);

  const getMajorItems = useMemo(() => {
    return majorItems.map((item: TMajorItem) => {
      return (
        <MajorItemContainerStyled
          key={item?.majorItemCode}
          id={item?.majorItemCode.replace(/\s/g, "")}
        >
          <MajorItemStepper>
            <span className="content">{item?.majorItemName}</span>
          </MajorItemStepper>
          <Row gutter={[20, 20]}>
            {item?.shumokus?.map((shumoku: TShumoku) => (
              <Col xs={24} sm={12} xl={8} xxl={6} key={shumoku.shumokuCode}>
                <ShumokuItemMemo
                  shumoku={shumoku}
                  majorItem={item}
                  selectedShumokuIds={selectedShumokuIds}
                  handleChangeShumokuActive={handleChangeShumokuActive}
                  handleOpenUpdateForm={handleOpenUpdateForm}
                />
              </Col>
            ))}
          </Row>
        </MajorItemContainerStyled>
      );
    });
  }, [majorItems]);
  return (
    <ShumokuMasterContentStyled>{getMajorItems}</ShumokuMasterContentStyled>
  );
};

export { ShumokuMasterContent };
