import AppLogoImage from "assets/images/app-logo.png";
import MemberDetailBGImage from "assets/images/member-detail-bg.png";
import MemberCofirmImage from "assets/images/member-info.png";
import OnboardingImage from "assets/images/onboarding-image.png";
import PattenMemberDetail from "assets/images/patten-member-detail.png";
import StoreNotFounded from "assets/images/store-not-found.png";

import AppLogoSecondary from "assets/images/app-logo-secondary.png";
import AppLogoGray from "assets/images/app-logo-gray.png";
import LoginImage from "assets/images/login-image.png";
import DumbbellImage from "assets/images/dumbbell.png";
import CheckListImage from "assets/images/check-list.png";
import ProfileInfoHeader from "assets/images/profile-info-header.png";
import AxtoxHeadquarterLogo from "assets/images/axtos-headquarter-logo.png";
import StoreAdminLogo from "assets/images/store-admin-logo.png";
import PersonalInfoAvatarBG from "assets/images/personal-info-avatar-background.png";

import ProgramHeaderImage from "assets/images/program-header-bg.png";
import ThumbnailImage from "assets/svgs/patterns/thumbnail-pattern.svg";
import FreeShumokuImage from "assets/svgs/shumoku-pattern.svg";
import ProgramSection from "assets/images/program-section-bg.png";

import HomePageBG from "assets/images/background/home-page-bg.png";
import HomePageImage from "assets/images/home-page-image.png";

export {
  AppLogoImage,
  OnboardingImage,
  MemberDetailBGImage,
  MemberCofirmImage,
};

const images = {
  LoginImage,
  AppLogoSecondary,
  DumbbellImage,
  CheckListImage,
  AppLogoGray,
  ProgramHeaderImage,
  ThumbnailImage,
  FreeShumokuImage,
  ProfileInfoHeader,
  AxtoxHeadquarterLogo,
  StoreAdminLogo,
  PersonalInfoAvatarBG,
  PattenMemberDetail,
  ProgramSection,
  HomePageImage,
  HomePageBG,
  StoreNotFounded,
};

export default images;
