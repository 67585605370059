import { DEFAULT_PATHNAME } from "constants/pathname";
import { HomePageIcon } from "constants/svgs";
import { Fragment, PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HomePageButtonWrapper } from "./PageWrapper.styled";

type TPageWrapperProps = { title?: string; accessHomePage?: boolean };

const prefix =
  window.__RUNTIME_CONFIG__.NODE_ENV === "development"
    ? "Dev - "
    : window.__RUNTIME_CONFIG__.NODE_ENV === "local"
    ? "Local - "
    : "";

function PageWrapper(props: PropsWithChildren<TPageWrapperProps>) {
  const { title, accessHomePage, children } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(DEFAULT_PATHNAME);
  };
  useEffect(() => {
    document.title = `${prefix}${title || "コミットシステム"}`;
  });

  return (
    <Fragment>
      {children}
      {accessHomePage && (
        <HomePageButtonWrapper className="home-page-btn">
          <HomePageIcon onClick={handleClick} />
        </HomePageButtonWrapper>
      )}
    </Fragment>
  );
}

export { PageWrapper };
